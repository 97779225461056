import FormComponent from "../common/FormComponent";
import {withRouter} from "react-router-dom";
import React from "react";
import {
    connectEtsy,
    getCurrentUserAction
} from "../services/UserService";
import {withSnackbar} from "notistack";
import {setUserToLocalStorage} from "../base/OAuth";

class SetupInstructionsEtsy extends FormComponent {
    constructor() {
        super();

        this.state = {
            etsyConnected: false
        }
    }

    componentDidMount() {
        getCurrentUserAction().then(response => {
            if (!response.ok) {
                return
            }

            setUserToLocalStorage(response.data.user);

            this.setState({
                etsyConnected: !!response.data.user.customer.userCustomerEtsy
            })
        })
    }

    render() {
        return <div className={'setup-instructions'}>

            <h3>Setup Instructions</h3>
            <h4>Let’s get started by following this guide</h4>
            <div className={'steps'}>
                <div className={'item'}>
                    <div className={'step'}>
                        <label className={'stepLabel'}>Step 1</label>
                        <img src={'/images/splogo.png'}/>
                    </div>
                    <div className={'checkpoint'}>
                        <img src={'/images/checked-step.png'}/>
                        <label>Register / Create your Surge Print account</label>
                    </div>
                </div>
                <div className={'item'}>
                    <div className={'step'}>
                        <label className={'stepLabel'}>Step 2</label>
                        <img src={'/images/etsyLogo.png'} />
                    </div>
                    <div className={'checkpoint special'}>
                        {
                            this.state.etsyConnected &&
                            <img src={'/images/checked-step.png'}/>
                        }
                        {
                            !this.state.etsyConnected &&
                            <img src={'/images/unchecked-step.png'}/>
                        }
                        <div className={'helper'}>
                            <label >After clicking on <a
                                onClick={()=> {
                                    connectEtsy().then(response => {
                                        if (!response.ok){
                                            return
                                        }

                                        window.open(response.data, "_self");
                                    })
                                }}
                            >this</a> link, you will be prompted to an Etsy page like the one bellow,
                                where you can give us access to your shop, after clicking on the Allow Access button on the Etsy page,
                                you will be returned to Surge Print</label>

                            <img style={{maxWidth: '500px'}} src={'/images/etsyStep.png'} />
                        </div>
                    </div>
                </div>
                <div className={'item'}>
                    <div className={'step'}>
                        <label className={'stepLabel'}>That's it!</label>
                        <img />
                    </div>
                    <div className={'checkpoint'}>
                        <label>You have successfully connected your Etsy store to Surge Print and now you can submit orders and import & export products.</label>
                    </div>
                </div>
            </div>
        </div>
    }
}


export default withSnackbar(withRouter(SetupInstructionsEtsy));
