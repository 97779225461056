import React, {Component} from 'react'
import AnimateScroll from 'js-animate-scroll';
import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import {Button} from "@material-ui/core";
import strings from '../localization';
import TextField from '@material-ui/core/TextField';
import {hasError} from "../functions/Validation";
import Validators from "../constants/ValidatorTypes";
import {withSnackbar} from "notistack";
import OwlCarousel from "react-owl-carousel";

class Home extends Page {

    validationList = {
        email: [ {type: Validators.REQUIRED } ],
        password: [ {type: Validators.REQUIRED } ],
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            menuOpen : false,
            data: {
                email: null,
                password: null
            },
            howItWork: React.createRef(),
            about: React.createRef()
        };

        this.reportWindowSize = this.reportWindowSize.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.goToHowItWorks = this.goToHowItWorks.bind(this);
        this.goToHowAbout = this.goToHowAbout.bind(this);
        this.goToCatalog = this.goToCatalog.bind(this);
    }

    reportWindowSize(){
        this.setState({
            ...this.state,
            mobile: window.innerWidth < 768
        })
    }

    componentDidMount() {
        if(this.getUser() !== null){
            this.props.history.push('/home');
        }

        window.scrollTo(0, 0);
        this.reportWindowSize();
        window.addEventListener("resize", this.reportWindowSize);

        if (this.props.history.location.state && this.props.history.location.state.goTo){
            let that = this;
            var options = {
                duration: 1000,
                easing: 'linear',
                padding: 0,
                align: 'top',
                onFinish: function () {
                    that.setState({
                        ...this.state,
                        menuOpen : false
                    });
                }
            };
            new AnimateScroll(this.props.history.location.state.goTo, options);
        }
    }

    keyPress(event) {

        if(event.key == 'Enter') {
            this.goToRegistrationPage();
        }
    }

    goToRegistrationPage(){
        if(!this.validate()) {
            this.props.enqueueSnackbar("You need to entered required fields!", {variant: 'error'});
            return;
        }

        this.props.history.push("/registration");
    }

    goToRegistration(){
        this.props.history.push('/registration');
    }

    toggleMenu(){
        this.setState({
            ...this.state,
            menuOpen : !this.state.menuOpen
        })
    }

    goToHowItWorks(){
        let that = this;
        var options = {
            duration: 1000,
            easing: 'linear',
            padding: 0,
            align: 'top',
            onFinish: function () {
                that.setState({
                    ...this.state,
                    menuOpen : false
                });
            }
        };
        new AnimateScroll('#how-it-works', options);
    }

    goToCatalog(){
        let that = this;
        var options = {
            duration: 1000,
            easing: 'linear',
            padding: 0,
            align: 'top',
            onFinish: function () {
                that.setState({
                    ...this.state,
                    menuOpen : false
                });
            }
        };
        new AnimateScroll('#catalog', options);
    }

    goToHowAbout(){
        let that = this;
        var options = {
            duration: 600,
            easing: 'linear',
            padding: 0,
            align: 'top',
            onFinish: function () {
                that.setState({
                    ...this.state,
                    menuOpen : false
                });
            }
        };
        new AnimateScroll('#about', options);
    }

    render() {

        return (
            <div id="new-home">
                <div id={"top-header"} className="header">
                    <div className={'header-help'}>
                        <img className={'logo'} onClick={() => this.props.history.push('/')} src="./images/SurgePrintLogo1.png"></img>
                        {
                            !this.state.mobile &&
                            <div className="menu">
                                <div className="item" onClick={ () => this.goToHowItWorks()}>How it works</div>
                                <div className="item" onClick={ () => this.goToHowAbout()}>About</div>

                                <div className="item" onClick={ () => this.props.history.push("/faq")}>FAQ</div>
                                <div className="item" onClick={ () => this.props.history.push("/registration") }>Start Designing</div>

                                <div className="item" onClick={()  => {
                                    this.props.history.push('/contact');
                                }}>Contact</div>
                                <a className="item" href="https://tidycal.com/g1w5zr1" target="_blank">Chat with Sales</a>
                            </div>
                        }
                        {
                            !this.state.mobile &&
                            <div className="buttons">
                                <Button className="login" onClick={ () => this.props.history.push('/login') }>LOGIN</Button>
                                <Button className="sign-up" onClick={ () => this.props.history.push('/registration')}>SIGN UP</Button>
                            </div>
                        }
                        {
                            this.state.mobile &&
                            <button onClick={()=> {
                                this.toggleMenu();
                            }} className={this.state.menuOpen ? "hamburger hamburger--collapse is-active" : "hamburger hamburger--collapse"} type="button">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        }
                        {
                            this.state.mobile &&
                            <div className={this.state.menuOpen ? 'mobile-home-menu active' : 'mobile-home-menu'}>
                                <div className="menu">
                                    <div className="item" onClick={ () => this.goToHowItWorks()}>How it works</div>
                                    <div className="item" onClick={ () => this.goToHowAbout()}>About</div>
                                    <div className="item" onClick={ () => this.props.history.push('/registration')}>Start Designing</div>
                                    <div className="item" onClick={()  => {
                                        this.props.history.push('/contact');
                                    }}>Contact</div>
                                </div>
                                <div className="buttons">
                                    <Button className="login" onClick={ () => this.props.history.push('/login') }>LOGIN</Button>
                                    <Button className="sign-up" onClick={ () => this.props.history.push('/registration')}>SIGN UP</Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="baner">
                    <div className="text">
                        <div className="text-header">
                            Create and sell
                            custom products
                        </div>
                        <div className="text-contect">
                            <span className="checkmark-text"><span className="checkmark"></span>100% FREE to use</span>
                            <span className="checkmark-text"><span className="checkmark"></span>300+ High-Quality Products for you to customize</span>
                            <span className="checkmark-text"><span className="checkmark"></span>Sell globally, produce locally with our global print network</span>
                            <span className="checkmark-text"><span className="checkmark"></span>Get access to 20+ marketplaces and e-commerce platforms</span>
                        </div>

                        <Button className="designing" onClick={() => this.props.history.push('/registration')}>Start Designing</Button>
                    </div>
                    <img className="hero-animation" src="./images/hero-animation.gif"></img>
                </div>
                <div id={'how-it-works'} className="how-it-works" ref={this.state.howItWork}>
                    <div className="header"> 
                        <div className="name">How it works</div>
                        <div className="padding"></div>
                    </div>
                    <div className="row">
                        {
                            this.state.mobile &&
                            <OwlCarousel
                                margin={10}
                                items={1}
                                nav
                                stagePadding={50}
                            >
                                <div className="item">
                                    <div className="image">
                                        <img src="./images/how-it-work1.png"></img>
                                    </div>
                                    <div className="header">
                                        Your Store
                                        <div className="padding"></div>
                                    </div>
                                    <div className="item-text" style={{fontWeight: 'bold'}}>
                                        Connect your online store to Surge Print, simply link your products or create products from our catalog and add your designs.
                                        A customer then makes a purchase on your store.
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <img src="./images/how-it-work2.png"></img>
                                    </div>
                                    <div className="header">
                                        Production Time
                                        <div className="padding"></div>
                                    </div>
                                    <div className="item-text" style={{fontWeight: 'bold'}}>
                                        That order is automatically synced and our proprietary smart routing system will dynamically route it to the closest manufacturing facility to your customer in order to reduce shipping costs.

                                        We will print, pack and ship your order on demand.
                                        You will get a tracking number as soon as the order is shipped.
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <img src="./images/how-it-work3.png"></img>
                                    </div>
                                    <div className="header">
                                        <div>Warehouse optional services</div>
                                        <div className="padding"></div>
                                    </div>
                                    <div className="item-text" style={{fontWeight: 'bold'}}>
                                        You can store these products in one of 4 modern warehouses located in the US, China, EU and Non-EU strategic locations
                                        and you'll never again need to worry about production delays, holiday cut-offs and out of stock nightmares!

                                        We can also store your handmade items or items which were produced by your vendors.
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <img src="./images/how-it-work4.png"></img>
                                    </div>
                                    <div className="header">
                                        Your Customer
                                        <div className="padding"></div>
                                    </div>
                                    <div className="item-text" style={{fontWeight: 'bold'}}>
                                        Your customers will receive their orders as fast
                                        as possible in a package with your branding!
                                    </div>
                                </div>
                            </OwlCarousel>
                        }
                        {
                            !this.state.mobile &&
                            <div className={'desktop-container'}>
                                <div className="item">
                                    <div className="image">
                                        <img src="./images/how-it-work1.png"></img>
                                    </div>
                                    <div className="header">
                                        Your Store
                                        <div className="padding"></div>
                                    </div>
                                    <div className="item-text" style={{fontWeight: 'bold'}}>
                                        Connect your online store to Surge Print, simply link your products or create products from our catalog and add your designs.
                                        A customer then makes a purchase on your store.
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <img src="./images/how-it-work2.png"></img>
                                    </div>
                                    <div className="header">
                                        Production Time
                                        <div className="padding"></div>
                                    </div>
                                    <div className="item-text" style={{fontWeight: 'bold'}}>
                                        That order is automatically synced and our proprietary smart routing system will dynamically route it to the closest manufacturing facility to your customer in order to reduce shipping costs.

                                        We will print, pack and ship your order on demand.
                                        You will get a tracking number as soon as the order is shipped.
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <img src="./images/how-it-work3.png"></img>
                                    </div>
                                    <div className="header">
                                        <div>Warehouse optional services</div>
                                        <div className="padding"></div>
                                    </div>
                                    <div className="item-text" style={{fontWeight: 'bold'}}>
                                        You can store these products in one of 4 modern warehouses located in the US, China, EU and Non-EU strategic locations
                                        and you'll never again need to worry about production delays, holiday cut-offs and out of stock nightmares!

                                        We can also store your handmade items or items which were produced by your vendors.
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <img src="./images/how-it-work4.png"></img>
                                    </div>
                                    <div className="header">
                                        Your Customer
                                        <div className="padding"></div>
                                    </div>
                                    <div className="item-text" style={{fontWeight: 'bold'}}>
                                        Your customers will receive their orders as fast
                                        as possible in a package with your branding!
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={"video"}>
                    <div className="header">
                        <div className={"name"}>Video</div>
                        <div className="padding"></div>
                    </div>

                    <iframe src="https://www.youtube.com/embed/zgIRE8N6zDE" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div id={'about'} className="about-us" ref={this.state.about}>
                    <div className={'top-white'}></div>
                    <div className={'cont'}>
                        <img src="./images/section 33_image.png">
                        </img>
                        <div className="content">
                            <div className="header">About Us</div>
                            <div className="padding"></div>

                            <div className="sub-header">
                                Customized fulfillment services for print on demand businesses
                            </div>

                            <div className="text">
                                Surge Print is the first eCommerce platform built from the ground up for direct-to-customer brands.
                                Now, 7 years later, our partners can source products (DTG&screen printing, embroidery, full dye sublimation, and laser printing), use our on-demand warehousing network to store their goods ( US, China, EU and Non-Eu options available) while they focus on growing their brand!
                            </div>

                            <div className="text1">
                                Our individual solutions are tailored specifically to your needs
                            </div>

                            <div className="text2">
                                It really is that simple - our customers can choose between in house and specialized manufacturers which were vetted by our Product team. They can print products on demand or choose to store them in our facilities. With more than 40 printing facilities across the world, 4 warehouses on 3 continents and 10+ reliable shipping partners - we can do whatever your brand needs to scale.
                            </div>

                            <div className="text3">
                                Get in touch and learn how over 12  years of experience in product sourcing, distribution and fulfilment services can help your business grow.
                            </div>

                            <Button onClick={()=> this.props.history.push('/contact')} className="contact">Contact</Button>
                        </div>
                    </div>
                </div>
                <div id={"catalog"} className="product-catalog">
                    <div className="header"> 
                        <div className="name">
                            Premium products you can customize and sell
                            <div className="padding"></div>
                        </div>
                    </div>
                    <div className={'rows'}>
                        <div className="item">
                            <div className={"img-cont"} onClick={()=> this.props.history.push('/registration')}>
                                <img src="./images/1_T-shirts_from_5.99.jpg"></img>
                            </div>
                            <div className="desc">
                                <div className="name">T-Shirts</div>
                                <div className="price">From $4.99</div>
                            </div>
                        </div>

                        <div className="item">
                            <div className={"img-cont"} onClick={()=> this.props.history.push('/registration')}>
                                <img src="./images/2_Mugs_from_3.99.jpg"></img>
                            </div>
                            <div className="desc">
                                <div className="name">Mugs</div>
                                <div className="price">From $3.49</div>
                            </div>
                        </div>

                        <div className="item">
                            <div className={"img-cont"} onClick={()=> this.props.history.push('/registration')}>
                                <img src="./images/3_Hoodies_from_9.99.jpg"></img>
                            </div>
                            <div className="desc">
                                <div className="name">Hoodies</div>
                                <div className="price">From $9.99</div>
                            </div>
                        </div>

                        <div className="item">
                            <div className={"img-cont"} onClick={()=> this.props.history.push('/registration')}>
                                <img src="./images/4 Canvas Wraps from 4.99.jpg"></img>
                            </div>
                            <div className="desc">
                                <div className="name">Canvas Wraps</div>
                                <div className="price">From $4.99</div>
                            </div>
                        </div>

                        <div className="item">
                            <div className={"img-cont"} onClick={()=> this.props.history.push('/registration')}>
                                <img src="./images/5._Tote_Bag_from_7.99.jpg"></img>
                            </div>
                            <div className="desc">
                                <div className="name">Tote Bag</div>
                                <div className="price">From $7.99</div>
                            </div>
                        </div>

                        <div className="item">
                            <div className={"img-cont"} onClick={()=> this.props.history.push('/registration')}>
                                <img src="./images/6.Phone_Cases_from_9.99.jpg"></img>
                            </div>
                            <div className="desc">
                                <div className="name">Phone Cases</div>
                                <div className="price">From $9.99</div>
                            </div>
                        </div>

                        <div className="item">
                            <div className={"img-cont"} onClick={()=> this.props.history.push('/registration')}>
                                <img src="./images/7._FaceMasks_from_3.99.jpg"></img>
                            </div>
                            <div className="desc">
                                <div className="name">FaceMasks</div>
                                <div className="price">From $3.99</div>
                            </div>
                        </div>

                        <div className="item">
                            <div className={"img-cont"} onClick={()=> this.props.history.push('/registration')}>
                                <img src="./images/8_Pillow_from_8.99.jpg"></img>
                            </div>
                            <div className="desc">
                                <div className="name">Pillow</div>
                                <div className="price">From $8.99</div>
                            </div>
                        </div>

                        <div className="item">
                            <div className={"img-cont"} onClick={()=> this.props.history.push('/registration')}>
                                <img src="./images/9_Tank_Tops_from_5.99.jpg"></img>
                            </div>
                            <div className="desc">
                                <div className="name">Tank Tops</div>
                                <div className="price">From $5.99</div>
                            </div>
                        </div>
                        <div className="item">
                            <div className={"img-cont"} onClick={()=> this.props.history.push('/registration')}>
                                <img src="./images/10_Blankets_from_12.99.jpg"></img>
                            </div>
                            <div className="desc">
                                <div className="name">Blankets</div>
                                <div className="price">From $12.99</div>
                            </div>
                        </div>
                    </div>
                    <div className="header sub-header"> 
                        <div className="name"><Button className="sign-up" onClick={() => this.props.history.push('/registration')}>SIGN UP</Button> to see the full list of products</div>
                    </div>
                    <div className="why-choose-us">
                        <div className="header">
                            <div className="name">Why Choose US?</div>
                            <div className="padding">
                            </div>
                        </div>
                        <div className={'helper'}>
                            <div className="why-content">
                                <div className="reasons">

                                    <div className="item">
                                        <div className="item-header">
                                            <img className="image-header" src="./images/tick1.png"></img>
                                            <div className="item-header">
                                                <div className="item-header-text" style={{fontWeight: 'bold'}}>
                                                    No Sign Up or Monthly fees
                                                </div>
                                                <div className="padding"></div>
                                            </div>
                                        </div>
                                        <div className="item-text" style={{fontWeight: 'bold'}}>
                                            Surge Print is free and always will be! There are no hidden costs.
                                        </div>
                                    </div>


                                    <div className="item">
                                        <div className="item-header">
                                            <img className="image-header" src="./images/tick1.png"></img>
                                            <div className="item-header">
                                                <div className="item-header-text" style={{fontWeight: 'bold'}}>
                                                    Print od Demand drop shipping
                                                </div>
                                                <div className="padding"></div>
                                            </div>
                                        </div>
                                        <div className="item-text" style={{fontWeight: 'bold'}}>
                                            Sell your designs on 100s of products. We'll print, pack, and ship everything on demand - you only pay when an order comes in.
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="item-header">
                                            <img className="image-header" src="./images/tick1.png"></img>
                                            <div className="item-header">
                                                <div className="item-header-text" style={{fontWeight: 'bold'}}>
                                                    Products and Mockups
                                                </div>
                                                <div className="padding"></div>
                                            </div>
                                        </div>
                                        <div className="item-text" style={{fontWeight: 'bold'}}>
                                            Link existing products and stores to us or choose from more than 200 products. Use our state-of-the-art mock up generator and create amazing product previews.
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="item-header">
                                            <img className="image-header" src="./images/tick1.png"></img>
                                            <div className="item-header">
                                                <div className="item-header-text" style={{fontWeight: 'bold'}}>
                                                    No Order minimums and
                                                    Volume Discounts!
                                                </div>
                                                <div className="padding"></div>
                                            </div>
                                        </div>
                                        <div className="item-text" style={{fontWeight: 'bold'}}>
                                            We can print 1 or 10,000 items per order, there are no limits! If you are selling 100s of items per month, let us know and we'll create custom discounted pricing just for you.
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="item-header">
                                            <img className="image-header" src="./images/tick1.png"></img>
                                            <div className="item-header">
                                                <div className="item-header-text" style={{fontWeight: 'bold'}}>
                                                    Warehousing and Fulfillment
                                                </div>
                                                <div className="padding"></div>
                                            </div>
                                        </div>
                                        <div className="item-text" style={{fontWeight: 'bold'}}>
                                            Store your products at our warehouses. When you get an order, we will ship it in less than 24 hours!
                                        </div>
                                    </div>


                                    <div className="item">
                                        <div className="item-header">
                                            <img className="image-header" src="./images/tick1.png"></img>
                                            <div className="item-header">
                                                <div className="item-header-text" style={{fontWeight: 'bold'}}>
                                                    Support and tools
                                                </div>
                                                <div className="padding"></div>
                                            </div>
                                        </div>
                                        <div className="item-text" style={{fontWeight: 'bold'}}>
                                            Tired of waiting hours, if not days, for a simple answer? We're not that kind of a company. With us you'll get 24/7 customer support and a dedicated Account Manager to help you scale up. Connect existing products, Manual order form, CSV import, Flat Shipping Rates, Inventory Manager are some of the tools our partners can use.
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="create-acc" id="my-input">
                                <div className="create-acc-header">
                                    <div className="create-acc-header-text">Create an Account for</div>
                                    <div className="padding"></div>
                                    <div className="create-acc-header-free">FREE</div>
                                </div>
                                <TextField
                                    className={"text-field"}
                                    error={ hasError(this.state.errors, 'email') }
                                    fullWidth
                                    autoFocus
                                    name='email'
                                    onChange={ this.changeData }
                                    onKeyPress={ this.keyPress }
                                    margin="normal"
                                    value={ this.state.data.email }
                                    placeholder = { strings.login.email }
                                    variant="outlined"
                                />
                                <TextField
                                    className={"text-field"}
                                    error={ hasError(this.errors, 'password') }
                                    fullWidth
                                    autoFocus
                                    name='password'
                                    type="password"
                                    onChange={ this.changeData }
                                    onKeyPress={ this.keyPress }
                                    margin="normal"
                                    value={ this.state.data.password }
                                    placeholder = { strings.login.password }
                                    variant="outlined"
                                />
                                <Button className="contact" onClick={() => this.props.history.push('/registration')}>Sign up</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="footer-content">
                        <div className="footer-logo">
                            <img src="./images/SurgePrintLogo11.png"></img>
                        </div>
                        {/* <div className="about">
                            <div className="option" onClick={ () => this.goToHowAbout()}>About</div>
                            <div className="option" onClick={ () => this.goToHowItWorks()}>How it Works</div>
                            <div className="option" onClick={ () => this.goToCatalog()}>Product catalog</div>
                        </div>

                        <div className="about two">
                            <div className="option" onClick={()  => {
                                this.props.history.push('/contact');
                            }}>Contact us</div>
                            <div className="option" onClick={ () => this.props.history.push("/privacy-policy")}>Private Policy</div>
                            <div className="option" onClick={ () => this.props.history.push("/terms-of-service")}>Terms Of Service </div>
                            <div className="option" onClick={ () => this.props.history.push("/faq")}>FAQ</div>
                        </div> */}

                        <div className={'about contact-us'}>
                            <p style={{fontWeight: 'bold'}}>Surge Print offers support 24 hours a day for 7 days a week via email : support@surgeprint.com</p>
                            <p >For Sales or Urgent inquires call us at +1 (307) 392-4806</p>
                            <p >working hours: Monday - Friday, 05 - 17 EST</p>
                            <p >We'd love to hear from you!</p>
                        </div>

                        <div className="payment">
                            <div className="carriers">Shipping carriers:</div>
                            <div className="carriers-options">
                                <img src="./images/carrier1.png"></img>
                                <img src="./images/carrier2.png"></img>
                                <img src="./images/carrier3.png"></img>
                                <img src="./images/fedex(2).png"></img>
                            </div>

                            <div className="carriers">Payment methods:</div>

                            <div className="carriers-options">
                                <img className="stripe" src="./images/stripe1.png"></img>
                                <img src="./images/maestro-light-noborder.png"></img>
                                <img src="./images/mastercard-light-noborder.png"></img>
                                <img src="./images/paypal-light-noborder.png"></img>
                                <img src="./images/union-pay-light-noborder.png"></img>
                                <img src="./images/visa-light-noborder.png"></img>
                                <img src="./images/american-express-light-noborder.png"></img>
                                <img src="./images/diners-club-light-noborder.png"></img>
                                <img src="./images/discover-light-noborder.png"></img>
                                <img src="./images/jcb-light-noborder.png"></img>
                            </div>
                        </div>
                    </div>

                    <div className="footer-rights"><div className="text">©Surge Print. All rights reserved</div></div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers };
}

export default  withSnackbar(withRouter(Home));
