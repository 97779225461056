import Page from "../common/Page";
import {withRouter} from "react-router-dom";
import React from 'react'
import {
    getTemplatesForUser,
    importProductsFromShopify,
    deleteCustomerTemplate, importProductsFromEtsy, getImportedProduct
} from "../services/admin/ProductVariationService";
import {Box, Button, Tab, Tooltip} from "@mui/material";
import {addItemsToShoppingCart} from "../services/ShoppingCart";
import AttributeType from "../constants/AttributeType";
import {withSnackbar} from "notistack";
import Store from '../constants/Store';
import {getActiveStores, getUserFromLocalStorage} from "../base/OAuth";
import { addNewItemsToOrder } from "../services/OrderService";
import UserType from "../constants/UserType";
import SelectControl from "../components/controls/SelectControl";
import {getAllCustomers} from "../services/UserService";
import ReactDOM from 'react-dom';
import _ from "lodash";
import {TabContext, TabList, TabPanel} from "@mui/lab";


class ProductTemplates extends Page {

    constructor(props) {
        super(props);

        const stores = getActiveStores();

        this.state = {
            page: 1,
            perPage: 12,
            variations: [],
            selectedTemplates: [],
            data: {
                customer: null,
                keywords: '',
            },
            stores: {
                shopify: stores.shopify,
                etsy: stores.etsy
            },
            shopifyTab: '1',
            etsyTab: '1',
            selectedTemplate: null,
            currentTab: this.props.match.params.store ?? Store.storage,
            importedProducts: [],
            importedProductPage: 1,
            orderId: props.match.params.id ? props.match.params.id : null,
            customerId: props.match.params.customerId ? props.match.params.customerId : null,
            owlItems: 6,
            templateSelectCount : 0,
            userType: getUserFromLocalStorage().user_type,
            dropdownOpen: null,
            errors: [],
            selectedImportedProduct: null,
            loaderActive: true,
            fromPage: props.history.location.state?.fromPage === "placeAnOrder"
        };

        this.reportWindowSize = this.reportWindowSize.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.changeShopifyTab = this.changeShopifyTab.bind(this);
        this.changeEtsyTab = this.changeEtsyTab.bind(this);
        this.changeCheckBoxTemplate = this.changeCheckBoxTemplate.bind(this);
        this.changeCheckboxVariation = this.changeCheckboxVariation.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.search = _.debounce(this.search, 1000)
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);

        if (this.state.dropdownOpen) {
            if (event.target.className != "opt" && event.target.className != "dropdown-opt") {
                event.stopPropagation();
                this.setState({
                    ...this.state,
                    dropdownOpen: false
                });
            }
        }
    }

    reportWindowSize() {
        if (window.innerWidth < 992 && window.innerWidth >= 768 && this.state.owlItems !== 2) {
            this.setState({
                ...this.state,
                owlItems: 1
            })
        } else if (window.innerWidth < 1220 && window.innerWidth >= 992 && this.state.owlItems !== 4) {
            this.setState({
                ...this.state,
                owlItems: 3
            })
        } else if (window.innerWidth >= 1220 && this.state.owlItems !== 6) {
            this.setState({
                ...this.state,
                owlItems: 6
            })
        }
    }

    componentDidMount() {
        this.getTemplates();

        if (this.state.userType === UserType.ADMIN) {
            this.getAllCustomers();
        }

        this.reportWindowSize();
        window.addEventListener("resize", this.reportWindowSize);
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    getAllCustomers() {
        getAllCustomers().then(response => {

            if (!response.ok) {
                return;
            }

            if (this.state.customerId) {
                let orderCustomer = null;
                for (let customer of response.data) {
                    if (customer.id == this.state.customerId) {
                        orderCustomer = customer;
                        this.state.data.customer = orderCustomer;
                        this.getTemplates();
                        break;
                    }
                }
            }

            this.setState({
                ...this.state,
                customers: response.data,
                loaderActive: false
            })
        });
    }

    changeData(event, data = 'data') {
        if (event.target) {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [event.target.name]: event.target.value
                },
                page: 1
            });
        }


        setTimeout(() => {
            if (this.state.currentTab != Store.storage) {
                this.getImportedProductFromPlatform();
            }

            this.getTemplates();

        }, 100);
    }

    search = (event) =>  {
        this.changeData(event)
    }

    searchDebounced(event) {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [event.target.name]: event.target.value
            },
            page: 1
        });

        this.search(event)
    }

    getTemplates() {
        this.setState({
            ...this.state,
            loaderActive: true
        });

        getTemplatesForUser(
            this.state.page,
            {
                shopifyExported: this.state.currentTab === Store.shopify,
                etsyExported: this.state.currentTab === Store.etsy,
                customerId: this.state.data.customer ? this.state.data.customer.id : null,
                storage: this.state.currentTab === Store.storage,
                keywords: this.state.data.keywords
            }).then(response => {

            if (!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                // importedProducts : [],
                variations: response.data.templateArr,
                page: +response.data.paginationInfo.page,
                totalPages: Math.ceil(response.data.paginationInfo.total / response.data.paginationInfo.perPage),
                perPage: response.data.paginationInfo.perPage,
                loaderActive: false
            })
        })
    }

    changeTab(store) {
        if (!this.state.loaderActive) {
            this.setState({
                ...this.state,
                importedProductTotalPages: 0,
                importedProductPage: 1,
                importedProducts: [],
                page: 1,
                currentTab: store,
                selectedTemplate: null,
            }, () => {
                this.getImportedProductFromPlatform();
                this.getTemplates();
            });
        }
    }

    getImportedProductFromPlatform() {
        if (this.state.currentTab === Store.storage) {
            return;
        }

        getImportedProduct({
            page: this.state.importedProductPage,
        }, this.state.currentTab).then(response => {
            if (!response.ok) {
                return
            }

            const data = response.data;
            const header = response.headers;

            this.setState({
                ...this.state,
                loader : false,
                importedProducts: data,
                importedProductTotalPages: Number.parseInt(header['pagination-total-pages']),
                importedProductPage: Number.parseInt(header['pagination-page'])
            })
        })
    }

    importProducts(store) {
        if (getUserFromLocalStorage().roles[0] != "ROLE_CUSTOMER") {
            this.props.enqueueSnackbar("Admin can't import products", {variant: 'error'});
            return;
        }

        this.setState({
            ...this.state,
            loader : true
        });

        if (store === Store.shopify) {
            this.importShopifyProducts();
        } else if (store === Store.etsy) {
            this.importEtsyProducts();
        }
    }

    importShopifyProducts() {
        importProductsFromShopify().then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar("You need to connect your Shopify store!", {variant: 'error'});
                return;
            }

            if (response.data > 0) {
                this.props.enqueueSnackbar("Loading is in progress, we found " + response.data + " products in your Shopify store!", {variant: 'success', autoHideDuration: 10000});
            }

            this.setState({
                ...this.state,
                loader: false
            });

            this.state.syncImported = false;
        });
    }

    importEtsyProducts() {
        importProductsFromEtsy().then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar("You need to connect your Etsy store!", {variant: 'error'});
                return;
            }

            if (response.data > 0) {
                this.props.enqueueSnackbar("Loading is in progress, we found " + response.data + " products in your Etsy store!", {variant: 'success', autoHideDuration: 10000});
            }

            this.setState({
                ...this.state,
                loader: false
            });

            this.state.syncImported = false;
        });
    }

    changeShopifyTab(value) {
        this.setState({
            ...this.state,
            shopifyTab: value
        });
    }

    changeEtsyTab(value) {
        this.setState({
            ...this.state,
            etsyTab: value
        });
    }

    sync(productId, store) {
        localStorage.setItem('storeImportedFrom', store)
        this.props.history.push('/import-products/' + store + '/' + productId)
    }

    selectImportedProductForSync(id) {
        this.setState({
            ...this.state,
            selectedImportedProduct: id
        });
    }

    changeCheckBoxTemplate(e) {
        let templateId = e.target.name.replace(/\D/g, '');

        let variations = [];

        this.state.variations.forEach(items => {
            if (items.template.id !== +templateId) {
                return
            }

            variations = items.templateVariations;
        })

        let data = this.state.data;

        variations.forEach(variation => {
            data['customerVariation' + variation.customerVariation.id] = !data['customerVariation' + variation.customerVariation.id];
        })

        data['template' + templateId] = !data['template' + templateId];

        let count = this.state.templateSelectCount;

        if (data['template' + templateId]){
            count += 1;
        }else {
            count -= 1;
        }

        this.setState({
            data,
            templateSelectCount : count
        });
    }

    changeCheckboxVariation(e) {
        let variationId = e.target.name.replace(/\D/g, '');

        let data = this.state.data;

        data['customerVariation' + variationId] = !data['customerVariation' + variationId];

        this.setState({
            data
        });
    }

    renderSubVariations(i) {
        let render = [];

        this.state.variations[i].templateVariations.forEach(item => {
            render.push(
                <div key={item.customerVariation.id} className={'sub-card'}>
                    <span
                        className={'name'}>{item.customerVariation.product_variation.name ? item.customerVariation.product_variation.name : 'No Name'}</span>
                    <img src={item.image ? process.env.REACT_APP_baseUrl + item.image.merged_image_url : ''}/>
                    <input
                        checked={this.state.data['customerVariation' + item.customerVariation.id]}
                        className={'template-cb'}
                        type={'checkbox'}
                        name={'customerVariation' + item.customerVariation.id}
                        onChange={this.changeCheckboxVariation}
                        style={{opacity: this.state.data['customerVariation' + item.customerVariation.id] ? 1 : 0}}
                    />
                    <div className={'attributes'}>
                        {this.renderAttributesSub(item.values)}
                    </div>
                </div>
            )
        })

        return render;
    }

    renderAttributesSub(attributes) {
        let render = [];

        if (!attributes) return;
        attributes.forEach(attribute => {
            render.push(
                <div key={attribute[0].id} className={'attribute'}>
                    <span className={'attribute-name'}>{attribute[0].attribute_value.attribute.name}</span>
                    <div className={'attribute-values'}>
                        {this.renderAttributeValuesSub(attribute)}
                    </div>
                </div>
            )
        })

        return render;
    }

    renderAttributeValuesSub(values) {
        let render = [];

        values.forEach(item => {
            render.push(
                <div key={item.id} className={'attribute-value'}>
                    {
                        item.attribute_value.attribute.attribute_type === AttributeType.STRING &&
                        <span className={'value-string'}>{item.attribute_value.name}</span>
                    }
                    {
                        item.attribute_value.attribute.attribute_type === AttributeType.COLOR &&
                        <Tooltip title={item.attribute_value.customer_color_name}>
                            <span className={'attribute color'} style={{backgroundColor : item.attribute_value.name}} />
                        </Tooltip>
                    }
                </div>
            )
        });

        return render;
    }

    renderVariations(index, count) {
        let render = [];


        for (let i = (index + 1) - count; i <= index; i++) {
            if (this.state.variations[i].templateVariations.length === 0) {
                continue
            }

            render.push(
                <div key={this.state.variations[i].template.id} className={'template-card-container hidden'}
                     id={this.state.variations[i].template.id}>
                    {
                        this.renderSubVariations(i)
                    }
                </div>
            )
        }

        return render;
    }

    showTemplateVariation(e, id) {
        if (e.target.type === 'checkbox' || !document.getElementById(id)) {
            return
        }

        let elements = document.getElementsByClassName('template-card-container');

        if (document.getElementById(id).classList.length === 2) {
            document.getElementById(id).classList.remove('hidden');

            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.add('hidden')
            }

            document.getElementById(id).classList.remove('hidden');

            this.setState({
                ...this.state,
                selectedTemplate: id
            })

        } else {
            document.getElementById(id).classList.add('hidden');

            this.setState({
                ...this.state,
                selectedTemplate: null
            })
        }
    }

    openDropdown(id, e) {
        e.stopPropagation();

        if (this.state.dropdownOpen != null) {
            this.setState({
                ...this.state,
                dropdownOpen: null
            })
        } else {
            this.setState({
                ...this.state,
                dropdownOpen: id
            })
        }

    }

    deleteItem(id, e) {

        e.stopPropagation();
        this.setState({
            ...this.state,
            dropdownOpen: null
        });


        deleteCustomerTemplate({
            templateId: id
        }).then(response => {

            if (!response.ok) {
                return;
            }

            this.props.enqueueSnackbar("Successfully deleted customer template!", {variant: 'success'});
            this.getTemplates();
        });
    }

    editItem(id, e) {
        this.props.history.push('/export-product/' + id);
    }

    renderAttributeValues(values) {
        let render = [];

        if (values[0].attribute.attribute_type === AttributeType.STRING) {
            let elements = [];
            values.forEach(item => {
                elements.push(item.name);
            });

            render.push(
                <div className={'attribute-value'}>
                    <span className={'value-string'}>{ elements.join(', ') }</span>
                </div>
            )
        } else {
            values.forEach(item => {
                render.push(
                    <Tooltip title={item.customer_color_name}>
                        <span className={'attribute color'} style={{backgroundColor : item.name}} />
                    </Tooltip>
                )
            });
        }

        return render;
    }

    renderAttributes(attributes) {
        let render = [];

        attributes.forEach(attribute => {
            render.push(
                <div key={attribute[0].id} className={'attribute'}>
                    <span className={'attribute-name'}>{attribute[0].attribute.name}</span>
                    <div className={'attribute-values'}>
                        {this.renderAttributeValues(attribute)}
                    </div>
                </div>
            )
        })

        return render;
    }

    renderTemplates() {
        let render = [];
        let templateLength = this.state.variations.length;
        let count = 0;

        this.state.variations.forEach((item, index) => {
            let name = item.template.name;

            if (this.state.currentTab === Store.etsy) {
                name = item.template.customer_template_etsy?.name;
            } else if (this.state.currentTab === Store.shopify) {
                name = item.template.customer_template_shopify?.name;
            }

            count++;
            render.push(
                <div key={item.template.id} className={'card-container'}
                     onClick={(e) => this.showTemplateVariation(e, item.template.id)}>
                    <div className={this.state.selectedTemplate === item.template.id ? 'card active' : 'card'}>
                        <span className={'name'}>{name}</span>
                        <img src={item.template.image ? (item.template.image ? process.env.REACT_APP_baseUrl + item.template.image : '') : ''}/>
                        {/*{*/}
                        {/*    item.template.sync == false &&*/}
                            <input
                                checked={this.state.data['template' + item.template.id]}
                                className={'template-cb'}
                                type={'checkbox'}
                                name={'template' + item.template.id}
                                onChange={this.changeCheckBoxTemplate}
                                style={{opacity: this.state.data['template' + item.template.id] ? 1 : 0}}
                            />
                        <img className="more-option" onClick={(e) => this.openDropdown(item.template.id, e)} src="/images/info_hover1.png"/>
                        {
                            !(this.state.userType === UserType.ADMIN && this.state.currentTab === 'All') &&
                            <div className="options">
                                <div
                                    className={this.state.dropdownOpen == item.template.id ? "dropdown active" : "dropdown"}>
                                    {
                                        <div className="dropdown-opt"
                                             onClick={(e) => this.editItem(item.template.id, e)}>Edit</div>
                                    }
                                    {
                                        this.state.userType == UserType.CUSTOMER &&
                                        <div className="dropdown-opt"
                                             onClick={(e) => this.deleteItem(item.template.id, e)}>Delete</div>
                                    }
                                </div>
                            </div>
                        }

                        <div className={'attributes'}>
                            {this.renderAttributes(item.values)}
                        </div>
                        {this.state.currentTab === Store.storage &&
                            <>
                                <div className={'attributes'}>
                                    <div className={'attribute'}>
                                        {
                                            (item.template.sync || item.template.etsy_sync) &&
                                            <span className={'attribute-name platform'}>Imported from</span>
                                        }
                                        <div className={'attribute-values'}>
                                            {
                                                item.template.sync &&
                                                <span className={'platform shopify'}>Shopify</span>
                                            }
                                            {
                                                item.template.etsy_sync &&
                                                <span className={'platform etsy'}>Etsy</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={'attributes'}>
                                    <div className={'attribute attribute-shop'}>
                                        {
                                            (item.template.etsy_exported || item.template.shopify_exported) &&
                                            <span className={'attribute-name platform'}>Exported to</span>
                                        }
                                        <div className={'attribute-values platform'}>
                                            {
                                                item.template.shopify_exported &&
                                                <span className={'platform shopify'}>Shopify</span>
                                            }
                                        </div>
                                        <div className={'attribute-values platform'}>
                                            {
                                                item.template.etsy_exported &&
                                                <span className={'platform etsy'}>Etsy</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            );

            if (window.innerWidth < 768) {
                render.push(
                    <div key={666 * index} className={'sub-card-container'}>
                        {
                            this.renderVariations(index, count)
                        }
                    </div>
                );
            } else {
                if ((index + 1) % (this.state.owlItems) === 0 || templateLength === index + 1) {
                    render.push(
                        <div key={666 * index} className={'sub-card-container'}>
                            {
                                this.renderVariations(index, count)
                            }
                        </div>
                    );
                    count = 0
                }
            }
        });

        return render;
    }

    renderimportedProducts() {
        let render = [];
        let count = 0;

        this.state.importedProducts.forEach((item) => {
            count++;
            render.push(
                <div key={item.id} className={'card-container'}>
                    <div className={'card'}>

                        <span className={'name'}>{item.name ? item.name : 'No Name'}</span>
                        <img src={item.image ? item.image : "/images/no-image.png"}/>
                        {
                            item.sync === false &&
                            <Button variant="contained" color="primary" onClick={() => this.sync(item.importedId, this.state.currentTab)}>
                                Sync
                            </Button>
                        }
                        {
                            item.sync === true &&
                            <div className={"sync"}>Sync'd</div>
                        }

                        <div className={"variations-number"}>Connected SKUs {item.numberOfMappedVariations}/{item.numberOfVariations}</div>

                        {/* <div className={'attributes'}>
                            {this.renderAttributes(item.values)}
                        </div> */}
                    </div>
                </div>
            );

            // if ((index+1)%6 === 0 || templateLength === index + 1){
            //     render.push(
            //         <div key={666*index} className={'sub-card-container'}>
            //             {
            //                 this.renderVariations(index, count)
            //             }
            //         </div>
            //     );
            //     count = 0
            // }
        });

        return render;
    }

    addToCart() {

        if (getUserFromLocalStorage().roles[0] != "ROLE_CUSTOMER") {
            this.props.enqueueSnackbar("Admin can't make an order!", {variant: 'error'});
            return;
        }

        let arrayIds = [];

        for (const prop in this.state.data) {
            let fullProp = prop;
            let name = prop.replace(/[0-9]/g, '')

            if (name === 'customerVariation' && this.state.data[prop] === true) {
                arrayIds.push(fullProp.replace(/\D/g, ''));
            }
        }


        if (arrayIds.length == 0) {
            this.props.enqueueSnackbar("You must select template or customer product variation!", {variant: 'error'});
            return;
        }

        addItemsToShoppingCart(arrayIds).then(response => {
            if (!response.ok) {
                return
            }

            this.props.enqueueSnackbar("Successfully added to shopping cart!", {variant: 'success'});

        })
    }

    addNewItemsToOrder() {

        let arrayIds = [];

        for (const prop in this.state.data) {
            let fullProp = prop;
            let name = prop.replace(/[0-9]/g, '')

            if (name === 'customerVariation' && this.state.data[prop] === true) {
                arrayIds.push(fullProp.replace(/\D/g, ''));
            }
        }

        if (arrayIds.length == 0) {
            this.props.enqueueSnackbar("You must select template or customer product variation!", {variant: 'error'});
            return;
        }

        addNewItemsToOrder({
            ids: arrayIds,
            id: this.state.orderId
        }).then(response => {
            if (!response.ok) {
                return
            }

            this.props.history.push('/order-details/' + this.state.orderId);
        })
    }

    onPageClick(i) {
        this.state.page = i;
        this.getTemplates();
    }

    onImportedProductPageClick(i) {
        this.state.importedProductPage = i;
        this.getImportedProductFromPlatform();
    }

    renderPagination() {
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1) {
            return;
        }

        if (this.state.page > 5) {
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++) {
            if (index === 9 && ((this.state.page + 4) < this.state.totalPages)) {
                break;
            }
            pages.push(
                <div onClick={() => this.onPageClick(i)}
                     className={this.state.page === i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    renderImportedProductsPagination() {
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.importedProductTotalPages === 1) {
            return;
        }

        if (this.state.importedProductPage > 5) {
            start = this.state.importedProductPage - 4;
        }

        for (let i = start; i <= this.state.importedProductTotalPages; i++) {
            if (index === 9 && ((this.state.importedProductPage + 4) < this.state.importedProductTotalPages)) {
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() => this.onImportedProductPageClick(i)}
                     className={this.state.importedProductPage === i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    render() {
        return (
            <div className="my-div">
                <div className={'product-templates-search'}>
                    <input className={'search'}
                        value={this.state.data.keywords}
                        onChange={(e) => this.searchDebounced(e)}
                        name={'keywords'}
                        placeholder={'Type to search...'}
                        disabled={this.state.loaderActive}
                    />
                    <img src={'/images/search.png'} />
                </div>
                {
                    this.state.userType == UserType.ADMIN && !this.state.loaderActive &&
                    <SelectControl
                        className='select'
                        options={this.state.customers}
                        valueKey={'id'}
                        onChange={this.changeData}
                        nameKey={'displayName'}
                        name={'customer'}
                        selected={this.state.data.customer}
                        placeholder={'Select customer'}
                        disabled={this.state.customerId != null}
                    />
                }
                {
                    <div className={'product-templates container'}>
                        <div className={'top-buttons highest'}>
                            <div className={'left'}>
                                {/*<Button className={this.state.currentTab === Store.all ? 'order blue' : 'order'}*/}
                                {/*        onClick={() => this.changeTab(Store.all)}*/}
                                {/*>*/}
                                {/*    All*/}
                                {/*</Button>*/}
                                {
                                    !this.state.fromPage &&
                                    <Button className={this.state.currentTab === Store.storage ? 'order blue' : 'order'}
                                            onClick={() => this.changeTab(Store.storage)}
                                    >
                                        Storage
                                    </Button>
                                }
                                {
                                    !this.state.fromPage && this.state.stores.shopify &&
                                    <Button className={this.state.currentTab === Store.shopify ? 'order blue' : 'order'}
                                            onClick={() => this.changeTab(Store.shopify)}
                                    >
                                        Shopify
                                    </Button>
                                }
                                {
                                    !this.state.fromPage && this.state.stores.etsy &&
                                    <Button className={this.state.currentTab === Store.etsy ? 'order blue' : 'order'}
                                            onClick={() => this.changeTab(Store.etsy)}
                                    >
                                        Etsy
                                    </Button>
                                }
                            </div>
                            {
                                this.state.userType == UserType.CUSTOMER &&
                                <Button className={'order blue'}
                                        onClick={() => this.props.history.push('/product-catalog')}>Create Product</Button>
                            }
                        </div>
                    </div>
                }
                {
                    this.state.loader &&
                    <div className={'loader-cont'}>
                        <img src={'/images/loader.gif'} />
                    </div>
                }
                {
                    this.state.loaderActive &&
                    <img className={"my-div-loader"} src={"/images/loadersp.gif"} />
                }
                {
                    this.state.currentTab === Store.all && !this.state.loaderActive &&
                    <div className={'product-templates container'}>
                        {
                            !this.state.orderId && this.state.userType == UserType.CUSTOMER &&
                            <div className={'top-buttons'}>
                                <Button className={'order'} onClick={() => this.addToCart()}>Add to Cart</Button>
                            </div>
                        }

                        {
                            this.state.orderId &&
                            <div className={'top-buttons'}>
                                <Button className={'order'} onClick={() => this.addNewItemsToOrder()}>Save to
                                    order</Button>
                            </div>
                        }
                        <div className={'templates'}>
                            {this.renderTemplates()}
                        </div>
                        <div className={'pagination'}>
                            {this.renderPagination()}
                        </div>
                    </div>
                }
                {
                    this.state.currentTab === Store.shopify && !this.state.loaderActive &&
                    <div className={'product-templates container'}>
                        {
                            this.state.userType == UserType.CUSTOMER &&
                            <div className={'top-buttons'}>
                                <Button className={'order'} onClick={() => this.addToCart()}>Add to Cart</Button>
                                <Button className={'order export'}
                                        onClick={() => this.importProducts(Store.shopify)}
                                >
                                    Load Shopify Product
                                </Button>
                            </div>
                        }
                        <TabContext value={this.state.shopifyTab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList
                                    onChange={(e, value) => this.changeShopifyTab(value)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Exported products" value="1" />
                                    <Tab label="Imported products" value="2" />
                                </TabList>
                            </Box>

                            <TabPanel value="1">
                                <div className={'templates'}>
                                    {this.renderTemplates()}
                                </div>
                                <div className={'pagination'}>
                                    {this.renderPagination()}
                                </div>
                            </TabPanel>
                            <TabPanel value="2">
                                <div className={'templates'}>
                                    {this.renderimportedProducts()}
                                </div>
                                <div className={'pagination'}>
                                    {this.renderImportedProductsPagination()}
                                </div>
                            </TabPanel>
                        </TabContext>
                    </div>
                }
                {
                    this.state.currentTab === Store.etsy && !this.state.loaderActive &&
                    <div className={'product-templates container'}>
                        {
                            this.state.userType == UserType.CUSTOMER &&
                            <div className={'top-buttons'}>
                                <Button className={'order'} onClick={() => this.addToCart()}>Add to Cart</Button>
                                <Button className={'order export'}
                                        onClick={() => this.importProducts(Store.etsy)}
                                >
                                    Load Etsy Product
                                </Button>
                            </div>
                        }
                        <TabContext value={this.state.etsyTab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList
                                    onChange={(e, value) => this.changeEtsyTab(value)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Exported products" value="1" />
                                    <Tab label="Imported products" value="2" />
                                </TabList>
                            </Box>

                            <TabPanel value="1">
                                <div className={'templates'}>
                                    {this.renderTemplates()}
                                </div>
                                <div className={'pagination'}>
                                    {this.renderPagination()}
                                </div>
                            </TabPanel>
                            <TabPanel value="2">
                                <div className={'templates'}>
                                    {this.renderimportedProducts()}
                                </div>
                                <div className={'pagination'}>
                                    {this.renderImportedProductsPagination()}
                                </div>
                            </TabPanel>
                        </TabContext>

                        {/*{*/}
                        {/*    this.state.userType == UserType.CUSTOMER &&*/}
                        {/*    <div className={'top-buttons'}>*/}
                        {/*        <Button className={'order'} onClick={() => this.addToCart()}>Add to Cart</Button>*/}
                        {/*        <Button className={'order export'}*/}
                        {/*                onClick={() => this.importProductsFromEtsy()}>Load Etsy Product</Button>*/}
                        {/*    </div>*/}
                        {/*}*/}
                        {/*<div className="shopify-products">Products Exported to Etsy</div>*/}
                        {/*<div className={'templates'}>*/}
                        {/*    {this.renderTemplates()}*/}
                        {/*</div>*/}
                        {/*<div className={'pagination'}>*/}
                        {/*    {this.renderPagination()}*/}
                        {/*</div>*/}

                        {/*<div className="shopify-products">Imported products from Etsy</div>*/}
                        {/*<div className={'templates'}>*/}
                        {/*    {this.renderimportedProducts()}*/}
                        {/*</div>*/}
                        {/*<div className={'pagination'}>*/}
                        {/*    {this.renderImportedProductsPagination('Etsy')}*/}
                        {/*</div>*/}
                    </div>
                }
                {
                    this.state.currentTab === Store.storage && !this.state.loaderActive &&
                    <div className={'product-templates container'}>
                        <div className={'top-buttons'}>
                            <Button className={'order'} onClick={() => this.addToCart()}>Add to Cart</Button>
                        </div>
                            <div className={'templates'}>
                            {this.renderTemplates()}
                        </div>
                        <div className={'pagination'}>
                            {this.renderPagination()}
                        </div>
                    </div>
                }
            </div>
        );
    }
}



export default withSnackbar(withRouter(ProductTemplates));
