import React from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import './style.scss';

const VerticalNavigation = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <div className='vertical-navigation'>
      {tabs.map(({ text }, i) => (
        <div key={uuid()} onClick={() => setSelectedTab(i)} className={classNames('vertical-navigation-tab', {'vertical-navigation-tab-selected': selectedTab === i })}>
          {text}
          <div className={classNames('left-line', {'left-line-selected': selectedTab === i})} />
        </div>
      ))}
    </div>
  )
}

export default VerticalNavigation;