export const mapShippingData = ({ id, address, addressLineTwo, city, country, state, postalCode, email, firstName, lastName, phoneNumber }) => ({
  orderId: id,
  shippingAddress: address,
  shippingAddress2: addressLineTwo,
  shippingCity: city,
  shippingCountry: country.id,
  shippingState: state,
  shippingPostalCode: postalCode,
  shippingEmail: email,
  shippingFirstName: firstName,
  shippingLastName: lastName,
  shippingPhoneNumber: phoneNumber
});

export const mapUpdatedOrder = (order, { shippingAddress, shippingAddress2, shippingCity, shippingCountry, shippingState, shippingPostalCode, shippingEmail, shippingFirstName, shippingLastName, shippingPhoneNumber }) => ({
  ...order,
  address: shippingAddress,
  addressLineTwo: shippingAddress2,
  city: shippingCity,
  country: shippingCountry,
  state: shippingState,
  postalCode: shippingPostalCode,
  email: shippingEmail,
  firstName: shippingFirstName,
  lastName: shippingLastName,
  phoneNumber: shippingPhoneNumber
})