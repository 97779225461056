import React from 'react';
import { withRouter } from 'react-router-dom';

import CustomProductTemplate from 'src/redesign/components/CustomProductTemplate';
import IconBox from 'src/redesign/components/Icon/Box';
import IconTool from 'src/redesign/components/Icon/Tool';
import IconCheckmark from 'src/redesign/components/Icon/Checkmark';

import colors from 'src/scss/_colors.scss';

const LandingDrinkwarePage = ({ history }) => {

  const onStartDesigningClick = () => {
    history.push('/product-catalog-in-app/drinkware');
  }

  const categoryName = 'Drinkware';

  const easeOptions = [
    {
      icon: <IconBox width='37.22' height='37.89' color={colors.secondary} />,
      title: 'Large Product Variety',
      description: `Surge’s ever-expanding Product Catalog offers mugs in all styles, materials, and sizes. \t
      From personalized tea, coffee, and travel mugs to ones made of glass and even color-changing mugs.`
    },
    {
      icon: <IconTool width='36.53' height='36.52' color={colors.secondary} />,
      title: '100% Free Design Tool',
      description: `Design and list personalized mugs online with no up-front costs, risks, or inventory. Make your own mug easily with access to our free and beginner-friendly Mockup Generator design tool.`
    },
    {
      icon: <IconCheckmark width='35' height='35' color={colors.secondary} />,
      title: 'High-Quality Prints',
      description: `Customize mugs with stylish wrap-around design arrangements. We guarantee best print quality on A+ premium mug quality.`
    }
  ];

  const exploreMainProduct = {
    image: '/images/custom-mugs-explore-main.png',
    title: 'Ceramic Mugs',
    description: `11oz - Perfect for a classic coffee or tea experience. \t
      15oz - For those who like a little extra in every sip. \t
      Colors for Every Mood: White,Black and 9 other colors available for Ceramic Mugs.`,
    nameToFind: 'White Ceramic Mugs'
  }

  const exploreOtherProducts = [
    {
      image: '/images/custom-mugs-explore-other-1.png',
      title: 'Color-Changing (Morphing) Mugs',
      description: `11oz - Perfect for a classic coffee or tea experience. \t
        15oz - For those who like a little extra in every sip. \t
        Colors for Every Mood: White,Black and 9 other colors available for Ceramic Mugs. \t`,
      nameToFind: 'Morphing Mug'
    },
    {
      image: '/images/custom-mugs-explore-other-2.png',
      title: 'Tumblers',
      description: `Stay hydrated on the go with our travel-friendly tumblers. \t
        Your designs, wherever life takes your customers.`,
      nameToFind: 'Travel Tumbler'
    },
    {
      image: '/images/custom-mugs-explore-other-3.png',
      title: 'Enamel Campfire Mug',
      description: 'Elevate your hiking experience with our sturdy and classic enamel campfire mugs.',
      nameToFind: 'Enamel Campfire Mug'
    }
  ]

  return (
    <CustomProductTemplate
      onStartDesigningClick={onStartDesigningClick}
      categoryName={categoryName}
      //header
      headerTitle='Custom Mugs'
      headerSubtitle={`Design custom mugs for yourself or make money online with branded mug printing for your eCommerce store. Create, order, and sell personalized mugs with SurgePrint.`}
      headerImage='/images/drinkware-header.png'
      //design-with-ease
      easeTitle='Design a Mug With Ease'
      easeOptions={easeOptions}
      //on-demand
      onDemandTitle='On Demand Custom Mugs'
      onDemandSubtitle={`Surge maintains priority for every order, no matter the quantity. Order one custom mug with a design for your morning coffee, or order multiple for your store, charity, or public events.`}
      //explore-collection
      exploreTitle='Explore Our Drinkware Collection'
      exploreMainProduct={exploreMainProduct}
      exploreOtherProducts={exploreOtherProducts}
      //gift-memory
      giftMemoryImage='/images/drinkware-serving-online.png'
      giftMemoryTitle='Gift a Memory, Share a Smile'
      giftMemoryDescription={`SurgePrint drinkware makes for unforgettable gifts from your online shop to its end customers. Add a personal touch to birthdays, anniversaries, or holidays with customized drinkware that speaks volumes. Surprise your customers with a thoughtful and practical present they'll cherish every day.`}
      //start-today
      startTodayTitle='Start Designing Today'
      startTodaySubtitle={`Empower your online shop to offer a unique and personalized experience. Start featuring custom Mugs in your inventory and discover the joy your customers will experience while sipping from a cup that's uniquely theirs.`}
      startTodayLink={`Sign Up  and Brew Your Online Shop's Creativity!`}
      //personalized
      personalizedTitle='Personalized Mugs and Drinkware'
      personalizedSubtitle={`SurgePrint maintains priority for every order, no matter the quantity. Order one custom mug with a design for your morning coffee, or order multiple for your store, charity, or public events.`}
      //seo
      seoTitle='Custom Mugs - Print On Demand Mugs | Surge Print'
      seoDescription={`Explore personalized custom mugs at SurgePrint. Elevate your daily routine with unique designs. Perfect for gifts or keepsakes. Order your custom mug today!`}
    />
  )
}

export default withRouter(LandingDrinkwarePage);