import React from 'react';
import classNames from 'classnames';
import { string, node, bool } from 'prop-types';
import Link from 'src/redesign/components/Link';
import CustomTooltip from 'src/redesign/components/CustomTooltip';
import IconChevronDown from 'src/redesign/components/Icon/Chevron/Down';
import IconChevronUp from 'src/redesign/components/Icon/Chevron/Up';
import { v4 as uuid } from 'uuid';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const Tab = ({ text, to, icon, isExpanded, isSelected, tabs = [] }) => {
  const [isOpened, setIsOpened] = React.useState(isSelected);
  const className = classNames('tab', { 'tab-collapsed': !isExpanded, 'tab-selected': isSelected });
  const hasTabs = tabs.length > 0;
  const iconChevronProps = { width: 10, height: 25, color: colors.primary };
  
  return (
    hasTabs ? (
      <div className='tabs-container'>
          <div
            className={classNames('tab-title', {'tab-title-selected' : isSelected})}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpened(prev => !prev);
            }}
          >
            {icon}
            {isExpanded && text}
            {isOpened ? <IconChevronUp {...iconChevronProps}/> : <IconChevronDown {...iconChevronProps} />}
            </div>
            {isOpened && tabs.map(x => (
              <Link text={x.text} fontSize={16} lineHeight={'16px'} className='tab-child' to={x.to} key={uuid()}>
                {x.text}
            </Link>
          ))}
          </div>
          )
    : (
      <CustomTooltip text={text}>
      <Link fontSize={16} lineHeight={'22px'} className={className} to={to} text={text}>
        {icon}
        {isExpanded && text}
      </Link>
    </CustomTooltip>
    )
  )
};

Tab.propTypes = {
  text: string,
  to: string.isRequired,
  icon: node.isRequired,
  isExpanded: bool
}

export default Tab;