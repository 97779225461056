import React from 'react';
import { number, string } from 'prop-types';

const IconSync = ({ width, height, color }) => (
<svg width={width} height={height} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.00004 2.33344V0.841773C7.00004 0.466773 6.55004 0.28344 6.29171 0.550107L3.95837 2.87511C3.79171 3.04177 3.79171 3.30011 3.95837 3.46677L6.28337 5.79177C6.55004 6.05011 7.00004 5.86677 7.00004 5.49177V4.00011C9.75837 4.00011 12 6.24177 12 9.00011C12 9.65844 11.875 10.3001 11.6334 10.8751C11.5084 11.1751 11.6 11.5168 11.825 11.7418C12.25 12.1668 12.9667 12.0168 13.1917 11.4584C13.5 10.7001 13.6667 9.86677 13.6667 9.00011C13.6667 5.31677 10.6834 2.33344 7.00004 2.33344ZM7.00004 14.0001C4.24171 14.0001 2.00004 11.7584 2.00004 9.00011C2.00004 8.34177 2.12504 7.70011 2.36671 7.12511C2.49171 6.82511 2.40004 6.48344 2.17504 6.25844C1.75004 5.83344 1.03337 5.98344 0.808374 6.54177C0.500041 7.30011 0.333374 8.13344 0.333374 9.00011C0.333374 12.6834 3.31671 15.6668 7.00004 15.6668V17.1584C7.00004 17.5334 7.45004 17.7168 7.70837 17.4501L10.0334 15.1251C10.2 14.9584 10.2 14.7001 10.0334 14.5334L7.70837 12.2084C7.45004 11.9501 7.00004 12.1334 7.00004 12.5084V14.0001Z" fill={color}/>
</svg>
);

IconSync.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconSync;