import React from 'react';
import StartingDesigning from './pages/StartingDesigning';
import Error from "./pages/Error";
import Forbidden from "./pages/Forbidden";
import NotFound from "./pages/NotFound";

import { Route } from 'react-router-dom';
import { isUserLoggedIn } from "./base/OAuth";
import Login from "./pages/Login";
import Lock from "./pages/user/Lock";
import UserList from "./pages/admin/users/UserList";
import SignUp from './pages/SignUp';
import ResetPasswordRequest from "./pages/user/ResetPasswordRequest";
import ResetPassword from "./pages/user/ResetPassword";
import UserHome from './pages/UserHome';
import Category from './pages/Category';
import Products from "./pages/admin/Products";
import EditProduct from "./pages/admin/EditProduct";
import ProductCatalog from "./pages/ProductCatalog";
import ProductSelection from "./pages/ProductSelection";
import ProductVariationSelection from "./pages/ProductVariationSelection";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import OrderList from "./pages/OrderList";
import Orders from "./pages/Orders";
import PlaceAnOrder from "./pages/PlaceAnOrder";
import TemplateEdit from "./pages/TemplateEdit";
import ProductTemplates from "./pages/ProductTemplates";
import OrderDetails from "./pages/OrderDetails";
import DraftDetails from "./pages/DraftDetails";
import Settings from "./pages/Settings";
import Vendors from "./pages/Vendors";
import Users from "./pages/Users";
import TemplateVariationsEdit from "./pages/TemplateVariationsEdit";
import Admins from "./pages/Admins";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Warehouse from "./pages/Warehouse";
import Associate from "./pages/Associate";
import ExportProduct from './pages/ExportProduct';
import ImportProducts from "./pages/ImportProducts";
import TermsOfService from "./components/terms/TermsOfService";
import PrivacyPolicy from "./components/terms/PrivacyPolicy";
import FAQ from "./pages/FAQ";
import OrderImageEdit from "./pages/OrderImageEdit";
import InstallationSuccessful from "./pages/InstallationSuccessful";
import SetupInstructions from "./pages/SetupInstructions";
import EtsyConnected from "./pages/EtsyConnected";
import SalesChannels from "./pages/SalesChannels";
import SetupInstructionsEtsy from "./pages/SetupInstructionsEtsy";
import MediaModifierMockups from './pages/MediaModiferMockups';
import Documents from './pages/Documents';

import ProductCatalogPageRedesign from './redesign/pages/ProductCatalog';
import ProductCatalogPageCategoryRedesign from './redesign/pages/ProductCatalogCategory';
import ProductCatalogCategoryBrandRedesign from './redesign/pages/ProductCatalogCategoryBrand';
import ProductPageRedesign from './redesign/pages/Product';
import LandingPageRedesign from './redesign/pages/Landing';
import OrdersPageRedesign from './redesign/pages/Orders';
import OrderDetailsRedesign from './redesign/pages/OrderDetails';
import SettingsPageRedesign from './redesign/pages/Settings';
import StoresPageRedesign from './redesign/pages/Stores';
import EtsyConnectStoreRedesign from './redesign/pages/Stores/pages/EtsyConnect';
import ShopifyConnectStoreRedesign from './redesign/pages/Stores/pages/ShopifyConnect';
import AffiliateRedesign from './redesign/pages/Affiliate';
import WarehousePRORedesign from './redesign/pages/WarehousePRO';
import PlaceAnOrderRedesign from './redesign/pages/PlaceAnOrder';
import CartRedesign from './redesign/pages/Cart';
import CheckoutRedesign from './redesign/pages/Checkout';
import ProductLibraryPageRedesign from './redesign/pages/ProductLibrary';
import ImportProductsRedesign from './redesign/pages/ImportProducts';
import ProductCatalogPageRedesignInApp from './redesign/pages/ProductCatalogInApp';
import ProductSelectionRedesign from './redesign/pages/ProductSelection';
import ExportProductPageRedesign from './redesign/pages/ExportProduct';
import TemplateEditRedesign from './redesign/pages/TemplateEdit';
import HomePageRedesign from './redesign/pages/Home';
import RegistrationRedesign from './redesign/pages/Registration';
import ImageEditor from './redesign/pages/ImageEditor';
import ContactUs from './redesign/pages/ContactUs';
import RedesignFaq from './redesign/pages/FAQ';
import IntegrationShopifyPage from './redesign/pages/IntegrationShopify';
import IntegrationEtsyPage from './redesign/pages/IntegrationEtsy';
import PrintingMethodsDTG from './redesign/pages/PrintingMethodsDTG';
import PrintingMethodsScreenPrinting from './redesign/pages/PrintingMethodsScreenPrinting';
import PrintingMethodsEmbroidery from './redesign/pages/PrintingMethodsEmbroidery';
import PrintingMethodsBulkOrders from './redesign/pages/PrintingMethodsBulkOrders';
import WhyUsPage from './redesign/pages/WhyUs';
import WLMPage from './redesign/pages/WLM';
import AdminsPage from './redesign/pages/Admin/Admins';
import UsersPage from './redesign/pages/Admin/Users';
import VendorsPage from './redesign/pages/Admin/Vendors';
import MediaModifierMockupsPage from './redesign/pages/Admin/MediaModifierMockups';
import DocumentsPage from './redesign/pages/Admin/Documents';
import ProductsPage from './redesign/pages/Admin/Products';
import ProductDetailsPage from './redesign/pages/Admin/ProductDetails';
import LandingDrinkwarePage from './redesign/pages/LandingDrinkware';
import LandingBlanketPage from './redesign/pages/LandingBlankets';
import LandingHoodiesPage from './redesign/pages/LandingHoodies';
import CustomerStoryTeeCommerce from './redesign/pages/CustomerStoryTeeCommerce';
import NotFoundPageRedesign from './redesign/pages/NotFound';
import PrivacyPolicyRedesign from './redesign/pages/PrivacyPolicy';
import TermsOfServiceRedesign from './redesign/pages/TermsOfService';

let ROUTES = {
    Home: {
        path: '/',
        component: <LandingPageRedesign/>,
        auth: false
    },
    Error: {
        path: '/error',
        component: <Error/>,
        auth: false
    },
    Forbidden: {
        path: '/forbidden',
        component: <Forbidden/>,
        auth: false
    },
    NotFound: {
        path: '/not-found',
        component: <NotFound/>,
        auth: false
    },
    Login: {
        path: '/login',
        component: <Login/>,
        auth: false
    },
    Lock: {
        path: '/lock',
        component: <Lock/>,
        auth: false
    },
    UserList: {
        path: '/user-list',
        component: <UserList showFilter={ false }/>,
        auth: true
    },
    Products: {
        path: '/products',
        component: <Products />,
        auth: true
    },
    EditProduct: {
        path: '/old/products/:id/:page',
        component: <EditProduct />,
        auth: true
    },
    SignUp: {
        path: '/old/registration',
        component: <SignUp/>,
        auth: false
    },
    SetupInstructions: {
        path: '/old/setup-instructions',
        component: <SetupInstructions/>,
        auth: true
    },
    SetupInstructionsEtsy: {
        path: '/old/setup-instructions-etsy',
        component: <SetupInstructionsEtsy/>,
        auth: true
    },
    InstallationSuccessful: {
        path: '/installation-success/:token',
        component: <InstallationSuccessful/>,
        auth: false
    },
    ResetPasswordRequest : {
        path : '/reset-password-request',
        component: <ResetPasswordRequest/>,
        auth: false
    },
    ResetPassword : {
        path : '/reset_password',
        component: <ResetPassword/>,
        auth: false
    },
    UserHome: {
        path: '/old/home',
        component: <UserHome/>,
        auth: true
    },
    Category: {
        path: '/old/category/:id',
        component: <Category/>,
        auth: true
    },
    ProductCatalogPageRedesign: {
        path: '/product-catalog-website',
        component: <ProductCatalogPageRedesign />,
        auth: false
    },
    ProductCatalogPageCategoryRedesign: {
        path: '/product-catalog-website/:tab',
        component: <ProductCatalogPageCategoryRedesign />,
        auth: false
    },
    ProductCatalogCategoryBrandRedesign: {
        path: '/product-catalog-category/:name/:id',
        component: <ProductCatalogCategoryBrandRedesign />,
        auth: false
    },
    ProductPageRedesign: {
        path: '/product/:name/:brandId/:id',
        component: <ProductPageRedesign />,
        auth: false
    },
    LandingPageRedesign: {
        path: '/landing',
        component: <LandingPageRedesign />,
        auth: false
    },
    OrdersPageRedesign: {
        path: '/orders',
        component: <OrdersPageRedesign />,
        auth: true,
    },
    OrderDetailsRedesign: {
        path: '/order-details/:id/:tab',
        component: <OrderDetailsRedesign />,
        auth: true  
    },
    SettingsPageRedesign: {
        path: '/settings/:tab',
        component: <SettingsPageRedesign />,
        auth: true
    },
    StoresPageRedesign: 
    {
        path: '/stores',
        component: <StoresPageRedesign />,
        auth: true
    },
    EtsyConnectStoreRedesign:
    {
        path: '/setup-instructions-etsy',
        component: <EtsyConnectStoreRedesign />,
        auth: true
    },
    ShopifyConnectStoreRedesign:
    {
        path: '/setup-instructions',
        component: <ShopifyConnectStoreRedesign />,
        auth: true
    },
    AffiliateRedesign:
    {
        path: '/associate',
        component: <AffiliateRedesign />,
        auth: true
    },
    WarehousePRORedesign:
    {
        path: '/warehouse',
        component: <WarehousePRORedesign />,
        auth: true
    },
    PlaceAnOrderRedesign:
    {
        path: '/place-an-order',
        component: <PlaceAnOrderRedesign />,
        auth: true,
    },
    ImportProductsRedesign:
    {
        path: '/import-products/:store/:id',
        component: <ImportProductsRedesign />,
        auth: true
    },
    CartRedesign:
    {
        path: '/cart',
        component: <CartRedesign />,
        auth: true
    },
    CheckoutRedesign:
    {
        path: '/checkout',
        component: <CheckoutRedesign />,
        auth: true
    },
    ProductCatalogPageRedesignInApp:
    {
        path: '/product-catalog-in-app/:tab',
        component: <ProductCatalogPageRedesignInApp />,
        auth: true
    },
    ProductSelectionRedesign:
    {
        path: '/product-selection/:id',
        component: <ProductSelectionRedesign />,
        auth: true,
    },
    ExportProductPageRedesign:
    {
        path: '/export-product/:id',
        component: <ExportProductPageRedesign />,
        auth: true,
    },
    TemplateEditRedesign:
    {
        path: '/template-edit/:id',
        component: <TemplateEditRedesign />,
        auth: true,
    },
    HomePageRedesign:
    {
        path: '/home',
        component: <HomePageRedesign />,
        auth: true,
    },
    RegistrationRedesign:
    {
        path: '/registration',
        component: <RegistrationRedesign />,
        auth: false,
    },
    ProductLibraryPage:
    {
        path: '/product-templates/:store',
        component: <ProductLibraryPageRedesign />,
        auth: true
    },
    ProductSelectionVariationRedesign:
    {
        path: '/product-selection-variation',
        component: <ImageEditor />,
        auth: true,
    },
    ContactUsRedesign:
    {
        path: '/contact',
        component: <ContactUs />,
        auth: false,
    },
    RedesignFAQ:
    {
        path: '/faq',
        component: <RedesignFaq />,
        auth: false
    },
    IntegrationShopifyPage:
    {
        path: '/integration-shopify',
        component: <IntegrationShopifyPage />,
        auth: false
    },
    IntegrationEtsyPage:
    {
        path: '/integration-etsy',
        component: <IntegrationEtsyPage />,
        auth: false
    },
    PrintingMethodsDTG:
    {
        path: '/printing-methods-dtg',
        component: <PrintingMethodsDTG />,
        auth: false
    },
    PrintingMethodsScreenPrinting:
    {
        path: '/printing-methods-screen-printing',
        component: <PrintingMethodsScreenPrinting />,
        auth: false
    },
    PrintingMethodsEmbroidery:
    {
        path: '/printing-methods-embroidery',
        component: <PrintingMethodsEmbroidery />,
        auth: false
    },
    PrintingMethodsBulkOrders:
    {
        path: '/printing-methods-bulk-orders',
        component: <PrintingMethodsBulkOrders />,
        auth: false
    },
    WhyUsPage:
    {
        path: '/why-us',
        component: <WhyUsPage />,
        auth: false
    },
    WLMPage:
    {
        path: '/wlm',
        component: <WLMPage />,
        auth: false
    },
    AdminsPage:
    {
        path: '/admins',
        component: <AdminsPage />,
        auth: true,
    },
    UsersPage:
    {
        path: '/users',
        component: <UsersPage />,
        auth: true
    },
    VendorsPage:
    {
        path: '/vendors',
        component: <VendorsPage />,
        auth: true
    },
    MediaModifierMockupsPage:
    {
        path: '/media-modifier-mockups',
        component: <MediaModifierMockupsPage />,
        auth: true
    },
    DocumentsPage: {
        path: '/sp-documents',
        component: <DocumentsPage />,
        auth: true
    },
    ProductsPage: {
        path: '/category/:id',
        component: <ProductsPage />,
        auth: true
    },
    ProductDetailsPage: {
        path: '/product/:tab/:id',
        component: <ProductDetailsPage />,
        auth: true
    },
    LandingDrinkwarePage:
    {
        path: '/custom-mugs',
        component: <LandingDrinkwarePage />,
        auth: false,
    },
    LandingBlanketPage:
    {
        path: '/custom-blankets',
        component: <LandingBlanketPage />,
        auth: false
    },
    LandingHoodiesPage:
    {
        path: '/custom-hoodies',
        component: <LandingHoodiesPage />,
        auth: false
    },
    CustomerStoryTeeCommerce:
    {
        path: '/customer-story-teecommerce',
        component: <CustomerStoryTeeCommerce />,
        auth: false
    },
    ProductSelection: {
        path: '/old/product-selection/:id',
        component: <ProductSelection/>,
        auth: true
    },
    ProductVariationSelection: {
        path: '/old/product-selection-variation',
        component: <ProductVariationSelection/>,
        auth: true
    },
    TemplateVariationsEdit: {
        path: '/template-variations-edit',
        component: <TemplateVariationsEdit/>,
        auth: true
    },
    CartItemEdit: {
        path: '/order-edit',
        component: <OrderImageEdit/>,
        auth: true
    },
    ProductVariationSelectionTemplate: {
        path: '/old/product-selection-variation/:templateId',
        component: <ProductVariationSelection/>,
        auth: true
    },
    Cart: {
        path: '/old/cart',
        component: <Cart/>,
        auth: true
    },
    Checkout: {
        path: '/old/checkout',
        component: <Checkout/>,
        auth: true
    },
    OrderList: {
        path: '/drafts',
        component: <OrderList/>,
        auth: true
    },
    Orders: {
        path: '/old/orders',
        component: <Orders/>,
        auth: true
    },
    PlaceAnOrder: {
        path: '/old/place-an-order',
        component: <PlaceAnOrder/>,
        auth: true
    },
    OrderDetails: {
        path: '/old/order-details/:id',
        component: <OrderDetails/>,
        auth: true
    },
    TemplateEdit: {
        path: '/old/template-edit/:id',
        component: <TemplateEdit/>,
        auth: true
    },
    ProductTemplatesOrder: {
        path: '/old/product-templates/:id/:customerId',
        component: <ProductTemplates/>,
        auth: true
    },
    ProductTemplates: {
        path: '/old/product-templates',
        component: <ProductTemplates/>,
        auth: true
    },
    ProductTemplatesStore: {
        path: '/old/product-templates/:store',
        component: <ProductTemplates/>,
        auth: true
    },
    DraftDetails: {
        path: '/draft-details/:id',
        component: <DraftDetails/>,
		auth: true
	},
    Settings: {
        path: '/old/settings',
        component: <Settings/>,
        auth: true
    },
    BillingSettings: {
        path: '/old/settings/:type',
        component: <Settings/>,
        auth: true
    },
    Vendors: {
        path: '/old/vendors',
        component: <Vendors/>,
        auth: true
    },
    Users: {
        path: '/old/users',
        component: <Users/>,
        auth: true
    },
    Admins: {
        path: '/old/admins',
        component: <Admins/>,
        auth: true
    },
    MediaModifierMockups: {
        path: '/old/media-modifier-mockups',
        component: <MediaModifierMockups/>,
        auth: true
    },
    Documents: {
        path: '/old/sp-documents',
        component: <Documents/>,
        auth: true
    },
    Contact: {
        path: '/old/contact',
        component: <Contact/>,
        auth: false
    },
    Warehouse: {
        path: '/old/warehouse',
        component: <Warehouse/>,
        auth: true
    },
    Associate: {
        path: '/old/associate',
        component: <Associate/>,
        auth: true
    },
    ExportProduct: {
        path: '/old/export-product',
        component: <ExportProduct/>,
        auth: true
    },
    ExportProductEdit: {
        path: '/old/export-product/:id',
        component: <ExportProduct/>,
        auth: true
    },
    ImportProducts: {
        path: '/old/:store/import-products/:id',
        component: <ImportProducts/>,
        auth: true
    },
    TermsOfService:
    {
        path: '/terms-of-service',
        component: <TermsOfServiceRedesign/>,
        auth: false
    },
    TermsOfServiceOld: {
        path: '/old/terms-of-service',
        component: <TermsOfService/>,
        auth: false
    },
    PrivacyPolicy: {
        path: '/privacy-policy',
        component: <PrivacyPolicyRedesign/>,
        auth: false
    },
    PrivacyPolicyOld: {
        path: '/old/privacy-policy',
        component: <PrivacyPolicy/>,
        auth: false
    },
    FAQ: {
        path: '/old/faq',
        component: <FAQ/>,
        auth: false
    },
    EtsyConnected: {
        path: '/etsy-connected',
        component: <EtsyConnected/>,
        auth: false
    },
    SalesChannels: {
        path: '/old/stores',
        component: <SalesChannels/>,
        auth: true
    },
    NotFoundRedesign: {
        path: '*',
        component: <NotFoundPageRedesign />,
        auth: false
    }
};

export default ROUTES;

function getRoute(path) {

    for(const [key, value] of Object.entries(ROUTES)) {

        if(path.includes("/reset-password/")){
            path = "/reset-password/:token"
        }

        if(value.path === path || (path.includes(value.path.split(':')[0]) && value.path !== "/")) {
            return value;
        }
    }

    return null;
}

export function checkPath(path) {
    const pathObject = getRoute(path);

    if(!pathObject) {
        return false;
    }

    if(pathObject.auth)
        return !isUserLoggedIn();
    else
        return false;
}

export function getRoutes() {

    let result = [];

    for(const [key, value] of Object.entries(ROUTES)) {

        result.push(
            <Route key={ 'route-' + result.length } exact path={ value.path } render={() => (
                value.component
            )}/>
        )
    }

    return result;
}
