import React, {Component} from 'react'
import {Link, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import connect from "react-redux/es/connect/connect";
import MenuState from "../constants/MenuState";
import strings from "../localization";
import {
    getImpersonatedUser,
    getUserFromLocalStorage,
    lock,
    logout, removeImpersonatedUser,
    setUserToLocalStorage
} from "../base/OAuth";
import {Button} from "@material-ui/core";


class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            userRole: null,
            impersonate: false,
            userName: null
        }
    }

    componentWillMount(){
        if (getUserFromLocalStorage()) {
            this.setState({ userRole: getUserFromLocalStorage().roles[0] });
        }

        if (getImpersonatedUser()) {
            const user = getUserFromLocalStorage();
            this.setState({
                impersonate: true,
                userName: user.first_name + ' ' + user.last_name
            })
        }
    }

    /** HANDLERS **/

    handleMenuClick(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleMenuClose() {
        this.setState({ anchorEl: null });
    }

    logout() {
        logout();
        this.props.logout();
        this.props.history.push('/');
    }

    quitImpersonate() {
        const impersonated = getImpersonatedUser();
        setUserToLocalStorage(impersonated);
        removeImpersonatedUser();

        this.props.history.push('/old/users');
    }

    lock() {
        lock();
        this.props.history.push('/');
    }

    getHeaderClass() {

        if(this.props.menu.state === MenuState.SHORT) {
            return 'short';
        }
        else {
            return '';
        }
    }
    
    cart() {
        this.props.history.push('/cart');
    }

    render() {

        if (!getUserFromLocalStorage()){
            return ''
        }

        return (
            <div id="header">
                {
                    (this.state.impersonate) &&
                    <div className={'menu-item error-container'}>
                        Warning: You're logged in as { this.state.userName }
                    </div>
                }
                { this.state.userRole == "ROLE_CUSTOMER" &&
                <div className='menu-item'>
                    <Link className="profile" to={ this.state.userRole == "ROLE_CUSTOMER" ? '/settings' : "/home" }>{ strings.userHome.profile }</Link>
                    <div className={'sub-menu-item'}>
                        <div className={'cont'}>
                            <Link to={'/settings'}>
                                {strings.menu.options}
                            </Link>
                            <div onClick={() => this.state.impersonate ? this.quitImpersonate() : this.logout()}>
                                {strings.menu.logout}
                            </div>
                        </div>
                    </div>
                </div>
                }
                { 
                    this.state.userRole == "ROLE_CUSTOMER" &&
                    <Button onClick={ () => this.cart() }><img src="/images/cart.png"></img> {strings.menu.cart}</Button>
                }
                {
                    this.state.userRole != "ROLE_CUSTOMER" &&
                    <div className='menu-item' style={{ marginRight: "50px"}}>
                        <Link className="profile" onClick={() => this.logout()} to={'/'}>{strings.menu.logout}</Link>
                    </div>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeMenuState: Actions.changeMenuState,
        logout: Actions.logout,
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
