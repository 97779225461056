import { Redirect, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export const RemoveTrailingSlash = ({...rest}) => {
    const location = useLocation();

    // If the last character of the url is '/'
    if (location.pathname.match('/.*/$')) {
        return <Redirect replace {...rest} to={{
            pathname: location.pathname.replace(/\/+$/, ""),
            search: location.search
        }}/>
    } else return (
        <Helmet>
            <link rel="canonical" href={location.pathname} />
        </Helmet>
    );
}