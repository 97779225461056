import React from 'react';
import { number, string } from 'prop-types';

const IconChevronDown = ({ width, height, color }) => (
  <svg width={width} height={height} style={{ minWidth: width }} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 7L7 1L13 7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

IconChevronDown.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired
}

export default IconChevronDown;