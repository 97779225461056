import React from 'react';
import { string } from 'prop-types';
import { Transformer } from 'react-konva';

const TransformerComponent = ({ selectedShapeName }) => {
  const transformerRef = React.useRef(null);

  React.useEffect(() => {
    checkNode();
  }, [selectedShapeName])

  const checkNode = () => {
    const transformer = transformerRef.current;
    if(!transformer)
      return;

    const stage = transformer.getStage();

    const selectedNode = stage.findOne("." + selectedShapeName);
    // do nothing if selected node is already attached
    if (selectedNode === transformer.node()) {
        return;
    }

    if (selectedNode) {
        // attach to another node
        transformer.attachTo(selectedNode);
    } else {
        // remove transformer
        transformer.detach();
    }

    transformer.getLayer().batchDraw();
  }

  return <Transformer
    ref={transformerRef}
    rotateEnabled={false}
  />
}

TransformerComponent.propTypes = {
  selectedShapeName: string.isRequired
}

export default TransformerComponent;