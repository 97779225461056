import React from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import SelectControl from "../components/controls/SelectControl";
import {Button, TextField, Checkbox, FormControlLabel } from "@mui/material";
import {getCountryList} from "../services/CountryService";
import {
    getShoppingCartItems,
    calculateShoppingCartSummary,
    calculateDynamicShoppingCartSummary
} from "../services/ShoppingCart";
import { createOrder, checkoutOrder } from "../services/OrderService";
import Validators from "../constants/ValidatorTypes";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "../components/stripe/CheckoutForm";
import update from "immutability-helper/index";
import {getPrimaryMethod} from "../services/BillingInformation";
import Helmet from 'react-helmet';

const stripePromise = loadStripe(process.env.REACT_APP_pk_live);

const US = "United States";

class Checkout extends Page {

    params = [ { name: 'page', default: 1 },
               { name: 'perPage', default: 12 }];

    validationList = {
        shippingFirstName: [ {type: Validators.REQUIRED } ],
        shippingLastName: [ {type: Validators.REQUIRED } ],
        shippingAddress1: [ {type: Validators.REQUIRED } ],
        shippingCity: [ {type: Validators.REQUIRED } ],
        shippingState: [ {type: Validators.REQUIRED } ],
        shippingCountry: [ {type: Validators.REQUIRED } ],
        shippingPostalCode: [ {type: Validators.REQUIRED } ],
        shippingPhoneNumber: [ {type: Validators.REQUIRED } ],
        shippingEmail: [ {type: Validators.REQUIRED } ],
        paymentCardName: [ {type: Validators.REQUIRED } ],
        // paymentCardNumber: [ {type: Validators.REQUIRED } ],
        // paymentMonth: [ {type: Validators.REQUIRED } ],
        // paymentYear: [ {type: Validators.REQUIRED } ],
        // paymentCVV: [ {type: Validators.REQUIRED } ],
        paymentAddress1: [ {type: Validators.REQUIRED } ],
        paymentCity: [ {type: Validators.REQUIRED } ],
        paymentState: [ {type: Validators.REQUIRED } ],
        paymentPostalCode: [ {type: Validators.REQUIRED } ],
    };

    constructor(props) {
        super(props);

        this.state = {
            countries: [],
            items: [],
            data: {
                displayBillingAndPaymentTab: false,
                shippingFirstName: null,
                shippingLastName: null,
                shippingAddress1: null,
                shippingAddress2: null,
                shippingCity: null,
                shippingState: null,
                shippingCountry: null,
                shippingPostalCode: null,
                shippingPhoneNumber: null,
                shippingEmail: null,
                saveAddress: false,

                paymentCardName: null,
                // paymentCardNumber: null,
                // paymentMonth: null,
                // paymentYear: null,
                // paymentCVV: null,
                paymentUseAsPrimaryMethod: false,
                paymentSameAsShipping: false,
                paymentAddress1: null,
                paymentAddress2: null,
                paymentCity: null,
                paymentState: null,
                paymentPostalCode: null,
                paymentCountry: null,
                paymentShippingMethod:   {
                    value: 0,
                    name: 'Standard'
                },
                orderId: null,
                tokenId: null,
                hasABC: true
            },
            page : 1,
            perPage : 5,
            total : 0,
            shipping : 0,
            disabled : false
        }

        this.getCountryList = this.getCountryList.bind(this);
        this.sameAddress = this.sameAddress.bind(this);
        this.onPageClick = this.onPageClick.bind(this);
        this.onForward = this.onForward.bind(this);
        this.onBack = this.onBack.bind(this);
        this.submitToken = this.submitToken.bind(this);
        this.getBillingInformation = this.getBillingInformation.bind(this);
        this.changeShippingCountry = this.changeShippingCountry.bind(this);
        this.changeDataCountry = this.changeDataCountry.bind(this);


    }

    componentDidMount(){
        this.getCountryList();
        this.getTotal();
        this.getBillingInformation();
    }

    getBillingInformation(){
        getPrimaryMethod().then(response => {

            if(!response.ok) {
                this.setState({ ...this.state.data, displayBillingAndPaymentTab: true})
                return;
            }

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    paymentCardName: response.data.name_on_card,
                    lastFourDigits: "**** **** **** " + response.data.last_four_digits != undefined ? response.data.last_four_digits : "****",
                    expiration: (response.data.expiration_month != undefined ? response.data.expiration_month : "") + "/" + (response.data.expiration_year != undefined ? response.data.expiration_year : "") ,
                    paymentAddress1: response.data.billing_address,
                    paymentAddress2: response.data.address_line_two,
                    paymentCity: response.data.city,
                    paymentState: response.data.state_province,
                    paymentPostalCode: response.data.postal,
                    paymentCountry: response.data.country,
                    hasABC: !!response.data.automatic_billing_code,
                    displayBillingAndPaymentTab: false
                },

            })
        });
    }

    getCountryList() {
        getCountryList().then(response => {
            const countries = response.data.countries;

            const us = countries.find((element) => {
                return element.country_code === 'US'
            });

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    shippingCountry: us
                },
                countries: countries
            })
        });
    }

    getTotal(){
        calculateShoppingCartSummary().then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                shipping: response.data.shipping,
                total : response.data.total,
                standard : response.data.standard,
                expedite : response.data.expedite,
                overnight : response.data.overnight
            })
        });
    }

    getTotalDynamic(){
        calculateDynamicShoppingCartSummary(this.state.data.shippingCountry.id).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                shipping: response.data.shipping,
                total : response.data.total,
                standard : response.data.standard,
                expedite : response.data.expedite,
                overnight : response.data.overnight
            })
        });
    }

    fetchData(){
        this.getItems(this.state.page, this.state.perPage);
    }

    getItems(page, perPage){
        if (this.state.disabled) {
            return;
        }

        this.state.disabled = true;

        getShoppingCartItems({
            page : page, 
            perPage : perPage,
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                items: response.data.result,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                page : response.data.page,
                disabled : false
            })
        });
    }

    changeShippingType(type){

        if(type.value == 0){
            this.state.shipping = this.state.standard;
        }else if(type.value == 1){
            this.state.shipping = this.state.expedite;
        }else if(type.value == 2){
            this.state.shipping = this.state.overnight;
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                paymentShippingMethod: type
            }
        });
    }

    changeShippingCountry(event, data = 'data') {

        if (this.state.data.shippingCountry && this.state.data.shippingCountry.name !== US) {
            this.state.shipping = this.state.standard;
        }

        this.setState({
            [data]: update(this.state[data], { [event.target.name]: {$set: event.target.value} })
        }, ()=> {
            this.getTotalDynamic();
        });
    }

    createOrder(){

        if (!this.state.data.hasABC) {
            console.log(!this.state.data.hasABC)
            document.getElementById("submit-card").click();
        }

        setTimeout(()=>{
            if(this.state.validCard == false) {
                return;
            }
            if(!this.validate()) {
                this.props.enqueueSnackbar("You need to entered required fields!", {variant: 'error'});
                return;
            }
    
            createOrder({
                shippingType : this.state.data.paymentShippingMethod.value
            }).then(response => {
    
                if(!response.ok) {
                    return;
                }
    
                this.state.data.orderId = response.data.id;
    
                setTimeout(() => {
                    checkoutOrder(this.state.data).then(response => {
    
                        if(!response.ok) {
                            return;
                        }
            
                        this.props.history.push('/orders')
                    });
    
                }, 500);
            });
        }, 1000)
    }

    sameAddress(){
        this.state.data.paymentSameAsShipping = !this.state.data.paymentSameAsShipping;
        if(this.state.data.paymentSameAsShipping){
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    paymentAddress1: this.state.data.shippingAddress1,
                    paymentAddress2: this.state.data.shippingAddress2,
                    paymentCity: this.state.data.shippingCity,
                    paymentState: this.state.data.shippingState,
                    paymentPostalCode: this.state.data.shippingPostalCode,
                    paymentCountry: this.state.data.shippingCountry
                }
            });
        }else{
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    paymentAddress1: "",
                    paymentAddress2: "",
                    paymentCity: "",
                    paymentState: "",
                    paymentPostalCode: "",
                    paymentCountry: null
                }
            });
        }
    
    }

    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return;
        }

        if(this.state.page > 5){
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.page + 4) < this.state.totalPages)){
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page == i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    onPageClick(i){
        this.state.page = i;
        this.getItems(i, this.state.perPage);
    }

    onBack(){
        this.state.page = Number(this.state.page) - 1;
        this.getItems(this.state.page, this.state.perPage);
    }

    onForward(){
        this.state.page = Number(this.state.page) + 1;
        this.getItems(this.state.page, this.state.perPage);
    }

    renderItems() {
        let result = [];
        
        for(let item of this.state.items) {
            let image = item.customer_product_variation_images[0] ? item.customer_product_variation_images[0].merged_image : '';
            result.push(

                <div className="item">
                    <img src={process.env.REACT_APP_baseUrl + image} />
                    <div className="product-info">
                        <div className="name">
                            { item.customer_product_variation.product_variation.product.name }</div>
                        <div className="quantity">Quantity: { item.quantity }</div>
                        {
                             !this.isUSShipping() && !this.itemHasIntExpeditedOption(item) ?
                                <div className="expedited-warning">No international expedited shipping option</div>
                                :
                                ""
                        }
                    </div>
                    <div className="price">${(item.customer_product_variation.product_variation.price * item.quantity).toFixed(2)}</div>
                </div>
            );
        }
        return result;
    }

    submitToken(token){

        if(token == null){
            this.props.enqueueSnackbar("Your card number is invalid!", {variant: 'error'});
            this.state.validCard = false;
            return;
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                tokenId: token.id,
                expMonth: token.card.exp_month,
                expYear: token.card.exp_year,
                last4: token.card.last4
            }
           
        })
    }

    isUSShipping() {
        return this.state.data.shippingCountry && this.state.data.shippingCountry.name === US;
    }

    itemHasIntExpeditedOption(item) {
        return item.product_variation_shipping_price &&
            item.product_variation_shipping_price.hasOwnProperty('int_expedited_first') &&
            item.product_variation_shipping_price.int_expedited_first
    }

    cartHasItemWithoutIntExpeditedOption() {
        let cc = 1;
        for (let item of this.state.items) {
            cc++;
            if (!this.itemHasIntExpeditedOption(item)) {
                return true;
            }
        }
        return false;
    }

    changeDataCountry(event, data = 'data') {
        this.setState({
            [data]: update(this.state[data], { [event.target.name]: {$set: event.target.value} })
        }, ()=> {
            this.getTotal();
        });
    }

    render() {
        return (
            <div id="checkout">
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                <div className="title">Checkout</div>
                <Elements stripe={stripePromise}>
                    <div className="content">
                        <div className="left-part">
                            <div className="shipping-address">
                                <div className="header">Shipping Address</div>
                                <TextField
                                    name='shippingFirstName'
                                    onChange={ this.changeData }
                                    margin="normal"
                                    type='text'
                                    value={ this.state.data.shippingFirstName }
                                    placeholder={'First Name'}
                                />
                                <TextField
                                    name='shippingLastName'
                                    onChange={ this.changeData }
                                    margin="normal"
                                    type='text'
                                    value={ this.state.data.shippingLastName }
                                    placeholder={'Last Name'}
                                />
                                <TextField
                                    name='shippingAddress1'
                                    onChange={ this.changeData }
                                    margin="normal"
                                    type='text'
                                    value={ this.state.data.shippingAddress1 }
                                    placeholder={'Address Line 1'}
                                />
                                <TextField
                                    name='shippingAddress2'
                                    onChange={ this.changeData }
                                    margin="normal"
                                    type='text'
                                    value={ this.state.data.shippingAddress2 }
                                    placeholder={'Address Line 2'}
                                />
                                <div className="row">
                                    <TextField
                                        name='shippingCity'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingCity }
                                        placeholder={'City'}
                                    />
                                    <TextField
                                        name='shippingState'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingState }
                                        placeholder={'State/Province/Region'}
                                    />
                                </div>
                                <div className="row">
                                    <SelectControl
                                        className='payment-country'
                                        options={this.state.countries}
                                        valueKey = { 'id' }
                                        onChange={ this.changeShippingCountry }
                                        nameKey= { 'name' }
                                        name = {'shippingCountry'}
                                        selected={this.state.data.shippingCountry}
                                        placeholder = {'Country'}
                                    />
                                    <TextField
                                        name='shippingPostalCode'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingPostalCode }
                                        placeholder={'Postal Code'}
                                    />
                                </div>
                                <div className="row">
                                    <TextField
                                        name='shippingPhoneNumber'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingPhoneNumber }
                                        placeholder={'Phone Number'}
                                    />
                                    <TextField
                                        name='shippingEmail'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingEmail }
                                        placeholder={'Your Email Address'}
                                    />
                                </div>
                            </div>

                            { this.state.data.displayBillingAndPaymentTab ? 
                            <div className="payment-and-billing">
                                <div className="header">Payment and Billing</div>
                                {/* <div className="row"> */}
                                    <TextField
                                        name='paymentCardName'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.paymentCardName }
                                        placeholder={'Name on card'}
                                    />
                                {/* </div> */}
                                 { !this.state.data.hasABC ? <CheckoutForm submitToken={this.submitToken} /> : '' }
                                    {/* <TextField
                                        name='paymentCardNumber'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.paymentCardNumber }
                                        placeholder={'Card number'}
                                    />
                                </div>

                                <div className="row1">
                                    <TextField
                                        name='paymentMonth'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.paymentMonth }
                                        placeholder={'Month'}
                                    />
                                    <TextField
                                        name='paymentYear'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.paymentYear }
                                        placeholder={'Year'}
                                    />
                                    <TextField
                                        name='paymentCVV'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.paymentCVV }
                                        placeholder={'CVV'}
                                    />
                                </div> */}
                                { !this.state.data.hasABC  ? <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.data.paymentUseAsPrimaryMethod}
                                        onChange={this.changeCheckBox}
                                        name="paymentUseAsPrimaryMethod"
                                        color="primary"
                                    />
                                    }
                                    label="Save card for future payments"
                                />
                                : ''}
                                <TextField
                                        name='paymentAddress1'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.paymentAddress1 }
                                        placeholder={'Billing Address'}
                                />
                                <TextField
                                        name='paymentAddress2'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.paymentAddress2 }
                                        placeholder={'Address Line 2'}
                                />
                                <TextField
                                        name='paymentCity'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.paymentCity }
                                        placeholder={'City'}
                                />
                                <div className="row">
                                    <TextField
                                        name='paymentState'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.paymentState }
                                        placeholder={'State/Province'}
                                    />
                                    <TextField
                                        name='paymentPostalCode'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.paymentPostalCode }
                                        placeholder={'Postal Code'}
                                    />
                                </div>
                                <SelectControl
                                    className='select'
                                    options={this.state.countries}
                                    valueKey = { 'id' }
                                    onChange={ this.changeData }
                                    nameKey= { 'name' }
                                    name = {'paymentCountry'}
                                    selected={this.state.data.paymentCountry}
                                    placeholder = {'Country'}
                                />
                            </div> : ''}

                            {/* <ReCAPTCHA
                                size="normal"
                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                //onChange={onChange}
                            /> */}
                            <div className="terms">Returns and refunds are subject to our Terms of Service.</div>
                            <div className="terms">International Shipping: Please note that your country may charge Import duties, taxes and fees that you may have to pay ahead of delivery.</div>
                        </div>
                        <div className="right-part">
                            <div className="header">
                                Order Summary
                                <div className="edit" onClick={ () => this.props.history.push('/cart') }>Edit</div>
                            </div>

                            { this.renderItems() }

                            <div className={'pagination'}>
                                {
                                    this.state.page > 1 &&
                                    <img onClick={this.onBack} src={'/images/leftFilter.png'} />
                                }
                                <div className={'pages'}>
                                    {this.renderPagination()}
                                </div>
                                {
                                    this.state.page < this.state.totalPages &&
                                    <img onClick={this.onForward} src={'/images/rightFilter.png'} />
                                }
                            </div>

                            <dev className="total">
                                <div className="text">
                                    <div className="left">Subtotal</div>
                                    <div className="right">${ this.state.total.toFixed(2) }</div>
                                </div>

                                <div className="text">
                                    <div className="left">Shipping</div>
                                    <div className="right">${ this.state.shipping.toFixed(2) }</div>
                                </div>

                                {/* <div className="text">
                                    <div className="left">Estimated Taxes</div>
                                    <div className="right">$00.00</div>
                                </div> */}

                                <div className="text">
                                    <div className="left">Shipping Type</div>
                                    <div className={ this.state.data.paymentShippingMethod.value == 0 ? "type active": "type"}
                                        onClick={ () => this.changeShippingType({value: 0,name: 'Standard'})}
                                    >Standard</div>
                                    <div className={ !this.isUSShipping() && this.cartHasItemWithoutIntExpeditedOption() ? "type disabled" : "" +
                                        this.state.data.paymentShippingMethod.value == 1 ? "type active": "type"}
                                        onClick={ () => this.changeShippingType({value: 1,name: 'Expedited'})}
                                    >Expedited</div>
                                    <div className={
                                        !this.isUSShipping() ? "type disabled" : "" +
                                        this.state.data.paymentShippingMethod.value == 2 ? "type active": "type"}
                                        onClick={ () => this.changeShippingType({value: 2,name: 'Overnight'})}
                                    >Overnight</div>
                                </div>
                            </dev>

                            <div className="total1">
                                <div className="left">Total</div>
                                <div className="right">${ (this.state.shipping + this.state.total).toFixed(2) }</div>
                            </div>

                            <Button className="proceed" onClick={() => this.createOrder()}>Place order</Button>

                        </div>
                    </div>
                </Elements>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout)));
