import React from 'react';
import classNames from 'classnames';
import { useTable, useBlockLayout } from 'react-table';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const Table = ({ columns, data, onRowClick, tableClassName, theadClassName, thClassName, tableDataClassName, colorfulRows = false, }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data, useBlockLayout });

  const getDataRowBackground = (i) => {
    if(!colorfulRows) return {};

    return { backgroundColor: i % 2 == 0 ? colors.white : colors.secondaryLight }
  }

  return (
    <table className={classNames('table', tableClassName)} {...getTableProps()}>
      <thead className={classNames("thead", theadClassName)}>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={classNames("th", thClassName)}  {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr onClick={() => onRowClick && onRowClick(row.original)} className={classNames("data-row",{'data-row-hover': onRowClick !== undefined })} {...row.getRowProps()} style={getDataRowBackground(i)}>
              {row.cells.map(cell => {
                return <td className={classNames("td", tableDataClassName)} {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

Table.propTypes = {

}

export default Table;