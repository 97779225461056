import React from 'react';
import { number, string } from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const Loader = ({ width, height, color, className }) => {
  return (
    <div className={classNames('loader-container', className)}>
      <svg className="loader" width={width} height={height} viewBox="0 0 402 402" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.3" d="M402 201C402 312.009 312.009 402 201 402C89.9908 402 0 312.009 0 201C0 89.9908 89.9908 0 201 0C312.009 0 402 89.9908 402 201ZM62.6752 201C62.6752 277.395 124.605 339.325 201 339.325C277.395 339.325 339.325 277.395 339.325 201C339.325 124.605 277.395 62.6752 201 62.6752C124.605 62.6752 62.6752 124.605 62.6752 201Z" fill={color ?? "#020C6A"}/>
        <path d="M370.662 201C387.97 201 402.249 186.886 399.562 169.789C397.101 154.134 392.792 138.789 386.7 124.081C376.599 99.6942 361.793 77.5361 343.128 58.8715C324.464 40.207 302.306 25.4014 277.919 15.3002C263.211 9.20781 247.866 4.89869 232.211 2.43798C215.114 -0.24948 201 14.0303 201 31.3376V31.3376C201 48.6449 215.205 62.3223 232.07 66.2099C239.522 67.9276 246.836 70.2643 253.935 73.2045C270.717 80.156 285.966 90.3449 298.81 103.19C311.655 116.034 321.844 131.283 328.795 148.065C331.736 155.164 334.072 162.478 335.79 169.93C339.678 186.795 353.355 201 370.662 201V201Z" fill="#020C6A"/>
      </svg>
    </div>
  )
}

Loader.propTypes = {
  width: number,
  height: number,
  color: string
}

export default Loader;