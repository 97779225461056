import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import IconExit from 'src/redesign/components/Icon/Exit';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import IconCircleExclamation from 'src/redesign/components/Icon/CircleExclamation';

const Dialog = ({ text, children, showExclamation = true, defaultButton, primaryButton, onClose }) => {
  return ReactDOM.createPortal(<div className="dialog-container">
    <div className="dialog-content">
      {showExclamation && (
        <div className='exclamation'>
          <IconCircleExclamation width={90} height={90} color={colors.orange}/>
        </div>
      )}
      {text && (
      <div className='text'>
        {text}
      </div>
      )}
      {children}
      {(defaultButton || primaryButton) && (
        <div className='buttons'>
          {defaultButton && <SecondaryButton {...defaultButton} className={classNames('button','defaultButton')}/>}
          {primaryButton && <SubmitButton {...primaryButton} className='button'/>}
        </div>
      )}
      <div className="icon-close" onClick={onClose}>
        <IconExit width={14} height={14} color={colors.darkBlue}/>
      </div>
    </div>
  </div>, document.getElementById('dialog-root'));
}

export default Dialog;