import React from 'react';

import Page from 'src/redesign/components/Page';
import FAQ from 'src/redesign/components/FAQ';

import LandingHeader from './components/LandingHeader';
import DesignWithEase from './components/DesignWithEase';
import ExploreCollection from './components/ExploreCollection';
import ServingOnline from './components/ServingOnline';
import OnDemand from './components/OnDemand';
import GiftMemory from './components/GiftMemory';
import StartDesigningToday from './components/StartDesigningToday';
import Personalized from './components/Personalized';

import './style.scss';

const CustomProductTemplate = ({
  onStartDesigningClick,
  categoryName,
  //header
  headerTitle,
  headerSubtitle,
  headerImage,
  //design with ease
  easeTitle,
  easeOptions,
  //on-demand
  onDemandTitle,
  onDemandSubtitle,
  //explore-collection
  exploreTitle,
  exploreMainProduct,
  exploreOtherProducts,
  //gift-memory
  giftMemoryImage,
  giftMemoryTitle,
  giftMemoryDescription,
  //start-today
  startTodayTitle,
  startTodaySubtitle,
  startTodayLink,
  //personalized
  personalizedTitle,
  personalizedSubtitle,
  //seo
  seoTitle,
  seoDescription
}) => {

  return (
    <Page
      seoTitle={seoTitle}
      seoDescription={seoDescription}
    >
      <div className='custom-product-landing-page'>
        
        <LandingHeader
          title={headerTitle}
          subtitle={headerSubtitle}
          onStartDesigningClick={onStartDesigningClick}
          image={headerImage}
        />

        <DesignWithEase
          title={easeTitle}
          options={easeOptions}
        />

        <OnDemand
          title={onDemandTitle}
          subtitle={onDemandSubtitle}
          onStartDesigningClick={onStartDesigningClick}
        />
        
        <ExploreCollection
          title={exploreTitle}
          categoryName={categoryName}
          mainProductToFind={exploreMainProduct}
          otherProductsToFind={exploreOtherProducts}
        />

        <ServingOnline />

        <GiftMemory
          image={giftMemoryImage}
          title={giftMemoryTitle}
          description={giftMemoryDescription}
        />

        <StartDesigningToday
          title={startTodayTitle}
          subtitle={startTodaySubtitle}
          linkText={startTodayLink}
        />

        <Personalized
          title={personalizedTitle}
          subtitle={personalizedSubtitle}
          onStartDesigningClick={onStartDesigningClick}
        />

        <FAQ />

      </div>
    </Page>
  )
}

export default CustomProductTemplate;