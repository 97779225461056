import React from 'react';
import { number, string } from 'prop-types';

const IconSync = ({ width, height, color, strokeWidth = 2 }) => (
<svg width={width} height={height} viewBox="0 0 310 72" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7 13.4901L64.298 58.5099L129.781 13.4901L191.172 58.5099L258.702 13.4901L316 58.5099" stroke={color} strokeWidth={strokeWidth}/>
</svg>
);

IconSync.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
  strokeWidth: number
}

export default IconSync;