import React from 'react';
import { number, string } from 'prop-types';

const IconSettings = ({ width, height, color, fillColor }) => (
  <svg width={width} height={height} viewBox="0 0 22 21" fill={fillColor ? fillColor : "white"} xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7458 2.43428C13.4984 3.62214 14.8725 4.33333 16.3363 4.33333C17.5746 4.33333 18.5586 4.94141 19.0821 5.76763C19.6028 6.58947 19.6746 7.63065 19.0821 8.56577C18.3251 9.76049 18.3251 11.2396 19.0821 12.4343C19.6746 13.3694 19.6028 14.4106 19.0821 15.2324C18.5586 16.0586 17.5746 16.6667 16.3363 16.6667C14.8725 16.6667 13.4985 17.3779 12.7458 18.5657C11.5341 20.4781 8.46597 20.4781 7.25424 18.5657C6.50157 17.3779 5.12751 16.6667 3.66373 16.6667C2.4254 16.6667 1.44144 16.0586 0.917927 15.2324C0.397192 14.4105 0.325407 13.3694 0.917928 12.4342C1.67494 11.2395 1.67494 9.7604 0.917931 8.56568C0.32542 7.63057 0.397205 6.58942 0.917931 5.7676C1.44144 4.9414 2.42538 4.33333 3.6637 4.33333C5.12746 4.33333 6.50151 3.62214 7.25417 2.43429C8.46591 0.521909 11.534 0.521902 12.7458 2.43428ZM6.84135 10.4017C6.82717 10.7923 6.89768 11.1806 7.04757 11.5439C7.19741 11.907 7.42297 12.2365 7.70887 12.5148C7.99467 12.7931 8.33537 13.015 8.7103 13.1699C9.0852 13.3248 9.48837 13.4104 9.89708 13.4227C10.3058 13.435 10.7139 13.3739 11.0985 13.2422C11.4831 13.1104 11.8382 12.91 12.1426 12.6504C12.4471 12.3907 12.6954 12.0764 12.8708 11.7241C13.0462 11.3715 13.1447 10.9889 13.1589 10.5983C13.1731 10.2077 13.1026 9.81941 12.9527 9.45613C12.8028 9.09301 12.5773 8.76349 12.2914 8.48516C12.0056 8.20692 11.6649 7.98503 11.2899 7.83009C10.915 7.67518 10.5119 7.58963 10.1032 7.57731C9.69444 7.56499 9.28635 7.62608 8.90176 7.75783C8.51713 7.8896 8.16203 8.09001 7.85761 8.34962C7.55311 8.60931 7.30486 8.92357 7.12948 9.27595C6.95402 9.62849 6.85554 10.0111 6.84135 10.4017Z" stroke={color}/>
  </svg>
);

IconSettings.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconSettings;