import React from 'react';
import { number, string } from 'prop-types';

const IconCopy = ({ width, height, color }) => (
    <svg width={width} height={height} style={{ minWidth: width, minHeight: height }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 5.5H13.5C14.0523 5.5 14.5 5.94772 14.5 6.5V13.5C14.5 14.0523 14.0523 14.5 13.5 14.5H6.5C5.94772 14.5 5.5 14.0523 5.5 13.5V6.5C5.5 5.94772 5.94772 5.5 6.5 5.5Z" fill="#E4E6F1"/>
      <path d="M10 5.5V2C10 1.44772 9.55229 1 9 1H2C1.44772 1 1 1.44772 1 2V9C1 9.55229 1.44772 10 2 10H5.5M13.5 5.5H6.5C5.94772 5.5 5.5 5.94772 5.5 6.5V13.5C5.5 14.0523 5.94772 14.5 6.5 14.5H13.5C14.0523 14.5 14.5 14.0523 14.5 13.5V6.5C14.5 5.94772 14.0523 5.5 13.5 5.5Z" stroke={color} strokeWidth="1.5"/>
    </svg>
);

IconCopy.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconCopy;