import React from 'react';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Loader from 'src/redesign/components/Loader';
import ListTable from 'src/redesign/components/ListTable';
import { getShippingPricingProduct } from 'src/services/admin/ProductService';

import colors from 'src/scss/_colors.scss';

const ProductDetailsShipping = ({ id }) => {
  const [loading, setLoading] = React.useState(false);

  const [pricing, setPricing] = React.useState([]);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setLoading(true);
    try {
      const { data } = await getShippingPricingProduct(id);

      setPricing([
        {
          to: 'United States',
          sizes: 'All',
          first: `USD ${data?.us_standard_first}`,
          additional: `USD ${data?.us_standard_n}`
        },
        {
          to: 'Rest of World',
          sizes: 'All',
          first: `USD ${data?.int_standard_first}`,
          additional: `USD ${data?.int_standard_n}`
        }
      ])
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load shipping!', false);
    } finally {
      setLoading(false);
    }
  }

  return <div>
    {loading ? (
      <Loader
        width={100}
        height={100}
        color={colors.primary}
      />
    ) : (
      <ListTable
        columns={[
          {
            name: 'to',
            text: 'Shipping to',
            flex: '25%'
          },
          {
            name: 'sizes',
            text: 'Sizes/Type',
            flex: '25%'
          },
          {
            name: 'first',
            text: 'First item',
            isPrice: true,
            flex: '25%'
          },
          {
            name: 'additional',
            text: 'Additional item',
            isPrice: true,
            flex: '25%'
          }
        ]}
        items={pricing}
      />
    )}
  </div>
}

export default ProductDetailsShipping;