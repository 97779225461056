import React, {Component} from 'react'

import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import { getOrderStatusString } from "../../constants/OrderStatus";
import { changeOrderItemQuantity,
         deleteItemFromOrder } from "../../services/OrderService";
import OrderEnum from "../../constants/OrderEnum";

class Item extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: this.props.order,
            orderItems: this.props.order.items,
            itemSKUs: {},
        }
    }

    componentDidMount(){
        let itemSKUs = {};

        this.state.orderItems.forEach(item => {
            itemSKUs[item.id] = item.sku;
        });

        this.setState({
            ...this.state,
            itemSKUs: itemSKUs
        });
    }

    changeData(event, id)
    {
        let itemSKUs = this.state.itemSKUs;
        itemSKUs[id] = event.target.value; 

        this.setState({
            ...this.state,
            itemSKUs: itemSKUs
        })    
    }

    deleteItemFromOrder(id, index) {

        let items = this.state.orderItems;

        items.splice(index,1);

        this.setState({
            ...this.state,
            orderItems: items
        })

        deleteItemFromOrder({
            id: id
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.state.order.quantity = response.data.quantity;
            this.state.order.total = response.data.total;

            this.props.enqueueSnackbar('Successfully deleted item', { variant: 'success' });
        });
    }

    plus(item) {
        item.quantity = item.quantity + 1;
        this.changeQuantity(item);
    }

    minus(item) {
        if (item.quantity > 0) {
            item.quantity = item.quantity - 1;
            this.changeQuantity(item);
        }
    }

    changeQuantity(item) {
        changeOrderItemQuantity({
            quantity : item.quantity,
            orderItemId : item.id
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.state.order.total = response.data.total;

            this.setState({
                ...this.state,
                orderItems : this.state.orderItems
            });

        });
    }

    renderAttributes(attributes){
        let result = [];
        for(let item of attributes)
        {
            result.push(
                <div className="info">
                    <div className="left">
                        { item.name }:
                    </div>
                    <div className="right">
                        { item.value }
                    </div>
                </div>
            );
        }

        return result;

    }

    renderItems() {
        let result = [];
        
        let i = 0;
        for (let item of this.state.orderItems) {
            let index = i;

            result.push(

                <div className="row">
                    <div className="details">
                        <div className="details-header">
                            Item { item.id }
                           {
                                this.state.order.status == OrderEnum.NewOrder &&
                                <a className="delete-a" onClick={ () => this.deleteItemFromOrder(item.id, index) }>
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.05648 1.36363C5.05648 0.91648 5.40545 0.58968 5.8526 0.58968H9.12536C9.57237 0.58968 9.92134 0.91648 9.92134 1.36363V2.2113H10.511V1.36363C10.511 0.591407 9.89759 0 9.12536 0H5.8526C5.08038 0 4.4668 0.591407 4.4668 1.36363V2.2113H5.05648V1.36363Z" fill="#C4C4C4"/>
                                        <path d="M4.0023 15H10.9752C11.6476 15 12.1693 14.4086 12.1693 13.6732V4.57002H2.80811V13.6732C2.80811 14.4086 3.32983 15 4.0023 15V15ZM9.3683 5.97786C9.3683 5.81503 9.50032 5.68302 9.66314 5.68302C9.82597 5.68302 9.95798 5.81503 9.95798 5.97786V12.9435C9.95798 13.1063 9.82597 13.2383 9.66314 13.2383C9.50032 13.2383 9.3683 13.1063 9.3683 12.9435V5.97786ZM7.19386 5.97786C7.19386 5.81503 7.32587 5.68302 7.4887 5.68302C7.65152 5.68302 7.78354 5.81503 7.78354 5.97786V12.9435C7.78354 13.1063 7.65152 13.2383 7.4887 13.2383C7.32587 13.2383 7.19386 13.1063 7.19386 12.9435V5.97786ZM5.01941 5.97786C5.01941 5.81503 5.15142 5.68302 5.31425 5.68302C5.47707 5.68302 5.60909 5.81503 5.60909 5.97786V12.9435C5.60909 13.1063 5.47707 13.2383 5.31425 13.2383C5.15142 13.2383 5.01941 13.1063 5.01941 12.9435V5.97786Z" fill="#C4C4C4"/>
                                        <path d="M2.50614 3.98034H12.4717C12.8788 3.98034 13.2088 3.65038 13.2088 3.24324C13.2088 2.83611 12.8788 2.50614 12.4717 2.50614H2.50614C2.09901 2.50614 1.76904 2.83611 1.76904 3.24324C1.76904 3.65038 2.09901 3.98034 2.50614 3.98034Z" fill="#C4C4C4"/>
                                    </svg>
                                </a>
                            }
                        </div>
                        <div className="partner-id">Partner Order Item ID: { item.id }</div>
                        <div className="partner-id">Vendor Order ID:N/A</div>
                        <div className="info" style={{marginTop:"25px"}}>
                            <div className="left">
                                Status
                            </div>
                            <div className="right">
                                { getOrderStatusString(item.status) } 
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                SKU
                            </div>
                            <div className="right">
                                { this.state.itemSKUs[item.id] }
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Quantity
                            </div>
                            <div className="right">
                                <div className="product-quantity">
                                    <div className="quantity-option" onClick={() => this.plus(item)}>+</div>
                                    <div>{ item.quantity }</div>
                                    <div className="quantity-option" onClick={() => this.minus(item)}>-</div>
                                </div>
                            </div>
                        </div>
                        { this.renderAttributes(item.attributes)}
                        { this.props.isAdmin ? <div> Admin Extra fields </div> : ''}
                        { this.props.isAdmin && item.extraFields && 
                        item.extraFields.map(
                            (extraField) => 
                                    <span>
                                    {extraField.name} : {extraField.value}
                                    </span>
                    )}
                    </div>

                    <div className="details">
                        <div className="details-header">
                            Replace this with customer art
                            {
                                this.state.order.status == OrderEnum.NewOrder &&
                                <a onClick={()=> {
                                    this.props.history.push('/order-edit',
                                        {
                                            arrayIds : [item.customer_product_variation.id],
                                            orderItemId: item.id ,
                                            orderId: this.state.order.id
                                        });
                                    }}>
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.3802 1.9648C12.3856 1.47526 12.1955 1.00379 11.852 0.654932C11.5027 0.309511 11.0333 0.11266 10.5421 0.105623C10.0595 0.103521 9.59577 0.293538 9.25342 0.633783L11.852 3.25351C12.2006 2.91675 12.3921 2.44932 12.3802 1.9648Z" fill="#C4C4C4"/>
                                        <path d="M11.2607 3.84501L8.66211 1.24645L2.64103 7.26753L5.23959 9.86609L11.2607 3.84501Z" fill="#C4C4C4"/>
                                        <path d="M2.30299 8.11269L1.71143 10.7958L4.39452 10.2042L2.30299 8.11269Z" fill="#C4C4C4"/>
                                        <path d="M14.5775 14.0493H0.422536C0.189169 14.0493 0 14.2385 0 14.4718C0 14.7052 0.189169 14.8944 0.422536 14.8944H14.5775C14.8108 14.8944 15 14.7052 15 14.4718C15 14.2385 14.8108 14.0493 14.5775 14.0493Z" fill="#C4C4C4"/>
                                    </svg>
                                </a>
                            }
                        </div>
                        <div className="customer-image">
                            <img src={item.image} />
                        </div>
                    </div>
                </div>
            );

            i++;
        }
        return result;
    }

    render() {

        return (
            <div id="item">
                { this.renderItems() }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Item)));
