import React from 'react';
import { LogoHeader } from 'src/redesign/components/Logo';
import Page from 'src/redesign/components/Page';

import './style.scss';

const PrivacyPolicy = () => (
  <Page
    seoTitle='Privacy Policy | Surge Print'
    seoDescription={`Privacy Policy Page. SurgePrint offers high-quality, customizable printing solutions for a lasting impression. Unleash the power of print excellence!`}
  >
    <div className='privacy-policy'>
      <div className='privacy-policy-content'>
        <div className='privacy-policy-logo-container'>
          <LogoHeader />
          <div className='privacy-policy-logo-title'>
            Surge print
          </div>
        </div>
        
        <h1><strong>Privacy Policy</strong></h1>

        <p>
          <em>Last updated: </em><span lang="en-US"><em>September</em></span><em> 2020</em>
        </p>
        <p>
          <span lang="en-US">T</span>hank you for using <span lang="en-US">Surge Print.</span>Before using or accessing our platform, please read this Privacy Policy carefully<span lang="en-US">.</span>We believe in transparency, and we&rsquo;re committed to being upfront about our privacy practices, including how we treat your personal information.
        </p>
        
        <p lang="en-US">
          We at Surge Print (&ldquo;we&rdquo; or &ldquo;our&rdquo; or &ldquo;us&rdquo;) want to make sure all the personal information we have collected about you is safe and secure whether we collect it through our website at www.surgeprint.com (&ldquo;Site&rdquo;) or from you via other electronic means (such as email or social media platforms). This notice sets out our commitments to you, in compliance with and beyond the General Data Protection Regulation (commonly known as the GDPR) and explains how we collect, store and use your personal information. 
        </p>

        <h3><strong>A. Consent </strong></h3>
        
        <p>
          By using this Website, you consent to the use of the information that you provide to us in accordance with this Policy. We do update this Policy from time to time, so please review this Policy regularly. If we materially alter our Policy, we will notify you of such changes by posting a notice on our Website. Your continued use of the Website will be deemed your agreement that your information may be used in accordance with the new policy. If you do not agree with the changes, then you should stop using the Website, and you should notify us that you do not want your information used in accordance with the changes
        </p>

        <h3><strong>B. Personal Data </strong></h3>
        
        <p>
          We collect and use both Personal Data and Anonymous Data about users to (i) provide, measure, and improve the Services, (ii) communicate with you about your Account (as defined below) or transactions, (iii) send you information about changes to the Services or our policies, (iv) send you offers and promotions, (v) personalize content for you on the Services, (vi) detect, investigate and prevent activities that may violate our policies or applicable law, and (vii) as otherwise described in this Policy.
        </p>

        <h3><strong>C. COLLECTION OF INFORMATION</strong></h3>

        <p>
          In operating our Website and in order to provide you with our services, we collect certain information from you and that you provide to us, when you create an account with us, buy a product, or simply visit the Website. For example, when you create an account with us we collect your name and e-mail address<span lang="en-US">. Once you start to use our platform we collect information about the products you have designed, the artwork you have uploaded as well as the sales you have made and your customers&rsquo; data.<br /><br /></span>By submitting a telephone number to us, you agree that a representative of <span lang="en-US">Surge Print</span> can contact you at the number you provide<span lang="en-US">.<br /><br /></span>A &ldquo;cookie&rdquo; is a piece of text which asks permission to be placed on your computer&rsquo;s or device&rsquo;s hard drive. Once you agree, this cookie file is stored on the hard drive of your computer or device.
        </p>

        <p>
          We do not require that you accept cookies, and you may disable cookies at any time. However, some functionality of our Website may be impaired if you decline to accept cookies. To disable cookies, follow the instructions in your web browser.
        </p>
        
        <p><span lang="en-US">In order to use our platform</span>, you need to create a user account (an &ldquo;Account&rdquo;). When you create or update an Account, you must provide Personal Data that lets us contact you and deliver items purchased through the <span lang="en-US">platform</span>, and when you place an order we collect financial details such as your bank account number and/or credit card number that allow us or our third party service providers to process payments (&ldquo;Financial Information&rdquo;) all of which we may store and use as set forth in this Policy.</p>
        
        <h3><strong>D. How and why we use your data</strong></h3>

        <p>
          We use the data we collect to operate and improve offerings through the Website; to help authenticate your identity when you visit and transact with the Website; to prevent fraud;Managing your requests (like creating products, integrating sales channels, completing transactions and fulfilling orders), login and authentication, remembering your settings, processing payments, hosting and back-end infrastructure. to remember your preferences and registration information; to present, help measure, and research the effectiveness of our various offerings;send you administrative e-mail notifications, such as security or support and maintenance advisories, as well as order and shipping confirmations; and to customize the content provided to you<span lang="en-US">.</span>
        </p>
        
        <h3 lang="en-US"><strong>E. Your privacy rights and choices</strong></h3>
        
        <p>
          When it comes to your data and privacy, you have a lot of control for what <span lang="en-US">Surge Print</span> can do. All of your data settings can be managed from your Printify account page, or by emailin<span lang="en-US">g us at support@surgeprint.com</span> These include your rights to review, correct and delete your data.<br /><br /> By contacting us, you can do any or all of the following (a) edit your account information and settings, including profile and contact data; (b) unsubscribe from marketing messages or newsletters; (c) opt out of ad retargeting or other ad network functions; (d) access, manage, and remove geo-location information associated with your account; (e) delete your account login credentials; (f) make your account inaccessible, and/or (g) remove or anonymize any content you have posted to the Website or shared with others on the Website.<br /><br />Email Communications. We may periodically send you newsletters and e-mails that promote our site or Services. When you receive newsletters or promotional communications from us, you may indicate a preference to stop receiving further communications from us and you will have the opportunity to &ldquo;opt-out&rdquo; by following the unsubscribe instructions provided in the e-mail you receive or by contacting us directly at<span lang="en-US"> support@surgeprintcom</span>. Despite your indicated e-mail preferences, we may send you service related communications, including notices of any updates to this Policy, via the email address associated with your Account.
        </p>
        
        <h3 lang="en-US"><strong>F. Legal bases for processing</strong></h3>

        <p>
          We will process your personal information lawfully, fairly and in a transparent manner. We collect and process information about you only where we have legal bases for doing so.
        </p>
        <p>
          These legal bases depend on the services you use and how you use them, meaning we collect and use your information only where:
        </p>
        <p>
          it&rsquo;s necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract (for example, when we provide a service you request from us);
        </p>
        <p>
          it satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to market and promote our services, and to protect our legal rights and interests;
        </p>
        <p>
          you give us consent to do so for a specific purpose (for example, you might consent to us sending you our newsletter); or we need to process your data to comply with a legal obligation.
        </p>
        <p>
          Where you consent to our use of information about you for a specific purpose, you have the right to change your mind at any time (but this will not affect any processing that has already taken place).
        </p>

        <p>
          We don&rsquo;t keep personal information for longer than is necessary. While we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification. That said, we advise that no method of electronic transmission or storage is 100% secure and cannot guarantee absolute data security. If necessary, we may retain your personal information for our compliance with a legal obligation or in order to protect your vital interests or the vital interests of another natural person.
        </p>   
        
        <h3 lang="en-US"><strong>G. International transfers of personal information</strong></h3>

        <p>
          The personal information we collect is stored and processed in United States, or where we or our partners, affiliates and third-party providers maintain facilities. By providing us with your personal information, you consent to the disclosure to these overseas third parties.
        </p>
        <p>
          We will ensure that any transfer of personal information from countries in the European Economic Area (EEA) to countries outside the EEA will be protected by appropriate safeguards, for example by using standard data protection clauses approved by the European Commission, or the use of binding corporate rules or other legally accepted means.
        </p>
        <p>
          Where we transfer personal information from a non-EEA country to another country, you acknowledge that third parties in other jurisdictions may not be subject to similar data protection laws to the ones in our jurisdiction. There are risks if any such third party engages in any act or practice that would contravene the data privacy laws in our jurisdiction and this might mean that you will not be able to seek redress under our jurisdiction&rsquo;s privacy laws.
        </p>

        <h3 lang="en-US"><strong>H. Cookies</strong></h3>

        <p>
          We use &ldquo;cookies&rdquo; to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified.<br /><br /> You can block cookies by activating a setting on your browser allowing you to refuse the setting of cookies. You can also delete cookies through your browser settings. If you use your browser settings to disable, reject, or block cookies (including essential cookies), certain parts of our website will not function fully. In some cases, our website may not be accessible at all. Please note that where third parties use cookies we have no control over how those third parties use those cookies.<br /><br /> Our site contains cookies (a small text-only file that is placed on your computer by another computer on the internet to enable that computer to know when you are visiting a particular site). They are widely used in order to make sites work, or work more efficiently, as well as to provide information to our site owners. Most web browsers allow some control of most cookies through the browser settings. Please be aware that if you decline to accept cookies, it may prevent you from taking full advantage of our site.
        </p>
        
        <h3>Why do we use cookies?</h3>

        <p>
          Our site uses cookies to distinguish you from other users of our site and to distinguish which webpages are being used. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. We only use information collected from cookies for statistical analysis purposes and then the data is removed from the system.
        </p>
        <p>
          Overall, cookies help us to provide you with a better site by enabling us to monitor which webpages you find useful and which you do not. This helps us to provide you with a good experience when you browse our site and also allows us to improve our site. 
        </p>

        <h3><strong>I. Business transfers</strong></h3>

        <p>
          If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may continue to use your personal information according to this policy.
        </p>
        
        <h3 lang="en-US"><strong>J.&nbsp;THIRD PARTY PAYMENT SERVICES AND WEBSITES</strong></h3>

        <p>
          Third party payment service providers manage and process all of our users&rsquo; payment transactions. <span lang="en-US">Surge Print</span> does not receive, process, store or view any payment information.
        </p>

        <p>
          By accessing third party websites through our Website, you are consenting to the terms and privacy policies of those websites. We do not accept any responsibility or liability for those third parties or their policies whatsoever as we have no control over them.&nbsp;<br /><br />
        </p>
          
        <h3 lang="en-US"><strong>K. Our Policy Towards Children</strong></h3>

        <p>
          <span lang="en-US">Our platform</span> is not intended for children under 13. If you become aware that we may have Personal Data of a child, please contact us at <span lang="en-US">support@surgeprint.com</span>. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to remove such information and terminate the associated Account.<br /><br /><span lang="en-US">I</span>f you are under 13 years of age, then please do not use, access or submit any information to the Website at any time or in any manner. If Bonfire learns that personally identifiable information of persons under 13 years of age has been collected on the Website without verified parental consent, then Bonfire will take the appropriate steps to delete this information.
        </p>
        <p>
          By using our Website, you affirm that you are over 13 years of age. We are not liable for any damages that may result from a user&rsquo;s misrepresentation of his or her age. Parents or legal guardians of children under 13 cannot agree to these Terms on their behalf.&nbsp;
        </p>

        <p>
          EU Age Limitation:&nbsp;Due to the data and privacy laws in the European Union, we do not ask for or knowingly accept personally identifiable data from EU users under the age of 16. If you are a resident of the EU, by using our Website, you affirm that you are over 16 years of age. We are not liable for any damages that may result from a user&rsquo;s misrepresentation of his or her age. If <span lang="en-US">Surge Print</span> learns that personally identifiable information of residents of the EU under 16 years of age has been collected on the Website without verified parental consent, then <span lang="en-US">Surge Print</span> will take the appropriate steps to delete this information.
        </p>
        
        <h3 lang="en-US"><strong>L. Limits of our policy</strong></h3>


        <p>
          Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.
        </p>

        <h3 lang="en-US"><strong>M. Changes to this policy</strong></h3>

        <p>
          At our discretion, we may change our privacy policy to reflect current acceptable practices. We will take reasonable steps to let users know about changes via our website. Your continued use of this site after any changes to this policy will be regarded as acceptance of our practices around privacy and personal information.
        </p>
        <p>
          If we make a significant change to this privacy policy, for example changing a lawful basis on which we process your personal information, we will ask you to re-consent to the amended privacy policy.
        </p>
        <p>
          If you have any questions, comments, complaints or suggestions regarding our Website&rsquo;s Privacy Policy, or regarding any other aspects of our Website, please use the CONTACT US link on the home page of<span lang="en-US"> our website at www.surgeprint</span><span lang="en-US">.</span><span lang="en-US">com</span>&nbsp;or email us at <span lang="en-US">support@surgeprin</span><span lang="en-US">t.</span><span lang="en-US">com</span>
        </p>
        <p><span lang="en-US"><br /><br /><br /><br /></span></p>
      </div>
    </div>
  </Page>
);

export default PrivacyPolicy;