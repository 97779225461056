import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { v4 as uuid } from 'uuid';

import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import IconEdit from 'src/redesign/components/Icon/Edit';
import IconTrash from 'src/redesign/components/Icon/Trash';
import IconCheckmark from 'src/redesign/components/Icon/Checkmark';
import TextInput from 'src/redesign/components/Form/Sidepane/TextInput';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import ColorPicker from "src/redesign/components/ColorPicker";
// import ColorPicker from "material-ui-color-picker";

import './style.scss';
import colors from 'src/scss/_colors.scss';

const NEW_ATTRIBUTE_VALUE = id => ({
  id,
  name: '',
  customer_color_name: ''
});

const AttributeValues = ({ attributes: initialAttributes, onAttributeChange, onAttributeAdd,onAttributeDelete, isColor }) => {
  const [attributes, setAttributes] = React.useState(initialAttributes);
  const [newAttributes, setNewAttributes] = React.useState([NEW_ATTRIBUTE_VALUE(uuid())]);

  const isCustomerColorNameChanged = attribute => attribute.customer_colorName != '' && initialAttributes.find(x => x.id == attribute.id)?.customer_color_name != attribute.customer_color_name;
  const isNameChanged = attribute => attribute.name != '' && initialAttributes.find(x => x.id == attribute.id).name != attribute.name;

  const isAttributeChanged = (attribute) =>  isColor 
    ? isNameChanged(attribute) || isCustomerColorNameChanged(attribute)
    : isNameChanged(attribute);

  return (
      <div className='attribute-values-column'>
        {attributes.map((x, i) => (
          <AttributeValue
            attribute={x}
            index={i}
            setName={newName => setAttributes(prev => prev.map(a => a.id == x.id ? { ...a, name: newName } : a))}
            setColorName={newColor => setAttributes(prev => prev.map(a => a.id == x.id ? { ...a, customer_color_name: newColor } : a))}
            isColor={isColor}
            isAttributeChanged={() => isAttributeChanged(x)}
            onAttributeChange={() => onAttributeChange(x)}
            onAttributeDelete={() => onAttributeDelete(x)}
          />
        ))}
        {newAttributes.map((x, i) => (
          <AttributeValue
            attribute={x}
            index={i}
            setName={newName => setNewAttributes(prev => prev.map(a => a.id == x.id ? { ...a, name: newName } : a))}
            setColorName={newColor => setNewAttributes(prev => prev.map(a => a.id == x.id ? { ...a, customer_color_name: newColor } : a))}
            isColor={isColor}
            isAttributeChanged={() => x.name != '' || x.customer_color_name != ''}
            onAttributeChange={() => onAttributeAdd(x)}
            onAttributeDelete={() => setNewAttributes(prev => prev.filter(a => a.id != x.id))}
          />
        ))}
        
        <SubmitPrimaryButton
          className='add-attribute-value-button'
          text='+'
          onClick={() => setNewAttributes(prev => [...prev, NEW_ATTRIBUTE_VALUE(uuid())])}
        />
      </div>
    
  );
}

const AttributeValue = ({ attribute, index, setName, setColorName, isColor, isAttributeChanged, onAttributeChange, onAttributeDelete, isNew = false }) => {
  const getAttributeName = (attribute) => isColor ? attribute.customer_color_name : attribute.name;


  return (
    <div className='attribute-row' key={`attribute-${isNew ? 'new' : ''}${index}`}>
      {isColor && (
        <ColorPicker
          name='color'
          onChange={setName}
          inputProps={{
            style: {
              visibility: 'hidden'
            }
          }}
          setValue={attribute.name}
          value={attribute.name}
        />
      )}
      <input
        className='attribute-input'
        value={getAttributeName(attribute)}
        onChange={(e) => {
          const newValue = e.target.value;

          if(isColor) 
            setColorName(newValue);
          else
            setName(newValue);
        }
        } 
      />
      {isAttributeChanged(attribute) ? 
        <SubmitPrimaryButton
          disabled={getAttributeName(attribute) == ''}
          text='Save'
          onClick={onAttributeChange}
        />
        : null
      }
      <SecondaryButton
        text={
          <IconTrash
            width={20}
            height={20}
            color={colors.red}
          />
        }
        className='attribute-delete'
        onClick={onAttributeDelete}
      />
    </div>
  );
}

export default AttributeValues;