import classNames from 'classnames';
import { arrayOf, func, number, shape, string } from 'prop-types';
import React from 'react';

import './style.scss';

const Navigation = ({ tabs, selectedTab, setSelectedTab }) => {

  return (
    <div className="navigation-tabs">
      {tabs.map(({ text }, i) => (
        <div key={i} onClick={() => setSelectedTab(i)} className={classNames("navigation-tab", {'navigation-tab-selected': selectedTab === i })}>
          {text}
        </div>
      ))}
    </div>
  )

}

Navigation.propTypes = {
  tabs: arrayOf(shape({
    text: string,
    to: string,
  })),
  selectedTab: number,
  setSelectedTab: func
}

export default Navigation;