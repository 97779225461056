import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";

export async function getBusinessInformation() {
    return await request('/api/business_information', [],  HttpMethod.GET);
}

export async function setBusinessInformation(data) {
    return await request('/api/business_information', data,  HttpMethod.POST);
}
