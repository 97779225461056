import React from 'react';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import IconChevronDown from 'src/redesign/components/Icon/Chevron/Down';

import colors from 'src/scss/_colors.scss';

import './style.scss';

function SelectMulti({ name, validate, options, placeholder }) {
  return (
    <Field name={name} validate={validate}>
      {({
        input: { name, value, onChange, ...restInput },
        meta,
        formControlProps
      }) => {
        const showError =
          ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
          meta.touched;

        const selectedValues = Array.isArray(value) ? value : [];
        const isValueSelected = v => selectedValues.includes(v);

        return (
          <FormControl {...formControlProps} error={showError}>
            <Select
              className='sidepane-multiselect'
              placeholder={placeholder}
              SelectDisplayProps={{
                style: {
                  backgroundColor: 'transparent',
                  height: 28,
                  width: 224,
                  fontSize: 12,
                  lineHeight: '14px',
                  padding: 12,
                  marginTop: 14
                },
              }}
              style={{
                backgroundColor: colors.white,
                color: colors.primary,
                border: `1px solid ${colors.blac03}`,
                borderRadius: 4,
                height: 28,
                width: 224,
                fontSize: 12,
                lineHeight: '14px',
              }}
              IconComponent={() =>
                <div style={{ marginRight: 10 }}>
                  <IconChevronDown
                    width={12}
                    height={6}
                    color={colors.primary}
                  />
                </div>
              }
              multiple
              name={name}
              value={selectedValues}
              onChange={onChange}
              disableUnderline={true}
              inputProps={restInput}
            >
              {options.map((option) => (
                <option
                  key={option.key}
                  value={option.key}
                  className={classNames('option', {'option-selected': isValueSelected(option.key)})}
                >
                  {option.text}
                </option>
              ))}
            </Select>
            {showError && (
              <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
}

SelectMulti.propTypes = {};

export default SelectMulti;