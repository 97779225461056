export const DEFAULT_VARIATION_PRICE = 5;

export const STORAGE_INITIAL_DATA = {
  name: null,
  description: null,
  image: null,
}

export const SHOPIFY_INITIAL_DATA = {
  id: null,
  name: null,
  description: null,
  tags: [],
  options: [],
  variations: [],
  earned: DEFAULT_VARIATION_PRICE,
  isDraft: false,
  isPublished: false,
  isImported: false
}

export const ETSY_INITIAL_DATA = {
  id: null,
  name: null,
  description: null,
  tags: [],
  options: [],
  variations: [],
  earned: DEFAULT_VARIATION_PRICE,
  isDraft: false,
  isPublished: false,
  isImported: false
}
