import React from 'react';
import CustomTooltip from '../../../../../../components/CustomTooltip';
import TextCopy from 'src/redesign/components/TextCopy';
import Overview from '../../../../../../components/Overview';

const OrderShipmentVariants = ({ order: { items = [] } }) => {
  return (
    <Overview
      items={items.map((x, i) => ({
        name: i + 1,
        value: <TextCopy text={x.sku}/>
      }))}
    />
  )
}

export default OrderShipmentVariants;