import React from 'react'
import {Router, Switch } from "react-router-dom";
import { createStore } from "redux";
import appReducers from "./reducers/Reducers";
import {loadUser} from "./actions/AuthActions";
import history from "./history";
import AuthWrapper from "./base/AuthWrapper";
import {getRoutes} from "./route";
import Provider from "react-redux/es/components/Provider";
import BaseLayout from "./base/BaseLayout";
import { RemoveTrailingSlash } from './redesign/util/removeTrailingSlash';
import { SnackbarProvider } from 'notistack';
import 'src/scss/_globals.scss';

const LIVE_CALLER_SCRIPT_ID = 'liveCallerScript';

const store = createStore(appReducers);
store.dispatch(loadUser());

if ('ontouchstart' in document.documentElement) {
    document.body.style.cursor = 'pointer';
}

load();

const App = () => {
    
    React.useEffect(() => {
        if(process.env.REACT_APP_appEnvironment === 'prod') {
            if(document.getElementById(LIVE_CALLER_SCRIPT_ID))
                return;

            const script = document.createElement('script');

            script.src = new URL('./LiveCaller.js', import.meta.url);
            script.id = LIVE_CALLER_SCRIPT_ID;
            script.type = 'text/javascript';
            script.async = false;

            document.head.appendChild(script);
        } else {
            const script = document.getElementById(LIVE_CALLER_SCRIPT_ID);
            if(script) document.head.removeChild(script);
        }
    }, [process.env.REACT_APP_baseUrl]);

    return (
    <Provider store={ store }>
            <Router history={history}>
                 <SnackbarProvider maxSnack={3}>
                    <AuthWrapper>
                        <BaseLayout>
                        <RemoveTrailingSlash/>
                        <Switch>
                            {getRoutes()}
                        </Switch>
                        </BaseLayout>
                    </AuthWrapper>
                 </SnackbarProvider>
            </Router>
    </Provider>
);
};

function load() {

}

export default App;
