import React from 'react';
import { node, string } from 'prop-types';
import Helmet from 'react-helmet';
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA);

import Header from 'src/redesign/components/Header';
import Footer from 'src/redesign/components/Footer';

const Page = ({ children, seoTitle, seoDescription }) => (
  <>
    <Helmet>
      <title>{seoTitle ?? 'Surge: Print on Demand'}</title>
      <meta name='description' content={seoDescription}/>
    </Helmet>
    <div id="page">
      <Header />
      <div className="content">
        {children}
      </div>
      <Footer />
    </div>
  </>
)

Page.propTypes = {
  children: node,
  seoTitle: string,
  seoDescription: string
}

export default Page;