import React from 'react';
import {Image, Layer, Rect, Stage} from "react-konva";

import './style.scss';
import colors from 'src/scss/_colors.scss';
import EditorControls from '../EditorControls';
import EditorSlider from '../EditorSlider';
import { bottomAlign, centerAlign, crop, horizontalAlign, leftAlign, realCrop, rightAlign, topAlign, verticalAlign } from './helpers';
import TransformerComponent from 'src/redesign/components/Transformer';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import Button from 'src/redesign/components/Buttons/SubmitPrimary';
import LazyLoadImage from 'src/redesign/components/Image';
import { v4 as uuid } from 'uuid';
import { getImageAlt } from 'src/redesign/helpers';

const CANVAS_IMAGE_WIDTH = 340;
const EXPANDED_MULTIPLIER = 2;
const COLLAPSED_MULTIPLIER = 1;

const Editor = ({ isLeftSectionExpanded, backgroundImage, originalImageWidth, originalImageHeight, imageSrc, onSaveChanges, isSaving }) => {
  if(!backgroundImage) return null;

  const [positionX, setPositionX] = React.useState(0);
  const [positionY, setPositionY] = React.useState(0);

  const [rotation, setRotation] = React.useState(0);
  const [zoom, setZoom] = React.useState(0);
  const [stageScale, setStageScale] = React.useState(1);
  
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  
  const [initialWidth, setInitialWidth] = React.useState(0);
  const [initialHeight, setInitialHeight] = React.useState(0);

  const [isCropOn, setIsCropOn] = React.useState(false);
  
  const stageRef = React.useRef(null);
  const imageRef = React.useRef(null);
  const cropRef = React.useRef(null);
  const transformerRef = React.useRef(null);
  
  const multiplier = isLeftSectionExpanded ? EXPANDED_MULTIPLIER : COLLAPSED_MULTIPLIER;

  // reset settings when new artwork is choosen
  React.useEffect(() => {
    if(!imageSrc)
      return () => {};

    const image = new window.Image();
    image.src = imageSrc;
    
    const originalDropzoneWidth = backgroundImage.real_dropzone_width;
    const originalDropzoneHeight = backgroundImage.real_dropzone_height;

    const dropzoneWidth = backgroundImage.dropzone_width
    const dropzoneHeight = backgroundImage.dropzone_height

    const heightRatio = dropzoneHeight/originalDropzoneHeight;
    const widthRatio = dropzoneWidth/originalDropzoneWidth;

    const height = originalImageHeight * heightRatio;
    const width = originalImageWidth * widthRatio;
    
    setWidth(width);
    setHeight(height);
    setInitialWidth(width);
    setInitialHeight(height);
    
    setStageScale(1);
    setZoom(0);
    setRotation(0);

    const { positionX, positionY } = centerAlign(width, height, stageWidth, stageHeight, 0, multiplier);

    setPositionX(positionX);
    setPositionY(positionY);
  }, [imageSrc]);

  React.useEffect(() => {
    setPositionX(prev => isLeftSectionExpanded ? (prev * EXPANDED_MULTIPLIER) : (prev / EXPANDED_MULTIPLIER));
    setPositionY(prev => isLeftSectionExpanded ? (prev * EXPANDED_MULTIPLIER) : (prev / EXPANDED_MULTIPLIER));
  }, [multiplier]);

  // #region dropzone

  const dropzoneWidth = backgroundImage.dropzone_width * multiplier;
  const dropzoneHeight = backgroundImage.dropzone_height * multiplier;

  const dropzoneX = backgroundImage.dropzone_x * multiplier;
  const dropzoneY = backgroundImage.dropzone_y * multiplier;

  const stageWidth = stageRef.current?.attrs.width;
  const stageHeight = stageRef.current?.attrs.height;

  // #endregion dropzone

  // #region original-dimensions

  const image = new window.Image();
  image.src = imageSrc;

  const realDropzoneWidth = React.useMemo(() => backgroundImage.real_dropzone_width, [backgroundImage]);
  const realDropzoneHeight = React.useMemo(() => backgroundImage.real_dropzone_height, [backgroundImage]);

  const originalSafeWidth = React.useMemo(() => backgroundImage.safeWidth, [backgroundImage]);
  const originalSafeHeight = React.useMemo(() => backgroundImage.safeHeight, [backgroundImage]);

  const originalSafeWidthRatio = (originalSafeWidth/realDropzoneWidth);
  const originalSafeHeightRatio = (originalSafeHeight/realDropzoneHeight);

  // #endregion original-dimension

  // #region safezone

  const safezoneWidth =  originalSafeWidthRatio*dropzoneWidth;
  const safezoneHeight = originalSafeHeightRatio*dropzoneHeight;

  const safezoneX = (dropzoneWidth - safezoneWidth)/2 - 2;
  const safezoneY = (dropzoneHeight - safezoneHeight)/2 - 2;

  // #endregion safezone

  // #region editor-controls

  const isNoArtwork = () => {
    return !imageSrc || !imageRef;
  }

  const onCropChange = () => {
    if(isNoArtwork()) return;

    setIsCropOn(true);
  }

  const onCropSave = () => {
    const cropAttrs = cropRef.current.attrs;
    const cropWidth = cropAttrs.width * (cropAttrs.scaleX ?? 1);//umesto 1 stageScale
    const cropHeight = cropAttrs.height * (cropAttrs.scaleY ?? 1);
    
    const imageAttrs = imageRef.current.attrs;
    const originalX = imageAttrs.x;
    const originalY = imageAttrs.y;
    const originalWidth = imageAttrs.width;
    const originalHeight = imageAttrs.height;

    const xIsY = rotation === 90 || rotation === 270;
    const { positionX, positionY } = crop(cropRef.current, imageRef.current, xIsY, rotation, width, height);
    // const positionX = cropRef.current.attrs.x;
    // const positionY = cropRef.current.attrs.y;
    const x = Math.round(positionX);
    const y = Math.round(positionY);

    // da ispratim saveCropRealSize

    setWidth(cropWidth);
    setHeight(cropHeight);
    setPositionX(positionX);
    setPositionY(positionY);

    console.log({ cropWidth, cropHeight, positionX, x, positionY, y, originalWidth, originalHeight, rotation });

    imageRef.current.crop({
      x: x,
      y: y,
      width: cropWidth,
      height: cropHeight
    });
    // imageRef.current.attrs.width = cropWidth;
    // imageRef.current.attrs.height = cropHeight;

    onCenterAlign();
    setIsCropOn(false);
  }

  const onCenterAlign = (scale = stageScale, rotat = rotation) => {
    if(isNoArtwork()) return;

    const width = initialWidth * scale;
    const height = initialHeight * scale;
    const { positionX, positionY } = centerAlign(width, height, stageWidth, stageHeight, rotat, multiplier);
    
    setWidth(width);
    setHeight(height);
    setPositionX(positionX);
    setPositionY(positionY);
  }

  const onZoomChange = (e) => {
    if(isNoArtwork()) return;

    const value = e.target.value;
    const scale = 1 + (value/100);
    setZoom(value);
    setStageScale(scale);
    onCenterAlign(scale);
  }

  const onRotationChange = () => {
    if(isNoArtwork()) return;

    const newRotation = rotation === 270 ? 0 : rotation + 90;

    setRotation(newRotation);
    onCenterAlign(stageScale, newRotation);
  }

  const onTopAlign = () => {
    if(isNoArtwork()) return;

    const positionY = topAlign(width, height, rotation, multiplier);
    setPositionY(positionY);
  }

  const onBottomAlign = () => {
    if(isNoArtwork()) return;

    const positionY = bottomAlign(width, height, stageHeight, rotation, multiplier);
    setPositionY(positionY);
  }

  const onRightAlign = () => {
    if(isNoArtwork()) return;

    const positionX = rightAlign(width, height, stageWidth, rotation, multiplier);
    setPositionX(positionX);
  }

  const onLeftAlign = () => {
    if(isNoArtwork()) return;

    const positionX = leftAlign(width, height, rotation, multiplier);
    setPositionX(positionX);
  }

  const onVerticalAlign = () => {
    if(isNoArtwork()) return;

    const positionX = verticalAlign(width, height, stageWidth, rotation, multiplier);
    setPositionX(positionX);
  }

  const onHorizontalAlign = () => {
    if(isNoArtwork()) return;

    const positionY = horizontalAlign(width, height, stageHeight, rotation, multiplier);
    setPositionY(positionY);
  }
  
  // #endregion editor-controls
  
  const onDownloadTemplate = () => {
    const uri = imageRef.current.toDataURL({
      mimeType: 'image/png',
      quality: 0,
      pixelRatio: 1,
      x: positionX,
      y: positionY,
      width: width,
      height: height
    });
    const link = document.createElement('a');
    link.download = 'artwork-' + uuid() + '.png';
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  return <div className='editor'>
    <LazyLoadImage
      alt={getImageAlt()}
      id ='canvas-image'
      style={{ width: CANVAS_IMAGE_WIDTH * multiplier}}
      src={backgroundImage.base64}
    />
      <div 
        style={{
          width: dropzoneWidth,
          height: dropzoneHeight,
          position: 'absolute',
          top: dropzoneY,
          left: dropzoneX,
          background: colors.primaryLight
        }}
      >
        <div
          style={{
            position: 'absolute',
            border: '2px dashed ' + colors.red,
            width: safezoneWidth,
            height: safezoneHeight,
            top: safezoneY,
            left: safezoneX,
          }}
        />
        <Stage
          key={backgroundImage.id}
          // 4 pixels are removed to show right border of the dropzone
          width={dropzoneWidth - 4}
          height={dropzoneHeight - 4}
          ref={stageRef}
          style={{
            border: `2px dashed ${colors.primary}`,
          }}
        >
          <Layer>
            {imageSrc && (
              <Image
                draggable
                image={image}
                width={width * multiplier}
                height={height * multiplier}
                x={positionX}
                y={positionY}
                rotation={rotation}
                ref={imageRef}
                name="image"
                onDragEnd={({ currentTarget: { attrs: { x, y }}}) => {
                  setPositionX(x);
                  setPositionY(y);
                }}
                onDragStart={() => setIsCropOn(false)}
              />
              )}
            {isCropOn && (
              <Rect
                ref={cropRef}
                width={width * multiplier}
                height={height * multiplier}
                rotation={rotation}
                x={positionX >= 0 ? positionX : 0}
                y={positionY >= 0 ? positionY : 0}
                shadowBlur={5}
                draggable
                name='rect'
                fill='rgba(255,255,255, 0.2)'

              />
            )}
            {isCropOn && (
              <TransformerComponent
                ref={transformerRef}
                state={{
                  x: positionX,
                  y: positionY,
                  width: width * multiplier,
                  height: height * multiplier
                }}
                selectedShapeName='rect'
              />
            )}
          </Layer>
        </Stage>
      </div>
      <EditorControls 
        onCrop={onCropChange}
        onRotate={onRotationChange}
        onTopAlign={onTopAlign}
        onBottomAlign={onBottomAlign}
        onRightAlign={onRightAlign}
        onLeftAlign={onLeftAlign}
        onVerticalAlign={onVerticalAlign}
        onHorizontalAlign={onHorizontalAlign}
      />
      <EditorSlider zoom={zoom} onChange={onZoomChange} />
      {isCropOn && (
        <SubmitButton
          style={{ margin: '0 auto' }}
          onClick={onCropSave}
          text='Save crop'
        />
      )}
      {!isNoArtwork() && (
        <div className='editor-actions'>
          <Button onClick={onDownloadTemplate} text='Download Template'/>
          <Button 
          onClick={() => 
            onSaveChanges(
              positionX,
              positionY,
              width,
              height,
              rotation,
              stageScale,
              )}
            text='Save changes'
            disabled={isSaving}
            loading={isSaving}
            width={128}
            />
        </div>
      )}
  </div>
}

export default Editor;