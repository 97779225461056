import React from 'react';
import { string } from 'prop-types';
import { withRouter } from 'react-router';

import './style.scss';

const StartDesigningToday = ({ title, subtitle, history, linkText }) => (
  <div className='start-designing-today-container'>
    <h2 className='start-designing-today-title'>
      {title}
    </h2>
    <div className='start-designing-today-description-container'>
      <div className='start-designing-today-description'>
        {subtitle}
      </div>
      <div
        className='start-designing-today-signup-link'
        onClick={() => history.push('/registration')}
      >
        {linkText}
      </div>
    </div>
  </div>
);

StartDesigningToday.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  linkText: string.isRequired
}

export default withRouter(StartDesigningToday);