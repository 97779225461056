import React from 'react';
import { func, string } from 'prop-types';

import LogoUp from 'src/redesign/components/Logo/LogoUp';
import LogoDown from 'src/redesign/components/Logo/LogoDown';
import PrimaryButton from 'src/redesign/components/Buttons/Primary';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const Personalized = ({ title, subtitle, onStartDesigningClick }) => (
  <div className='personalized'>
    <div className='personalized-outer-container'>
      <LogoUp width={278} height={224} color={colors.secondary} className='personalized-logo-up' />
      <LogoDown width={272} height={224} color={colors.secondary} className='personalized-logo-down' />
      <div className='personalized-container'>
        <h2 className='personalized-title'>
          {title}
        </h2>
        <div className='personalized-description'>
          {subtitle}
        </div>
        <PrimaryButton
          className='personalized-start-designing'
          text='Start Designing'
          width={160}
          onClick={onStartDesigningClick}
        />
      </div>
    </div>
  </div>
);

Personalized.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  onStartDesigningClick: func.isRequired
}

export default Personalized;