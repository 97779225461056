import {withRouter} from "react-router-dom";
import React from 'react'
import FormComponent from "../../common/FormComponent";
import {getBase64} from "../../services/ImageService";
import {
    createEditProductVariationShipping,
    deleteVariationImage,
    editMultiVariationInfo,
    editMultiVariationPrice,
    editSingleVariationInfo,
    editSingleVariationPrice,
    getProductVariationImages,
    getProductVariationsAdmin,
    getShippingPricingForProductVariation,
    replaceVariationImage,
    toggleVariationAvailability,
    uploadProductVariationImage,
    syncProductVariationsWithMockups,
    syncProductVariationsWithDesignTemplate
} from "../../services/admin/ProductVariationService";
import {Avatar, Button, Checkbox, TextField} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {Image, Layer, Rect, Stage} from "react-konva";
import TransformerComponent from "../TransformerComponent";
import update from "immutability-helper";
import {
    calculateHeightBasedOnWidthAndAspectRation,
} from "../../util/UserUtil";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {connect} from "react-redux";
import strings from '../../localization';
import Select from 'react-select'
import { getMediaModifierMockups } from "../../services/MediaModifierService";
import { Chip } from "@material-ui/core";
import { getDocuments } from "../../services/DocumentService";

class ProductVariations extends FormComponent{

    constructor(props) {
        super(props);

        this.state = {
            id : props.id,
            variations : [],
            data : {
                showCheckboxes : false,
                safeHeight : '',
                safeWidth : '',
                width : '',
                height : ''
            },
            tab : 'info',
            selectedVariation : {
                images : []
            },
            selectedVariations : [],
            activeSelectedImage : 0,
            x : 20,
            y : 50,
            width : 100,
            rotation : 0,
            height : 100,
            lastPicSrc : [],
            mediaModifierMockupsSelectedOptions: [],
            mediaModifierMockupsOptions: []
        };

        this.replaceImage = this.replaceImage.bind(this);
        this.deletePhoto = this.deletePhoto.bind(this);
        this.arrowClick = this.arrowClick.bind(this);
        this.toggleVariationAvailability = this.toggleVariationAvailability.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.uploadVariationImage = this.uploadVariationImage.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.selectVariation = this.selectVariation.bind(this);
        this.saveSingleVariationInfo = this.saveSingleVariationInfo.bind(this);
        this.saveSingleVariationPrice = this.saveSingleVariationPrice.bind(this);
        this.editSingleVariationShipping = this.editSingleVariationShipping.bind(this);
        this.changeSelectedOptions = this.changeSelectedOptions.bind(this);
    }

    deletePhoto(){
        if (!this.state.selectedVariation.images[this.state.activeSelectedImage]){
            return
        }

        deleteVariationImage(this.state.selectedVariation.images[this.state.activeSelectedImage].id).then(response => {
            if (!response.ok){
                return ;
            }

            let selected = this.state.selectedVariation;
            let toRender = [];

            selected.images.forEach((item, index) => {
                if (index === this.state.activeSelectedImage){
                    return ;
                }

                toRender.push(item);
            });

            if (toRender.length === 0){
                selected.is_available = false;
            }

            selected.images = toRender;

            let numberToSet = this.state.activeSelectedImage;

            if (this.state.activeSelectedImage === this.state.selectedVariation.images.length){
                numberToSet -= 1;
            }

            if (this.state.selectedVariation.images.length === 0){
                this.setState({
                    ...this.state,
                    selectedVariation : selected,
                    activeSelectedImage : numberToSet,
                    data : {
                        ...this.state.data,
                        width : '',
                        height : '',
                        safeWidth : '',
                        safeHeight : '',
                        name : ''
                    }
                }, ()=> {
                    this.getVariations();
                });
            }else {
                this.setState({
                    ...this.state,
                    selectedVariation : selected,
                    activeSelectedImage : numberToSet
                }, ()=> {
                    this.getVariations();
                });
            }

        })
    }

    getVariations(){
        getProductVariationsAdmin(this.state.id).then(response => {
            if (!response.ok){
                return ;
            }

            let variationIds = [];

            response.data.forEach(item => {
                variationIds.push(item.id);
            });

            getProductVariationImages({variationIds : variationIds}).then(responseImage => {
                if (!responseImage.ok){
                    return ;
                }

                let variations = response.data;

                variations.forEach(item => {
                    responseImage.data.forEach(image => {
                        if (item.id !== image.product_variation_id){
                            return ;
                        }

                        item.images = image.images;
                    })
                })

                this.setState({
                    ...this.state,
                    variations : variations,
                    variationImages : responseImage.data
                }, ()=> {
                    this.props.hideLoader();
                })
            })
        })
    }

    componentDidMount() {
        this.props.showLoader();

        this.getVariations();
        this.getMediaModifierMockups()
        this.getDesignTemplates();
    }
    updateProductVariationsMockups() {
        syncProductVariationsWithMockups({
             productVariationIds: this.state.selectedVariations,
             selectedMockupIds: this.state.mediaModifierMockupsSelectedOptions ? this.state.mediaModifierMockupsSelectedOptions.map(option => option.value) : []}).then(
                 response => {
                if(!response.ok) {
                    return
                }
                this.props.enqueueSnackbar('Successfully saved', { variant: 'success' });
                this.props.showLoader();
                this.getVariations();
        })
    }
    updateProductVariationsDesignTemplate() {
        syncProductVariationsWithDesignTemplate({
             productVariationIds: this.state.selectedVariations,
             selectedDesignTemplateId: this.state.designTemplatesSelectedOptions ? this.state.designTemplatesSelectedOptions.value : null }).then(
                 response => {
                if(!response.ok) {
                    return
                }
                this.props.enqueueSnackbar('Successfully saved', { variant: 'success' });
                this.props.showLoader();
                this.getVariations();
        })
    }

    getMediaModifierMockups(){
        getMediaModifierMockups().then(response => {
            if(!response.ok) {
                return;
            }
            const mediaModifierMockupsOptions =  response.data.data.map(mediaModifierMockup => 
             {
                   return {
                    value: mediaModifierMockup.id,
                    label: mediaModifierMockup.name
                   }
                } 
            )

            this.setState({
                ...this.state,
                mediaModifierMockupsOptions: mediaModifierMockupsOptions,
            })
        });
    }
    getDesignTemplates() {
        getDocuments().then( response => {
            if(!response.ok) {
                return;
            }
            this.setState({ designTemplates:
                 response.data.data.filter(document => document.type === 'DESIGN_TEMPLATE')
                    .map(document => { return { label: document.name, value: document.id }}) })
        })

    }

    selectVariation(e, item){
        if (this.state.data.showCheckboxes || this.state.toggling){
            return
        }

        if (this.state.tab === 'shipping'){
            getShippingPricingForProductVariation(item.id).then(response => {

                if (!response.ok){
                    return ;
                }

                if (!response.data){
                    response.data = {};
                }

                this.setState({
                    ...this.state,
                    selectedVariation : item,
                    activeSelectedImage : 0,
                    x : item.images[this.state.activeSelectedImage] && item.images[this.state.activeSelectedImage].product_variation_image.dropzone_x ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_x : 150,
                    y : item.images[this.state.activeSelectedImage] && item.images[this.state.activeSelectedImage].product_variation_image.dropzone_y ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_y : 150,
                    width : item.images[this.state.activeSelectedImage] && item.images[this.state.activeSelectedImage].product_variation_image.dropzone_width ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_width : 100,
                    height : item.images[this.state.activeSelectedImage] && item.images[this.state.activeSelectedImage].product_variation_image.dropzone_height ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_height : 100,
                    rotation : item.images[this.state.activeSelectedImage] && item.images[this.state.activeSelectedImage].product_variation_image.dropzone_rotation ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_rotation : 0,
                    data : {
                        ...this.state.data,
                        ids : [item.id],
                        id : item.id,
                        name : item.name ? item.name : '',
                        price : item.price ? item.price : '',
                        vendorPrice: item.vendor_price ? item.vendor_price : '',
                        width : item.real_dropzone_width ? item.real_dropzone_width : '',
                        height : item.real_dropzone_height ? item.real_dropzone_height : '',
                        usFirstStandard: response.data.us_standard_first ? response.data.us_standard_first : '',
                        usFirstExpedited: response.data.us_expedited_first ? response.data.us_expedited_first : '',
                        usFirstOvernight: response.data.us_overnight_first ? response.data.us_overnight_first : '',
                        usOtherStandard: response.data.us_standard_n ? response.data.us_standard_n : '',
                        usOtherExpedited: response.data.us_expedited_n ? response.data.us_expedited_n : '',
                        usOtherOvernight: response.data.us_overnight_n ? response.data.us_overnight_n : '',
                        intFirstStandard: response.data.int_standard_first ? response.data.int_standard_first : '',
                        intFirstExpedited: response.data.int_expedited_first ? response.data.int_expedited_first : '',
                        intOtherStandard: response.data.int_standard_n ? response.data.int_standard_n : '',
                        intOtherExpedited: response.data.int_expedited_n ? response.data.int_expedited_n : '',

                        vendorUsFirstStandard: response.data.vendor_us_standard_first ? response.data.vendor_us_standard_first : '',
                        vendorUsFirstExpedited: response.data.vendor_us_expedited_first ? response.data.vendor_us_expedited_first : '',
                        vendorUsFirstOvernight: response.data.vendor_us_overnight_first ? response.data.vendor_us_overnight_first : '',
                        vendorUsOtherStandard: response.data.vendor_us_standard_n ? response.data.vendor_us_standard_n : '',
                        vendorUsOtherExpedited: response.data.vendor_us_expedited_n ? response.data.vendor_us_expedited_n : '',
                        vendorUsOtherOvernight: response.data.vendor_us_overnight_n ? response.data.vendor_us_overnight_n : '',
                        vendorIntFirstStandard: response.data.vendor_int_standard_first ? response.data.vendor_int_standard_first : '',
                        vendorIntFirstExpedited: response.data.vendor_int_expedited_first ? response.data.vendor_int_expedited_first : '',
                        vendorIntOtherStandard: response.data.vendor_int_standard_n ? response.data.vendor_int_standard_n : '',
                        vendorIntOtherExpedited: response.data.vendor_int_expedited_n ? response.data.vendor_int_expedited_n : ''
                    }
                });
            })
        }else {
            let zoom = (item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.real_dropzone_width ? item.images[this.state.activeSelectedImage].product_variation_image.real_dropzone_width : '' : '') : '')/(item.images[this.state.activeSelectedImage] ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_width ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_width : 100 : '');

            let safeWidth = (item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.safeWidth ? item.images[this.state.activeSelectedImage].product_variation_image.safeWidth :'': '') : '')/zoom;
            let safeHeight = (item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.safeHeight ? item.images[this.state.activeSelectedImage].product_variation_image.safeHeight :'': '') : '')/zoom;

            let safeX = (item.images[this.state.activeSelectedImage] ? item.images[this.state.activeSelectedImage].product_variation_image.safeX ? item.images[this.state.activeSelectedImage].product_variation_image.safeX : '' : '');
            let safeY = (item.images[this.state.activeSelectedImage] ? item.images[this.state.activeSelectedImage].product_variation_image.safeY ? item.images[this.state.activeSelectedImage].product_variation_image.safeY : '' : '');
            getProductVariationsAdmin(this.state.id).then(response => {
                if (!response.ok){
                    return ;
                }

                let variationIds = [];

                response.data.forEach(item => {
                    variationIds.push(item.id);
                });

                getProductVariationImages({variationIds : variationIds}).then(responseImage => {
                    if (!responseImage.ok){
                        return ;
                    }

                    let variations = response.data;

                    variations.forEach(item => {
                        responseImage.data.forEach(image => {
                            if (item.id !== image.product_variation_id){
                                return ;
                            }

                            item.images = image.images;
                        })
                    })

                    this.setState({
                        ...this.state,
                        variations : variations,
                        variationImages : responseImage.data
                    })
                })
            })

            // console.log('dropzone_x', item.images[this.state.activeSelectedImage].product_variation_image.dropzone_x)
            // console.log('dropzone_y', item.images[this.state.activeSelectedImage].product_variation_image.dropzone_y)
            // console.log('dropzone_width', item.images[this.state.activeSelectedImage].product_variation_image.dropzone_width)
            // console.log('dropzone_height', item.images[this.state.activeSelectedImage].product_variation_image.dropzone_height)
   
            this.setState({
                ...this.state,
                safeWidth : safeWidth ? safeWidth : '',
                safeHeight : safeHeight ? safeHeight : '',
                safeX : safeX ? safeX : '',
                safeY : safeY ? safeY : '',
                selectedVariation : item,
                activeSelectedImage : 0,
                x : item.images[this.state.activeSelectedImage] ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_x ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_x : 150 : '',
                y : item.images[this.state.activeSelectedImage] ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_y ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_y : 150 : '',
                width : item.images[this.state.activeSelectedImage] ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_width ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_width : 100 : '',
                height : item.images[this.state.activeSelectedImage] ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_height ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_height : 100 : '',
                // rotation : item.images[this.state.activeSelectedImage] ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_rotation ? item.images[this.state.activeSelectedImage].product_variation_image.dropzone_rotation : 0 : '',
                data : {
                    ...this.state.data,
                    ids : [item.id],
                    id : item.id,
                    name : item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.public_name ? item.images[this.state.activeSelectedImage].product_variation_image.public_name  : '': '') : '',
                    price : item.price ? item.price : '',
                    vendorPrice: item.vendor_price ? item.vendor_price : '',
                    width : item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.real_dropzone_width ? item.images[this.state.activeSelectedImage].product_variation_image.real_dropzone_width : '' : '') : '',
                    height : item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.real_dropzone_height ? item.images[this.state.activeSelectedImage].product_variation_image.real_dropzone_height :'': '') : '',
                    safeHeight : item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.safeHeight ? item.images[this.state.activeSelectedImage].product_variation_image.safeHeight :'': '') : '',
                    safeWidth : item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.safeWidth ? item.images[this.state.activeSelectedImage].product_variation_image.safeWidth :'': '') : ''
                },
              mediaModifierMockupsSelectedOptions: item.media_modifier_mockups ? item.media_modifier_mockups.map((mmm) => { return {label: mmm.name, value: mmm.id}}) : [] 
            })
        }

        if (this.rect){
            this.rect.attrs.scaleX = 1;
            this.rect.attrs.scaleY = 1;
        }

        setTimeout(()=> {
            this.forceUpdate();
        }, 1000)
    }

    editSingleVariationShipping(){
        if (!this.state.selectedVariation.id){
            return ;
        }

        if (!this.isShippingValid()){
            this.props.enqueueSnackbar('Missing required field', { variant: 'error' });
            return
        }

        if (!this.isInternationalExpeditedPriceValid() || !this.isVendorInternationalExpeditedPriceValid()) {
            this.props.enqueueSnackbar('International expedited is not valid', {variant: 'error'});
            return;
        }


        createEditProductVariationShipping({data : this.state.data, ids : [this.state.selectedVariation.id]}).then(response => {
            if (!response.ok){
                return ;
            }

            this.props.enqueueSnackbar('Successfully saved', { variant: 'success' });
        })
    }

    isInfoValid(){
        return !(!this.state.data.name || this.state.data.height === '' || this.state.data.width === '' || this.state.data.safeWidth === '');
    }

    isPriceValid(){
        return this.state.data.price && this.state.data.vendorPrice;
    }

    isShippingValid(){
        return !(!this.state.data.usFirstStandard || !this.state.data.usFirstExpedited || !this.state.data.usFirstOvernight ||
             !this.state.data.usOtherStandard || !this.state.data.usOtherExpedited || !this.state.data.usOtherOvernight ||
             !this.state.data.intFirstStandard || !this.state.data.intOtherStandard ||

             !this.state.data.vendorUsFirstStandard || !this.state.data.vendorUsFirstExpedited || !this.state.data.vendorUsFirstOvernight ||
             !this.state.data.vendorUsOtherStandard || !this.state.data.vendorUsOtherExpedited || !this.state.data.vendorUsOtherOvernight ||
             !this.state.data.vendorIntFirstStandard || !this.state.data.vendorIntOtherStandard
        );
    }

    changeRectangleDimensions(){
        this.rect.attrs.height = calculateHeightBasedOnWidthAndAspectRation(this.state.data.width, this.state.data.height, 
            this.rect.attrs.width*(this.rect.attrs.scaleX ? this.rect.attrs.scaleX : 1))*(1/(this.rect.attrs.scaleY ? this.rect.attrs.scaleY : 1));
        this.forceUpdate();
    }

    formSafeArea(){
        let zoom = this.state.data.width/(this.rect.attrs.width*(this.rect.attrs.scaleX ? this.rect.attrs.scaleX : 1));

        let safeWidth = this.state.data.safeWidth/zoom;
        let safeHeight = this.state.data.safeHeight/zoom;

        let safeX = this.rect.attrs.x + (this.rect.attrs.width*(this.rect.attrs.scaleX ? this.rect.attrs.scaleX : 1) - safeWidth)/2;
        let safeY = this.rect.attrs.y + (this.rect.attrs.height*(this.rect.attrs.scaleY ? this.rect.attrs.scaleY : 1) - safeHeight)/2;

        this.setState({
            ...this.state,
            safeWidth,
            safeHeight,
            safeX,
            safeY
        })

        return [safeX, safeY];
    }

    saveSingleVariationInfo(){
        let index = this.state.activeSelectedImage + 1;
        if (!this.state.selectedVariation.id || this.state.selectedVariation.images.length < index){
            return ;
        }

        if (!this.isInfoValid()){
            this.props.enqueueSnackbar('Missing required field', { variant: 'error' });
            return
        }

        if (+this.state.data.width <= +this.state.data.safeWidth){
            this.props.enqueueSnackbar('Safe Area cannot be bigger than Print Area', { variant: 'info' });
            return
        }

        this.props.enqueueSnackbar('Successfully saved', { variant: 'success' });

        this.changeRectangleDimensions();
        // tuva
        let safeArea = this.formSafeArea();

        editSingleVariationInfo({
            ...this.state.data,
            image : this.state.selectedVariation.images[this.state.activeSelectedImage],
            dropzone : {
                x : this.rect.attrs.x ? this.rect.attrs.x : 0,
                y : this.rect.attrs.y ? this.rect.attrs.y : 0,
                width : this.rect.attrs.width * (this.rect.attrs.scaleX ? this.rect.attrs.scaleX : 1),
                height : this.rect.attrs.height * (this.rect.attrs.scaleY  ? this.rect.attrs.scaleY : 1),
                rotation : this.state.rotation,
                realWidth : this.state.data.width,
                realHeight : this.state.data.height,
                safeWidth : this.state.data.safeWidth,
                safeHeight : this.state.data.safeHeight,
                safeX : safeArea[0],
                safeY : safeArea[1]
            }

        }).then(response => {
            if (!response.ok){
                return ;
            }

            window.location.reload();

            // this.getVariations();
        })
    }

    saveMultiVariationInfo(){

        if (!this.isInfoValid()){
            this.props.enqueueSnackbar('Missing required field', { variant: 'error' });
            return
        }

        if (+this.state.data.height <= +this.state.data.safeHeight){
            this.props.enqueueSnackbar('Safe Area cannot be bigger than Print Area', { variant: 'info' });
            return
        }

        if (+this.state.data.width <= +this.state.data.safeWidth){
            this.props.enqueueSnackbar('Safe Area cannot be bigger than Print Area', { variant: 'info' });
            return
        }

        this.changeRectangleDimensions();
        let safeArea = this.formSafeArea();



        editMultiVariationInfo({

            width : this.rect.attrs.width * (this.rect.attrs.scaleX ? this.rect.attrs.scaleX : 1),
            height : this.rect.attrs.height * (this.rect.attrs.scaleY ? this.rect.attrs.scaleY : 1),
            rectHeight : this.rect.attrs.height * (this.rect.attrs.scaleY ? this.rect.attrs.scaleY : 1),
            rectWidth : this.rect.attrs.width * (this.rect.attrs.scaleX ? this.rect.attrs.scaleX : 1),
            data : this.state.data,
            ids : this.state.selectedVariations,
            x : this.rect.attrs.x ? this.rect.attrs.x : 0,
            y : this.rect.attrs.y ? this.rect.attrs.y : 0,
            safeWidth : this.state.data.safeWidth,
            safeHeight : this.state.data.safeHeight,
            realWidth : this.state.data.width,
            realHeight : this.state.data.height,
            safeX : safeArea[0],
            safeY : safeArea[1]
        }).then(response => {
            if (!response.ok){
                return ;
            }

            // this.rect.attrs.width = this.rect.attrs.width * (this.rect.attrs.scaleX ? this.rect.attrs.scaleX : 1)
            // this.rect.attrs.height = this.rect.attrs.height * (this.rect.attrs.scaleY ? this.rect.attrs.scaleY : 1)
            let variations = this.state.variations;

            // this.state.selectedVariations.forEach(varId => {
            //     variations.forEach(variation => {
            //         if (varId !== variation.id){
            //             return ;
            //         }
            //
            //         variation.dropzone_width = this.state.data.width;
            //         variation.dropzone_height = this.state.data.height;
            //     })
            this.props.enqueueSnackbar('Successfully saved', { variant: 'success' });
            // });

            this.changeCheckBox({target : {name : 'showCheckboxes'}})


            window.location.reload();
            // this.getVariations();
            // this.setState({
            //     ...this.state,
            //     // variations
            // }, ()=> {
            //     this.getVariations();
            // })
        })
    }

    saveMultiVariationPrice(){
        if (!this.isPriceValid()){
            this.props.enqueueSnackbar('Missing required field', { variant: 'error' });
            return
        }

        editMultiVariationPrice({vendorPrice : this.state.data.vendorPrice, price : this.state.data.price, ids : this.state.selectedVariations}).then(response => {
            if (!response.ok){
                return ;
            }

            let variations = this.state.variations;

            this.state.selectedVariations.forEach(varId => {
                variations.forEach(variation => {
                    if (varId !== variation.id){
                        return ;
                    }

                    variation.price = this.state.data.price;
                })
            });

            this.props.enqueueSnackbar('Successfully saved', { variant: 'success' });

            this.setState({
                ...this.state,
                variations
            })
        })
    }

    saveMultiVariationShipping(){
        if (!this.isShippingValid()){
            this.props.enqueueSnackbar('Missing required field', { variant: 'error' });
            return
        }

        if (!this.isInternationalExpeditedPriceValid() || !this.isVendorInternationalExpeditedPriceValid()) {
            this.props.enqueueSnackbar('International expedited is not valid', {variant: 'error'});
            return;
        }

        createEditProductVariationShipping({data : this.state.data, ids : this.state.selectedVariations}).then(response => {
            if (!response.ok){
                return ;
            }

            let variations = this.state.variations;

            this.state.selectedVariations.forEach(varId => {
                variations.forEach(variation => {
                    if (varId !== variation.id){
                        return ;
                    }

                    variation.price = this.state.data.price;
                })
            });

            this.props.enqueueSnackbar('Successfully saved', { variant: 'success' });

            this.setState({
                ...this.state,
                variations
            })
        })
    }

    saveSingleVariationPrice(){
        if (!this.state.selectedVariation.id){
            return ;
        }

        if (!this.isPriceValid()){
            this.props.enqueueSnackbar('Missing required field', { variant: 'error' });
            return
        }

        editSingleVariationPrice(this.state.data).then(response => {
            if (!response.ok){
                return ;
            }

            this.props.enqueueSnackbar('Successfully saved', { variant: 'success' });
        })
    }

    handleCheckbox(e){
        let selectedVariations = this.state.selectedVariations;
        let newSelected = [];
        let safeWidth;
        let safeHeight;
        let safeX;
        let safeY;
        let zoom;
        let width;
        let height;

        let toSet = this.state.activeSelectedImage;

        if (toSet < 0){
            toSet = this.state.selectedVariation.images.length - 1;
        }else if (toSet > this.state.selectedVariation.images.length - 1){
            toSet = 0;
        }

        let targetValue = +e.target.value;

        if (selectedVariations.includes(+e.target.value)){

            if (e.target.value == this.state.selectedVariations[0]){

                let itemId = this.state.selectedVariations[1];
                let item;

                getShippingPricingForProductVariation(itemId).then(response => {
                    if (!response.ok) {
                        return;
                    }

                    if (!response.data) {
                        response.data = {};
                    }

                    this.state.variations.forEach(variation => {
                        if (variation.id !== itemId) {
                            return
                        }

                        item = variation;
                    })

                    if (item) {
                        zoom = (item.images[toSet] ? (item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.real_dropzone_width ? item.images[toSet].product_variation_image.real_dropzone_width : '' : '') : '') / (item.images[toSet] ? item.images[toSet].product_variation_image.dropzone_width ? item.images[toSet].product_variation_image.dropzone_width : 100 : '');

                        width = item.images[toSet] ? item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.real_dropzone_width : '' : '';
                        height = item.images[toSet] ? item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.real_dropzone_height : '' : '';

                        safeWidth = (item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.safeWidth ? item.images[this.state.activeSelectedImage].product_variation_image.safeWidth : '' : '') : '') / zoom;
                        safeHeight = (item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.safeHeight ? item.images[this.state.activeSelectedImage].product_variation_image.safeHeight : '' : '') : '') / zoom;

                        safeX = (item.images[this.state.activeSelectedImage] ? item.images[this.state.activeSelectedImage].product_variation_image.safeX ? item.images[this.state.activeSelectedImage].product_variation_image.safeX : '' : '');
                        safeY = (item.images[this.state.activeSelectedImage] ? item.images[this.state.activeSelectedImage].product_variation_image.safeY ? item.images[this.state.activeSelectedImage].product_variation_image.safeY : '' : '');
                    }

                    selectedVariations.forEach(item => {
                        if (item === targetValue) {
                            return
                        }

                        newSelected.push(item);
                    })

                    this.setState({
                        ...this.state,
                        activeSelectedImage: 0,
                        selectedVariations: newSelected,
                        safeWidth: safeWidth ? safeWidth : '',
                        safeHeight: safeHeight ? safeHeight : '',
                        safeX: safeX ? safeX : '',
                        safeY: safeY ? safeY : '',
                        data : {
                            ...this.state.data,
                            ids : newSelected.map(it => it.id),
                            id : item ? item.id : '',
                            name : item ? item.name ? item.name : '' : '',
                            price : item ? item.price ? item.price : '' : '',
                            vendorPrice: item ? item.vendor_price ? item.vendor_price : '' : '',
                            width : width ? width : '',
                            height : height ? height : '',
                            safeHeight : item ? item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.safeHeight ? item.images[this.state.activeSelectedImage].product_variation_image.safeHeight :'': '') : '' : '',
                            safeWidth : item ? item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.safeWidth ? item.images[this.state.activeSelectedImage].product_variation_image.safeWidth :'': '') : '' : '',
                            usFirstStandard: response.data.us_standard_first ? response.data.us_standard_first : '',
                            usFirstExpedited: response.data.us_expedited_first ? response.data.us_expedited_first : '',
                            usFirstOvernight: response.data.us_overnight_first ? response.data.us_overnight_first : '',
                            usOtherStandard: response.data.us_standard_n ? response.data.us_standard_n : '',
                            usOtherExpedited: response.data.us_expedited_n ? response.data.us_expedited_n : '',
                            usOtherOvernight: response.data.us_overnight_n ? response.data.us_overnight_n : '',
                            intFirstStandard: response.data.int_standard_first ? response.data.int_standard_first : '',
                            intFirstExpedited: response.data.int_expedited_first ? response.data.int_expedited_first : '',
                            intOtherStandard: response.data.int_standard_n ? response.data.int_standard_n : '',
                            intOtherExpedited: response.data.int_expedited_n ? response.data.int_expedited_n : '',

                            vendorUsFirstStandard: response.data.vendor_us_standard_first ? response.data.vendor_us_standard_first : '',
                            vendorUsFirstExpedited: response.data.vendor_us_expedited_first ? response.data.vendor_us_expedited_first : '',
                            vendorUsFirstOvernight: response.data.vendor_us_overnight_first ? response.data.vendor_us_overnight_first : '',
                            vendorUsOtherStandard: response.data.vendor_us_standard_n ? response.data.vendor_us_standard_n : '',
                            vendorUsOtherExpedited: response.data.vendor_us_expedited_n ? response.data.vendor_us_expedited_n : '',
                            vendorUsOtherOvernight: response.data.vendor_us_overnight_n ? response.data.vendor_us_overnight_n : '',
                            vendorIntFirstStandard: response.data.vendor_int_standard_first ? response.data.vendor_int_standard_first : '',
                            vendorIntFirstExpedited: response.data.vendor_int_expedited_first ? response.data.vendor_int_expedited_first : '',
                            vendorIntOtherStandard: response.data.vendor_int_standard_n ? response.data.vendor_int_standard_n : '',
                            vendorIntOtherExpedited: response.data.vendor_int_expedited_n ? response.data.vendor_int_expedited_n : ''
                        }
                    }, ()=> {

                        // setTimeout(()=> {
                        //     this.getVariations()
                        // }, 500)
                    })

                });
            }

        }else {
            if (selectedVariations.length === 0){
                let itemId = +e.target.value;
                let item;

                getShippingPricingForProductVariation(itemId).then(response => {
                    if (!response.ok) {
                        return;
                    }

                    if (!response.data) {
                        response.data = {};
                    }

                    this.state.variations.forEach(variation => {
                        if (variation.id !== itemId) {
                            return
                        }

                        item = variation;
                    })

                    zoom = (item.images[toSet] ? (item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.real_dropzone_width ? item.images[toSet].product_variation_image.real_dropzone_width : '' : '') : '') / (item.images[toSet] ? item.images[toSet].product_variation_image.dropzone_width ? item.images[toSet].product_variation_image.dropzone_width : 100 : '');

                    width = item.images[toSet] ? item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.real_dropzone_width : '' : '';
                    height = item.images[toSet] ? item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.real_dropzone_height : '' : '';


                    safeWidth = (item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.safeWidth ? item.images[this.state.activeSelectedImage].product_variation_image.safeWidth : '' : '') : '') / zoom;
                    safeHeight = (item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.safeHeight ? item.images[this.state.activeSelectedImage].product_variation_image.safeHeight : '' : '') : '') / zoom;

                    safeX = (item.images[this.state.activeSelectedImage] ? item.images[this.state.activeSelectedImage].product_variation_image.safeX ? item.images[this.state.activeSelectedImage].product_variation_image.safeX : '' : '');
                    safeY = (item.images[this.state.activeSelectedImage] ? item.images[this.state.activeSelectedImage].product_variation_image.safeY ? item.images[this.state.activeSelectedImage].product_variation_image.safeY : '' : '');
                    selectedVariations.push(targetValue);
                    this.setState({
                        ...this.state,
                        activeSelectedImage: selectedVariations.length === 1 ? this.state.activeSelectedImage : 0,
                        selectedVariations: selectedVariations,
                        safeWidth: safeWidth ? safeWidth : '',
                        safeHeight: safeHeight ? safeHeight : '',
                        safeX: safeX ? safeX : '',
                        safeY: safeY ? safeY : '',
                        data : {
                            ...this.state.data,
                            ids : [itemId],
                            id : item ? item.id : '',
                            name : item ? item.name ? item.name : '' : '',
                            price : item ? item.price ? item.price : '' : '',
                            vendorPrice: item ? item.vendor_price ? item.vendor_price : '' : '',
                            width : width ? width : '',
                            height : height ? height : '',
                            safeHeight : item ? item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.safeHeight ? item.images[this.state.activeSelectedImage].product_variation_image.safeHeight :'': '') : '' : '',
                            safeWidth : item ? item.images[this.state.activeSelectedImage] ? (item.images[this.state.activeSelectedImage].product_variation_image ? item.images[this.state.activeSelectedImage].product_variation_image.safeWidth ? item.images[this.state.activeSelectedImage].product_variation_image.safeWidth :'': '') : '' : '',
                            usFirstStandard: response.data.us_standard_first ? response.data.us_standard_first : '',
                            usFirstExpedited: response.data.us_expedited_first ? response.data.us_expedited_first : '',
                            usFirstOvernight: response.data.us_overnight_first ? response.data.us_overnight_first : '',
                            usOtherStandard: response.data.us_standard_n ? response.data.us_standard_n : '',
                            usOtherExpedited: response.data.us_expedited_n ? response.data.us_expedited_n : '',
                            usOtherOvernight: response.data.us_overnight_n ? response.data.us_overnight_n : '',
                            intFirstStandard: response.data.int_standard_first ? response.data.int_standard_first : '',
                            intFirstExpedited: response.data.int_expedited_first ? response.data.int_expedited_first : '',
                            intOtherStandard: response.data.int_standard_n ? response.data.int_standard_n : '',
                            intOtherExpedited: response.data.int_expedited_n ? response.data.int_expedited_n : '',

                            vendorUsFirstStandard: response.data.vendor_us_standard_first ? response.data.vendor_us_standard_first : '',
                            vendorUsFirstExpedited: response.data.vendor_us_expedited_first ? response.data.vendor_us_expedited_first : '',
                            vendorUsFirstOvernight: response.data.vendor_us_overnight_first ? response.data.vendor_us_overnight_first : '',
                            vendorUsOtherStandard: response.data.vendor_us_standard_n ? response.data.vendor_us_standard_n : '',
                            vendorUsOtherExpedited: response.data.vendor_us_expedited_n ? response.data.vendor_us_expedited_n : '',
                            vendorUsOtherOvernight: response.data.vendor_us_overnight_n ? response.data.vendor_us_overnight_n : '',
                            vendorIntFirstStandard: response.data.vendor_int_standard_first ? response.data.vendor_int_standard_first : '',
                            vendorIntFirstExpedited: response.data.vendor_int_expedited_first ? response.data.vendor_int_expedited_first : '',
                            vendorIntOtherStandard: response.data.vendor_int_standard_n ? response.data.vendor_int_standard_n : '',
                            vendorIntOtherExpedited: response.data.vendor_int_expedited_n ? response.data.vendor_int_expedited_n : ''
                        }
                    }, () => {
                        //
                        // setTimeout(()=> {
                        //     this.getVariations()
                        // }, 500)
                    })
                })
            }else {

                selectedVariations.push(+e.target.value);
                this.setState({
                    ...this.state,
                    activeSelectedImage : selectedVariations.length === 1 ? this.state.activeSelectedImage : 0,
                    selectedVariations : selectedVariations
                }, () => {

                    // setTimeout(()=> {
                    //     this.getVariations()
                    // }, 500)
                })
            }
        }
    }

    renderVariations(){
        let toRender = [];

        this.state.variations.forEach(item => {
            toRender.push(
                <div key={item.id} className={this.state.selectedVariation.id === item.id ? 'variation selected' : 'variation'} onClick={(e) => {

                    this.selectVariation(e, item)

                }}>
                    <Checkbox
                        key={item.id}
                        onChange={ this.handleCheckbox }
                        margin="normal"
                        value={item.id}
                        name="variationSelect"
                        checked={this.state.selectedVariations.includes(item.id)}
                        className={this.state.data.showCheckboxes ? '' : 'disabled'}
                    />
                    <img src={((item.images.length > 0 && item.images[0].product_variation_image) ? item.images[0].product_variation_image.base64 : '/images/dummyImage.png')} />
                    <p>{item.id} {item.name}</p>
                    <div className="tags-container">
                    {
                    item.media_modifier_mockups ? item.media_modifier_mockups.map((mediaModifierMockup) => {
                     return <Chip label={mediaModifierMockup.name} color="primary" avatar={<Avatar>M</Avatar>}  size="small" />

                    })
                        : ''

                    }
                    {
                     item.design_template_document ?  <Chip label={item.design_template_document.name} style={{backgroundColor: '#d32727'}}  color="primary" avatar={<Avatar>DT</Avatar>}  size="small" /> : ''
                    }
                    </div>
                    <div>
                    <label className="switch" onClick={(e) => {
                        this.toggleVariationAvailability(e, item.id);
                    }} >
                        <input type="checkbox" checked={item.is_available}/>
                            <span className="slider round"></span>
                    </label>
                    </div>

                </div>
            );
        });

        return toRender;
    }

    toggleTab(tab){
        if (!this.state.selectedVariation.id && this.state.selectedVariations.length === 0){
            return ;
        }

        this.setState({
            ...this.state,
            tab : tab
        }, () => {

            if (this.state.data.showCheckboxes) {
                return;
            }

            if (this.state.tab === 'shipping'){
                getShippingPricingForProductVariation(this.state.selectedVariation.id).then(response => {
                    if (!response.ok || !response.data){
                        return ;
                    }

                    this.setState({
                       ...this.state,
                        data : {
                            ...this.state.data,
                            usFirstStandard: response.data.us_standard_first,
                            usFirstExpedited: response.data.us_expedited_first,
                            usFirstOvernight: response.data.us_overnight_first,
                            usOtherStandard: response.data.us_standard_n,
                            usOtherExpedited: response.data.us_expedited_n,
                            usOtherOvernight: response.data.us_overnight_n,
                            intFirstStandard: response.data.int_standard_first,
                            intFirstExpedited: response.data.int_expedited_first,
                            intOtherStandard: response.data.int_standard_n,
                            intOtherExpedited: response.data.int_expedited_n,

                            vendorUsFirstStandard: response.data.vendor_us_standard_first,
                            vendorUsFirstExpedited: response.data.vendor_us_expedited_first,
                            vendorUsFirstOvernight: response.data.vendor_us_overnight_first,
                            vendorUsOtherStandard: response.data.vendor_us_standard_n,
                            vendorUsOtherExpedited: response.data.vendor_us_expedited_n,
                            vendorUsOtherOvernight: response.data.vendor_us_overnight_n,
                            vendorIntFirstStandard: response.data.vendor_int_standard_first,
                            vendorIntFirstExpedited: response.data.vendor_int_expedited_first,
                            vendorIntOtherStandard: response.data.vendor_int_standard_n,
                            vendorIntOtherExpedited: response.data.vendor_int_expedited_n,
                        }
                    });
                })
            }else {
                this.setState({
                    ...this.state,
                    data : {
                        ...this.state.data,
                        usFirstStandard: '',
                        usFirstExpedited: '',
                        usFirstOvernight: '',
                        usOtherStandard: '',
                        usOtherExpedited: '',
                        usOtherOvernight: '',
                        intFirstStandard: '',
                        intFirstExpedited: '',
                        intOtherStandard: '',
                        intOtherExpedited: '',

                        vendorUsFirstStandard: '',
                        vendorUsFirstExpedited: '',
                        vendorUsFirstOvernight: '',
                        vendorUsOtherStandard: '',
                        vendorUsOtherExpedited: '',
                        vendorUsOtherOvernight: '',
                        vendorIntFirstStandard: '',
                        vendorIntFirstExpedited: '',
                        vendorIntOtherStandard: '',
                        vendorIntOtherExpedited: '',
                    }
                });
            }
        })
    }

    changeCheckBox(event) {

        const field = event.target.name;
        const data = this.state.data;
        let selectedVariations = this.state.selectedVariations;

        if(data[field]){
            selectedVariations = [];
        }

        data[field] = !data[field];
        data['ids'] = '';
        data['id'] = '';
        data['name'] = '';
        data['width'] = '';
        data['price'] = '';
        data['height'] = '';

        this.setState({
            ...this.state,
            data,
            selectedVariations,
            selectedVariation : {
                images : []
            }
        });
    }

    replaceImage(event){

        if (!this.state.selectedVariation.id && this.state.selectedVariations.length !== 0){
            return ;
        }

        let formData = new FormData();
        let acceptedFiles;

        acceptedFiles = event.target.files[0];

        formData.append('variationId', this.state.selectedVariation.id);
        formData.append('imageId', this.state.selectedVariation.images[this.state.activeSelectedImage].id);

        event.target.value = null;

        getBase64(acceptedFiles).then(image => {
            formData.append('base64', image);
            formData.append('image', acceptedFiles);


            replaceVariationImage(formData).then(response => {
                if (!response.ok){
                    return
                }

                let selected = this.state.selectedVariation;
                let toRender = [];

                selected.images.forEach((item, index) => {
                    if (this.state.activeSelectedImage === index){
                        toRender.push(response.data);
                        return ;
                    }

                    toRender.push(item);
                })

                selected.images = toRender;

                this.setState({
                    ...this.state,
                    selectedVariation : selected,
                    data : {
                        ...this.state.data,
                        width : '',
                        height : '',
                        safeWidth : '',
                        safeHeight : '',
                        name : ''
                    }
                }, () => {
                    setTimeout(()=> {
                        this.forceUpdate()
                    }, 600)
                });
            });
        });
    }

    uploadVariationImage(event){
        if (!this.state.selectedVariation.id && this.state.selectedVariations.length === 0){
            return ;
        }

        let formData = new FormData();
        let acceptedFiles;

        acceptedFiles = event.target.files[0];

        getBase64(acceptedFiles).then(image => {
            formData.append('base64', image);
            formData.append('image', acceptedFiles);

            if (this.state.selectedVariation.id) {
                formData.append('numberOfVariations', 1);
                formData.append('variationId1', this.state.selectedVariation.id);
            }else {
                formData.append('numberOfVariations', this.state.selectedVariations.length);

                this.state.selectedVariations.forEach((item, index) => {
                    formData.append('variationId' + (index + 1), item);
                })
            }

            uploadProductVariationImage(formData).then(response => {
                if (!response.ok){
                    return;
                }
                let variations = this.state.variations;

                if (this.state.selectedVariation.id) {
                    variations.forEach(item => {
                        if (item.id !== this.state.selectedVariation.id){
                            return ;
                        }

                        if (item.images){
                            item.images.push(response.data);
                        }else {
                            item.images = [response.data];
                        }

                        item.is_available = true;
                    })
                }else {
                    variations.forEach(item => {
                        this.state.selectedVariations.forEach(selectedVar => {
                            if (item.id !== selectedVar){
                                return ;
                            }

                            if (item.images){
                                item.images.push(response.data);
                            }else {
                                item.images = [response.data];
                            }

                            item.is_available = true;
                        })
                    })
                }

                this.randomNum = "";

                this.setState({
                    ...this.state,
                    activeSelectedImage : this.state.activeSelectedImage === -1 ? 0 : this.state.activeSelectedImage,
                    variations,
                    x : 150,
                    y : 150,
                    width : 100,
                    height : 100,
                    rotation : 0
                })
            });
        });
    }

    centerAlign(width, safeW, safeH, x, y, rw, rh){
        let zoom = width/rw;

        this.state.safeWidth = safeW/zoom;
        this.state.safeHeight = safeH/zoom;

        this.state.safeX = x + (rw - this.state.safeWidth)/2;
        this.state.safeY = y + (rh - this.state.safeHeight)/2;
    }

    renderSelectedImage(){
        if (!this.state.selectedVariation.id && this.state.selectedVariations.length === 0){
            return [<img key={-1} className={'dummy'}/>];
        }

        let toRender = [];

        if (this.state.selectedVariations.length > 0){
            let gotIt = false;

            this.state.selectedVariations.forEach((item, index) => {
                this.state.variations.forEach((variation) => {
                    if ((item !== variation.id) || gotIt){
                        return ;
                    }

                    gotIt = true;

                    let image = new window.Image();
                    if (!this.state.selectedImage){
                        this.state.selectedImage = 0;
                    }

                    if (!variation.images[this.state.selectedImage]){
                        return
                    }

                    image.src = variation.images[this.state.selectedImage].product_variation_image.base64;

                    let newHeight = 340;

                    let oldWidth = image.width;
                    let oldHeight = image.height;
                    let aspectRatio = oldWidth/oldHeight;
                    newHeight = 340/aspectRatio;

                    image.height = newHeight;
                    image.width = 340;
                    image.style.objectFit = 'cover';
                    image.style.objectPosition = 'center center';

                    if(variation.images[this.state.selectedImage].product_variation_image && this.state.activeSelectedImage === index){
                        this.state.x = variation.images[this.state.selectedImage].product_variation_image.dropzone_x;
                        this.state.y = variation.images[this.state.selectedImage].product_variation_image.dropzone_y;
                        this.state.width = variation.images[this.state.selectedImage].product_variation_image.dropzone_width ? variation.images[this.state.selectedImage].product_variation_image.dropzone_width : 100;
                        this.state.height = variation.images[this.state.selectedImage].product_variation_image.dropzone_height ? variation.images[this.state.selectedImage].product_variation_image.dropzone_height : 100;
                    }

                    if (this.state.x === undefined && this.state.y === undefined){
                        this.state.x = image.width/2 - 50;
                        this.state.y = image.height/2 - 50;
                    }

                    let that = this;


                    toRender.push(
                        <div
                            key={index}
                            className={this.state.activeSelectedImage === index ? 'stage-cont active' : 'stage-cont'}
                        >
                        <div
                            className={'safe-zone'}
                            style={{
                                width : this.state.safeWidth,
                                height : this.state.safeHeight,
                                left : this.state.safeX,
                                top : this.state.safeY,
                                position : 'absolute',
                                border: '2px dashed rgb(208 155 208)'
                            }}
                        />
                        <Stage width={340} height={newHeight} key={index}
                               className={this.state.activeSelectedImage === index ? 'stage active' : 'stage'}
                        >
                            <Layer name={"layer"}>
                                <Image image={image}></Image>
                                <Rect
                                    ref={node => {
                                        this.rect = node;
                                    }}
                                    x={this.state.x}
                                    y={this.state.y}
                                    width={this.state.width}
                                    height={this.state.height}
                                    shadowBlur={5}
                                    draggable
                                    rotation={this.state.rotation}
                                    name={'rect'}
                                    fill={'rgba(255, 255, 255, 0.2)'}
                                    onDragEnd={(e) => {
                                        this.state.x = e.currentTarget.attrs.x;
                                        this.state.y = e.currentTarget.attrs.y;
                                        this.state.width = e.currentTarget.attrs.width * (e.currentTarget.attrs.scaleX ? e.currentTarget.attrs.scaleX : 1);
                                        this.state.height = e.currentTarget.attrs.height * (e.currentTarget.attrs.scaleY ? e.currentTarget.attrs.scaleY : 1);
                                    }}
                                    onDragStart={(e) => {
                                        // this.centerAlign();
                                    }}
                                    onTransformEnd={(e) => {
                                        this.state.x = e.currentTarget.attrs.x;
                                        this.state.y = e.currentTarget.attrs.y;
                                        this.state.width = e.currentTarget.attrs.width * (e.currentTarget.attrs.scaleX ? e.currentTarget.attrs.scaleX : 1);
                                        this.state.height = e.currentTarget.attrs.height * (e.currentTarget.attrs.scaleY ? e.currentTarget.attrs.scaleY : 1);

                                    }}
                                />
                                <TransformerComponent
                                    state={{
                                        x : this.state.x,
                                        y : this.state.y,
                                        width : this.state.width,
                                        height : this.state.height,
                                    }}

                                    ref={node => {
                                        this.transf = node;
                                    }}
                                    selectedShapeName={'rect'}
                                />
                            </Layer>
                        </Stage>
                        </div>
                    )
                })
            })
        }else {

            this.state.selectedVariation.images.forEach((item, index) => {
                let image = new window.Image();

                image.src = item.product_variation_image.base64;

                let newHeight = 340;

                let oldWidth = image.width;
                let oldHeight = image.height;
                let aspectRatio = oldWidth/oldHeight;
                newHeight = 340/aspectRatio;

                image.height = newHeight;
                image.width = 340;
                image.style.objectFit = 'cover';
                image.style.objectPosition = 'center center';
                let scale = 1;

                if(item.product_variation_image && this.state.activeSelectedImage === index){
                    this.state.x = item.product_variation_image.dropzone_x;
                    this.state.y = item.product_variation_image.dropzone_y;
                    this.state.width = item.product_variation_image.dropzone_width ? item.product_variation_image.dropzone_width : 100;
                    this.state.height = item.product_variation_image.dropzone_height ? item.product_variation_image.dropzone_height : 100;
                }

                if (this.state.x === undefined && this.state.y === undefined){
                    this.state.x = image.width/2 - 50;
                    this.state.y = image.height/2 - 50;
                }

                let that = this;

                toRender.push(
                    <div
                        key={index}
                        className={this.state.activeSelectedImage === index ? 'stage-cont active' : 'stage-cont'}
                    >
                        <div
                            className={'safe-zone'}
                            style={{
                                width : this.state.safeWidth,
                                height : this.state.safeHeight,
                                left : this.state.safeX,
                                top : this.state.safeY,
                                position : 'absolute',
                                border: '2px dashed rgb(208 155 208)'
                            }}
                        />
                    <Stage width={340} height={newHeight} key={index}
                           className={this.state.activeSelectedImage === index ? 'stage active' : 'stage'}
                    >
                        <Layer name={"layer"}>
                            <Image image={image}></Image>
                            <Rect
                                ref={node => {
                                    this.rect = node;
                                }}
                                x={this.state.x}
                                y={this.state.y}
                                width={this.state.width}
                                height={this.state.height}
                                shadowBlur={5}
                                draggable
                                rotation={this.state.rotation}
                                name={'rect'}
                                fill={'rgba(255, 255, 255, 0.2)'}
                                onDragEnd={(e) => {
                                    this.state.x = e.currentTarget.attrs.x;
                                    this.state.y = e.currentTarget.attrs.y;
                                    this.state.width = e.currentTarget.attrs.width * (e.currentTarget.attrs.scaleX ? e.currentTarget.attrs.scaleX : 1);
                                    this.state.height = e.currentTarget.attrs.height * (e.currentTarget.attrs.scaleY ? e.currentTarget.attrs.scaleY : 1);
                                }}
                                onTransformEnd={(e) => {
                                    this.state.x = e.currentTarget.attrs.x;
                                    this.state.y = e.currentTarget.attrs.y;
                                    this.state.width = e.currentTarget.attrs.width * (e.currentTarget.attrs.scaleX ? e.currentTarget.attrs.scaleX : 1);
                                    this.state.height = e.currentTarget.attrs.height * (e.currentTarget.attrs.scaleY ? e.currentTarget.attrs.scaleY : 1);
                                }}
                            />
                            <TransformerComponent
                                state={{
                                    x : this.state.x,
                                    y : this.state.y,
                                    width : this.state.width,
                                    height : this.state.height,
                                }}
                                ref={node => {
                                    this.transf = node;
                                }}

                                selectedShapeName={'rect'}
                            />
                        </Layer>
                    </Stage>
                    </div>
                )
            })
        }

        return toRender;
    }

    arrowClick(number){
        let toSet = this.state.activeSelectedImage + number;

        if (toSet < 0){
            toSet = this.state.selectedVariation.images.length - 1;
        }else if (toSet > this.state.selectedVariation.images.length - 1){
            toSet = 0;
        }

        let item = this.state.selectedVariation;
        let zoom = (item.images[toSet] ? (item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.real_dropzone_width ? item.images[toSet].product_variation_image.real_dropzone_width : '' : '') : '')/(item.images[toSet] ? item.images[toSet].product_variation_image.dropzone_width ? item.images[toSet].product_variation_image.dropzone_width : 100 : '');

        let safeWidth = (item.images[toSet] ? (item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.safeWidth ? item.images[toSet].product_variation_image.safeWidth :'': '') : '')/zoom;
        let safeHeight = (item.images[toSet] ? (item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.safeHeight ? item.images[toSet].product_variation_image.safeHeight :'': '') : '')/zoom;

        this.setState({
            ...this.state,
            safeWidth : safeWidth ? safeWidth : '',
            safeHeight : safeHeight ? safeHeight : '',
            activeSelectedImage : toSet,
            safeX : item.images[toSet].product_variation_image.safeX ? item.images[toSet].product_variation_image.safeX : '',
            safeY : item.images[toSet].product_variation_image.safeY ? item.images[toSet].product_variation_image.safeY : '',
            x : item.images[toSet].product_variation_image.dropzone_x ? item.images[toSet].product_variation_image.dropzone_x : 150,
            y : item.images[toSet].product_variation_image.dropzone_y ? item.images[toSet].product_variation_image.dropzone_y : 150,
            width : item.images[toSet].product_variation_image.dropzone_width ? item.images[toSet].product_variation_image.dropzone_width : 100,
            height : item.images[toSet].product_variation_image.dropzone_height ? item.images[toSet].product_variation_image.dropzone_height : 100,
            rotation : item.images[toSet].product_variation_image.dropzone_rotation ? item.images[toSet].product_variation_image.dropzone_rotation : 0,
            data : {
                ...this.state.data,
                safeWidth : item.images[toSet] ? (item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.safeWidth ? item.images[toSet].product_variation_image.safeWidth  : '': '') : '',
                safeHeight : item.images[toSet] ? (item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.safeHeight ? item.images[toSet].product_variation_image.safeHeight  : '': '') : '',
                name : item.images[toSet] ? (item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.public_name ? item.images[toSet].product_variation_image.public_name  : '': '') : '',
                width : item.images[toSet] ? (item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.real_dropzone_width ? item.images[toSet].product_variation_image.real_dropzone_width : '' : '') : '',
                height : item.images[toSet] ? (item.images[toSet].product_variation_image ? item.images[toSet].product_variation_image.real_dropzone_height ? item.images[toSet].product_variation_image.real_dropzone_height :'': '') : '',
            }
        });
    }

    toggleVariationAvailability(e, id){
        e.stopPropagation();

        if (this.state.toggling){
            return ;
        }
        this.state.toggling = true;

        let variations = this.state.variations;
        let goAhead = true;

        variations.forEach(item => {
            if (item.id !== id){
                return
            }

            if (item.images.length === 0){
                goAhead = false;
            }
        })

        if (!goAhead){
            this.props.enqueueSnackbar('Product Variation must have an image to be published', { variant: 'error' });

            setTimeout(() => {
                this.state.toggling = false;

            }, 500);

            return ;
        }


        toggleVariationAvailability(id).then(response => {
            if (!response.ok){
                return ;
            }

            let variations = this.state.variations;

            variations.forEach(item => {
                if (item.id !== id){
                    return ;
                }

                item.is_available = !item.is_available;
            })

            this.setState({
                ...this.state,
                variations,
                toggling : false
            })
        });
    }

    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {

        var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return { width: srcWidth*ratio, height: srcHeight*ratio };
    }

  
    changeSelectedOptions(key, option) {
        this.setState(state => {
          return {
            [key]: option
          };
        });
      }

      

    changeData(event, data = 'data') {
        // if(event.target.name === 'safeWidth'){
        //     let rectW = this.rect.attrs.width*(this.rect.attrs.scaleX ? this.rect.attrs.scaleX : 1);
        //     let rectH = this.rect.attrs.height*(this.rect.attrs.scaleY ? this.rect.attrs.scaleY : 1);
        //
        //     let height = calculateHeightBasedOnWidthAndAspectRation(rectW, rectH, event.target.value);
        //
        //     let heightKey = event.target.name === 'width' ? 'height' : 'safeHeight';
        //
        //     this.setState({
        //         ...this.state,
        //         data : {
        //             ...this.state.data,
        //             [heightKey] : height,
        //             [event.target.name] : event.target.value
        //         }
        //     })
        // }else if(event.target.name === 'safeHeight'){
        //     let rectW = this.rect.attrs.width*(this.rect.attrs.scaleX ? this.rect.attrs.scaleX : 1);
        //     let rectH = this.rect.attrs.height*(this.rect.attrs.scaleY ? this.rect.attrs.scaleY : 1);
        //
        //     let width = calculateWidthBasedOnWidthAndAspectRation(rectW, rectH, event.target.value);
        //
        //     let widthKey = event.target.name === 'height' ? 'width' : 'safeWidth';
        //
        //     this.setState({
        //         ...this.state,
        //         data : {
        //             ...this.state.data,
        //             [widthKey] : width,
        //             [event.target.name] : event.target.value
        //         }
        //     })
        // }else

        if(event.target.name === 'width' || event.target.name === 'height'){

            this.setState({
                ...this.state,
                data : {
                    ...this.state.data,
                    safeWidth : '',
                    safeHeight : '',
                    [event.target.name] : event.target.value
                }
            })
        } else{
            this.setState({
                [data]: update(this.state[data], { [event.target.name]: {$set: event.target.value} })
            });
        }
    }

    isInternationalExpeditedPriceValid() {
        return !((this.state.data.intOtherExpedited && !this.state.data.intFirstExpedited) ||
            (!this.state.data.intOtherExpedited && this.state.data.intFirstExpedited)
        );
    }

    isVendorInternationalExpeditedPriceValid() {
        return !((this.state.data.vendorIntOtherExpedited && !this.state.data.vendorIntFirstExpedited) ||
            (!this.state.data.vendorIntOtherExpedited && this.state.data.vendorIntFirstExpedited)
        );
    }

    render() {
        return (
            <div className={'product-variations'}>
                <div className={'variations'}>
                    <div className={'variation-header'}>
                        <Checkbox
                            onChange={ this.changeCheckBox }
                            margin="normal"
                            value={this.state.data.showCheckboxes}
                            name="showCheckboxes"
                            checked={this.state.data.showCheckboxes}
                        />
                        <p className={'image'}>Image</p>
                        <p className={'sku'}>SKU</p>
                    </div>
                    <div className={'variation-content'}>
                        {this.renderVariations()}
                    </div>
                </div>
                <div className={'variation-controls'}>
                    <div className={'image-control'}>
                        <div className={'image-help'}>
                            <div className={'image-container'}>
                                {
                                    this.state.selectedVariation.images.length > 1 &&
                                    <img className={'arrow'} src={'/images/left.png'} onClick={() => this.arrowClick(-1)} />
                                }
                                {this.renderSelectedImage()}
                                {
                                    this.state.selectedVariation.images.length > 1 &&
                                    <img className={'arrow'} src={'/images/right.png'} onClick={() => this.arrowClick(1)} />
                                }
                            </div>
                            {
                                this.state.selectedVariation.images.length > 0 &&
                                <p>
                                    <input className={this.state.selectedVariation.id ? '' : 'disabled'} onChange={this.replaceImage} type={'file'}/>
                                    Replace Image
                                </p>
                            }
                        </div>
                        <div className={'image-buttons'}>
                            <p className={'counter'}>{this.state.activeSelectedImage + 1}/{this.state.selectedVariation.images.length}</p>
                            <div className={'button-container'}>
                                <Button variant="contained">
                                    <input type={'file'} onChange={this.uploadVariationImage} value={this.randomNum}/>
                                    Add slot</Button>
                                {
                                    !this.state.data.showCheckboxes ?
                                        <Button onClick={this.deletePhoto} variant="contained">Delete</Button>
                                        :
                                        ''
                                }

                            </div>
                        </div>
                    </div>
                    <div className={'info-control'}>
                        <div className={'tabs'}>
                            <label
                                onClick={() => this.toggleTab('info')}
                                className={this.state.tab === 'info' ? 'active' : ''}>Info</label>
                            <label
                                className={this.state.tab === 'price' ? 'active' : ''}
                                onClick={() => this.toggleTab('price')}>Price</label>
                            <label
                                className={this.state.tab === 'shipping' ? 'active' : ''}
                                onClick={() => this.toggleTab('shipping')}>Shipping Price</label>
                           <label
                                className={this.state.tab === 'mediamodifier' ? 'active' : ''}
                                onClick={() => this.toggleTab('mediamodifier')}>Media Modifier</label>
                           <label
                                className={this.state.tab === 'designtemplates' ? 'active' : ''}
                                onClick={() => this.toggleTab('designtemplates')}>Design templates</label>     
                        </div>
                        <div className={'tab-windows'}>
                            <div className={this.state.tab === 'info' ? 'window window-info' : 'window window-info disabled'}>
                                <div className={'info'}>
                                    <label>Name</label>
                                    <TextField
                                        autoFocus
                                        name='name'
                                        onChange={ this.changeData }
                                        value={ this.state.data.name }
                                    />
                                </div>
                                <div className={'dimensions rect'}>
                                    <div className={'dimension-real-rect'}>
                                        <label>Dimensions</label>
                                        <div className={'dimension-fields'}>
                                            <TextField
                                                placeholder={'Height'}
                                                name='height'
                                                onChange={ this.changeData }
                                                value={ this.state.data.height }
                                                label={'Height'}
                                            />
                                            <TextField
                                                placeholder={'Width'}
                                                name='width'
                                                onChange={ this.changeData }
                                                value={ this.state.data.width}
                                                label={'Width'}
                                            />
                                        </div>
                                    </div>
                                    <Button onClick={()=> this.changeRectangleDimensions()}>Save Dimensions</Button>
                                    {/*<Button onClick={()=> this.forceUpdate()}>force</Button>*/}
                                    {/*<Button onClick={()=> console.log(this.rect.attrs)}>rect attrs</Button>*/}
                                    {/*<Button onClick={()=> this.getVariations()}>get vars</Button>*/}

                                </div>
                                <div className={'dimensions'}>
                                    <div>
                                        <label>Safe area</label>
                                        <div className={'dimension-fields'}>
                                            <TextField
                                                placeholder={'Height'}
                                                name='safeHeight'
                                                onChange={ this.changeData }
                                                value={ this.state.data.safeHeight }
                                                disabled={this.state.data.height === '' || this.state.data.width === ''}
                                                label={'Height'}
                                            />
                                            <TextField
                                                placeholder={'Width'}
                                                name='safeWidth'
                                                onChange={ this.changeData }
                                                value={ this.state.data.safeWidth}
                                                disabled={this.state.data.height === '' || this.state.data.width === ''}
                                                label={'Width'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Button onClick={() => {
                                    if (!this.state.data.showCheckboxes){
                                        this.saveSingleVariationInfo()
                                    }else {
                                        this.saveMultiVariationInfo()
                                    }
                                }} variant="contained" color="primary">
                                    Save
                                </Button>
                            </div>
                            <div className={this.state.tab === 'price' ? 'window window-price' : 'window window-price disabled'}>
                                <div>
                                    <label>Price</label>
                                    <TextField
                                        name='price'
                                        onChange={ this.changeData }
                                        value={ this.state.data.price }
                                        type={'number'}
                                    />
                                </div>
                                <br/><br/>
                                <div>
                                    <label>Vendor price</label>
                                    <TextField
                                        name='vendorPrice'
                                        onChange={ this.changeData }
                                        value={ this.state.data.vendorPrice }
                                        type={'number'}
                                    />
                                </div>
                                <Button onClick={() => {
                                    if (!this.state.data.showCheckboxes){
                                        this.saveSingleVariationPrice();
                                    }else{
                                        this.saveMultiVariationPrice();
                                    }
                                }} variant="contained" color="primary">
                                    Save
                                </Button>
                            </div>
                            <div className={this.state.tab === 'shipping' ? 'window window-shipping' : 'window window-shipping disabled'}>
                                <div className={'shipping-container'}>
                                    <div className={'container-price'}>
                                        <div className={'sub-container'}>
                                            <p className={'title'}>US</p>
                                            <div className={'hr'}/>
                                            <div className={'pricing-field-container'}>
                                                <label>First</label>
                                                <div>
                                                    <label>Standard</label>
                                                    <TextField
                                                        name='usFirstStandard'
                                                        onChange={ this.changeData }
                                                        margin="normal"
                                                        type='number'
                                                        value={ this.state.data.usFirstStandard }
                                                    />
                                                </div>
                                                <div>
                                                    <label>Expedited</label>
                                                    <TextField
                                                        name='usFirstExpedited'
                                                        onChange={ this.changeData }
                                                        margin="normal"
                                                        type='number'
                                                        value={ this.state.data.usFirstExpedited }
                                                    />
                                                </div>
                                                <div>
                                                    <label>Overnight</label>
                                                    <TextField
                                                        name='usFirstOvernight'
                                                        onChange={ this.changeData }
                                                        margin="normal"
                                                        type='number'
                                                        value={ this.state.data.usFirstOvernight }
                                                    />
                                                </div>
                                            </div>
                                            <div className={'pricing-field-container'}>
                                                <label>Each Additional</label>
                                                <TextField
                                                    name='usOtherStandard'
                                                    onChange={ this.changeData }
                                                    margin="normal"
                                                    type='number'
                                                    value={ this.state.data.usOtherStandard }
                                                />
                                                <TextField
                                                    name='usOtherExpedited'
                                                    onChange={ this.changeData }
                                                    margin="normal"
                                                    type='number'
                                                    value={ this.state.data.usOtherExpedited }
                                                />
                                                <TextField
                                                    name='usOtherOvernight'
                                                    onChange={ this.changeData }
                                                    margin="normal"
                                                    type='number'
                                                    value={ this.state.data.usOtherOvernight }
                                                />
                                            </div>
                                        </div>
                                        <div className={'sub-container'}>
                                            <p className={'title'}>International</p>
                                            <div className={'hr'}></div>
                                            <div className={'pricing-field-container'}>
                                                <label>First</label>
                                                <div>
                                                    <label>Standard</label>
                                                    <TextField
                                                        name='intFirstStandard'
                                                        onChange={ this.changeData }
                                                        margin="normal"
                                                        type='number'
                                                        value={ this.state.data.intFirstStandard }
                                                    />
                                                </div>
                                                <div>
                                                    <label>Expedited (optional)</label>
                                                    <TextField
                                                        name='intFirstExpedited'
                                                        onChange={ this.changeData }
                                                        margin="normal"
                                                        type='number'
                                                        value={ this.state.data.intFirstExpedited }
                                                    />
                                                </div>
                                            </div>
                                            <div className={'pricing-field-container'}>
                                                <label>Each Additional</label>
                                                <TextField
                                                    name='intOtherStandard'
                                                    onChange={ this.changeData }
                                                    margin="normal"
                                                    type='number'
                                                    value={ this.state.data.intOtherStandard }
                                                />
                                                <TextField
                                                    name='intOtherExpedited'
                                                    onChange={ this.changeData }
                                                    margin="normal"
                                                    type='number'
                                                    value={ this.state.data.intOtherExpedited }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br/><br/>
                                <label className={'shipping'}>Vendor shipping prices</label>
                                <br/>

                                <div className={'shipping-container'}>
                                    <div className={'container-price'}>
                                        <div className={'sub-container'}>
                                            <p className={'title'}>US</p>
                                            <div className={'hr'}/>
                                            <div className={'pricing-field-container'}>
                                                <label>First</label>
                                                <div>
                                                    <label>Standard</label>
                                                    <TextField
                                                        name='vendorUsFirstStandard'
                                                        onChange={ this.changeData }
                                                        margin="normal"
                                                        type='number'
                                                        value={ this.state.data.vendorUsFirstStandard }
                                                    />
                                                </div>
                                                <div>
                                                    <label>Expedited</label>
                                                    <TextField
                                                        name='vendorUsFirstExpedited'
                                                        onChange={ this.changeData }
                                                        margin="normal"
                                                        type='number'
                                                        value={ this.state.data.vendorUsFirstExpedited }
                                                    />
                                                </div>
                                                <div>
                                                    <label>Overnight</label>
                                                    <TextField
                                                        name='vendorUsFirstOvernight'
                                                        onChange={ this.changeData }
                                                        margin="normal"
                                                        type='number'
                                                        value={ this.state.data.vendorUsFirstOvernight }
                                                    />
                                                </div>
                                            </div>
                                            <div className={'pricing-field-container'}>
                                                <label>Each Additional</label>
                                                <TextField
                                                    name='vendorUsOtherStandard'
                                                    onChange={ this.changeData }
                                                    margin="normal"
                                                    type='number'
                                                    value={ this.state.data.vendorUsOtherStandard }
                                                />
                                                <TextField
                                                    name='vendorUsOtherExpedited'
                                                    onChange={ this.changeData }
                                                    margin="normal"
                                                    type='number'
                                                    value={ this.state.data.vendorUsOtherExpedited }
                                                />
                                                <TextField
                                                    name='vendorUsOtherOvernight'
                                                    onChange={ this.changeData }
                                                    margin="normal"
                                                    type='number'
                                                    value={ this.state.data.vendorUsOtherOvernight }
                                                />
                                            </div>
                                        </div>
                                        <div className={'sub-container'}>
                                            <p className={'title'}>International</p>
                                            <div className={'hr'}></div>
                                            <div className={'pricing-field-container'}>
                                                <label>First</label>
                                                <div>
                                                    <label>Standard</label>
                                                    <TextField
                                                        name='vendorIntFirstStandard'
                                                        onChange={ this.changeData }
                                                        margin="normal"
                                                        type='number'
                                                        value={ this.state.data.vendorIntFirstStandard }
                                                    />
                                                </div>
                                                <div>
                                                    <label>Expedited (optional)</label>
                                                    <TextField
                                                        name='vendorIntFirstExpedited'
                                                        onChange={ this.changeData }
                                                        margin="normal"
                                                        type='number'
                                                        value={ this.state.data.vendorIntFirstExpedited }
                                                    />
                                                </div>
                                            </div>
                                            <div className={'pricing-field-container'}>
                                                <label>Each Additional</label>
                                                <TextField
                                                    name='vendorIntOtherStandard'
                                                    onChange={ this.changeData }
                                                    margin="normal"
                                                    type='number'
                                                    value={ this.state.data.vendorIntOtherStandard }
                                                />
                                                <TextField
                                                    name='vendorIntOtherExpedited'
                                                    onChange={ this.changeData }
                                                    margin="normal"
                                                    type='number'
                                                    value={ this.state.data.vendorIntOtherExpedited }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Button onClick={() => {
                                    if (this.state.selectedVariations.length > 1){
                                        this.saveMultiVariationShipping()
                                    }else {
                                        this.editSingleVariationShipping()
                                    }
                                }} variant="contained" color="primary">
                                    Save
                                </Button>
                            </div>
                            <div className={this.state.tab === 'mediamodifier' ? 'window window-mediamodifier override' : 'window window-mediamodifier disabled'}>
                                <div > 
                                    <label>{strings.mediaModifier.editSelectedMockupsForVariationLabel}</label>
                                </div>
                                <Select
                                        name="mediaModifierMockups"
                                        options={this.state.mediaModifierMockupsOptions}
                                        onChange={(value) => this.changeSelectedOptions('mediaModifierMockupsSelectedOptions', value)}
                                        isMulti
                                        value={this.state.mediaModifierMockups}
                                     />
                                <br/><br/>
                                <Button onClick={() => {
                                    this.updateProductVariationsMockups()
                                    
                                    
                                }} variant="contained" color="primary">
                                    Save
                                </Button>
                            </div>
                            <div className={this.state.tab === 'designtemplates' ? 'window window-designtemplates override' : 'window window-designtemplates disabled'}>
                                <div > 
                                    <label>Design templates</label>
                                </div>
                                <Select
                                        name="designtemplates"
                                        onChange={(value) => this.changeSelectedOptions('designTemplatesSelectedOptions', value)}
                                        options={this.state.designTemplates}
                                     />
                                <br/><br/>
                                <Button onClick={() => {
                                    this.updateProductVariationsDesignTemplate()
                                    
                                }} variant="contained" color="primary">
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        showLoader: Actions.showLoader,
        hideLoader: Actions.hideLoader
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}


export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductVariations)));
