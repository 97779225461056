import React from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import {Button, Checkbox, MenuItem, TextField} from "@mui/material";

import {withSnackbar} from "notistack";
import {
    getImportedProduct, matchImportedVariations,
} from '../services/admin/ProductService';
import {getCustomerTemplate, getUnMappedTemplates} from "../services/admin/ProductVariationService";
import {Text} from "react-konva";
import {Autocomplete} from "@mui/lab";
import Divider from "../components/Divider";

class ImportProducts extends Page {

    constructor(props) {
        super(props);

        let templateId = localStorage.getItem('selectedTemplateId');

        this.state = {
            template: {
                variations: []
            },
            id: this.props.match.params.id,
            store: this.props.match.params.store,
            variants: [],
            images: [],
            options: [],
            mainImage: null,
            title: null,
            productExists: true,
            popup: false,
            page: 1,
            selectedTemplate: templateId ? templateId : null,
            selectedTemplateData: {
                id: null,
                image: null,
                name: null
            },
            selectedVariant: {
                id: null,
                sku: null
            },
            autocompleteLoading: true,
            unusedTemplates: [],
            customerVariantsPlusImportedVariants: []
        }

        localStorage.removeItem('selectedTemplateId')
    }

    componentDidMount() {
        getImportedProduct(this.state.store, this.state.id).then(response => {
            if (!response.ok) {
                return;
            }

            let data = response.data;
            let customerTemplate = this.state.selectedTemplate;

            // if you come after create new product button, to find out which template is created
            if (this.props.location.state !== undefined) {
                if ('customerTemplate' in this.props.location.state) {
                    customerTemplate = this.props.location.state.customerTemplate;
                }
            } else {
                customerTemplate = data.customerTemplateId;
            }


            this.setState({
                ...this.state,
                variants: data.variations,
                mainImage: data.image,
                name: data.name,
                selectedTemplate: customerTemplate,
                productExists: (data.numberOfMappedVariations > 0)
            })
        }).then(() => {
            this.getUnMappedTemplates();
            this.selectTemplate();
        });
    }

    changeProductExists(exists){
        this.setState({
            ...this.state,
            productExists: exists
        });
    }

    checkTemplate(e){
        this.setState({
            ...this.state,
            selectedTemplate: this.state.selectedTemplate == e.target.value ? null : e.target.value
        });
    }

    getUnMappedTemplates() {
        getUnMappedTemplates().then(response => {
            this.setState({
                ...this.state,
                unusedTemplates: response.data,
                autocompleteLoading: false
            });
        }).then(() => {
            this.getAlreadyCreatedTemplate();
        })
    }

    getAlreadyCreatedTemplate() {
        if (this.state.selectedTemplate) {
            const data = this.state.unusedTemplates.find((element) => element.id === this.state.selectedTemplate);

            this.setState({
                ...this.state,
                selectedTemplateData: data
            });

        }
    }

    selectTemplate(){
        this.setState({
            ...this.state,
            popup: false
        });

        if (this.state.selectedTemplate == null) {
            return;
        }

        getCustomerTemplate(this.state.selectedTemplate).then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                template: response.data,
                popup: false
            })

        });
    }

    cancelPopup() {
        this.setState({
            ...this.state,
            popup: false
        });
    }

    openPopup() {
        this.setState({
            ...this.state,
            popup: true
        })
    }

    match(id) {
        let customerVariantsPlusImportedVariants = this.state.customerVariantsPlusImportedVariants;
        const selectedVariationId = this.state.selectedVariant.id;

        // customerVariantsPlusImportedVariants[id] == null is null or undefined
        if (customerVariantsPlusImportedVariants[id] == null) {
            if (selectedVariationId === null) {
                this.props.enqueueSnackbar("Please select variation.", {variant: 'error'});
                return;
            }

            if (customerVariantsPlusImportedVariants.includes(selectedVariationId)) {
                this.props.enqueueSnackbar("Variation is already matched.", {variant: 'error'});
                return;
            }

            customerVariantsPlusImportedVariants[id] = selectedVariationId;
        } else {
            customerVariantsPlusImportedVariants[id] = null;
        }

        this.setState({
            ...this.state,
            customerVariantsPlusImportedVariants: customerVariantsPlusImportedVariants,
            selectedVariant: {
                id: null,
                sku: null
            }
        })
    }

    selectVariant(variation) {
        if (variation.mapped) {
            return;
        }

        if (variation === this.state.selectedVariant) {
            variation = {
                id: null,
                sku: null
            };
        }

        this.setState({
            ...this.state,
            selectedVariant: variation
        })
    }

    createTemplate = () => {
        this.props.history.push('/product-catalog', {
            importedProduct: {
                id: this.state.id,
                variants: this.state.variants,
                name: this.state.name,
                image: this.state.image
            }
        });
    }

    finalizeMatching() {
        matchImportedVariations(this.state.store, {
            matching: this.state.customerVariantsPlusImportedVariants,
            id: this.state.id
        }).then(response => {
            if (!response.ok) {
                return;
            }

            this.props.enqueueSnackbar("Successfully matched " + this.state.store + " product!", {variant: 'success'});
            this.props.history.push("/product-templates");
        });
    }

    renderVariations(){
        let toRender = [];

        this.state.template.variations.forEach(item => {
            let customerProductVariationId = item.customerProductVariation.id;
            let checked = this.state.customerVariantsPlusImportedVariants[customerProductVariationId];

            toRender.push(
                <div className={"customer-variation-item"}>
                    <div className={"item checkbox-item"}>
                        <Checkbox
                            checked={checked != null || item.mapped}
                            disabled={item.mapped}
                            color="primary"
                            onChange={() => this.match(customerProductVariationId)}
                        />
                        {/*<Button*/}
                        {/*    className={this.state.customerVariantsPlusImportedVariants[item.customerProductVariationId] !== null ? "matched" : ""}*/}
                        {/*    onClick={ () => this.match(item.customerProductVariationId) }*/}
                        {/*>*/}
                        {/*    {this.state.customerVariantsPlusImportedVariants[item.customerProductVariationId] !== null ? "Matched" : "Match"}*/}
                        {/*</Button>*/}
                    </div>
                    <div className={"item"}> <img src={item.image} /> </div>
                    <div className={"item"}> { item.name } </div>
                    <div className={"item"}>
                        <Text style={{ whiteSpace: "pre-line"}}>
                            {
                                item.attributes.map((item) => {
                                    return item ? item.name + ' - ' + item.value : "-";
                                }).join("\n")
                            }
                        </Text>

                    </div>
                    <div className={"item"}>
                        $ { Number.parseFloat(item.price).toFixed(2) }
                    </div>
                </div>
            );
        });

        return toRender;
    }

    renderTemplates(){
        let toRender = [];

        this.state.templates.forEach(item => {
            toRender.push(
                <div key={item.id} className={'variation'}>
                    <Checkbox
                        key={item.id}
                        onChange={ (e) => this.checkTemplate(e) }
                        margin="normal"
                        value={item.id}
                        name="variationSelect"
                        checked={ item.id == this.state.selectedTemplate }
                    />
                    <img className={"template-image"} src={process.env.REACT_APP_baseUrl + item.image} />
                    <p>{item.name}</p>
                </div>
            );
        });

        return toRender;
    }

    renderUnusedTemplateSelections() {
        let result = [];

        this.state.unusedTemplates.forEach((value) => {
            result.push(
                <MenuItem value={value.id}>{value.name}</MenuItem>
            )
        });

        return result;
    }

    changeUnusedTemplateSelection = (value) => {
        const templateId = value?.id;
        this.setState({
            ...this.state,
            selectedTemplate: (templateId !== undefined) ? templateId : null
        }, () => {
            this.selectTemplate();
        });
    }

    renderVariants(){
        let result = [];

        let index = 0;
        for(let item of this.state.variants) {
            result.push(
                <div key={item.id} className={this.state.selectedVariant.id == item.id ? "variant selected" : "variant" } onClick={ () => this.selectVariant(item)}>
                    <div className={"variant-header"}>{item.sku ? item.sku : item.name}</div>
                    <div className={"variant-options"}>{item.attributes}</div>
                    <img src={item.image} />
                    {
                        (this.state.customerVariantsPlusImportedVariants.includes(item.id) || item.mapped) &&
                        <div className={"match"}>Sync'd</div>
                    }
                    <div style={{margin: '0 auto', textAlign : 'center'}}>{this.state.options[index]}</div>
                </div>
            );
            index ++;
        }
        return result;
    }

    onPageClick(i) {
        this.state.page = i;
        this.getTemplates();
    }

    renderPagination() {
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1) {
            return ;
        }

        if (this.state.page > 5) {
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.page + 4) < this.state.totalPages)){
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page === i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    render() {
        return (
            <div id={"import-products"}>
                {
                    this.state.popup === true &&
                    <div className="variations-popup">
                        <div className="variations-popup-overlay"/>
                        <div className="variations-popup-content">
                            <div className="header">
                                Select customer template for sync
                            </div>
                            <div className="other-variations">
                                {/*{ this.renderTemplates() }*/}
                                <div className={'pagination'}>
                                    { this.renderPagination() }
                                </div>
                            </div>
                            <div className="cacel-add-buttons">
                                <Button className="cancel" onClick={this.cancelPopup}>Cancel</Button>
                                <Button className="done" onClick={this.selectTemplate}>Select</Button>
                            </div>

                        </div>
                    </div>
                }
                <div className={"import-product-header"}>Product:</div>
                <div className={"product-row"}>
                    <div className={"product-image"}>
                        <div className={"product-image-header"}>Product - {this.state.name}</div>
                        <img src={this.state.mainImage} />
                    </div>
                    <div className={"save-options"}>
                        {
                           
                            <>
                                <div className={"product-saved"}>
                                    <div className={"number"}>{this.state.selectedTemplate !== null ? '✓' : ''}</div>
                                    <div className={"question"}>Link your product to one of our products for automatic order fulfilment</div>
                                </div>

                                <Button variant="contained" onClick={this.createTemplate}>Create New Product</Button>

                                <Divider> Or </Divider>

                                <div className={"mt10"} />

                                <Autocomplete
                                    value={this.state.selectedTemplateData}
                                    autoSelect={true}
                                    id="demo-simple-select"
                                    options={this.state.unusedTemplates}
                                    getOptionLabel={(option) => option.name}
                                    size="small"
                                    InputLabelProps={{shrink: true}}
                                    loading={this.state.autocompleteLoading}
                                    renderOption={(option) => (
                                        <>
                                            <img height="36" width="36" style={{marginRight: 5}} src={option.image} /> {option.name}
                                        </>
                                    )}
                                    renderInput={(params) => <TextField {...params} label="Templates" variant="outlined" />}
                                    onChange={(event, newValue) => this.changeUnusedTemplateSelection(newValue)}
                                />

                                <div className={"mt20"} />
                            </>
                        }
                        <div className={"product-saved"}>
                            <div className={"number"} />
                            <div className={"question"}>Next: Sync your Variants</div>
                        </div>
                    </div>
                </div>
                <div className={"import-product-header"}>Variants:</div>
                <div className={"variants-row"}>
                    { this.renderVariants() }
                </div>
                {
                    this.state.selectedTemplate !== null &&
                    <div>
                        <div className={"import-product-header"}>Select a match for: <b>{ this.state.selectedVariant.sku }</b></div>
                        <div className={"customer-prod-items"}>
                            <div className={"customer-products-header"}>
                                <div className={"header-item first"} />
                                <div className={"header-item"}>Mockup image</div>
                                <div className={"header-item"}>Product Name</div>
                                <div className={"header-item"}>Attributes</div>
                                <div className={"header-item"}>Price</div>
                            </div>
                            { this.renderVariations() }
                        </div>
                        <div className={"buttons"}>
                            <Button className={"second"} onClick={ () => this.finalizeMatching() }>Finalize matching</Button>
                        </div>
                    </div>
                }

            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(ImportProducts));
