import React from 'react';
import { number } from 'prop-types';

const IconPaypal = ({ width, height }) => (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 24">
    <g fill="none" fillRule="evenodd">
        <rect width={width} height={height} fill="#FFF" rx="4"/>
        <g fillRule="nonzero">
            <path fill="#283B82" d="M6.595 7.701h-2.33a.327.327 0 0 0-.32.28l-.943 6.137a.198.198 0 0 0 .193.23h1.112c.16 0 .295-.118.32-.28l.254-1.656a.326.326 0 0 1 .32-.28h.737c1.535 0 2.421-.763 2.653-2.275.104-.661.004-1.181-.297-1.545-.332-.4-.919-.611-1.699-.611zm.269 2.241c-.128.859-.766.859-1.384.859h-.352l.247-1.604a.196.196 0 0 1 .192-.168h.16c.422 0 .819 0 1.024.246.122.147.16.366.113.667zM13.56 9.915h-1.115a.196.196 0 0 0-.192.168l-.05.32-.078-.116c-.241-.36-.78-.48-1.318-.48-1.233 0-2.286.959-2.491 2.304-.107.672.045 1.313.416 1.76.34.412.826.584 1.405.584.994 0 1.545-.656 1.545-.656l-.05.318a.198.198 0 0 0 .191.231h1.006c.16 0 .294-.119.32-.28l.603-3.923a.197.197 0 0 0-.192-.23zm-1.555 2.23c-.108.655-.614 1.094-1.259 1.094-.324 0-.583-.106-.75-.309-.164-.2-.227-.486-.174-.805.1-.649.615-1.103 1.25-1.103.317 0 .575.108.744.312.17.206.238.494.189.811zM19.503 9.915h-1.121a.323.323 0 0 0-.268.146l-1.547 2.34-.655-2.25a.326.326 0 0 0-.31-.236h-1.103a.199.199 0 0 0-.184.263l1.235 3.723-1.161 1.683c-.092.133 0 .315.158.315h1.12a.32.32 0 0 0 .266-.142l3.73-5.53c.09-.131-.003-.312-.16-.312"/>
            <path fill="#469BDB" d="M23.216 7.701h-2.33a.327.327 0 0 0-.32.28l-.943 6.137a.198.198 0 0 0 .192.23h1.196a.228.228 0 0 0 .223-.196l.268-1.74a.326.326 0 0 1 .32-.28h.737c1.535 0 2.42-.763 2.652-2.275.105-.661.004-1.181-.297-1.545-.331-.4-.918-.611-1.698-.611zm.269 2.241c-.127.859-.766.859-1.384.859h-.352l.247-1.604a.195.195 0 0 1 .192-.168h.161c.42 0 .818 0 1.023.246.122.147.16.366.113.667zM30.181 9.915h-1.115a.195.195 0 0 0-.192.168l-.05.32-.077-.116c-.242-.36-.78-.48-1.318-.48-1.233 0-2.286.959-2.491 2.304-.106.672.045 1.313.415 1.76.341.412.827.584 1.406.584.993 0 1.544-.656 1.544-.656l-.05.318a.198.198 0 0 0 .193.231h1.004c.16 0 .295-.119.32-.28l.604-3.923a.198.198 0 0 0-.193-.23zm-1.555 2.23c-.107.655-.614 1.094-1.26 1.094-.323 0-.582-.106-.749-.309-.165-.2-.227-.486-.175-.805.101-.649.615-1.103 1.25-1.103.318 0 .575.108.745.312.17.206.238.494.189.811zM31.497 7.87l-.957 6.248a.198.198 0 0 0 .192.23h.961c.16 0 .296-.118.32-.28l.943-6.137a.198.198 0 0 0-.191-.23h-1.077a.196.196 0 0 0-.191.168"/>
        </g>
    </g>
</svg>

);

IconPaypal.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
}

export default IconPaypal;