export function mapPaymentInformation(
  {
    name_on_card,
    last_four_digits,
    expiration_month,
    expiration_year,
    billing_address,
    address_line_two,
    city,
    country,
    state_province,
    postal,
    automatic_billing_code
  }) {
  return {
    paymentCardName: name_on_card,
    lastFourDigits: last_four_digits,
    expiration: (expiration_month != undefined ? expiration_month : "") + "/" + (expiration_year != undefined ? expiration_year : ""),
    paymentAddress1: billing_address,
    paymentAddress2: address_line_two,
    paymentCity: city,
    paymentCountry: country,
    paymentState: state_province,
    paymentPostalCode: postal,
    hasABC: !!automatic_billing_code,
  }
}