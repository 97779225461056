import React from 'react';
import { number, string } from 'prop-types';

const IconSearch = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_57_1487)">
    <path d="M16.625 16.625L21.875 21.875" stroke={color} strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="12.25" cy="12.25" r="6.125" fill="#E4E6F1" fillOpacity="0.8" stroke={color} strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_57_1487">
    <rect width={width} height={height} fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

IconSearch.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired
}

export default IconSearch;