import React from 'react';
import { func, string } from 'prop-types';

import LogoUp from 'src/redesign/components/Logo/LogoUp';
import LogoDown from 'src/redesign/components/Logo/LogoDown';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const OnDemand = ({ title, subtitle, onStartDesigningClick }) => (
  <div className='on-demand'>
    <div className='on-demand-outer-container'>
      <LogoUp width={278} height={159} color={colors.secondary} className='on-demand-logo-up' />
      <LogoDown width={272} height={188} color={colors.secondary} className='on-demand-logo-down' />
      <div className='on-demand-container'>
        <h2 className='on-demand-title'>
          {title}
        </h2>
        <div className='on-demand-description'>
          {subtitle}
        </div>
        <SecondaryButton
          text='Start Designing'
          width={164}
          onClick={onStartDesigningClick}
        />
      </div>
    </div>
  </div>
);

OnDemand.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  onStartDesigningClick: func.isRequired
}

export default OnDemand;