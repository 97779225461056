import React from 'react';
import Overview from 'src/redesign/components/Overview';

const PartnerInfoDetails = ({ order: { user: { fullName, id: userId, email }, id }, phoneNumber }) => {
  return (
    <Overview
      items={[
        {
          name: 'Partner Name',
          value: fullName
        },
        {
          name: 'Partner ID',
          value: userId
        },
        {
          name: 'Partner Order ID',
          value: id
        },
        {
          name: 'Email',
          value: email
        },
        {
          name: 'Phone',
          value: phoneNumber
        }
      ]}
    />
  )
}

export default PartnerInfoDetails;