import {withRouter} from "react-router-dom";
import React from 'react'
import Page from "../common/Page";
import {
    getOrderCustomerProductVariationsById,
    getProductVariationImagesFromCustomerProductVariations,
    uploadArtworkImage, uploadMergedImages
} from "../services/admin/ProductVariationService";
import AttributeType from "../constants/AttributeType";
import Tooltip from '@material-ui/core/Tooltip';
import {Image, Layer, Rect, Stage} from "react-konva";
import TransformerComponent from "../components/TransformerComponent";
import Button from "@material-ui/core/Button";
import {getBase64} from "../services/ImageService";
import {deleteCustomerArtworkImage, getCustomersArtwork} from "../services/UserService";
import {withSnackbar} from "notistack";
import Helmet from 'react-helmet';

class OrderImageEdit extends Page{

    constructor(props) {
        super(props);

        if (!props.location.state){
            this.props.history.push('/orders');
            return
        }

        this.state = {
            variations : {
                dimensions : [],
                items  : []
            },
            data : {
                selectedVariations : []
            },
            variation : {},
            checkedDimensions : [],
            artworkImages : [],
            miniCanvasXArr : [],
            miniCanvasYArr : [],
            attributes : [],
            filterAttributes : [],
            selectedFilters : [],
            firstClick : -1,
            zoom : 0,
            artPage : 1,
            selectedImage : 0,
            imagesPage : 1,
            image : new window.Image(),
            stageScale: 1,
            shadowBlur : 5,
            stageWidth: 0,
            stageX: 0,
            stageY: 0,
            rotation: 0,
            customerTemplateId : props.history.location.state.templateId ? props.history.location.state.templateId : undefined,
            crop: false,
            loaderActive : false,
            x: 0,
            y: 0,
            width: 50,
            height: 50,
            positionX: 0,
            positionY: 0,
            indx: 0,
            imageWidth: 100,
            imageHeight: 100,
            version: 0,
            name: "image",
            myArtworkPopup: false,
            editingStarted: false,
            variationsState: [],
            customerProductVariation: [],
            customerProductVariationObj: [],
            productVariationImage: [],
            customerProductVariationImage: [],
            renderFirstTime: false
        };

        this.closeWindows = this.closeWindows.bind(this);
        this.updateData = this.updateData.bind(this);
    }

    componentDidMount() {
        this.updateData();
    }

    updateData(){
        if (!this.props.history.location.state){
            return
        }

        getOrderCustomerProductVariationsById({Ids : this.props.history.location.state.arrayIds, orderId : this.props.history.location.state.orderId, orderItemId : this.props.history.location.state.orderItemId}).then(response => {
            if (!response.ok){
                return
            }

            let variations = response.data.variations;
            this.state.product = response.data.product;

            let variationIds = this.props.history.location.state.arrayIds;

            getProductVariationImagesFromCustomerProductVariations({variationIds}).then(responseImage => {
                if (!responseImage.ok){
                    return
                }

                variations.forEach(item => {
                    item.variation.show = false;
                    item.variation.showOptions = false;

                    responseImage.data.forEach(image => {
                        if (item.variation.id !== image.product_variation_id){
                            return
                        }

                        this.state.customerProductVariation[item.variation.id] = item.variation.id;
                        this.state.customerProductVariationObj[item.variation.id] = item.variation;
                        this.state.customerProductVariationImage[item.variation.id] = item.customerProductVariationImages ? item.customerProductVariationImages : [];
                        this.state.productVariationImage[item.variation.id] = image.images;
                        item.variation.images = image.images;
                    })
                })

                let sortedVariations = {
                    dimensions : [],
                    items : []
                };

                variations.forEach((item, index) => {
                    if (!sortedVariations.dimensions.includes(item.productVariation.dropzone_width + ' x ' + item.productVariation.dropzone_height)){
                        sortedVariations.dimensions.push(item.productVariation.dropzone_width + ' x ' + item.productVariation.dropzone_height)
                        item.variation.attributes = item.attributes;

                        sortedVariations.items.push([item.variation])
                    }else{
                        let indexOf = sortedVariations.dimensions.indexOf(item.productVariation.dropzone_width + ' x ' + item.productVariation.dropzone_height);
                        item.variation.attributes = item.attributes;
                        sortedVariations.items[indexOf].push(item.variation);
                    }
                })

                this.setState({
                    ...this.state,
                    variations : sortedVariations,
                    savedVariations : sortedVariations
                });
            })
        });
    }

    mouseAction(id){
        let dimensions = this.state.variations.dimensions;
        let variations = this.state.variations.items;

        variations.forEach(items => {
            items.forEach(item => {
                if (item.id !== id){
                    return ;
                }

                item.show = !item.show;
            })
        })

        this.setState({
            ...this.state,
            variations : {
                dimensions : dimensions,
                items : variations
            }
        })
    }

    showOptions(id){

        let variations = this.state.variations.items;
        let dimensions = this.state.variations.dimensions;

        variations.forEach(items => {
            items.forEach(item => {
                if (item.id !== id){
                    return ;
                }

                item.showOptions = !item.showOptions;
            })
        })

        this.setState({
            ...this.state,
            variations : {
                items : variations,
                dimensions : dimensions
            }
        })
    }

    closeWindows(e){
        if (e.target.className === 'option'){
            return
        }

        let variations = this.state.variations.items;
        let dimensions = this.state.variations.dimensions;

        variations.forEach(items => {
            items.forEach(item => {
                item.showOptions = false;
            })
        })

        this.setState({
            ...this.state,
            variations : {
                items : variations,
                dimensions : dimensions
            }
        });
    }



    checkIfHasMergedImage(item){
        if (this.state.customerProductVariationImage[item.id] && this.state.customerProductVariationImage[item.id][0] && this.state.customerProductVariationImage[item.id][0].merged_image){
            return this.state.customerProductVariationImage[item.id][0].merged_image;
        }else {
            return item.images[0] ? item.images[0].product_variation_image ? (item.images[0].product_variation_image.base64) : '/images/shirt.png' : '/images/shirt.png';
        }
    }

    renderVariations(indx){
        let render = [];

        this.state.variations.items[indx].forEach((item, index) => {
            render.push(
                <div key={item.id} className={item.showOptions ? 'variation black' : 'variation'} onMouseLeave={() => this.mouseAction(item.id)} onMouseEnter={()=> this.mouseAction(item.id)}>
                    <img src={process.env.REACT_APP_baseUrl + this.state.customerProductVariationImage[item.id][0].merged_image_url} />
                    <span className={'title'}>{item.product_variation.name}</span>
                    <div className={'price-delete'}>
                        <div className={'price'}>$ {item.product_variation.price}</div>
                        <div onClick={() => this.deleteVariation(item.id)} className={'delete'}>Delete <img src={'/images/redTrash.png'} /></div>
                    </div>
                    <div className={'options'} style={{opacity : window.innerWidth > 992 ? ((item.show || this.state.data['variation' + item.id]) ? 1 : 0) : 1}}>
                        <input className={'variation' + item.id} onChange={(e) => this.changeCheckBox(e, item.id)} type={'checkbox'} name={'variation' + item.id} checked={this.state.data['variation' + item.id]}/>
                    </div>
                </div>
            );
        });

        return render;
    }

    toggleVariationAvailability(e, index, dimension){
        e.stopPropagation();
        e.preventDefault();

        let variations = this.state.variations.items[index];
        let data = this.state.data;
        let dimensions = this.state.checkedDimensions;

        if (this.state.checkedDimensions.includes(dimension)){

            let renderDimensions = [];

            dimensions.forEach( item => {
                if (item === dimension){
                    return
                }

                renderDimensions.push(item);
            })

            dimensions = renderDimensions;
        }else {
            dimensions.push(dimension);
        }

        let first = false;

        this.state.data.selectedVariations.length === 0 ? first = true : first = false;

        variations.forEach((item, index) => {
            this.changeCheckBox({target : {name : 'variation' + item.id}}, item.id)
        })


        this.setState({
            ...this.state,
            checkedDimensions : dimensions,
            renderFirstTime: true
        })
    }

    renderDimensions(){
        let render = [];

        let dim = null;
        let i = null;
        let j = 0;
        this.state.variations.dimensions.forEach((dimension, indx) => {
            if (dimension === ''){
                return
            }

            render.push(
                <div className={'dimensions'} key={dimension}>
                    <div className={'dim-edit'}>
                        <span className={'dimension'}>{dimension} px (widthxheight)</span>
                        <div className={'edits'}>
                            <span className={'edit'}>Apply Edits to All</span>
                            <label className="switch" onClick={(e) => {
                                this.toggleVariationAvailability(e, indx, dimension);
                            }} >
                                <input type="checkbox" checked={this.state.checkedDimensions.includes(dimension)}/>
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div className={'variationss'}>
                        {this.renderVariations(indx)}
                    </div>
                </div>
            );

            if(j===0){
                dim = dimension;
                i = indx;
            }

            j++;
        })

        if(!this.state.renderFirstTime && i != null && dim != null){
            this.toggleVariationAvailability(new MouseEvent('click'), i, dim);
        }

        return render;
    }

    filterVariations(id){
        if (id === -1){
            let attributes = this.state.attributes;

            attributes.forEach(item => {
                if (item.id !== id){
                    item.selected = false;
                    return
                }

                item.selected = true;
            })

            setTimeout(()=> {
                this.setState({
                    ...this.state,
                    attributes : attributes,
                    selectedFilters : [-1],
                    variations : {
                        dimensions : this.state.savedVariations.dimensions,
                        items : this.state.savedVariations.items,
                    }
                })

            }, 100)

            return

        }
        let attributes = this.state.attributes;

        attributes.forEach(item => {
            if (item.id === -1){
                item.selected = false;
                return ;
            }

            if (item.id !== id){
                return
            }

            item.selected = !item.selected;
        })

        let selected = this.state.selectedFilters;

        if (!selected.includes(id)){
            let reSelect = [];

            selected.forEach(selection => {
                if (selection === -1){
                    return
                }

                reSelect.push(selection);
            })

            selected = reSelect;

            selected.push(id);
        }else {
            selected = [];

            this.state.selectedFilters.forEach(item => {
                if (item === id || item === -1){
                    return
                }

                selected.push(item)
            })
        }

        let render = [];

        this.state.savedVariations.items.forEach((items, index) => {
            render.push([])

            items.forEach(item => {
                let hasAttributesCount = 0;
                let supposedToHave = selected.length;

                item.attributes.forEach(attribute => {
                    selected.forEach(id => {
                        if (attribute.attribute_value.id !== id){
                            return
                        }

                        hasAttributesCount++
                    })
                })
                if (hasAttributesCount === supposedToHave){
                    render[index].push(item);
                }
            })
        })

        let renderDimension = [];

        render.forEach((item, index) => {
            if (item.length === 0){
                renderDimension.push('');
                return
            }

            renderDimension.push(this.state.savedVariations.dimensions[index]);
        });

        setTimeout(()=> {
            this.setState({
                ...this.state,
                attributes : attributes,
                selectedFilters : selected,
                variations : {
                    dimensions : renderDimension,
                    items : render,
                }
            })
        }, 100)
    }

    renderFilter(){
        let render = [];

        this.state.attributes.forEach(item => {
            if (item.attribute.attribute_type === AttributeType.STRING){
                render.push(
                    <span key={item.id} className={item.selected ? 'attribute string selected' : 'attribute string'} onClick={()=> this.filterVariations(item.id)}>
                        {item.name}
                    </span>
                );
            }else {
                render.push(
                    <span key={item.id} className={'attribute color'} onClick={()=> this.filterVariations(item.id)} style={{backgroundColor : item.name}}>
                        {
                            item.selected &&
                            <img src={'/images/checked.png'} />
                        }
                    </span>
                );
            }
        })

        return render;
    }

    changeCheckBox(event, id) {
        if (this.state.data.selectedVariations.length === 0 || this.state.data.selectedVariations[0] === id){
            this.setState({
                ...this.state,
                loaderActive : true
            })
        }

        if(id == this.firstSelected){
            this.changedVar = true;
        }else {
            this.changedVar = false;
        }
        this.state.selectedImage = 0;
        let variation;

        this.state.variations.items.forEach(items => {
            items.forEach(item => {
                if (!this.state.data.selectedVariations[0]){
                    if (item.id !== id){
                        return
                    }
                }else {
                    if (item.id !== this.state.data.selectedVariations[0]){
                        return
                    }
                }

                variation = item;
            })
        })


        this.state.selectedProductVariationId = id;

        if(this.state.customerProductVariationImage[id][this.state.selectedImage] && this.state.customerProductVariationImage[id][this.state.selectedImage].product_variation_image.id === variation.images[this.state.selectedImage].product_variation_image.id && this.state.customerProductVariationImage[id].length != 0 && this.state.data.selectedVariations.length === 0){
            this.state.file = this.state.customerProductVariationImage[id][this.state.selectedImage].edited_artwork_image ? this.state.customerProductVariationImage[id][this.state.selectedImage].edited_artwork_image : this.state.customerProductVariationImage[id][this.state.selectedImage].customer_artwork_image.base64;
        }else{
            this.state.file = undefined;
        }

        const field = event.target.name;
        const data = this.state.data;
        let lastOne = !data[field];
        data[field] = !data[field];

        let checked = data[field];

        if (!data.selectedVariations.includes(id)){
            data.selectedVariations.push(id);
        }else {
            let selected = [];

            data.selectedVariations.forEach(item => {
                if (item === id){
                    return
                }

                selected.push(item);
            })

            data.selectedVariations = selected;
        }

        if (data.selectedVariations[0] && data.selectedVariations.length > 1){
            this.firstSelected = data.selectedVariations[0];
        }

        let newCanvasImage = new window.Image();

        if (!this.state.productVariationImage[id][this.state.selectedImage]){
            return
        }

        newCanvasImage.src = this.state.productVariationImage[id][this.state.selectedImage].product_variation_image.base64;

        let that = this;

        setTimeout(()=> {
            this.setState({
                ...this.state,
                loaderActive : false
            })
        }, 1000)

        newCanvasImage.onload = function (){
            if (that.canvasBG === undefined){
                that.canvasBG = [];
                that.newHeight = [];
            }
            let oldWidth;
            let oldHeight;

            oldWidth = newCanvasImage.width;
            oldHeight = newCanvasImage.height;
            if (!that.canvasBG[id]){
                that.canvasBG[id] = newCanvasImage;
            }

            let aspectRatio = oldWidth/oldHeight;
            let newHeight = 340/aspectRatio;

            if (!that.newHeight[id]){
                that.newHeight[id] = newHeight;
            }

            if (that.state.data.selectedVariations && that.state.customerProductVariationImage[that.state.data.selectedVariations[0]] && that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage]){
                that.state.file = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].edited_artwork_image;
            }else {
                that.state.file = undefined;
            }

            that.setState({
                ...that.state,
                data,
            }, ()=> {
                if (that.state.customerProductVariationImage[id][that.state.selectedImage] && that.state.customerProductVariationImage[id][that.state.selectedImage].product_variation_image.id === variation.images[that.state.selectedImage].product_variation_image.id && that.state.data.selectedVariations.length > 0 && lastOne){
                    let im = new window.Image();
                    im.onload = function(){

                        that.state.originalImage = that.state.customerProductVariationImage[id][that.state.selectedImage].set_full_size_art_only ? that.state.customerProductVariationImage[id][that.state.selectedImage].set_full_size_art_only : '';


                        that.state.imgWidth = im.width;
                        that.state.imgHeight = im.height;

                        if(that.state.customerProductVariationImage[id][that.state.selectedImage].rotation === 0 || that.state.customerProductVariationImage[id][that.state.selectedImage].rotation === 270){
                            that.state.positionX = that.state.customerProductVariationImage[id][that.state.selectedImage].position_x;
                        }else if(that.state.customerProductVariationImage[id][that.state.selectedImage].rotation === 90){
                            that.state.positionX = that.state.customerProductVariationImage[id][that.state.selectedImage].position_x - im.width;
                        }else if (that.state.customerProductVariationImage[id][that.state.selectedImage].rotation === 180){
                            that.state.positionX = that.state.customerProductVariationImage[id][that.state.selectedImage].position_x- im.width;
                        }

                        if(that.state.customerProductVariationImage[id][that.state.selectedImage].rotation === 0 || that.state.customerProductVariationImage[id][that.state.selectedImage].rotation === 90){
                            that.state.positionY = that.state.customerProductVariationImage[id][that.state.selectedImage].position_y;
                        }else if (that.state.customerProductVariationImage[id][that.state.selectedImage].rotation === 180){
                            that.state.positionY = that.state.customerProductVariationImage[id][that.state.selectedImage].position_y - im.height;
                        }else if (that.state.customerProductVariationImage[id][that.state.selectedImage].rotation === 270){
                            that.state.positionY = that.state.customerProductVariationImage[id][that.state.selectedImage].position_y - im.height;
                        }

                        that.state.file = that.state.customerProductVariationImage[id][that.state.selectedImage].edited_artwork_image ? that.state.customerProductVariationImage[id][that.state.selectedImage].edited_artwork_image : that.state.customerProductVariationImage[id][that.state.selectedImage].customer_artwork_image.base64;


                        // that.state.stage.scale({ x: that.state.customerProductVariationImage[id][that.state.selectedImage].stage_scale, y: that.state.customerProductVariationImage[id][that.state.selectedImage].stage_scale });
                        that.state.artworkImageId = that.state.customerProductVariationImage[id][that.state.selectedImage].customer_artwork_image ? that.state.customerProductVariationImage[id][that.state.selectedImage].customer_artwork_image.id : ''
                        that.forceUpdate();
                    }

                    im.src = that.state.file;


                }else {
                    that.state.selectedImage = 0;
                    that.state.rotation = 0;
                }

                if( that.state.data.selectedVariations.length > 0){

                    let canvas = document.getElementById('canvas-image');
                    let testImage = new window.Image();
                    testImage = that.state.productVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].product_variation_image.base64
                    let oldWidth = testImage.width;
                    let oldHeight = testImage.height;
                    let aspectRatio = oldWidth/oldHeight;
                    let newHeight = 340/aspectRatio;
                    canvas.style.height= newHeight;
                    that.state.file = undefined;
                    that.state.selectedImage = 0;
                    that.firstSelected = that.state.data.selectedVariations[0];
                    // that.state.newImage = undefined;

                    if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage] && that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage]){
                        let im = new window.Image();


                        im.onload = function(){
                         that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale = 1;
                            if(that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 0 || that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 270){
                                that.state.positionX = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x;
                            }else if(that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 90){
                                that.state.positionX = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x - im.width;
                            }else if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 180){
                                that.state.positionX = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x - im.width;
                            }

                            if(that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 0 || that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 90){
                                that.state.positionY = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y;
                            }else if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 180){
                                that.state.positionY = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y - im.height;
                            }else if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 270){
                                that.state.positionY = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y - im.height;
                            }

                            // that.state.rotation = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation;
                            that.state.stageScale = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale;
                            // that.state.imageNode.attrs.height = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].crop_height;
                            // that.state.imageNode.attrs.width = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].crop_width;
                            that.state.file = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].edited_artwork_image ? that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].edited_artwork_image : that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].customer_artwork_image.base64;
                            that.state.loaderActive = false;

                            that.forceUpdate();
                        }

                        setTimeout(()=> {

                            im.src = that.state.file;
                        }, 1000)


                        if(that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 0 || that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 270){
                            that.state.positionX = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x;
                        }else if(that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 90){
                            that.state.positionX = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x - im.width;
                        }else if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 180){
                            that.state.positionX = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x - im.width;
                        }

                        if(that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 0 || that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 90){
                            that.state.positionY = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y;
                        }else if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 180){
                            that.state.positionY = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y - im.height;
                        }else if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 270){
                            that.state.positionY = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y - im.height;
                        }

                        // that.state.rotation = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation;
                        that.state.stageScale = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale;
                        // that.state.imageNode.attrs.height = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].crop_height;
                        // that.state.imageNode.attrs.width = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].crop_width;
                        that.state.file = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].edited_artwork_image ? that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].edited_artwork_image : that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].customer_artwork_image.base64;
                        // that.state.loaderActive = false;
                    }
                    // that.state.stage.scale({ x: that.state.stageScale, y: that.state.stageScale });
                    that.forceUpdate();
                }else if (id == that.firstSelected && !lastOne && that.state.data.selectedVariations.length === 0) {
                    that.state.file = undefined;
                    that.firstSelected = undefined;
                    that.state.selectedImage = 0;
                }

                // if (!checked && that.state.wholeStage){
                //     let stages = [];
                //
                //
                //     that.state.wholeStage.forEach(item => {
                //         if (id === item.variation){
                //             return
                //         }
                //
                //         stages.push(item);
                //     })
                //
                //     that.state.wholeStage = stages;
                // }

                // that.state.newImage = undefined;

                that.forceUpdate();
            });
        }
    }

    handleWheel = e => {

        if (!this.state.file){
            return
        }
        let event = e;
        if(this.state.stage != null) {
            let prev = this.val;
            this.val = 1 + (e.target.value)/100;

            this.setState({
                ...this.state,
                stageScale: this.val,
                zoom : e.target.value
            }, () =>{
                this.state.stage.scale({ x: this.state.stageScale, y: this.state.stageScale });
                this.fullSizeArt.scale({ x: this.state.stageScale, y: this.state.stageScale });

                this.centerAlign();
            })
        }
    };

    deleteVariation(id){
        let variations = this.state.variations.items;
        let render = [];

        let isFirst = this.state.data.selectedVariations[0] === id;

        variations.forEach((items, index) => {
            render.push([]);

            items.forEach(item => {
                if (item.id === id){
                    return
                }

                render[index].push(item);
            })
        })

        let dimensions = [];

        let selectedVariations = this.state.data.selectedVariations;
        let newSelectedVariations = [];

        selectedVariations.forEach(item => {
            if (item === id){
                return
            }

            newSelectedVariations.push(item)
        })

        render.forEach((items, index) => {
            if (items.length > 0){
                dimensions.push(this.state.variations.dimensions[index])
            }else {
                dimensions.push('')
            }
        })

        let deleted = this.state.deletedVariations ? this.state.deletedVariations : [];
        deleted.push(id)

        setTimeout(()=> {
            this.setState({
                ...this.state,
                deletedVariations : deleted,
                file : isFirst ? undefined : this.state.file,
                variations : {
                    dimensions : dimensions,
                    items : render
                },
                data : {
                    ...this.state.data,
                    selectedVariations : newSelectedVariations
                }
            })
        }, 100)
    }

    renderImage(){
        if (this.state.data.selectedVariations.length === 0){
            return
        }

        let variation;

        this.state.variations.items.forEach(items => {
            items.forEach(item => {
                if (item.id !== this.state.data.selectedVariations[0]){
                    return
                }

                variation = item;
            })
        })

        this.variation = variation;

        let images = [];

        if (!variation){
            return images;
        }

        let image = variation.images[this.state.selectedImage ? this.state.selectedImage : 0];

        let indx = image.product_variation_image.dropzone_x + (image.product_variation_image.dropzone_y * image.product_variation_image.dropzone_width);
        let x = indx%image.product_variation_image.dropzone_width;
        let y = indx/image.product_variation_image.dropzone_height;
        let img = new window.Image();

        if(this.state.file){
            img.src = this.state.file;
        }


        if (!this.state.miniCanvasX){
            this.state.miniCanvasX = image.product_variation_image.dropzone_x;
        }

        if (!this.state.miniCanvasY){
            this.state.miniCanvasY = image.product_variation_image.dropzone_y;
        }

        if (!this.state.miniCanvasWidth){
            this.state.miniCanvasWidth = image.product_variation_image.dropzone_width;
        }

        if (!this.state.miniCanvasHeight){
            this.state.miniCanvasHeight = image.product_variation_image.dropzone_height;
        }

        if (this.state.imageNode){
            let scale = this.state.stage.attrs.scaleX ? this.state.stage.attrs.scaleX : 1;

            if (this.state.imageNode.attrs.width * scale > image.product_variation_image.dropzone_width){
                this.state.width = image.product_variation_image.dropzone_width*(1/scale);
            }else {
                this.state.width = this.state.imageNode.attrs.width;
            }

            if (this.state.imageNode.attrs.height * scale > image.product_variation_image.dropzone_height){
                this.state.height = image.product_variation_image.dropzone_height*(1/scale)
            }else {
                this.state.height = this.state.imageNode.attrs.height;
            }
        }else {
            if (img.width > image.product_variation_image.dropzone_width){
                this.state.width = image.product_variation_image.dropzone_width
            }else {
                this.state.width = img.width
            }

            if (img.height > image.product_variation_image.dropzone_height){
                this.state.height = image.product_variation_image.dropzone_height
            }else {
                this.state.height = img.height
            }

        }

        let zoom = image.product_variation_image.real_dropzone_width / image.product_variation_image.dropzone_width;

        let safeWidth = image.product_variation_image.safeWidth/zoom;
        let safeHeight = image.product_variation_image.safeHeight/zoom;

        let safeX = (image.product_variation_image.dropzone_width - safeWidth)/2;
        let safeY = (image.product_variation_image.dropzone_height- safeHeight)/2;


        images.push(
            <div className={'active variation-image-container'} key={this.state.selectedImage ? this.state.selectedImage : 0}>
                <img id={"canvas-image"}
                    // style={{height : newHeight, width : 340}}
                     src={image.product_variation_image.base64} />
                <div className={'cont-help'}
                     style={{
                         width : image.product_variation_image.dropzone_width,
                         height : image.product_variation_image.dropzone_height,
                         position: 'absolute',
                         top : image.product_variation_image.dropzone_y,
                         left : image.product_variation_image.dropzone_x
                     }}
                >
                    <div
                        style={{
                            // zIndex : '99',
                            width : safeWidth, height : safeHeight, left : safeX, top : safeY, position: 'absolute', border: '2px dashed rgb(208 155 208)'}}
                    />
                    <Stage key={image.id}
                           width={image.product_variation_image.dropzone_width}
                           height={image.product_variation_image.dropzone_height}
                           ref={ node => {
                               this.state.stage = node;
                           }}
                           style={{
                               // position: 'absolute',
                               top : image.product_variation_image.dropzone_y,
                               left : image.product_variation_image.dropzone_x }}
                           className={'stage active'}>
                        <Layer>
                            <Image
                                image={img}
                                draggable
                                width={img.width}
                                height={img.height}
                                x={this.state.positionX}
                                y={this.state.positionY}
                                rotation={this.state.rotation}
                                ref={node => {
                                    this.state.imageNode = node;
                                }}
                                name={"image"}

                                onDragEnd={(e) => {
                                    this.state.positionX = e.currentTarget.attrs.x;
                                    this.state.positionY = e.currentTarget.attrs.y;
                                }}

                                onDragStart={()=> {
                                    this.state.crop = false;
                                    this.forceUpdate();
                                }}
                            ></Image>
                            {
                                this.state.crop &&
                                <Rect
                                    ref={node => {
                                        this.state.rect = node;
                                    }}
                                    width={this.state.width}
                                    height={this.state.height}
                                    rotation={this.state.rotation}
                                    shadowBlur={this.state.shadowBlur}
                                    x={this.state.x === 0 ? (this.state.positionX >= 0 ? this.state.positionX : 0) : this.state.x}
                                    y={this.state.y === 0 ? (this.state.positionY >=0 ? this.state.positionY : 0) : this.state.y}
                                    draggable
                                    name={'rect'}
                                    fill={'rgba(255, 255, 255, 0.2)'}
                                    onDragEnd={(e) => {

                                        // if(this.state.imageNode.attrs.width*this.state.stage.attrs.scaleX <= this.state.stage.attrs.width || this.state.imageNode.attrs.height*this.state.stage.attrs.scaleY <= this.state.stage.attrs.height){
                                        //     if (this.state.rotation === 0){
                                        //         if (
                                        //             Math.round(this.state.rect.attrs.x) < Math.round(this.state.imageNode.attrs.x) ||
                                        //             Math.round(this.state.rect.attrs.x) < 0 ||
                                        //             (Math.round(this.state.rect.attrs.x + this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)) ||
                                        //                 Math.round((this.state.rect.attrs.x + this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) > Math.round(this.state.imageNode.attrs.x) + this.state.imageNode.attrs.width))){
                                        //
                                        //             if (Math.round(this.state.imageNode.attrs.x) < 0){
                                        //                 this.state.rect.x(0);
                                        //             }else{
                                        //                 this.state.rect.x(this.state.imageNode.attrs.x);
                                        //             }
                                        //         }
                                        //
                                        //         if(
                                        //             Math.round(this.state.rect.attrs.y) < Math.round(this.state.imageNode.attrs.y) ||
                                        //             Math.round(this.state.rect.attrs.y) < 0 ||
                                        //             Math.round(this.state.rect.attrs.y + this.state.rect.attrs.height*e.currentTarget.attrs.scaleX) > (this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY)) ||
                                        //             Math.round(this.state.rect.attrs.y + this.state.rect.attrs.height*e.currentTarget.attrs.scaleY) > Math.round(this.state.imageNode.attrs.y) + this.state.imageNode.attrs.height
                                        //         ){
                                        //             if (Math.round(this.state.imageNode.attrs.y) < 0){
                                        //                 this.state.rect.y(0);
                                        //             }else{
                                        //                 this.state.rect.y(this.state.imageNode.attrs.y);
                                        //             }
                                        //         }
                                        //     }else if (this.state.rotation === 90){
                                        //         if(
                                        //             Math.round(this.state.rect.attrs.x) > Math.round(this.state.imageNode.attrs.x) ||
                                        //             Math.round(this.state.rect.attrs.x) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)) ||
                                        //             Math.round(this.state.rect.attrs.x - this.state.rect.attrs.height*e.currentTarget.attrs.scaleY) < 0 ||
                                        //             Math.round(this.state.rect.attrs.x - this.state.rect.attrs.height*e.currentTarget.attrs.scaleY) < Math.round(this.state.imageNode.attrs.x - this.state.imageNode.attrs.height)
                                        //         ){
                                        //             if (Math.round(this.state.imageNode.attrs.x) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX))){
                                        //                 this.state.rect.x(Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)));
                                        //             }else{
                                        //                 this.state.rect.x(this.state.imageNode.attrs.x);
                                        //             }
                                        //         }
                                        //
                                        //         if(
                                        //             Math.round(this.state.rect.attrs.y) < 0 ||
                                        //             Math.round(this.state.rect.attrs.y) < Math.round(this.state.imageNode.attrs.y) ||
                                        //             Math.round(this.state.rect.attrs.y + this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) > Math.round(this.state.imageNode.attrs.y + this.state.imageNode.attrs.width) ||
                                        //             Math.round(this.state.rect.attrs.y + this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) > this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY) ||
                                        //             Math.round(this.state.rect.attrs.y - this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) < Math.round(this.state.imageNode.attrs.y - this.state.imageNode.attrs.width)
                                        //         ){
                                        //             if (Math.round(this.state.imageNode.attrs.y) < 0){
                                        //                 this.state.rect.y(0);
                                        //             }else{
                                        //                 this.state.rect.y(this.state.imageNode.attrs.y);
                                        //             }
                                        //         }
                                        //     }else if(this.state.rotation === 180){
                                        //         if(
                                        //             Math.round(this.state.rect.attrs.x) > Math.round(this.state.imageNode.attrs.x) ||
                                        //             Math.round(this.state.rect.attrs.x) - Math.round(this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) < 0 ||
                                        //             Math.round(this.state.rect.attrs.x) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)) ||
                                        //             Math.round(this.state.rect.attrs.x) - Math.round(this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) <
                                        //             Math.round(this.state.imageNode.attrs.x) - this.state.imageNode.attrs.width
                                        //         ){
                                        //             if (Math.round(this.state.imageNode.attrs.x) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX))){
                                        //                 this.state.rect.x(Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)));
                                        //             }else{
                                        //                 this.state.rect.x(this.state.imageNode.attrs.x);
                                        //             }
                                        //         }
                                        //
                                        //         if(
                                        //             Math.round(this.state.rect.attrs.y) > Math.round(this.state.imageNode.attrs.y) ||
                                        //             Math.round(this.state.rect.attrs.y - this.state.rect.attrs.height*e.currentTarget.attrs.scaleY) < Math.round(this.state.imageNode.attrs.y - this.state.imageNode.attrs.height) ||
                                        //             Math.round(this.state.rect.attrs.y) - Math.round(this.state.rect.attrs.height*e.currentTarget.attrs.scaleY) < 0 ||
                                        //             Math.round(this.state.rect.attrs.y) > Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY))
                                        //         ){
                                        //             if (Math.round(this.state.imageNode.attrs.y) > Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY))){
                                        //                 this.state.rect.y(Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY)))
                                        //             }else{
                                        //                 this.state.rect.y(this.state.imageNode.attrs.y);
                                        //             }
                                        //         }
                                        //     }else if(this.state.rotation === 270){
                                        //         if (
                                        //             Math.round(this.state.rect.attrs.x) < 0 ||
                                        //             Math.round(this.state.rect.attrs.x) < Math.round(this.state.imageNode.attrs.x) ||
                                        //             Math.round(this.state.rect.attrs.x + this.state.rect.attrs.height*e.currentTarget.attrs.scaleY) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)) ||
                                        //             Math.round(this.state.rect.attrs.x + this.state.rect.attrs.height*e.currentTarget.attrs.scaleY) > Math.round(this.state.imageNode.attrs.x + this.state.imageNode.attrs.height)
                                        //         ){
                                        //             if (Math.round(this.state.imageNode.attrs.x) < 0){
                                        //                 this.state.rect.x(0);
                                        //             }else {
                                        //                 this.state.rect.x(this.state.imageNode.attrs.x);
                                        //             }
                                        //         }
                                        //
                                        //         if(
                                        //             Math.round(this.state.rect.attrs.y) > Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY)) ||
                                        //             Math.round(this.state.rect.attrs.y) > Math.round(this.state.imageNode.attrs.y) ||
                                        //             Math.round(this.state.rect.attrs.y - this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) < Math.round(this.state.imageNode.attrs.y - this.state.imageNode.attrs.width) ||
                                        //             Math.round(this.state.rect.attrs.y - this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) < 0
                                        //         ){
                                        //             if (Math.round(this.state.imageNode.attrs.y) > Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY))){
                                        //                 this.state.rect.y(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY));
                                        //             }else {
                                        //                 this.state.rect.y(this.state.imageNode.attrs.y);
                                        //             }
                                        //         }
                                        //     }
                                        // }else {
                                        //     if (this.state.rotation === 0){
                                        //         if (Math.round(this.state.rect.attrs.x) < 0 ||
                                        //             Math.round(this.state.rect.attrs.x + this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX))){
                                        //             this.state.rect.x(0);
                                        //             this.state.rect.y(0);
                                        //         }
                                        //
                                        //         if(Math.round(this.state.rect.attrs.y) < 0 ||
                                        //             Math.round(this.state.rect.attrs.y + this.state.rect.attrs.height*e.currentTarget.attrs.scaleY) > Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY))
                                        //         ){
                                        //             this.state.rect.x(0);
                                        //             this.state.rect.y(0);
                                        //         }
                                        //     }else if (this.state.rotation === 90){
                                        //         if(Math.round(this.state.rect.attrs.x) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)) ||
                                        //             Math.round(this.state.rect.attrs.x - this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) < 0
                                        //         ){
                                        //             this.state.rect.x(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY));
                                        //             this.state.rect.y(0);
                                        //         }
                                        //
                                        //         if (Math.round(this.state.rect.attrs.y) < 0 ||
                                        //             Math.round(this.state.rect.attrs.y + this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) >  Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY))
                                        //         ) {
                                        //             this.state.rect.x(this.state.rect.attrs.width*e.currentTarget.attrs.scaleX);
                                        //             this.state.rect.y(0);
                                        //         }
                                        //     }else if (this.state.rotation === 180){
                                        //         if (
                                        //             Math.round(this.state.rect.attrs.x) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)) ||
                                        //             Math.round(this.state.rect.attrs.x - this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) < 0
                                        //         ){
                                        //             this.state.rect.x(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX));
                                        //             this.state.rect.y(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX));
                                        //         }
                                        //
                                        //         if(
                                        //             Math.round(this.state.rect.attrs.y) > Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY)) ||
                                        //             Math.round(this.state.rect.attrs.y - this.state.rect.attrs.height*e.currentTarget.attrs.scaleY) < 0
                                        //         ){
                                        //             this.state.rect.x(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX));
                                        //             this.state.rect.y(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX));
                                        //         }
                                        //     }else if (this.state.rotation === 270){
                                        //         if (Math.round(this.state.rect.attrs.x) < 0 ||
                                        //             Math.round(this.state.rect.attrs.x + this.state.rect.attrs.height*e.currentTarget.attrs.scaleY) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX))){
                                        //             this.state.rect.x(0);
                                        //             // this.state.rect.y(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY));
                                        //         }
                                        //
                                        //         if(
                                        //             Math.round(this.state.rect.attrs.y) > Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY)) ||
                                        //             Math.round(this.state.rect.attrs.y - this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) < 0){
                                        //             // this.state.rect.x(0);
                                        //             this.state.rect.y(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY));
                                        //         }
                                        //     }
                                        // }
                                    }}

                                    onTransformEnd={(e) => {
                                        // if (this.state.imageNode.attrs.width*this.state.stage.attrs.scaleX <= this.state.stage.attrs.width || this.state.imageNode.attrs.height*this.state.stage.attrs.scaleY <= this.state.stage.attrs.height){
                                        //     if (this.state.rotation === 0){
                                        //         if(Math.round(e.currentTarget.attrs.width * e.currentTarget.attrs.scaleX + this.state.rect.attrs.x) > Math.round(this.state.imageNode.attrs.x + this.state.imageNode.attrs.width) ||
                                        //             (Math.round(e.currentTarget.attrs.x) < Math.round(this.state.imageNode.attrs.x))
                                        //         ){
                                        //             this.state.rect.scaleX(1);
                                        //             this.state.rect.scaleY(1);
                                        //
                                        //             if (this.state.imageNode.attrs.x < 0){
                                        //                 this.state.rect.x(0);
                                        //             }else{
                                        //                 this.state.rect.x(this.state.imageNode.x());
                                        //             }
                                        //
                                        //             if (this.state.imageNode.attrs.y < 0){
                                        //                 this.state.rect.y(0);
                                        //             }else{
                                        //                 this.state.rect.y(this.state.imageNode.y());
                                        //             }
                                        //         }
                                        //
                                        //         if (Math.round(e.currentTarget.attrs.height * e.currentTarget.attrs.scaleY + this.state.rect.attrs.y)  > Math.round(this.state.imageNode.attrs.y + this.state.imageNode.attrs.height) ||
                                        //             (Math.round(e.currentTarget.attrs.y) < Math.round(this.state.imageNode.attrs.y))
                                        //         ){
                                        //             this.state.rect.scaleY(1);
                                        //             this.state.rect.scaleX(1);
                                        //
                                        //             if (this.state.imageNode.attrs.x < 0){
                                        //                 this.state.rect.x(0);
                                        //             }else{
                                        //                 this.state.rect.x(this.state.imageNode.x());
                                        //             }
                                        //
                                        //             if (this.state.imageNode.attrs.y < 0){
                                        //                 this.state.rect.y(0);
                                        //             }else{
                                        //                 this.state.rect.y(this.state.imageNode.y());
                                        //             }
                                        //         }
                                        //     }else if (this.state.rotation === 90){
                                        //         if((Math.round(this.state.rect.attrs.x) > Math.round(this.state.imageNode.attrs.x) ||
                                        //             Math.round(this.state.imageNode.attrs.x - this.state.imageNode.attrs.height) > Math.round(this.state.rect.attrs.x - this.state.rect.attrs.height*e.currentTarget.attrs.scaleY ))
                                        //         ){
                                        //             this.state.rect.scaleY(1);
                                        //             this.state.rect.scaleX(1);
                                        //
                                        //             if (this.state.imageNode.attrs.x > this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)){
                                        //                 this.state.rect.x(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX));
                                        //             }else{
                                        //                 this.state.rect.x(this.state.imageNode.x());
                                        //             }
                                        //
                                        //             if (this.state.imageNode.attrs.y < 0){
                                        //                 this.state.rect.y(0);
                                        //             }else{
                                        //                 this.state.rect.y(this.state.imageNode.y());
                                        //             }
                                        //         }
                                        //
                                        //         if (((Math.round(this.state.rect.attrs.y) < Math.round(this.state.imageNode.attrs.y)) || Math.round((this.state.rect.attrs.y + this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) > Math.round(this.state.imageNode.attrs.y + this.state.imageNode.attrs.width)))
                                        //         ){
                                        //             this.state.rect.scaleX(1);
                                        //             this.state.rect.scaleY(1);
                                        //
                                        //             if (this.state.imageNode.attrs.y < 0){
                                        //                 this.state.rect.y(0);
                                        //             }else{
                                        //                 this.state.rect.y(this.state.imageNode.y());
                                        //             }
                                        //
                                        //             if (this.state.imageNode.attrs.x > this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)){
                                        //                 this.state.rect.x(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX));
                                        //             }else{
                                        //                 this.state.rect.x(this.state.imageNode.x());
                                        //             }
                                        //         }
                                        //     }else if (this.state.rotation === 180){
                                        //         if((Math.round(this.state.imageNode.attrs.x) < Math.round(this.state.rect.attrs.x))||(Math.round(this.state.imageNode.attrs.x - this.state.imageNode.attrs.width) > Math.round(this.state.rect.attrs.x - this.state.rect.attrs.width*e.currentTarget.attrs.scaleX))
                                        //         ){
                                        //             this.state.rect.scaleX(1);
                                        //             this.state.rect.scaleY(1);
                                        //
                                        //             if(this.state.imageNode.attrs.x > this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)){
                                        //                 this.state.rect.x(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX));
                                        //             }else{
                                        //                 this.state.rect.x(this.state.imageNode.x());
                                        //             }
                                        //
                                        //             if(this.state.imageNode.attrs.y > this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY)){
                                        //                 this.state.rect.y(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY));
                                        //             }else{
                                        //                 this.state.rect.y(this.state.imageNode.y());
                                        //             }
                                        //         }
                                        //
                                        //         if ((Math.round(this.state.rect.attrs.y) > Math.round(this.state.imageNode.attrs.y)) || (Math.round(this.state.imageNode.attrs.y - this.state.imageNode.attrs.height) > Math.round(this.state.rect.attrs.y - this.state.rect.attrs.height*e.currentTarget.attrs.scaleY))){
                                        //
                                        //             this.state.rect.scaleX(1);
                                        //             this.state.rect.scaleY(1);
                                        //
                                        //             if(this.state.imageNode.attrs.x > this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)){
                                        //                 this.state.rect.x(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX));
                                        //             }else{
                                        //                 this.state.rect.x(this.state.imageNode.x());
                                        //             }
                                        //
                                        //             if(this.state.imageNode.attrs.y > this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY)){
                                        //                 this.state.rect.y(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY));
                                        //             }else{
                                        //                 this.state.rect.y(this.state.imageNode.y());
                                        //             }
                                        //         }
                                        //     }else if (this.state.rotation === 270){
                                        //
                                        //         if((Math.round(this.state.rect.attrs.x) < Math.round(this.state.imageNode.attrs.x)) || (Math.round(this.state.imageNode.attrs.x + this.state.imageNode.attrs.height) < Math.round(this.state.rect.attrs.x + this.state.rect.attrs.height*e.currentTarget.attrs.scaleY))){
                                        //             this.state.rect.scaleX(1);
                                        //             this.state.rect.scaleY(1);
                                        //
                                        //             if (Math.round(this.state.imageNode.attrs.x) < 0){
                                        //                 this.state.rect.x(0);
                                        //             }else{
                                        //                 this.state.rect.x(this.state.imageNode.x());
                                        //             }
                                        //
                                        //             if(Math.round(this.state.imageNode.attrs.y) > Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY))){
                                        //                 this.state.rect.y(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY));
                                        //             }else{
                                        //                 this.state.rect.y(this.state.imageNode.y());
                                        //             }
                                        //         }
                                        //         if(((Math.round(this.state.imageNode.attrs.y) < Math.round(this.state.rect.attrs.y)) || (Math.round(this.state.rect.attrs.y - this.state.rect.attrs.width*e.currentTarget.attrs.scaleX)) < Math.round(this.state.imageNode.attrs.y - this.state.imageNode.attrs.width))
                                        //         ){
                                        //             this.state.rect.scaleX(1);
                                        //             this.state.rect.scaleY(1);
                                        //
                                        //             if (Math.round(this.state.imageNode.attrs.x) < 0){
                                        //                 this.state.rect.x(0);
                                        //             }else{
                                        //                 this.state.rect.x(this.state.imageNode.x());
                                        //             }
                                        //
                                        //             if(Math.round(this.state.imageNode.attrs.y) > Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY))){
                                        //                 this.state.rect.y(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY));
                                        //             }else{
                                        //                 this.state.rect.y(this.state.imageNode.y());
                                        //             }
                                        //         }
                                        //     }
                                        // }else {
                                        //
                                        //     if (this.state.rotation === 0){
                                        //         if ((Math.round(this.state.rect.attrs.x +this.state.rect.attrs.width*e.currentTarget.attrs.scaleX)) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)) ||
                                        //             Math.round(this.state.rect.attrs.x) < 0){
                                        //             this.state.rect.scaleY(1);
                                        //             this.state.rect.scaleX(1);
                                        //
                                        //             if (this.state.imageNode.attrs.x < 0){
                                        //                 this.state.rect.x(0);
                                        //             }else {
                                        //                 this.state.rect.x(this.state.imageNode.attrs.x);
                                        //             }
                                        //
                                        //             if (this.state.imageNode.attrs.y < 0){
                                        //                 this.state.rect.y(0);
                                        //             }else {
                                        //                 this.state.rect.y(this.state.imageNode.attrs.y);
                                        //             }
                                        //         }
                                        //
                                        //         if(Math.round(this.state.rect.attrs.y + this.state.rect.attrs.height*e.currentTarget.attrs.scaleY) > Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY)) ||
                                        //             Math.round(this.state.rect.attrs.y) < 0 || Math.round(this.state.rect.attrs.y) < Math.round(this.state.imageNode.attrs.y)){
                                        //             this.state.rect.scaleY(1);
                                        //             this.state.rect.scaleX(1);
                                        //
                                        //             if (this.state.imageNode.attrs.x < 0){
                                        //                 this.state.rect.x(0);
                                        //             }else {
                                        //                 this.state.rect.x(this.state.imageNode.attrs.x);
                                        //             }
                                        //
                                        //             if (this.state.imageNode.attrs.y < 0){
                                        //                 this.state.rect.y(0);
                                        //             }else {
                                        //                 this.state.rect.y(this.state.imageNode.attrs.y);
                                        //             }
                                        //         }
                                        //     }else if (this.state.rotation === 90){
                                        //         if ((Math.round(this.state.rect.attrs.x) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX))) || (Math.round(this.state.rect.attrs.x) - Math.round(this.state.rect.attrs.height*e.currentTarget.attrs.scaleY)) < 0){
                                        //             this.state.rect.scaleY(1);
                                        //             this.state.rect.scaleX(1);
                                        //
                                        //             if (this.state.imageNode.attrs.x - this.state.imageNode.attrs.height < 0){
                                        //                 this.state.rect.x(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX));
                                        //             }else {
                                        //                 this.state.rect.x(0);
                                        //             }
                                        //         }
                                        //
                                        //         if (Math.round(this.state.rect.attrs.y) < 0 || Math.round((this.state.rect.attrs.y) + this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) > this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleX)){
                                        //             this.state.rect.scaleY(1);
                                        //             this.state.rect.scaleX(1);
                                        //
                                        //             if (this.state.imageNode.attrs.y - this.state.imageNode.attrs.width < 0){
                                        //                 this.state.rect.y(0);
                                        //             }else {
                                        //                 this.state.rect.y(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY));
                                        //             }
                                        //         }
                                        //     }else if(this.state.rotation === 180){
                                        //         if(Math.round(this.state.rect.attrs.x) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX)) ||
                                        //             Math.round(this.state.rect.attrs.x - this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) < 0){
                                        //             this.state.rect.scaleY(1);
                                        //             this.state.rect.scaleX(1);
                                        //             this.state.rect.x(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX));
                                        //             this.state.rect.y(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleX));
                                        //         }
                                        //
                                        //         if(Math.round(this.state.rect.attrs.y) > Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY)) ||
                                        //             Math.round(this.state.rect.attrs.y - this.state.rect.attrs.height*e.currentTarget.attrs.scaleY) < 0){
                                        //             this.state.rect.scaleY(1);
                                        //             this.state.rect.scaleX(1);
                                        //             this.state.rect.x(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX));
                                        //             this.state.rect.y(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleX));
                                        //         }
                                        //     }else if(this.state.rotation === 270){
                                        //         if (Math.round(this.state.rect.attrs.x) < 0 || (Math.round(this.state.rect.attrs.x +this.state.rect.attrs.height*e.currentTarget.attrs.scaleY)) > Math.round(this.state.stage.attrs.width*(1/this.state.stage.attrs.scaleX))){
                                        //             this.state.rect.scaleY(1);
                                        //             this.state.rect.scaleX(1);
                                        //             this.state.rect.x(0);
                                        //             this.state.rect.y(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleX));
                                        //         }
                                        //
                                        //         if(Math.round(this.state.rect.attrs.y) > Math.round(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleY)) ||
                                        //             Math.round(this.state.rect.attrs.y - this.state.rect.attrs.width*e.currentTarget.attrs.scaleX) < 0
                                        //         ){
                                        //             this.state.rect.scaleY(1);
                                        //             this.state.rect.scaleX(1);
                                        //             this.state.rect.x(0);
                                        //             this.state.rect.y(this.state.stage.attrs.height*(1/this.state.stage.attrs.scaleX));
                                        //         }
                                        //     }
                                        // }
                                    }}
                                />
                            }
                            {
                                this.state.crop &&
                                <TransformerComponent
                                    ref={node => {
                                        this.state.transformNode = node;
                                    }}
                                    state={{
                                        x : this.state.x,
                                        y : this.state.y,
                                        width :  this.state.width,
                                        height : this.state.height,
                                    }}
                                    selectedShapeName={'rect'}
                                />
                            }
                        </Layer>
                    </Stage>
                </div>
            </div>
        )




        return images;
    }

    rotate(){
        if (!this.state.file){
            return
        }
        if(this.state.rotation == 270) { this.state.rotation = 0; }
        else {
            this.state.rotation = this.state.rotation + 90;
        }
        this.state.crop = false;

        let zoom = this.state.stage.attrs.scaleX ? this.state.stage.attrs.scaleX : 1;

        if(this.state.rotation == 0){
            this.xIsY = false;
            this.state.positionX = (this.state.stage.attrs.width*(1/zoom) - this.state.imageNode.attrs.width)/2;
            this.state.positionY = (this.state.stage.attrs.height*(1/zoom) - this.state.imageNode.attrs.height)/2;
        }else if(this.state.rotation == 90){
            this.xIsY = true;
            this.state.positionX = (this.state.stage.attrs.width*(1/zoom) - this.state.imageNode.attrs.height)/2 + this.state.imageNode.attrs.height;
            this.state.positionY = (this.state.stage.attrs.height*(1/zoom) - this.state.imageNode.attrs.width)/2;
        }else if(this.state.rotation == 180){
            this.xIsY = false;
            this.state.positionX = (this.state.stage.attrs.width*(1/zoom) - this.state.imageNode.attrs.width)/2 + this.state.imageNode.attrs.width;
            this.state.positionY = (this.state.stage.attrs.height*(1/zoom) - this.state.imageNode.attrs.height)/2 + this.state.imageNode.attrs.height;
        }else if(this.state.rotation == 270){
            this.xIsY = true;
            this.state.positionX = (this.state.stage.attrs.width*(1/zoom) - this.state.imageNode.attrs.height)/2;
            this.state.positionY = (this.state.stage.attrs.height*(1/zoom) - this.state.imageNode.attrs.width)/2 + this.state.imageNode.attrs.width;
        }


        this.state.editingStarted = true;
    }

    leftAlign(){
        if (!this.state.file){
            return
        }
        this.state.editingStarted = true;
        this.state.crop = false;

        if(this.state.rotation == 0){
            this.state.positionX = 0;
        }else if(this.state.rotation == 90){
            this.state.positionX = this.state.imageNode.attrs.height;
        }else if(this.state.rotation == 180){
            this.state.positionX = this.state.imageNode.attrs.width;
        }else if(this.state.rotation == 270){
            this.state.positionX = 0;
        }
    }

    saveImage(){
        this.state.canvasLoader = true;

        this.forceUpdate();
        let width = 0;
        let height = 0;
        let x = 0;
        let y = 0;

        if (Math.sign(this.state.imageNode.attrs.x) === -1 && Math.sign(this.state.imageNode.attrs.y) === -1){
            x = 0;
            y = 0;
            width = this.state.imageNode.attrs.width + this.state.imageNode.attrs.x;
            height = this.state.imageNode.attrs.height + this.state.imageNode.attrs.y;
        }else if(Math.sign(this.state.imageNode.attrs.x) === -1 && Math.sign(this.state.imageNode.attrs.y) === 1){
            x = 0;
            y = this.state.imageNode.attrs.y;
            width = this.state.imageNode.attrs.width + this.state.imageNode.attrs.x;
            height = this.state.stage.attrs.height - this.state.imageNode.attrs.y;
        }else if(Math.sign(this.state.imageNode.attrs.y) === -1 && ((this.state.imageNode.attrs.x + this.state.imageNode.attrs.width) > this.state.stage.attrs.width)){
            x = this.state.imageNode.attrs.x;
            y = 0;
            width = this.state.stage.attrs.width + this.state.imageNode.attrs.x;
            height = this.state.imageNode.attrs.height - this.state.imageNode.attrs.y;
        }else if((this.state.imageNode.attrs.x + this.state.imageNode.attrs.width) > this.state.stage.attrs.width &&
            ((this.state.imageNode.attrs.y + this.state.imageNode.attrs.height)) > this.state.stage.attrs.height){
            x = this.state.imageNode.attrs.x;
            y = this.state.imageNode.attrs.y;
            width = this.state.stage.attrs.width - this.state.imageNode.attrs.x;
            height = this.state.stage.attrs.height - this.state.imageNode.attrs.y;
        }else if(Math.sign(this.state.imageNode.attrs.x) === -1){
            x = 0;
            y = this.state.imageNode.attrs.y;
            width = this.state.imageNode.attrs.width - this.state.imageNode.attrs.x;
            height = this.state.imageNode.attrs.height;
        }else if((this.state.imageNode.attrs.y + this.state.imageNode.attrs.height) > this.state.stage.attrs.height){
            x = this.state.imageNode.attrs.x;
            y = this.state.imageNode.attrs.y;
            width = this.state.imageNode.attrs.width;
            height = this.state.stage.attrs.height - this.state.imageNode.attrs.y;
        }else if(Math.sign(this.state.imageNode.attrs.y) === -1){
            height = this.state.imageNode.attrs.height + this.state.imageNode.attrs.y;
            width = this.state.imageNode.attrs.width;
            x = this.state.imageNode.attrs.x;
            y = 0;
        }else if((this.state.imageNode.attrs.x + this.state.imageNode.attrs.width) > this.state.stage.attrs.width){
            height = this.state.imageNode.attrs.height;
            width = this.state.stage.attrs.width - this.state.imageNode.attrs.x;
            x = this.state.imageNode.attrs.x;
            y = this.state.imageNode.attrs.y;
        }else {
            height = this.state.imageNode.attrs.height;
            width = this.state.imageNode.attrs.width;
            x = this.state.imageNode.attrs.x;
            y = this.state.imageNode.attrs.y;
        }
        let length = this.state.data.selectedVariations.length;

        this.state.data.selectedVariations.forEach((item, index) => {
            let testImage = this.state.imageNode.toDataURL({
                mimeType: "image/png",
                quality: 0,
                pixelRatio: 1,
                // x : x * this.state.stageScale,
                // y : y * this.state.stageScale,
                // width : width * this.state.stageScale,
                // height : height * this.state.stageScale
            });
            let testImageStage = this.state.stage.toDataURL({
                mimeType: "image/png",
                quality: 0,
                pixelRatio: 1,
                // x : x * this.state.stageScale,
                // y : y * this.state.stageScale,
                // width : width * this.state.stageScale,
                // height : height * this.state.stageScale
            });

            if (this.state.productVariationImage[item][this.state.selectedImage]){
                // this.state.miniCanvasWidth = width * this.state.stageScale;
                // this.state.miniCanvasHeight = height * this.state.stageScale;

                let dropzoneX;
                let dropzoneY;
                let variation;

                this.state.variations.items.forEach(items => {
                    items.forEach(varItem => {
                        if (varItem.id !== item){
                            return
                        }

                        variation = varItem;
                    })
                })


                this.state.productVariationImage[item].forEach(imageItem => {
                    if (imageItem.product_variation_image.id !== variation.images[this.state.selectedImage].product_variation_image.id){
                        return
                    }

                    dropzoneX = imageItem.product_variation_image.dropzone_x;
                    dropzoneY = imageItem.product_variation_image.dropzone_y;
                })

                this.state.miniCanvasX = dropzoneX + (Math.sign(this.state.imageNode.attrs.x) === -1 ? 0 : this.state.imageNode.attrs.x)* this.state.stageScale;
                this.state.miniCanvasY = dropzoneY + (Math.sign(this.state.imageNode.attrs.y) === -1 ? 0 : this.state.imageNode.attrs.y)* this.state.stageScale;
                let scal = this.state.stage.attrs.scaleX ? this.state.stage.attrs.scaleX : 1;
                this.state.miniCanvasXArr[item] = dropzoneX;
                this.state.miniCanvasYArr[item] = dropzoneY;

                // if(this.state.rotation === 0 || this.state.rotation === 270){
                //     this.state.miniCanvasXArr[item] = dropzoneX + (Math.sign(this.state.imageNode.attrs.x) === -1 ? 0 : this.state.imageNode.attrs.x)*(scal);
                // }else if(this.state.rotation === 90){
                //     this.state.miniCanvasXArr[item] = dropzoneX + (Math.sign(this.state.imageNode.attrs.x) === -1 ? 0 : this.state.imageNode.attrs.x - this.state.imageNode.attrs.height)*(scal);
                // }else if (this.state.rotation === 180){
                //     this.state.miniCanvasXArr[item] = dropzoneX + (Math.sign(this.state.imageNode.attrs.x) === -1 ? 0 : this.state.imageNode.attrs.x - this.state.imageNode.attrs.width)*(scal);
                // }
                //
                // if(this.state.rotation === 0 || this.state.rotation === 90){
                //     this.state.miniCanvasYArr[item] = dropzoneY + (Math.sign(this.state.imageNode.attrs.y) === -1 ? 0 : this.state.imageNode.attrs.y)*(scal);
                // }else if (this.state.rotation === 180){
                //     this.state.miniCanvasYArr[item] = dropzoneY + (Math.sign(this.state.imageNode.attrs.y) === -1 ? 0 : this.state.imageNode.attrs.y - this.state.imageNode.attrs.height)*(scal);
                // }else if(this.state.rotation === 270){
                //     this.state.miniCanvasYArr[item] = dropzoneY + (Math.sign(this.state.imageNode.attrs.y) === -1 ? 0 : this.state.imageNode.attrs.y - this.state.imageNode.attrs.width)*(scal);
                // }

            }else {
                if (!this.state.productVariationImage[item][this.state.selectedImage]){
                    return
                }
                this.state.miniCanvasWidth = width;
                this.state.miniCanvasHeight = height;
                this.state.miniCanvasX = (Math.sign(this.state.imageNode.attrs.x) === -1 ? 0 : this.state.imageNode.attrs.x) + this.state.productVariationImage[item][this.state.selectedImage].product_variation_image.dropzone_x;
                this.state.miniCanvasY = (Math.sign(this.state.imageNode.attrs.y) === -1 ? 0 : this.state.imageNode.attrs.y) + this.state.productVariationImage[item][this.state.selectedImage].product_variation_image.dropzone_y;
            }


            let newImage = new window.Image();
            newImage.src = testImageStage;
            this.state.newImage = newImage;

            this.forceUpdate();
            // return


            setTimeout(()=> {

                if(this.state.artworkImageId == undefined) {
                    this.props.enqueueSnackbar("You must upload artwork for selected product variation!", {variant: 'error'});
                    return;
                }

                this.forceUpdate()

                let wholeImage = this.state.wholeStage[index].node.toDataURL({
                    mimeType: "image/png",
                    quality: 1,
                    pixelRatio: 1
                });


                let fullSizeArt = this.fullSizeArt.toDataURL({
                    mimeType: "image/png",
                    quality: 1,
                    pixelRatio: 1
                })

                let fullSizeArtOnly = this.state.fullImageNode.toDataURL({
                    mimeType: "image/png",
                    quality: 1,
                    pixelRatio: 1
                })

                let formData = new FormData();

                formData.append('customer_artwork_image_id', this.state.artworkImageId);
                formData.append('customer_product_variation', wholeImage);
                formData.append('fullSizeArt', fullSizeArt);
                formData.append('fullSizeArtOnly', fullSizeArtOnly);
                formData.append('customer_artwork_image_edited', testImage);

                if (!this.state.productVariationImage[item][this.state.selectedImage]){
                    return
                }

                formData.append('product_variation_image_id', this.state.productVariationImage[item][this.state.selectedImage].id);
                formData.append('variation_id', this.state.customerProductVariation[item]);
                formData.append('positionX', this.state.positionX ? this.state.positionX : 0);
                formData.append('positionY', this.state.positionY ? this.state.positionY : 0);
                formData.append('rotation', this.state.rotation);
                // formData.append('zoom', this.state.zoom);
                formData.append('stageScale', this.state.stageScale);

                formData.append('cropHeight',  height * this.state.stageScale);
                formData.append('cropWidth',  width * this.state.stageScale);
                formData.append('cropX',  this.state.imageNode.attrs.cropX ? this.state.imageNode.attrs.cropX : 0);
                formData.append('cropY',  this.state.imageNode.attrs.cropY ? this.state.imageNode.attrs.cropY : 0);

                let orderItemId = this.props.history.location.state.orderItemId;
                formData.append('orderItemId',  orderItemId ? orderItemId : null);

                uploadMergedImages(formData).then(response => {
                    if (!response.ok){
                        return;
                    }

                    if (index + 1 === length){
                        this.state.canvasLoader = false;
                    }
                    this.updateData();


                })

            }, 100)
        })

    }

    rightAlign(){

        if (!this.state.file){
            return
        }
        this.state.editingStarted = true;
        let zoom = this.state.stage.attrs.scaleX ? this.state.stage.attrs.scaleX : 1;
        this.state.crop = false;

        if(this.state.rotation == 0){
            this.state.positionX = this.state.stage.attrs.width*(1/zoom) - this.state.imageNode.attrs.width;
        }else if(this.state.rotation == 90){
            this.state.positionX = this.state.stage.attrs.width*(1/zoom);
        }else if(this.state.rotation == 180){
            this.state.positionX = this.state.stage.attrs.width*(1/zoom) + 1;
        }else if(this.state.rotation == 270){
            this.state.positionX = this.state.stage.attrs.width*(1/zoom) - this.state.imageNode.attrs.width;
        }
    }

    bottomAlign(){
        if (!this.state.file){
            return
        }
        this.state.editingStarted = true;
        let zoom = this.state.stage.attrs.scaleX ? this.state.stage.attrs.scaleX : 1;
        this.state.crop = false;

        if(this.state.rotation == 0){
            this.state.positionY = this.state.stage.attrs.height*(1/zoom) - this.state.imageNode.attrs.height;
        }else if(this.state.rotation == 90){
            this.state.positionY = this.state.stage.attrs.height*(1/zoom) - this.state.imageNode.attrs.width;
        }else if(this.state.rotation == 180){
            this.state.positionY = this.state.stage.attrs.height*(1/zoom);
        }else if(this.state.rotation == 270){
            this.state.positionY = this.state.stage.attrs.height*(1/zoom);
        }
    }

    topAlign(){
        if (!this.state.file){
            return
        }
        this.state.crop = false;

        if(this.state.rotation == 0){
            this.state.positionY = 0;
        }else if(this.state.rotation == 90){
            this.state.positionY = 0;
        }else if(this.state.rotation == 180){
            this.state.positionY = this.state.imageNode.attrs.height;
        }else if(this.state.rotation == 270){
            this.state.positionY = this.state.imageNode.attrs.width;
        }
    }

    centerAlign(){
        let zoom = this.state.stage.attrs.scaleX ? this.state.stage.attrs.scaleX : 1;
        this.state.crop = false

        if(this.state.rotation == 0){
            this.state.positionX = (this.state.stage.attrs.width*(1/zoom) - this.state.imageNode.attrs.width)/2;
            this.state.positionY = (this.state.stage.attrs.height*(1/zoom) - this.state.imageNode.attrs.height)/2;
        }else if(this.state.rotation == 90){
            this.state.positionX = (this.state.stage.attrs.width*(1/zoom) - this.state.imageNode.attrs.height)/2 + this.state.imageNode.attrs.height;
            this.state.positionY = (this.state.stage.attrs.height*(1/zoom) - this.state.imageNode.attrs.width)/2;
        }else if(this.state.rotation == 180){
            this.state.positionX = (this.state.stage.attrs.width*(1/zoom) - this.state.imageNode.attrs.width)/2 + this.state.imageNode.attrs.width;
            this.state.positionY = (this.state.stage.attrs.height*(1/zoom) - this.state.imageNode.attrs.height)/2 + this.state.imageNode.attrs.height;
        }else if(this.state.rotation == 270){
            this.state.positionX = (this.state.stage.attrs.width*(1/zoom) - this.state.imageNode.attrs.height)/2;
            this.state.positionY = (this.state.stage.attrs.height*(1/zoom) - this.state.imageNode.attrs.width)/2 + this.state.imageNode.attrs.width;
        }

        this.forceUpdate()
    }

    verticalAlign(){
        if (!this.state.file){
            return
        }
        let zoom = this.state.stage.attrs.scaleX ? this.state.stage.attrs.scaleX : 1;
        this.state.crop = false;

        if(this.state.rotation == 0){
            this.state.positionX = (this.state.stage.attrs.width*(1/zoom)/2 - this.state.imageNode.attrs.width/2);
        }else if(this.state.rotation == 90){
            this.state.positionX = this.state.stage.attrs.width*(1/zoom)/2 + this.state.imageNode.attrs.height/2;
        }else if(this.state.rotation == 180){
            this.state.positionX = this.state.stage.attrs.width*(1/zoom)/2 + this.state.imageNode.attrs.width/2;
        }else if(this.state.rotation == 270){
            this.state.positionX = this.state.stage.attrs.width*(1/zoom)/2 - this.state.imageNode.attrs.height/2;
        }

        console.log(this.state.positionX)
    }

    horizontalAlign(){
        if (!this.state.file){
            return
        }
        let zoom = this.state.stage.attrs.scaleX ? this.state.stage.attrs.scaleX : 1;
        this.state.crop = false;

        if(this.state.rotation == 0){
            this.state.positionY = (this.state.stage.attrs.height*(1/zoom)/2 - this.state.imageNode.attrs.height/2);
            // this.state.positionX *= 1/zoom;
        }else if(this.state.rotation == 90){
            this.state.positionY = this.state.stage.attrs.height*(1/zoom)/2 - this.state.imageNode.attrs.width/2;
        }else if(this.state.rotation == 180){
            this.state.positionY = this.state.stage.attrs.height*(1/zoom)/2 + this.state.imageNode.attrs.height/2;
        }else if(this.state.rotation == 270){
            this.state.positionY = this.state.stage.attrs.height*(1/zoom)/2 + this.state.imageNode.attrs.width/2;
        }
    }

    crop(){

        if (!this.state.file){
            return
        }
        setTimeout(()=> {
            this.setState({
                ...this.state,
                crop: !this.state.crop,
                loaderActive : false
            })
        }, 100)
    }

    cropHelp(){
        if (!this.state.crop){
            setTimeout(()=> {
                this.setState({
                    ...this.state,
                    crop: !this.state.crop,
                    loaderActive : false
                }, ()=> {
                    this.saveCropHelp();
                })
            }, 100)
        }
    }

    saveCropHelp(){
        let cropW = this.state.rect.attrs.width * (this.state.rect.attrs.scaleX ? this.state.rect.attrs.scaleX : 1);
        let cropH = this.state.rect.attrs.height * (this.state.rect.attrs.scaleY ? this.state.rect.attrs.scaleY : 1);

        this.state.imageNode.crop({
            x: this.state.rect.attrs.x - this.state.imageNode.attrs.x + (this.state.imageNode.attrs.cropX ? this.state.imageNode.attrs.cropX : 0),
            y: this.state.rect.attrs.y - this.state.imageNode.attrs.y + (this.state.imageNode.attrs.cropY ? this.state.imageNode.attrs.cropY : 0),
            width: cropW,
            height: cropH
        });

        this.state.imageNode.attrs.height = cropH;
        this.state.imageNode.attrs.width = cropW;

        this.state.positionX = this.state.rect.attrs.x;
        this.state.positionY = this.state.rect.attrs.y;
        this.state.crop = false
        this.state.loaderActive = false;

        this.forceUpdate();
        this.saveImage();
    }

    changeImage(index, id){
        if (this.state.customerProductVariationImage[id].length < index ){
            this.props.enqueueSnackbar(`You haven't edited the previous print`, {variant: 'error'});
            return
        }
        // return
        let variation;

        this.state.variations.items.forEach(items => {
            items.forEach(item => {
                if (!this.state.data.selectedVariations[0]){
                    if (item.id !== id){
                        return
                    }
                }else {
                    if (item.id !== this.state.data.selectedVariations[0]){
                        return
                    }
                }

                variation = item;
            })
        })

        this.state.loaderActive = true;

        this.updateData();

        let newCanvasImage = new window.Image();
        newCanvasImage.src = this.state.productVariationImage[id][index].product_variation_image.base64;
        if (this.state.customerProductVariationImage[id][index] && this.state.customerProductVariationImage[id][index].product_variation_image.id === variation.images[index].product_variation_image.id){
            this.state.file = this.state.customerProductVariationImage[id][index].edited_artwork_image ? this.state.customerProductVariationImage[id][index].edited_artwork_image : this.state.customerProductVariationImage[id][index].customer_artwork_image.base64;
        }else {
            this.state.file = undefined;
        }

        this.state.originalImage = undefined;
        this.fullClone = undefined;
        this.state.rotation = 0;
        this.state.zoom = 0;
        this.state.imageNode.attrs.cropWidth = 0;
        this.state.imageNode.attrs.cropHeight = 0;
        this.state.imageNode.attrs.cropX = 0;
        this.state.imageNode.attrs.cropY = 0;

        if (this.state.fullImageNode){
            this.state.fullImageNode.attrs.cropWidth = 0;
            this.state.fullImageNode.attrs.cropHeight = 0;
            this.state.fullImageNode.attrs.cropX = 0;
            this.state.fullImageNode.attrs.cropY = 0;

            this.newPic = true;
            // this.state.fullImageNode.attrs.width = 0;
            // this.state.fullImageNode.attrs.height = 0;
        }

        let that = this;
        newCanvasImage.onload = function () {

            if (that.canvasBG === undefined) {
                that.canvasBG = [];
                that.newHeight = [];
            }
            let oldWidth;
            let oldHeight;

            oldWidth = newCanvasImage.width;
            oldHeight = newCanvasImage.height;
            that.canvasBG[id] = newCanvasImage;

            //
            let aspectRatio = oldWidth / oldHeight;
            let newHeight = 340 / aspectRatio;
            that.newHeight[id] = newHeight;
        }

        setTimeout(()=> {
            this.setState({
                ...this.state,
                selectedImage : index,
                // file: this.state.customerProductVariationImage[this.state.selectedProductVariationId][index] ? (this.state.customerProductVariationImage[this.state.selectedProductVariationId][index].edited_artwork_image ? this.state.customerProductVariationImage[this.state.selectedProductVariationId][index].edited_artwork_image : (this.state.customerProductVariationImage[this.state.selectedProductVariationId][index].customer_artwork_image ? this.state.customerProductVariationImage[this.state.selectedProductVariationId][index].customer_artwork_image.base64 : null )) : null
            }, () => {
                if (!this.state.customerProductVariationImage[this.state.selectedProductVariationId][this.state.selectedImage]){
                    return
                }
                let im = new window.Image();

                im.onload = function(){
                    that.state.originalImage = that.state.customerProductVariationImage[id][that.state.selectedImage].set_full_size_art_only ? that.state.customerProductVariationImage[id][that.state.selectedImage].set_full_size_art_only : '';

                    if(that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 0 || that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 270){
                        that.state.positionX = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x;
                    }else if(that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 90){
                        that.state.positionX = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x - im.width;
                    }else if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 180){
                        that.state.positionX = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x - im.width;
                    }

                    if(that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 0 || that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 90){
                        console.log('y hi', that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y* that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale)
                        that.state.positionY = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y;
                    }else if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 180){
                        that.state.positionY = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y - im.height;
                    }else if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 270){
                        that.state.positionY = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y - im.height;
                    }

                    // that.state.rotation = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation;
                    that.state.stageScale = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale;
                    // that.state.imageNode.attrs.height = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].crop_height;
                    // that.state.imageNode.attrs.width = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].crop_width;
                    that.state.file = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].edited_artwork_image ? that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].edited_artwork_image : that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].customer_artwork_image.base64;
                    that.state.loaderActive = false;

                    that.forceUpdate();
                }

                // im.src = that.state.file;

                setTimeout(()=> {

                    im.src = that.state.file;
                }, 1000)


                if(that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 0 || that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 270){
                    that.state.positionX = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x* that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale;
                }else if(that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 90){

                    console.log('x hi', that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x* that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale - im.width)
                    console.log('imw', im.width)
                    that.state.positionX = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x* that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale - im.width;
                }else if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 180){
                    that.state.positionX = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_x* that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale - im.width;
                }

                if(that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 0 || that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 90){
                    console.log('y hi', that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y* that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale)
                    that.state.positionY = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y* that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale;
                }else if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 180){
                    that.state.positionY = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y* that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale - im.height;
                }else if (that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation === 270){
                    that.state.positionY = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].position_y * that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale - im.height;
                }

                // that.state.rotation = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].rotation;
                that.state.stageScale = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].stage_scale;
                // that.state.imageNode.attrs.height = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].crop_height;
                // that.state.imageNode.attrs.width = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].crop_width;
                that.state.file = that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].edited_artwork_image ? that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].edited_artwork_image : that.state.customerProductVariationImage[that.state.data.selectedVariations[0]][that.state.selectedImage].customer_artwork_image.base64;
            });
            this.forceUpdate();
        }, 100);

        setTimeout(()=> {
            this.setState({
                ...this.state,
                loaderActive : false
            })
        }, 600)

    }

    renderImageNames(){
        let variation;

        this.state.variations.items.forEach(items => {
            items.forEach(item => {
                if (item.id !== this.state.data.selectedVariations[0]){
                    return
                }

                variation = item;
            })
        })

        let render = [];

        if (!variation){
            return render
        }

        variation.images.forEach((image, index) => {
            render.push(
                <span key={image.id} className={this.state.selectedImage === index ? 'active' : ''} onClick={()=> this.changeImage(index, variation.id)}
                >{image.product_variation_image.public_name}</span>
            )
        })

        return render;
    }

    getArtwork(){
        getCustomersArtwork({page : this.state.imagesPage}).then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                ...this.state,
                artworkImages : response.data.result,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                page : response.data.page
            });
        })
    }

    toggleArtwork(){
        getCustomersArtwork({page : this.state.imagesPage}).then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                ...this.state,
                myArtworkPopup : !this.state.myArtworkPopup,
                artworkImages : response.data.result,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                page : response.data.page
            });
        })
    }

    imageToDataUri(img, width, height) {

        // create an off-screen canvas
        var canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d');

        // set its dimension to target size
        canvas.width = width;
        canvas.height = height;
        // draw source image into the off-screen canvas:
        ctx.drawImage(img, 0, 0, width, height);

        // encode image to data-uri with base64 version of compressed image
        return canvas.toDataURL();
    }

    selectImageArtwork(imageSrc){
        let image = new window.Image();
        image.src = imageSrc.base64;

        // let originalImage = imageSrc.base64;
        let resizedImage;

        let newWidth;
        let newHeight;

        if (this.state.stage.attrs.width < image.width || this.state.stage.attrs.height < image.height){
            let oldWidth = image.width;
            let oldHeight = image.height;
            let aspectRatio = oldWidth/oldHeight;
            newWidth = this.state.stage.attrs.width;
            newHeight = newWidth/aspectRatio;
            this.fitted = true;
            resizedImage = this.imageToDataUri(image, newWidth, newHeight)
        }else {
            resizedImage = imageSrc.base64;
            newWidth = image.width;
            newHeight = image.height;
        }

        this.state.originalImage = undefined;
        this.fullClone = undefined;
        this.state.rotation = 0;
        this.state.zoom = 0;
        this.state.imageNode.attrs.width = newWidth;
        this.state.imageNode.attrs.height = newHeight;
        this.state.imageNode.attrs.cropWidth = 0;
        this.state.imageNode.attrs.cropHeight = 0;
        this.state.imageNode.attrs.cropX = 0;
        this.state.imageNode.attrs.cropY = 0;

        if (this.state.fullImageNode){
            this.state.fullImageNode.attrs.cropWidth = 0;
            this.state.fullImageNode.attrs.cropHeight = 0;
            this.state.fullImageNode.attrs.cropX = 0;
            this.state.fullImageNode.attrs.cropY = 0;

            this.newPic = true;
            // this.state.fullImageNode.attrs.width = 0;
            // this.state.fullImageNode.attrs.height = 0;
        }


        setTimeout(()=> {
            this.state.positionX = 0;
            this.state.positionY = 0;

            this.setState({
                imgWidth : newWidth,
                imgHeight : newHeight,
                ...this.state,
                myArtworkPopup : !this.state.myArtworkPopup,
                file: resizedImage,
                artworkImageId : imageSrc.id,
                originalImage : imageSrc.base64,
                loaderActive : true
            },()=> {
                setTimeout(()=> {
                    this.state.loaderActive = false
                    this.forceUpdate()
                }, 200)
            });
        }, 200)
    }

    removeArtwork(e, id){
        e.stopPropagation();

        deleteCustomerArtworkImage(id).then(response => {
            if (!response.ok){
                return
            }

            setTimeout(()=> {
                this.getArtwork();
                this.props.enqueueSnackbar(`Successfully deleted artwork image`, {variant: 'success'});
            }, 800)
        })
    }

    renderArtworkImages(){
        let render = [];

        this.state.artworkImages.forEach((image, index) => {
            render.push(
                <div className={'image'} key={index} onClick={() => this.selectImageArtwork(image)}>
                    <img src={image.base64}></img>
                    <img className="close" src="/images/x.png" onClick={(e) => this.removeArtwork(e, image.id)}></img>
                </div>
            )

        })

        return render;
    }

    uploadArtworkPicture(event){

        if (!event.target.files[0]){
            return
        }

        let file = event.target.files[0];


        getBase64(file).then(image => {

            let formData = new FormData();

            formData.append('image', file);
            formData.append('base64', image);

            uploadArtworkImage(formData).then(response => {
                if (!response.ok){
                    return
                }

                this.setState({
                    ...this.state,
                    file: image,
                    artworkImageId : response.data.id
                }, () => {
                    setTimeout(()=> {
                        getCustomersArtwork({page : this.state.imagesPage}).then(response => {
                            if (!response.ok){
                                return
                            }

                            this.setState({
                                ...this.state,
                                artworkImages : response.data.result,
                                totalPages : Math.ceil(response.data.total / response.data.perPage),
                                page : response.data.page
                            });
                        })
                    }, 700)
                });
            })
        });
    }

    openUploadArtwork(){
        document.getElementById("artwork-file").click();
    }

    renderWholeStageCanvas(){
        let render = [];

        this.state.data.selectedVariations.forEach((item, index) => {
            render.push(
                <Stage key={index}
                       width={340}
                       height={this.newHeight ? this.newHeight[item] : 0}
                       ref={ node => {
                           if (this.state.wholeStage === undefined || index === 0){
                               this.state.wholeStage = [];
                           }
                           let has = false;

                           this.state.wholeStage.forEach(stage => {
                               if (!stage.node || !node){
                                   return
                               }

                               if (stage.node._id === node._id){
                                   has = true;
                               }
                           })

                           if (!has && node !== null){
                               this.state.wholeStage.push({node : node, variation : item});
                           }

                       }}
                >
                    <Layer>
                        <Image
                            image={this.canvasBG ? this.canvasBG[item] : ''}
                            width={340}
                            height={this.newHeight ? this.newHeight[item] : 0}
                            x={0}
                            y={0}
                            rotation={0}
                            ref={node => {
                                this.state.canvasBackgroundNode = node;
                            }}
                            name={"image"}
                        ></Image>
                        <Image
                            image={this.state.newImage}
                            // width={this.state.miniCanvasWidth}
                            // height={this.state.miniCanvasHeight}
                            x={this.state.miniCanvasXArr[item]}
                            y={this.state.miniCanvasYArr[item]}
                            rotation={0}
                            ref={node => {
                                this.state.canvasArtworkNode = node;
                            }}
                            name={"image"}
                        ></Image>
                    </Layer>
                </Stage>
            )
        })

        return render;
    }

    saveCrop(){
        this.forceUpdate()
        let cropW = this.state.rect.attrs.width * (this.state.rect.attrs.scaleX ? this.state.rect.attrs.scaleX : 1);
        let cropH = this.state.rect.attrs.height * (this.state.rect.attrs.scaleY ? this.state.rect.attrs.scaleY : 1);

        console.log('---------------')
        console.log('crw', cropW)
        console.log('crh', cropH)
        // return

        this.state.originalX = this.state.imageNode.attrs.x;
        this.state.originalY = this.state.imageNode.attrs.y;
        this.state.originalWidth = this.state.imageNode.attrs.width;
        this.state.originalHeight = this.state.imageNode.attrs.height;
        this.state.originalCropW = (!this.xIsY ? (this.state.imageNode.attrs.cropX ? this.state.imageNode.attrs.cropX : 0) : (this.state.imageNode.attrs.cropY ? this.state.imageNode.attrs.cropY : 0));
        this.state.originalCropH = (!this.xIsY ? (this.state.imageNode.attrs.cropY ? this.state.imageNode.attrs.cropY : 0) : (this.state.imageNode.attrs.cropX ? this.state.imageNode.attrs.cropX : 0));

        if (this.state.rotation === 0){
            this.state.imageNode.crop({
                x: this.state.rect.attrs.x - this.state.imageNode.attrs.x + (!this.xIsY ? (this.state.imageNode.attrs.cropX ? this.state.imageNode.attrs.cropX : 0) : (this.state.imageNode.attrs.cropY ? this.state.imageNode.attrs.cropY : 0)),
                y: this.state.rect.attrs.y - this.state.imageNode.attrs.y + (!this.xIsY ? (this.state.imageNode.attrs.cropY ? this.state.imageNode.attrs.cropY : 0) : (this.state.imageNode.attrs.cropX ? this.state.imageNode.attrs.cropX : 0)),
                width: cropW,
                height: cropH
            });
        }else if(this.state.rotation === 90){
            this.state.imageNode.crop({
                y: Math.abs(this.state.rect.attrs.x - this.state.imageNode.attrs.x + (!this.xIsY ? (this.state.imageNode.attrs.cropX ? this.state.imageNode.attrs.cropX : 0) : (this.state.imageNode.attrs.cropY ? this.state.imageNode.attrs.cropY : 0))),
                x: Math.abs(this.state.rect.attrs.y - this.state.imageNode.attrs.y + (!this.xIsY ? (this.state.imageNode.attrs.cropY ? this.state.imageNode.attrs.cropY : 0) : (this.state.imageNode.attrs.cropX ? this.state.imageNode.attrs.cropX : 0))),
                width: cropW,
                height: cropH
            });
        }else if (this.state.rotation === 180){
            this.state.imageNode.crop({
                x: Math.abs(this.state.imageNode.attrs.x - this.state.rect.attrs.x + (!this.xIsY ? (this.state.imageNode.attrs.cropX ? this.state.imageNode.attrs.cropX : 0) : (this.state.imageNode.attrs.cropY ? this.state.imageNode.attrs.cropY : 0))),
                y: Math.abs(this.state.imageNode.attrs.y - this.state.rect.attrs.y + (!this.xIsY ? (this.state.imageNode.attrs.cropY ? this.state.imageNode.attrs.cropY : 0): (this.state.imageNode.attrs.cropX ? this.state.imageNode.attrs.cropX : 0))),
                width: cropW,
                height: cropH
            });
        }else if (this.state.rotation === 270){
            this.state.imageNode.crop({
                y: Math.abs(this.state.imageNode.attrs.x - this.state.rect.attrs.x + (!this.xIsY ? (this.state.imageNode.attrs.cropX ? this.state.imageNode.attrs.cropX : 0) : (this.state.imageNode.attrs.cropY ? this.state.imageNode.attrs.cropY : 0))),
                x: Math.abs(this.state.imageNode.attrs.y - this.state.rect.attrs.y + (!this.xIsY ? (this.state.imageNode.attrs.cropY ? this.state.imageNode.attrs.cropY : 0): (this.state.imageNode.attrs.cropX ? this.state.imageNode.attrs.cropX : 0))),
                width: cropW,
                height: cropH
            });
        }

        this.state.imageNode.attrs.height = cropH;
        this.state.imageNode.attrs.width = cropW;

        this.centerAlign();
    }

    saveCropRealSize(){
        this.forceUpdate()

        let scaleW = this.w/this.state.originalWidth
        let scaleH = this.h/this.state.originalHeight


        let cropW = this.state.rect.attrs.width * (this.state.rect.attrs.scaleX ? this.state.rect.attrs.scaleX : 1)*scaleW;
        let cropH = this.state.rect.attrs.height * (this.state.rect.attrs.scaleY ? this.state.rect.attrs.scaleY : 1)*scaleH;
        let x
        let y

        if (this.state.rotation === 0){
            x = ((this.state.rect.attrs.x*scaleW - this.state.originalX*scaleW) + this.state.originalCropW*scaleW);
            y = ((this.state.rect.attrs.y*scaleH - this.state.originalY*scaleH) + this.state.originalCropH*scaleH);
        }else if (this.state.rotation === 90){
            y = Math.abs(((this.state.rect.attrs.x*scaleW - this.state.originalX*scaleW) + this.state.originalCropW*scaleW));
            x = Math.abs(((this.state.rect.attrs.y*scaleH - this.state.originalY*scaleH) + this.state.originalCropH*scaleH));
        }else if (this.state.rotation === 180){
            x = Math.abs(((this.state.originalX*scaleW - this.state.rect.attrs.x*scaleW) + this.state.originalCropW*scaleW));
            y = Math.abs(((this.state.originalY*scaleH - this.state.rect.attrs.y*scaleH) + this.state.originalCropH*scaleH));
        }else if (this.state.rotation === 270){
            y = Math.abs(((this.state.originalX*scaleW - this.state.rect.attrs.x*scaleW) + this.state.originalCropW*scaleW));
            x = Math.abs(((this.state.originalY*scaleH - this.state.rect.attrs.y*scaleH) + this.state.originalCropH*scaleH));
        }

        this.state.fullImageNode.crop({
            x: x,
            y: y,
            width: cropW,
            height: cropH
        });

        this.state.fullImageNode.attrs.height = cropH;
        this.state.fullImageNode.attrs.width = cropW;

        this.state.crop = false;

        // this.downloadURI(this.state.fullImageNode.toDataURL({
        //     mimeType: "image/png",
        //     quality: 0,
        //     pixelRatio: 1,
        //     // x : x * this.state.stageScale,
        //     // y : y * this.state.stageScale,
        //     // width : width * this.state.stageScale,
        //     // height : height * this.state.stageScale
        // }));
        // return
    }

    calculateFullSizeArtwork(){
        let render = [];

        if (!this.state.originalImage){
            return
        }

        let variation;

        this.state.variations.items.forEach(items => {
            items.forEach(item => {
                if (item.id !== this.state.data.selectedVariations[0]){
                    return
                }

                variation = item;
            })
        })

        this.variation = variation;
        let images = [];

        if (!variation){
            return images;
        }

        if (this.changedVar){
            this.state.selectedImage = 0;
        }
        let image = variation.images[this.state.selectedImage ? this.state.selectedImage : 0];

        let img = new window.Image();

        if (this.state.originalImage){
            img.src = this.state.originalImage;
        }else {
            // this.forceUpdate();
        }

        if (!this.state.stage || !this.state.imageNode){
            return
        }

        let resizedImg;

        let toRenderImg = new window.Image();

        let that = this;

        toRenderImg.onload = function(){

            console.log('width', toRenderImg.width)
            console.log('height', toRenderImg.height)
            if (!that.state.imageNode){
                return
            }
            that.fullClone = [ <Stage key={-1}
                                      width={image.product_variation_image.real_dropzone_width}
                                      height={image.product_variation_image.real_dropzone_height}
                                      ref={node => {
                                          that.fullSizeArt = node;
                                      }}
            >
                <Layer>
                    <Image
                        image={toRenderImg}
                        // width={appliedW}
                        // height={appliedH}
                        x={that.state.positionX*toRenderImg.width/that.state.imageNode.attrs.width}
                        y={that.state.positionY*toRenderImg.width/that.state.imageNode.attrs.width}
                        rotation={that.state.rotation}
                        ref={node => {
                            that.state.fullImageNode = node;
                        }}
                        name={"image"}
                    ></Image>
                </Layer>
            </Stage>]

            // that.forceUpdate();

        }
        setTimeout(()=> {

            let appliedW;
            let appliedH;
            // console.log('height', this.state.fullImageNode.attrs.height)
            // console.log('width', this.state.fullImageNode.attrs.width)
            if (this.state.fullImageNode && this.state.fullImageNode.attrs.height && this.state.fullImageNode.attrs.width){
                toRenderImg.width = this.state.fullImageNode.attrs.width;
                toRenderImg.height = this.state.fullImageNode.attrs.height;
                appliedW = this.state.fullImageNode.attrs.width;
                appliedH = this.state.fullImageNode.attrs.height;

                if (this.resizedSrc){
                    toRenderImg.src = this.resizedSrc;
                }else {
                    toRenderImg.src = img.src;
                }


            }else {
                if (!this.state.stage || !this.state.imageNode){
                    return
                }

                let ratioW = this.state.stage.attrs.width/this.state.imageNode.attrs.width;
                let ratioH = this.state.stage.attrs.height/this.state.imageNode.attrs.height;

                if (this.state.fullImageNode){
                    this.state.fullImageNode.attrs.width = appliedW;
                    this.state.fullImageNode.attrs.height = appliedH;
                }

                appliedW = image.product_variation_image.real_dropzone_width/ratioW
                appliedH = image.product_variation_image.real_dropzone_height/ratioH
                resizedImg = this.imageToDataUri(img, appliedW, appliedH)

                toRenderImg.src = resizedImg;
                this.resizedSrc = resizedImg;
            }
            // if (this.state.fullImageNode && this.state.fullImageNode.attrs.height && this.state.fullImageNode.attrs.width){
            //     toRenderImg.width = this.state.fullImageNode.attrs.width;
            //     toRenderImg.height = this.state.fullImageNode.attrs.height;
            //     appliedW = this.state.fullImageNode.attrs.width;
            //     appliedH = this.state.fullImageNode.attrs.height;
            //
            //     if (this.resizedSrc){
            //         toRenderImg.src = this.resizedSrc;
            //     }else {
            //         toRenderImg.src = img.src;
            //     }
            //
            //     console.log('a')
            //
            // }else {
            //     let orW = this.state.stage.attrs.width/this.state.imageNode.attrs.width;
            //     let orH = this.state.stage.attrs.height/this.state.imageNode.attrs.height;
            //
            //     console.log(img.width)
            //     if (img.width > image.product_variation_image.real_dropzone_width && img.height > image.product_variation_image.real_dropzone_height){
            //         console.log('b')
            //         resizedImg = this.imageToDataUri(img, image.product_variation_image.real_dropzone_width*1/orW, image.product_variation_image.real_dropzone_height*1/orH)
            //     }else {            console.log('c')
            //
            //         resizedImg = img.src;
            //     }
            //     console.log('f')
            //
            //     toRenderImg.src = resizedImg;
            //     this.resizedSrc = resizedImg;
            //     appliedW = image.product_variation_image.real_dropzone_width*1/orW;
            //     appliedH = image.product_variation_image.real_dropzone_height*1/orH;
            // }
            //

            this.w = appliedW;
            this.h = appliedH;
        }, 1000)

    }

    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return ;
        }

        if(this.state.imagesPage > 5){
            start = this.state.imagesPage - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.imagesPage + 4) < this.state.totalPages)){
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.imagesPage === i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    onPageClick(i){
        this.state.imagesPage = i;
        this.getArtwork();
    }

    checkIfAllHaveDesign(){
        let canPass = true;

        this.state.variations.items.forEach(items => {
            items.forEach(variation => {
                variation.images.forEach((image, index) => {
                    if (!(this.state.customerProductVariationImage[variation.id] && this.state.customerProductVariationImage[variation.id][index] && this.state.customerProductVariationImage[variation.id][index].merged_image)){
                        canPass = false;
                    }
                })
            })
        })

        return canPass;
    }

    downloadURI(uri, name) {
        var link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        if (!this.props.location.state){
            return '';
        }
        return (
            <div className={'product-variation-selection container'} onClick={this.closeWindows}>
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                {
                    this.state.canvasLoader &&
                    <div className={'load-helper'}>
                        <div className={'bg'}/>
                        <img src={'/images/loadersp.gif'} />
                    </div>
                }
                <div className={'hidden-canvas'}>
                    {this.renderWholeStageCanvas()}
                    {this.calculateFullSizeArtwork()}
                    {this.fullClone}
                </div>
                {
                    this.state.myArtworkPopup &&
                    <div className="my-artwork">
                        <div className="my-artwork-overlay" onClick={ () => this.toggleArtwork()}/>
                        <div className="my-artwork-content">
                            <div className="header">
                                My Artwork
                                <input style={{display: "none"}} id="artwork-file" type={'file'} name={'artwork'} className="upload" onChange={(e) => this.uploadArtworkPicture(e)}/>
                                <a onClick={() => this.openUploadArtwork()} className={"upload"}>Upload new artwork</a>
                            </div>
                            <div className="artwork-images">
                                {this.renderArtworkImages()}
                            </div>
                            <div className={'pagination'}>
                                {this.renderPagination()}
                            </div>
                        </div>
                    </div>
                }
                <div className={'left'}>
                    <div className={'top'}>
                        <div className={'image-names'}>
                            {this.renderImageNames()}
                        </div>
                    </div>
                    <div className={'bottom'}>
                        <div className={'helper'}>
                            <Button variant="contained" color="primary" onClick={()=> this.toggleArtwork()} className={'upload-button'} style={{pointerEvents : this.state.data.selectedVariations.length === 0 ? 'none' : ''}}>Upload artwork</Button>
                            <div className={'images'}>
                                {
                                    <img className={this.state.loaderActive ? 'loader active' : 'loader'} src={'/images/Preloader.gif'} />
                                }
                                {
                                    this.renderImage()
                                        ?
                                        <div className={'image-container'}>
                                            {this.renderImage()}
                                        </div>
                                        :
                                        <img />
                                }
                            </div>
                            <div className={'controls'}>
                                <div className={'top-control'}>
                                    <div className={'left'}>
                                        <Tooltip title="Crop" onClick={() => this.crop()}>
                                            <img src={'/images/crop.png'} />
                                        </Tooltip>
                                        <Tooltip title="Rotate" onClick={() => this.rotate()}>
                                            <img src={'/images/rotate.png'} />
                                        </Tooltip>
                                    </div>
                                    <div className={'right'}>
                                        <Tooltip title="Horizontal alignment">
                                            <img src={'/images/horizontal.png'} onClick={()=> this.horizontalAlign()} />
                                        </Tooltip>
                                        <Tooltip title="Vertical alignment">
                                            <img src={'/images/vertical.png'} onClick={()=> this.verticalAlign()} />
                                        </Tooltip>
                                        <Tooltip title="Top alignment">
                                            <img src={'/images/top-align.png'} onClick={()=> this.topAlign()} />
                                        </Tooltip>
                                        <Tooltip title="Bottom alignment" onClick={() => this.bottomAlign()}>
                                            <img src={'/images/bottom-align.png'} />
                                        </Tooltip>
                                        <Tooltip title="Right alignment">
                                            <img src={'/images/right-align.png'} onClick={() => this.rightAlign()} />
                                        </Tooltip>
                                        <Tooltip title="Left alignment">
                                            <img src={'/images/left-align.png'} onClick={() => this.leftAlign()} />
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="slidecontainer">
                                    <span>Zoom:</span>
                                    <input onChange={this.handleWheel} name={'zoom'} className="slider" type="range" min="-100" max="100" value={this.state.zoom} />
                                </div>
                                {
                                    this.state.crop &&
                                    <Button onClick={() => {
                                        this.cropped = true;
                                        this.saveCrop();
                                        this.saveCropRealSize();
                                    }} style={{ marginTop: "30px"}} variant="contained" >Save crop</Button>
                                }
                                {
                                    this.state.file &&
                                    <Button onClick={() => this.saveImage()} style={{ marginTop: "30px"}} variant="contained" >Save Image</Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'right'}>
                    <div className={'top'}>
                        <span className={'title'}>Variants</span>
                    </div>
                    <div className={'bottom'}>
                        <div className={'filter'}>
                            {this.renderFilter()}
                        </div>
                        <div className={'variations'}>
                            {this.renderDimensions()}
                        </div>
                        <div className={'buttons'}>
                            <button className={'prev'} onClick={()=> {
                                if (this.state.variations.items[0][0]){
                                    this.props.history.push('/product-selection/' + this.state.variations.items[0][0].product.category.id);
                                }
                            }}>Previous Step</button>
                            <button className={'next'} color="primary" onClick={()=> {
                                if (!this.checkIfAllHaveDesign()){
                                    this.props.enqueueSnackbar("Some of your variations don't have a design added", {variant: 'info', autoHideDuration: 6000});
                                    return ;
                                }

                                if(!this.props.history.location.state.orderItemId){
                                    this.props.history.push('/template-edit',
                                        {
                                            productVariations : this.state.variations.items,
                                            customerProductVariationImage : this.state.customerProductVariationImage,
                                            template : this.state.customerTemplateId,
                                            importProduct : this.props.location.state ? this.props.location.state.importProduct : undefined
                                        });
                                }else{
                                    this.props.history.push('/order-details/' + this.props.history.location.state.orderId);
                                }

                            }}>
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(withRouter(OrderImageEdit));
