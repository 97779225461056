import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { getUserFromLocalStorage } from '../../../../../base/OAuth';
import { changeOrderStatus } from '../../../../../services/OrderService';
import SubmitButton from '../../../../components/Buttons/Submit';
import StatusDropdown from '../../../../components/Dropdown/StatusDropdown';
import TextInput from '../../../../components/Form/TextInput';
import useSnackbar from '../../../../hooks/useSnackbar';
import { getAvailableStatuses, getIsStatusDisabled } from '../../../../helpers/form';

import './style.scss';
import OrderEnum from 'src/constants/OrderEnum';

const OrderDetailsStatus = ({ order, onUpdate }) => {
  const { id, status } = order;
  const user = getUserFromLocalStorage();
  const isAdmin = user?.roles?.includes('SUPER_ADMIN');

  const { openSnackBar } = useSnackbar();

  const saveStatus = async ({ status, comment }) => {
    try {
      await changeOrderStatus({
        orderId: id,
        status,
        comment
      });
      const newStatus = Number.parseInt(status);
      onUpdate({ ...order, status: newStatus, items: order.items.map(x => ({ ...x, status: newStatus })) });
      openSnackBar('Successfully changed status!');
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to change order item status!', false);
    }
  }
  return (
     <FinalForm onSubmit={saveStatus} initialValues={{ status, comment: '' }}
            render={({ handleSubmit, valid, submitting }) => (
              <form className='order-details-status' onSubmit={e => {
                handleSubmit(e);
              }}>
                  <div className='title'>
                    Change Order Status
                  </div>
                  <div className='status-input'>
                    <div className='label'>
                      Update Status
                    </div>
                    <StatusDropdown
                      name='status'
                      options={getAvailableStatuses(status, isAdmin)}
                      disabled={getIsStatusDisabled(status, isAdmin)}
                    />
                  </div>
                  {!isAdmin && status === OrderEnum.NeedsCustomization && (
                    <div className='status-note'>
                      Once item status is in Cancelled/ ReadyForPrint only Admin can change it. Please contact Support if you need to make a change.
                    </div>
                  )}
                  <div className='status-input' style={{ alignItems: 'flex-start' }}>
                    <div className='label'>
                      Comments
                    </div>
                    <TextInput name='comment' placeholder='Add Comment...' type='textarea' containerClassName='container-textarea' className='textarea'/>
                  </div>
                  <SubmitButton type='submit' text='Change Status' width={220} className='change-status-button' disabled={!valid || submitting} loading={submitting}/>
              </form>
            )}/>
  )
};

export default OrderDetailsStatus;