import React from 'react';
import { number, string } from 'prop-types';

const IconCircleExclamation = ({ width, height, color }) => (
    <svg width={width} height={height} viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45 56.287V56.25" stroke={color} strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="45" cy="45" r="27.1875" stroke={color} strokeWidth="6"/>
      <path d="M45 33.75V46.875" stroke={color} strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

IconCircleExclamation.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconCircleExclamation;