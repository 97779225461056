import classNames from 'classnames';
import React from 'react';

import './style.scss';

const ToggleSwitch = ({ label, checked, onChange, className, disabled = false, withGradient = false }) => {
  return (
    <div className={classNames('toggle-container', className)}>
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox" 
               name={label} id={label} onChange={onChange} checked={checked} disabled={disabled}/>
        <label className={classNames("label", { "label-disabled": disabled })} htmlFor={label}>
          <span className={classNames("inner", { "inner-disabled": disabled, 'inner-gradient': withGradient })} />
          <span className="switch" />
        </label>
      </div>
      <div className='label'>
        {label}
      </div>
    </div>
  )
}

export default ToggleSwitch;