import React from 'react';
import { changeUserPassword } from 'src/services/UserService';
import Form from 'src/redesign/components/Form/Form';
import TextInput from 'src/redesign/components/Form/TextInput';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import * as Validators from 'src/redesign/util/validators';

const FormSavePassword = ({ }) => {
  const { openSnackBar } = useSnackbar();

  const savePassword = async ({ newPassword, oldPassword }, form) => {
    try {
      await changeUserPassword({ newPassword, oldPassword });
      // reset form
      form.reset();
      form.resetFieldState('oldPassword');
      form.resetFieldState('newPassword');
      form.resetFieldState('confirmPassword');

      openSnackBar('Successfully changed password!');
    } catch (error) {
      openSnackBar('Changing password failed!', false);
    }
  }

  return (
    <Form initialValues={{}} title="Change password" onSubmit={savePassword} submitButtonText="Save Changes" validate={({ newPassword, confirmPassword }) => {
      const errors = {};
      
      if(newPassword && confirmPassword && newPassword !== confirmPassword)
        errors.confirmPassword = 'Passwords must match.';

      return errors;
    }}>
      <TextInput label="Current password" name="oldPassword" placeholder="Current password" type="password" validate={Validators.required('Current password is required.')}/>
      <TextInput label="New password" name="newPassword" placeholder="New password" type="password" validate={Validators.required('New password is required.')}/>
      <TextInput label="Confirm password" name="confirmPassword" placeholder="confirmPassword" type="password" validate={Validators.required('Confirm password is required.')}/>
    </Form>
  )
}

export default FormSavePassword;