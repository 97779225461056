import React from 'react';
import { v4 as uuid } from 'uuid';

import IconQuote from 'src/redesign/components/Icon/Quote';
import IconStar from 'src/redesign/components/Icon/Star';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const Testemonial = ({ image, title, subtitle, onReadStoryClick }) => (
  <div className='testemonial-main-container'>
    <div className='testemonial-logo-container'>
      <div className='testemonial-logo-quote'>
        <IconQuote width={82} height={96} color={colors.secondary} />
      </div>
      <div className='testemonial-logo-image-container'>
        <Image alt={getImageAlt()} src={image} />
      </div>
    </div>

    <div className='testemonial-text-container'>
      <div className='testemonial-text-starts-container'>
        {Array.from({ length: 5 }).map((_) => (
          <IconStar key={uuid()} width={28} height={28} color={colors.yellowLight} />
        ))}
      </div>
      <h3 className='testemonial-text-title'>
        {title}
      </h3>
      <div className='testemonial-text-subtitle'>
        {subtitle}
      </div>
      <SecondaryButton
        className='testemonial-text-read-story-button'
        text='Read their story'
        width={168}
        onClick={onReadStoryClick}
      />
    </div>
  </div>
);

export default Testemonial;