import React from 'react';

import './style.scss';

const EditorSlider = ({ zoom = 0, onChange }) => {
  const calculatedZoom = (typeof zoom === 'string' 
        ? Number.parseInt(zoom) 
        : typeof zoom === 'number' 
            ? zoom 
            : 0)
         + 100;

  return <div className='editor-slider'>
    <div className='zoom-container'>
      <div className='zoom'>
        Zoom 
      </div>
      <div className='zoom-label'>
        {calculatedZoom}%
      </div>
    </div>
    <input onChange={onChange} name='zoom' className='slider'
      type="range" min="-100" max="100" value={zoom} />
  </div>
}

export default EditorSlider;