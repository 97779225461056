import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

import { getImageAlt } from 'src/redesign/helpers';
import Image from 'src/redesign/components/Image';

import './style.scss';

const ImageAnimated = ({ className, src }) => (
  <div className={classNames(className, 'image-container')}>
    <Image alt={getImageAlt()} className={classNames(className, 'image-container__image')} src={src} />
  </div>
);

ImageAnimated.propTypes = {
  className: string,
  src: string.isRequired,
};

export default ImageAnimated;
