import React from 'react';
import { withRouter } from 'react-router-dom';

import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Loader from 'src/redesign/components/Loader';
import Table from 'src/redesign/components/Table';
import ToggleSwitch from 'src/redesign/components/Form/ToggleSwitch';
import Checkbox from 'src/redesign/components/Checkbox';
import EditVariation from './components/EditVariation';
import Image from 'src/redesign/components/Image';
import { getProductVariationImages, getProductVariationsAdmin, toggleVariationAvailability } from 'src/services/admin/ProductVariationService';
import { getImageAlt } from 'src/redesign/helpers';
import { mapVariation } from './helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const SkuProduct = ({ match: { params: { id }} }) => {
  const [loading, setLoading] = React.useState(false);
  const [variations, setVariations] = React.useState([]);

  const [selectedVariations, setSelectedVariations] = React.useState([]);

  const { openSnackBar } = useSnackbar();

  //#region load

  React.useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setLoading(true);
    try {
      const { data } = await getProductVariationsAdmin(id);

      const variationIds = data.map(x => x.id);

      const { data: variationImages } = await getProductVariationImages({ variationIds });

      setVariations(data.map(x => mapVariation(x, variationImages)));
      setSelectedVariations([]);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load variations', false);
    } finally {
      setLoading(false);
    }
  }

  //#endregion load

  const toggleVariation = async (id, isAvailable) => {
    await toggleVariationAvailability(id);
    setVariations(prev => prev.map((x) => x.id == id ? ({ ...x, isAvailable: !isAvailable }) : x ));
  }

  const isAllVariationsSelected = selectedVariations.length == variations.length;

  const columns = [
    {
      Header: () => <Checkbox checked={isAllVariationsSelected} onChange={()=> setSelectedVariations(isAllVariationsSelected ? [] : variations)}/>,
      accessor: 'id',
      Cell: ({ row: { original }}) => {
        const isSelected = selectedVariations.includes(original);

        return (
          <Checkbox
            checked={isSelected}
            onChange={() =>
              setSelectedVariations(prev =>
              isSelected
                ? prev.filter(x => x.id != original.id)
                : [...prev, original]
              )
            }
          />
        );
      }
    },
    {
      Header: 'Image',
      accessor: 'image',
      Cell: ({ row: { original: { images }}}) => <Image alt={getImageAlt()} style={{ width: 29, height: 23, borderRadius: 2 }} src={images?.[0]?.base64} />
    },
    {
      Header: 'SKU',
      accessor: 'name',
    },
    {
      Header: '',
      accessor: 'mediaModifierMockups',
      Cell: ({ row: { original: { mediaModifierMockups, document }}}) =>
      <div className='variation-mockups'>
        {mediaModifierMockups.map(mockup => 
          <div className='variation-mockups-chip'>
            <div className='variation-mockups-avatar'>
            M
            </div>
            {mockup?.name}
          </div>
        )}
        {document && (
          <div
            className='variation-mockups-chip'
            style={{ backgroundColor: colors.secondary, color: colors.blackDark }}
          >
            <div
              className='variation-mockups-avatar'
              style={{ backgroundColor: colors.secondaryLight }}
            >
              DT
            </div>
            {document?.name}
          </div>
        )}
      </div>
    },
    {
      Header: '',
      accessor: 'isAvailable',
      Cell: ({ row: { original: { id, isAvailable }}}) => 
      <div onClick={() => toggleVariation(id, isAvailable)}>
        <ToggleSwitch className='variation-toggle-switch' checked={isAvailable} onChange={()=>{}} />
      </div>
    }
  ]; 

  return (
    loading ? (
      <div className='sku-product-page-loader-container'>
        <Loader width={200} height={200} color={colors.primary} />
      </div>
      ) :
      <div className='sku-product-page'>
        <div className='sku-table-container'>
          <Table
            columns={columns}
            data={variations}
            theadClassName='sku-table-thead'
            thClassName='sku-table-th'
          />
        </div>
        <div className='sku-edit-container'>
          <EditVariation selectedVariations={selectedVariations} onLoad={load} />
        </div>
      </div>
  )
}

export default withRouter(SkuProduct);