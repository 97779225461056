import {withRouter} from "react-router-dom";
import React from 'react'
import FormComponent from "../../common/FormComponent";
import {
    deleteProduct,
    getProduct,
    getProductAttributes,
    getShippingPricingProduct, setVariationAvailability,
    updateProduct,
    uploadProductImage
} from "../../services/admin/ProductService";
import {Button, TextField} from "@material-ui/core";
import CreatableSelect from 'react-select/creatable';
import SelectControl from "../controls/SelectControl";
import {getCategories} from "../../services/CategoryService";
import AddAttribute from "./AddAttribute";
import {createBrand, getBrands} from "../../services/admin/BrandService";
import {deleteAttribute, getAttributeValuesForAttribute} from "../../services/admin/AttributeService";
import AttributeType from "../../constants/AttributeType";
import {withSnackbar} from "notistack";
import update from "immutability-helper";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import {getProductVariationsAdmin} from "../../services/admin/ProductVariationService";
import strings from "../../localization";
import {isInt, isNumeric} from "../../util/DataValidation";
import AddAttributeEditProduct from "./AddAttributeEditProduct";

class AboutProduct extends FormComponent{

    constructor(props) {
        super(props);

        this.state = {
            showAddAttribute : false,
            edited : false,
            leave : false,
            selectedAttributeValue : {},
            showNewAttributeWindow : false,
            data : {
                changeShipping : false,
                changeVendorShipping: false,
                changePrice : false,
                changeVendorPrice: false,
                guidelines_link: null
            },
            brands : [],
            brand : {},
            editAttribute : {},
            attributeNames : [],
            attributeValues : [],
            newAttributeNames : [],
            newAttributeValues : [],
            deletedValues : []
        };

        this.closeWindow = this.closeWindow.bind(this);
        this.saveAndGenerateNew = this.saveAndGenerateNew.bind(this);
        this.handleRadio = this.handleRadio.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.saveProduct = this.saveProduct.bind(this);
        this.deleteAttribute = this.deleteAttribute.bind(this);
        this.saveAttribute = this.saveAttribute.bind(this);
        this.handleCreateSelect = this.handleCreateSelect.bind(this);
        this.toggleAttribute = this.toggleAttribute.bind(this);
    }

    componentDidMount() {
        getProductVariationsAdmin(this.props.match.params.id).then(response => {
            if (!response.ok){
                return
            }

            let isAvailable = false;

            response.data.forEach(item => {
                if (!item.is_available){
                    return
                }

                isAvailable = true;
            })

            this.setState({
                ...this.state,
                isAvailable : isAvailable
            })
        });

        getCategories().then(response => {
            if (!response.ok){
                return ;
            }
            let categories = response.data;
            let toRender = [];

            categories.forEach(firstItem => {
                let add = true;

                categories.forEach(item => {
                    if (item.parent_category && item.parent_category.id === firstItem.id){
                        add = false
                    }
                })

                if (add){
                    toRender.push(firstItem)
                }
            })

            this.setState({
                ...this.state,
                categories : toRender
            })
        })

        getBrands().then(response =>{
            if (!response.ok){
                return ;
            }

            let brands = response.data;
            let toSend = [];

            brands.forEach(item => {
                toSend.push({
                    value : item.id,
                    label : item.name
                });
            })

            this.setState({
                ...this.state,
                brands : toSend
            });
        })

        getProduct(this.props.id).then(response => {
            if (!response.ok){
                return ;
            }

            this.setState({
                ...this.state,
                data : {
                    ...this.state.data,
                    productName : response.data.name,
                    category : response.data.category,
                    productDescription : response.data.description,
                    price : response.data.price,
                    vendorPrice: response.data.vendor_price,
                    guidelinesLink: response.data.guidelines_link
                },
                brand : { label : response.data.brand.name, value : response.data.brand.id},
                image : response.data.product_image
            })
        })

        getProductAttributes(this.props.id).then(response =>  {
            if (!response.ok){
                return ;
            }

            let productAttributes = response.data;

            let attributeNames = [];
            let attributeValues = [];

            productAttributes.forEach(item => {
                let name = item.attribute_value.attribute.name;

                if (!attributeNames.includes(name)){
                    attributeNames.push(name);
                    attributeValues.push(
                        {attributeValues : [], type : 0}
                    );
                }
            });

            attributeNames.forEach((item, index) => {

                productAttributes.forEach(itemAttr => {
                    let name = itemAttr.attribute_value.attribute.name;

                    if (item === name){
                        attributeValues[index].attributeValues.push(itemAttr.attribute_value);
                        attributeValues[index].type = itemAttr.attribute_value.attribute.attribute_type;
                    }
                })
            });

            this.setState({
                ...this.state,
                attributeNames : attributeNames,
                attributeValues : attributeValues
            })
        })

        getShippingPricingProduct(this.props.id).then(response => {
            if (!response.ok){
                return ;
            }

            this.setState({
                ...this.state,
                data : {
                    ...this.state.data,
                    usFirstStandard: response.data.us_standard_first,
                    usFirstExpedited: response.data.us_expedited_first,
                    usFirstOvernight: response.data.us_overnight_first,
                    usOtherStandard: response.data.us_standard_n,
                    usOtherExpedited: response.data.us_expedited_n,
                    usOtherOvernight: response.data.us_overnight_n,
                    intFirstStandard: response.data.int_standard_first,
                    intFirstExpedited: response.data.int_expedited_first,
                    intOtherStandard: response.data.int_standard_n,
                    intOtherExpedited: response.data.int_expedited_n,
                    vendorUsFirstStandard: response.data.vendor_us_standard_first,
                    vendorUsFirstExpedited: response.data.vendor_us_expedited_first,
                    vendorUsFirstOvernight: response.data.vendor_us_overnight_first,
                    vendorUsOtherStandard: response.data.vendor_us_standard_n,
                    vendorUsOtherExpedited: response.data.vendor_us_expedited_n,
                    vendorUsOtherOvernight: response.data.vendor_us_overnight_n,
                    vendorIntFirstStandard: response.data.vendor_int_standard_first,
                    vendorIntFirstExpedited: response.data.vendor_int_expedited_first,
                    vendorIntOtherStandard: response.data.vendor_int_standard_n,
                    vendorIntOtherExpedited: response.data.vendor_int_expedited_n,
                }
            })
        })
    }

    onUpload(event) {

        let formData = new FormData();
        let acceptedFiles;

        acceptedFiles = event.target.files[0];

        formData.append('image', acceptedFiles);
        event.target.value = null;

        uploadProductImage(formData).then(response => {
            if (!response.ok){
                return;
            }

            this.setState({
                ...this.state,
                image : response.data
            })
        })
    }

    toggleAttribute(){

        if(this.state.newAttributeValues.length != 0){
            this.props.enqueueSnackbar('You must save your changes first!', { variant: 'error' });
            return;
        }

        this.setState({
            ...this.state,
            showAddAttribute : !this.state.showAddAttribute
        })
    }

    saveAttribute(name, attributes, type){
        if(!Array.isArray(attributes)){
            return
        }

        getAttributeValuesForAttribute({
            new : true,
            attributeId : attributes[0].id,
            alreadyHave : []
        }).then(response => {
            if (!response.ok || response.data.length === 0){
                return
            }

            let returnedValues = response.data;

            let names = this.state.attributeNames;
            let values = this.state.attributeValues;

            let newNames = this.state.newAttributeNames;
            let newValues = this.state.newAttributeValues;

            let index = names.indexOf(response.data[0].attribute.name);

            if (!names.includes(response.data[0].attribute.name)){
                names.push(response.data[0].attribute.name);
                newNames.push(response.data[0].attribute.name);
            }

            let currentOnesIds = [];

            if (isNumeric(index) && values[index]){
                values[index].attributeValues.forEach(item => {
                    currentOnesIds.push(item.id);
                })

                returnedValues.forEach(returnedValue => {
                    if (!currentOnesIds.includes(returnedValue.id)){
                        currentOnesIds.push(returnedValue.id);
                        values[index].attributeValues.push(returnedValue)
                        newValues.push({attributeValues: returnedValue, type: type, new : true})
                    }
                })
            }else {
                values.push({attributeValues: response.data, type: type, new : true})
                newValues.push({attributeValues: response.data, type: type, new : true})
            }

            this.setState({
                ...this.state,
                attributeNames : names,
                attributeValues : values,
                newAttributeNames : newNames,
                newAttributeValues : newValues,
                showAddAttribute : false
            })
        });

    }

    removeAttributeValue(i, id){
        let allValues = this.state.attributeValues;

        let values = this.state.attributeValues[i].attributeValues;
        let newValues = this.state.newAttributeValues;
        let type = this.state.attributeValues[i].type;
        let toSend = [];

        values.forEach(item => {
            if (item.id === id){
                this.state.deletedValues.push(item.id);
                return
            }

            toSend.push(item);
        });

        newValues.forEach(items => {
            let newA = [];

            items.attributeValues.forEach(value => {
                if (value.id === id){
                    return
                }

                newA.push(value)
            })

            items.attributeValues = newA;
        })

        allValues[i].attributeValues = toSend;
        allValues[i].type = type;

        this.setState({
            ...this.state,
            attributeValues : allValues,
            newAttributeValues : newValues
        })

    }

    handleCreateSelect(e){
        if (e.__isNew__){
            createBrand({name: e.value}).then(response => {
                if (!response.ok){
                    return ;
                }

                let brands = this.state.brands;

                brands.push({label : response.data.name, value: response.data.id});

                this.setState({
                    ...this.state,
                    brands : brands,
                    brand : response.data
                })
            })
        }else {
            this.setState({
                ...this.state,
                brand : e
            })
        }
    }

    addExistingAttributeValue(attrNameIndex){
        let have = [];

        this.state.attributeValues[attrNameIndex].attributeValues.forEach(item => {
            have.push(item.id);
        })

        getAttributeValuesForAttribute({
            new : false,
            attributeId : this.state.attributeValues[attrNameIndex].attributeValues[0].attribute.id,
            alreadyHave : have
        }).then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                ...this.state,
                quickAddAttributes : response.data,
                quickAddIndex : attrNameIndex
            })
        });
    }

    addNewAttributeValue(attrNameIndex){
        let have = [];

        this.state.attributeValues[attrNameIndex].attributeValues.forEach(item => {
            have.push(item.id);
        })

        getAttributeValuesForAttribute({
            new : true,
            attributeId : this.state.attributeValues[attrNameIndex].attributeValues[0].id,
            alreadyHave : have
        }).then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                ...this.state,
                quickAddAttributes : response.data,
                quickAddIndex : attrNameIndex
            })
        });
    }

    renderAttributeValues(i){
        let toRender = [];
        let values = this.state.attributeValues[i].attributeValues;

        if (this.state.attributeValues[i].type === AttributeType.STRING){
            values.forEach((item) => {
                toRender.push(
                    <label key={item.id} className={'attribute-value'}>
                        {item.name}
                        {
                            this.state.attributeValues[i].new &&
                            <label className={'x'} onClick={()=> {
                                this.removeAttributeValue(i, item.id)
                            }}
                            >x</label>
                        }
                    </label>
                );
            });
        }else {
            values.forEach((item) => {
                toRender.push(
                    <label key={item.id} className={'attribute-value'}>
                        <div style={{backgroundColor: item.name}} className={'circle'}/>
                        {item.customer_color_name}
                        {
                            this.state.attributeValues[i].new &&
                            <label className={'x'} onClick={()=> {
                                this.removeAttributeValue(i, item.id)
                            }}
                            >x</label>
                        }
                    </label>
                );
            });
        }

        toRender.push(
            <label key={-1} className={'attribute-value add'} onClick={() => {
                this.addNewAttributeValue(i)

            }}>
                +
                {
                    (this.state.quickAddIndex === i) && (this.state.quickAddAttributes.length > 0) &&
                    <div className={'quick-add-container'}>
                        {this.renderQuickAdd()}
                    </div>
                }
            </label>
        );

        toRender.push(
            <label key={-2} className={'attribute-value add'} onClick={() => {
                this.editAttribute(this.state.attributeValues[i]);
            }}
            >
                Edit
            </label>
        );

        return toRender;
    }

    editAttribute(values){
        this.setState({
            ...this.state,
            showAddAttribute : true,
            editAttribute : values.attributeValues[0].attribute
        })
    }

    renderAttributes(){
        let toRender = [];

        this.state.attributeNames.forEach((item, index) => {
            toRender.push(
                <div key={index} className={'attribute'}>
                    <label className={'attribute-name'}>
                        <label>{item}</label>
                        {
                            this.state.newAttributeNames.includes(item) &&
                            <label className={'x'} onClick={()=> {
                            this.removeAttribute(index)}
                            }>x</label>
                        }
                    </label>
                    <div className={'attribute-values'}>
                        {this.renderAttributeValues(index)}
                    </div>
                </div>
            );
        });

        return toRender;
    }

    removeAttribute(i){
        let attributeNames = this.state.attributeNames;
        let attributeValues = this.state.attributeValues;

        let toRenderNames = [];
        let toRenderValues = [];
        let removed;

        attributeNames.forEach((item, index) => {
            if (index === i){
                removed = this.state.attributeValues[index];
                return ;
            }

            toRenderValues.push(this.state.attributeValues[index])
            toRenderNames.push(item)
        });

        let newValues = [];
        let type;

        removed.attributeValues.forEach(items => {
            this.state.newAttributeValues.forEach(newItems => {
                let newValues = newItems.attributeValues;
                type = newItems.type;

                newValues.forEach(newVal => {
                    if (newVal.id === items.id){
                        return
                    }

                    newValues.push(newVal);
                })
            })
        })

        this.setState({
            ...this.state,
            attributeNames : toRenderNames,
            attributeValues : toRenderValues,
            newAttributeValues : {
                attributeValues : newValues,
                type : type
            }
        });
    }

    deleteAttribute(id){
        deleteAttribute(id).then(response => {
            if (!response.ok){
                return ;
            }

            this.toggleAttribute();

        })
    }

    isValid(){
        // console.log(!this.state.data.usFirstStandard)
        // console.log(!this.state.data.usOtherStandard )
        // console.log(!this.state.data.intFirstStandard )
        // console.log(!this.state.data.intOtherStandard)
        //
        // console.log(!this.state.data.usFirstExpedited)
        // console.log(!this.state.data.usOtherExpedited)
        // console.log(!this.state.data.intFirstExpedited)
        // console.log(!this.state.data.intOtherExpedited)
        //
        // console.log(!this.state.data.usFirstOvernight)
        // console.log(!this.state.data.usOtherOvernight )
        //
        // console.log(!this.state.attributeValues)
        // console.log(!this.state.image)
        // console.log(!this.state.data.price)
        // console.log(!this.state.brand)
        // console.log(!this.state.data.category)
        // console.log(!this.state.data.productName)
        // console.log(this.state.data.productDescription)
        return !(!this.state.data.productName || !this.state.data.productDescription ||
            !this.state.data.price || !this.state.data.vendorPrice || !this.state.brand || !this.state.data.category ||
            !this.state.attributeValues || !this.state.image ||
            !this.state.data.usFirstStandard || !this.state.data.usFirstExpedited || !this.state.data.usFirstOvernight ||
            !this.state.data.usOtherStandard || !this.state.data.usOtherExpedited || !this.state.data.usOtherOvernight ||
            !this.state.data.intFirstStandard || !this.state.data.intOtherStandard ||
            !this.state.data.vendorUsFirstStandard || !this.state.data.vendorUsFirstExpedited || !this.state.data.vendorUsFirstOvernight ||
            !this.state.data.vendorUsOtherStandard || !this.state.data.vendorUsOtherExpedited || !this.state.data.vendorUsOtherOvernight ||
            !this.state.data.vendorIntFirstStandard || !this.state.data.vendorIntOtherStandard);
    }

    isInternationalExpeditedPriceValid() {
        return !((this.state.data.intOtherExpedited && !this.state.data.intFirstExpedited) ||
            (!this.state.data.intOtherExpedited && this.state.data.intFirstExpedited)
        );
    }

    isVendorInternationalExpeditedPriceValid() {
        return !((this.state.data.vendorIntOtherExpedited && !this.state.data.vendorIntFirstExpedited) ||
            (!this.state.data.vendorIntOtherExpedited && this.state.data.vendorIntFirstExpedited)
        );
    }

    renderNewAttributes(){
        let newAttributesValues = [];
        let goThrough = [];

        this.state.attributeValues.forEach(items => {
            let values = items.attributeValues;

            this.state.newAttributeValues.forEach(items2 => {
                if (!items2.new){
                    return
                }

                let newVals = items2.attributeValues;

                if (Array.isArray(newVals)){
                    newVals.forEach(newVal => {
                        values.forEach(value => {
                            if (value.id === newVal.id){
                                goThrough.push({attributeValues : [newVal], type : items2.type});
                            }
                        })
                    })
                }else {
                    values.forEach(value => {
                        if (value.id === newVals.id){
                            goThrough.push({attributeValues : [newVals], type : items2.type});
                        }
                    })
                }
            })
        })

        goThrough.forEach(items => {
            items.attributeValues.forEach(value => {
                if (items.type === AttributeType.STRING){
                    newAttributesValues.push(
                        <FormControlLabel value={value.name} control={<Radio />} label={value.name} />
                    )
                }else {
                    newAttributesValues.push(
                        <FormControlLabel value={value.name} control={<Radio />} label={
                            <div className={'color'} style={{backgroundColor : value.name}}/>} />
                    )
                }
            })
        })

        return newAttributesValues;
    }

    saveProduct(){
        if (!this.isValid()){
            this.props.enqueueSnackbar('All fields are required', {variant: 'error'});
            return ;
        }

        if (!this.isInternationalExpeditedPriceValid() || !this.isVendorInternationalExpeditedPriceValid()) {
            this.props.enqueueSnackbar('International expedited is not valid', {variant: 'error'});
            return;
        }

        if (this.state.newAttributeValues.length > 0 && this.state.newAttributeNames.length > 0){
            this.setState({
                ...this.state,
                showNewAttributeWindow : true
            })
            return
        }

        let product = {
            id : this.props.id,
            name : this.state.data.productName,
            description : this.state.data.productDescription,
            price : this.state.data.price,
            vendorPrice: this.state.data.vendorPrice,
            brandId : this.state.brand.value,
            categoryId : this.state.data.category.id,
            attributeValues : this.state.attributeValues,
            imageId : this.state.image.id,
            newAttributeValues : this.state.newAttributeValues ? this.state.newAttributeValues : [],
            usFirstStandard: this.state.data.usFirstStandard,
            usFirstExpedited: this.state.data.usFirstExpedited,
            usFirstOvernight: this.state.data.usFirstOvernight,
            usOtherStandard: this.state.data.usOtherStandard,
            usOtherExpedited: this.state.data.usOtherExpedited,
            usOtherOvernight: this.state.data.usOtherOvernight,
            intFirstStandard: this.state.data.intFirstStandard,
            intFirstExpedited: this.state.data.intFirstExpedited,
            intOtherStandard: this.state.data.intOtherStandard,
            intOtherExpedited: this.state.data.intOtherExpedited,
            vendorUsFirstStandard: this.state.data.vendorUsFirstStandard,
            vendorUsFirstExpedited: this.state.data.vendorUsFirstExpedited,
            vendorUsFirstOvernight: this.state.data.vendorUsFirstOvernight,
            vendorUsOtherStandard: this.state.data.vendorUsOtherStandard,
            vendorUsOtherExpedited: this.state.data.vendorUsOtherExpedited,
            vendorUsOtherOvernight: this.state.data.vendorUsOtherOvernight,
            vendorIntFirstStandard: this.state.data.vendorIntFirstStandard,
            vendorIntFirstExpedited: this.state.data.vendorIntFirstExpedited,
            vendorIntOtherStandard: this.state.data.vendorIntOtherStandard,
            vendorIntOtherExpedited: this.state.data.vendorIntOtherExpedited,
            deletedValues : this.state.deletedValues,
            changeShipping : this.state.data.changeShipping,
            changeVendorShipping: this.state.data.changeVendorShipping,
            changePrice : this.state.data.changePrice,
            changeVendorPrice: this.state.data.changeVendorPrice,
            selectedAttributeValue : null,
            guidelinesLink: this.state.data.guidelinesLink
        };

        updateProduct(product).then(response => {
            if (!response.ok){
                return ;
            }

            this.setState({
                ...this.state,
                product : response.data
            }, () => {
                this.props.enqueueSnackbar('Successfully saved', { variant: 'success' });

                this.props.edit(false);
            })
        })

        this.setState({
            ...this.state,
            newAttributeValues: [],
            newAttributeNames: []
        })
    }

    changeData(event, data = 'data') {

        this.setState({
            [data]: update(this.state[data], { [event.target.name]: {$set: event.target.value} }),
            edited : true
        }, () => {
            this.props.edit(true);
        });
    }

    handleRadio(e){
        this.setState({
            ...this.state,
            selectedAttributeValue : e.target.value
        })
    }

    closeWindow(){
        this.setState({
            ...this.state,
            showNewAttributeWindow : false
        })
    }

    saveAndGenerateNew(){
        let keyId = null;

        this.state.newAttributeValues.forEach(items => {
            if (!items.new){
                return
            }

            let values = items.attributeValues;

            values.forEach(item => {
                if (this.state.selectedAttributeValue !== item.name){
                    return
                }

                keyId = item.id;
            })
        })

        let product = {
            id : this.props.id,
            name : this.state.data.productName,
            description : this.state.data.productDescription,
            price : this.state.data.price,
            vendorPrice: this.state.data.vendorPrice,
            brandId : this.state.brand.value,
            categoryId : this.state.data.category.id,
            attributeValues : this.state.attributeValues,
            newAttributeValues : this.state.newAttributeValues ? this.state.newAttributeValues : [],
            imageId : this.state.image.id,
            usFirstStandard: this.state.data.usFirstStandard,
            usFirstExpedited: this.state.data.usFirstExpedited,
            usFirstOvernight: this.state.data.usFirstOvernight,
            usOtherStandard: this.state.data.usOtherStandard,
            usOtherExpedited: this.state.data.usOtherExpedited,
            usOtherOvernight: this.state.data.usOtherOvernight,
            intFirstStandard: this.state.data.intFirstStandard,
            intFirstExpedited: this.state.data.intFirstExpedited,
            intOtherStandard: this.state.data.intOtherStandard,
            intOtherExpedited: this.state.data.intOtherExpedited,
            vendorUsFirstStandard: this.state.data.vendorUsFirstStandard,
            vendorUsFirstExpedited: this.state.data.vendorUsFirstExpedited,
            vendorUsFirstOvernight: this.state.data.vendorUsFirstOvernight,
            vendorUsOtherStandard: this.state.data.vendorUsOtherStandard,
            vendorUsOtherExpedited: this.state.data.vendorUsOtherExpedited,
            vendorUsOtherOvernight: this.state.data.vendorUsOtherOvernight,
            vendorIntFirstStandard: this.state.data.vendorIntFirstStandard,
            vendorIntFirstExpedited: this.state.data.vendorIntFirstExpedited,
            vendorIntOtherStandard: this.state.data.vendorIntOtherStandard,
            vendorIntOtherExpedited: this.state.data.vendorIntOtherExpedited,
            deletedValues : this.state.deletedValues,
            changeShipping : this.state.data.changeShipping,
            changeVendorShipping : this.state.data.changeVendorShipping,
            changeVendorPrice : this.state.data.changeVendorPrice,
            changePrice: this.state.data.price,
            selectedAttributeValue : keyId,
            guidelinesLink: this.state.data.guidelinesLink
        };

        updateProduct(product).then(response => {
            if (!response.ok){
                return ;
            }

            this.setState({
                ...this.state,
                product : response.data,
                showNewAttributeWindow : false,
                newAttributeValues : []
            }, () => {
                this.props.enqueueSnackbar('Successfully saved', { variant: 'success' });

                this.props.edit(false);
            })
        })
    }

    deleteProduct(){
        deleteProduct(this.props.match.params.id).then(response => {
            if (!response.ok){
                return
            }

            this.props.enqueueSnackbar('Successfully deleted ' + response.data + ' product', {variant: 'success'});
            this.props.history.push('/products');
        })
    }

    quickAdd(item){
        let values = this.state.attributeValues;

        values[this.state.quickAddIndex].attributeValues.push(item);
        let newAtts = this.state.newAttributeValues;
        newAtts.push(item)

        setTimeout(()=> {
            this.setState({
                ...this.state,
                attributeValues : values,
                newAttributeValues : newAtts,
                quickAddIndex : -1
            })
        }, 100)
    }

    renderQuickAdd(){
        let toRender = [];

        this.state.quickAddAttributes.forEach(item => {
            if (item.attribute.attribute_type === AttributeType.COLOR){
                toRender.push(
                    <div style={{display: 'flex', alignItems: 'center'}} key={item.id} className={'quick-add-values'} onClick={()=>this.quickAdd(item)}>
                        <span style={{
                            backgroundColor: item.name,
                            width: '10px',
                            height: '10px',
                            display: 'block',
                            margin: '4px 0',
                            marginRight: '4px'
                        }} />
                        {item.customer_color_name}
                    </div>
                )
            }else{
                toRender.push(
                    <div key={item.id} className={'quick-add-values'} onClick={()=>this.quickAdd(item)}>
                        {item.name}
                    </div>
                )
            }
        })

        return toRender;
    }

    toggleProductAvailability(e){
        e.stopPropagation();
        e.preventDefault();

        setVariationAvailability(this.props.match.params.id, !this.state.isAvailable).then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                ...this.state,
                isAvailable : !this.state.isAvailable
            })
        })
    }

    render() {
        return (
            <div className={'about-product'}>
                {
                    this.state.showNewAttributeWindow && this.state.newAttributeValues.length > 0 &&
                    <div className={'attribute-container'}>
                        <div className={'attribute-values'}>
                            <div className={'values'}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Choose an attribute to connect with existing variations</FormLabel>
                                    <RadioGroup value={this.state.selectedAttributeValue} onChange={this.handleRadio} className={'group'} aria-label="attributeValue" name="selectedAttributeValue">
                                        {this.renderNewAttributes()}
                                    </RadioGroup>
                                </FormControl>
                                <Button color="primary" variant="contained" onClick={this.saveAndGenerateNew}>Save</Button>
                            </div>
                        </div>
                        <div className={'bg'} onClick={this.closeWindow}></div>
                    </div>
                }
                <div className={'white'}>
                    <div className={'fields'}>
                        <div className={'field-container'}>
                            <label>Image</label>
                            <div className={'field-helper'}>
                                <div className={'field'}>
                                    <div className={'image-container'}>
                                        {
                                            this.state.image &&
                                            <img className={'product-image'} src={process.env.REACT_APP_baseUrl + this.state.image.url} alt={'Product Image'} />
                                        }
                                        <div className={'upload'}>
                                            <p>Upload Image</p>
                                            <input type={'file'} onChange={this.onUpload}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={'options'}>
                                    <div className={'edits'}>
                                        <span className={'edit'}>Deactivate product</span>
                                        <label className="switch" onClick={(e) => {
                                            this.toggleProductAvailability(e);
                                        }} >
                                            <input type="checkbox" checked={this.state.isAvailable === true} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className={'deletes'}>
                                        <span className={'delete'} onClick={()=> {
                                            this.deleteProduct();
                                        }}>Delete product</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'field-container'}>
                            <label>Product Name</label>
                            <TextField
                                name='productName'
                                onChange={ this.changeData }
                                margin="normal"
                                type='text'
                                value={ this.state.data.productName }
                                placeholder={'Product Name'}
                            />
                        </div>
                        <div className={'field-container'}>
                            <label>Product Category</label>
                            <SelectControl
                                className='select'
                                options={this.state.categories}
                                valueKey = { 'id' }
                                onChange={ this.changeData }
                                nameKey= { 'name' }
                                name = {'category'}
                                selected={this.state.data.category}
                            />
                        </div>
                        <div className={'field-container'}>
                            <label>Brand</label>
                            <CreatableSelect
                                className='select'
                                options={this.state.brands}
                                placeholder={'Brand'}
                                onChange={this.handleCreateSelect}
                                value={this.state.brands.find(option => option.value === this.state.brand.value)}
                            />
                        </div>
                        <div className={'field-container'}>
                            <label>Description</label>
                            <TextField
                                name='productDescription'
                                onChange={ this.changeData }
                                margin="normal"
                                type='text'
                                value={ this.state.data.productDescription }
                                placeholder={'Description'}
                                multiline
                                rows={4}
                            />
                        </div>
                        <div className={'field-container'}>
                            <label>Attributes</label>
                            <div>
                                {/*<p className={'new'} onClick={this.toggleAttribute}>Add new attribute</p>*/}
                                <div className={'attributes'}>
                                    {this.renderAttributes()}
                                </div>
                            </div>
                        </div>
                        <div className={'field-container'}>
                            <label>Price</label>
                            <div>
                                <div className={'change'}>
                                    <label>Change Variation Price</label>
                                    <input checked={this.state.data.changePrice} onChange={this.changeCheckBox} name={'changePrice'} className={'checkbox'} type={'checkbox'} />
                                </div>
                                <TextField
                                    name='price'
                                    onChange={ this.changeData }
                                    margin="normal"
                                    type='number'
                                    value={ this.state.data.price }
                                    placeholder={'Price'}
                                />
                            </div>
                        </div>
                        <div className={'field-container'}>
                            <label>Vendor price</label>
                            <div>
                                <div className={'change'}>
                                    <label>Change Variation Vendor Price</label>
                                    <input checked={this.state.data.changeVendorPrice} onChange={this.changeCheckBox} name={'changeVendorPrice'} className={'checkbox'} type={'checkbox'} />
                                </div>
                                <TextField
                                    name='vendorPrice'
                                    onChange={ this.changeData }
                                    margin="normal"
                                    type='number'
                                    value={ this.state.data.vendorPrice }
                                    placeholder={'Vendor price'}
                                />
                            </div>
                        </div>
                        <div className={'shipping-container'}>
                            <label>Shipping prices</label>
                            <div className={'container-price'}>
                                <div className={'change'}>
                                    <label>Change Shipping Price</label>
                                    <input checked={this.state.data.changeShipping} onChange={this.changeCheckBox} name={'changeShipping'} className={'checkbox'} type={'checkbox'} />
                                </div>
                                <div className={'sub-container'}>
                                    <p className={'title'}>US</p>
                                    <div className={'hr'}/>
                                    <div className={'pricing-field-container'}>
                                        <label>First</label>
                                        <div>
                                            <label>Standard</label>
                                            <TextField
                                                name='usFirstStandard'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.usFirstStandard }
                                            />
                                        </div>
                                        <div>
                                            <label>Expedited</label>
                                            <TextField
                                                name='usFirstExpedited'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.usFirstExpedited }
                                            />
                                        </div>
                                        <div>
                                            <label>Overnight</label>
                                            <TextField
                                                name='usFirstOvernight'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.usFirstOvernight }
                                            />
                                        </div>
                                    </div>
                                    <div className={'pricing-field-container'}>
                                        <label>Each Additional</label>
                                        <TextField
                                            name='usOtherStandard'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.usOtherStandard }
                                        />
                                        <TextField
                                            name='usOtherExpedited'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.usOtherExpedited }
                                        />
                                        <TextField
                                            name='usOtherOvernight'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.usOtherOvernight }
                                        />
                                    </div>
                                </div>
                                <div className={'sub-container'}>
                                    <p className={'title'}>International</p>
                                    <div className={'hr'}></div>
                                    <div className={'pricing-field-container'}>
                                        <label>First</label>
                                        <div>
                                            <label>Standard</label>
                                            <TextField
                                                name='intFirstStandard'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.intFirstStandard }
                                            />
                                        </div>
                                        <div>
                                            <label>Expedited (optional)</label>
                                            <TextField
                                                name='intFirstExpedited'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.intFirstExpedited }
                                            />
                                        </div>
                                    </div>
                                    <div className={'pricing-field-container'}>
                                        <label>Each Additional</label>
                                        <TextField
                                            name='intOtherStandard'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.intOtherStandard }
                                        />
                                        <TextField
                                            name='intOtherExpedited'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.intOtherExpedited }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'shipping-container'}>
                            <label>Vendor shipping prices</label>
                            <div className={'container-price'}>
                                <div className={'change'}>
                                    <label>Change Vendor Shipping Price</label>
                                    <input checked={this.state.data.changeVendorShipping} onChange={this.changeCheckBox} name={'changeVendorShipping'} className={'checkbox'} type={'checkbox'} />
                                </div>
                                <div className={'sub-container'}>
                                    <p className={'title'}>US</p>
                                    <div className={'hr'}/>
                                    <div className={'pricing-field-container'}>
                                        <label>First</label>
                                        <div>
                                            <label>Standard</label>
                                            <TextField
                                                name='vendorUsFirstStandard'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.vendorUsFirstStandard }
                                            />
                                        </div>
                                        <div>
                                            <label>Expedited</label>
                                            <TextField
                                                name='vendorUsFirstExpedited'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.vendorUsFirstExpedited }
                                            />
                                        </div>
                                        <div>
                                            <label>Overnight</label>
                                            <TextField
                                                name='vendorUsFirstOvernight'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.vendorUsFirstOvernight }
                                            />
                                        </div>
                                    </div>
                                    <div className={'pricing-field-container'}>
                                        <label>Each Additional</label>
                                        <TextField
                                            name='vendorUsOtherStandard'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.vendorUsOtherStandard }
                                        />
                                        <TextField
                                            name='vendorUsOtherExpedited'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.vendorUsOtherExpedited }
                                        />
                                        <TextField
                                            name='vendorUsOtherOvernight'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.vendorUsOtherOvernight }
                                        />
                                    </div>
                                </div>
                                <div className={'sub-container'}>
                                    <p className={'title'}>International</p>
                                    <div className={'hr'}></div>
                                    <div className={'pricing-field-container'}>
                                        <label>First</label>
                                        <div>
                                            <label>Standard</label>
                                            <TextField
                                                name='vendorIntFirstStandard'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.vendorIntFirstStandard }
                                            />
                                        </div>
                                        <div>
                                            <label>Expedited (optional)</label>
                                            <TextField
                                                name='vendorIntFirstExpedited'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.vendorIntFirstExpedited }
                                            />
                                        </div>
                                    </div>
                                    <div className={'pricing-field-container'}>
                                        <label>Each Additional</label>
                                        <TextField
                                            name='vendorIntOtherStandard'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.vendorIntOtherStandard }
                                        />
                                        <TextField
                                            name='vendorIntOtherExpedited'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.vendorIntOtherExpedited }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'field-container'}>
                            <label>Guidelines link</label>
                            <TextField
                                name='guidelinesLink'
                                onChange={ this.changeData }
                                margin="normal"
                                type="text"
                                value={ this.state.data.guidelinesLink }
                            />
                            
                        </div>

                        <Button onClick={this.saveProduct} className={'submit'} variant="contained" color="primary">
                            Save
                        </Button>
                    </div>
                </div>
                {
                    this.state.showAddAttribute &&
                    <AddAttributeEditProduct close={this.toggleAttribute} showAddAttribute={this.state.showAddAttribute} editAttribute={this.state.editAttribute} deleteAttribute={this.deleteAttribute} onSave={this.saveAttribute} />
                }
                {
                    this.state.showAddAttribute &&
                    <div onClick={this.toggleAttribute} className={'bg'}></div>
                }
            </div>
        );
    }
}

export default withSnackbar(withRouter(AboutProduct));