import React from 'react';
import { getOrderStatus } from 'src/services/OrderService';
import { dateToString } from 'src/util/DateUtil';
import Loader from 'src/redesign/components/Loader';
import Pagination from 'src/redesign/components/Pagination';
import Status from 'src/redesign/components/Status';
import Table from 'src/redesign/components/Table';
import useSnackbar from 'src/redesign/hooks/useSnackbar';

const PER_PAGE = 50;

const OrderDetailsHistory = ({ order }) => {
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const [history, setHistory] = React.useState([]);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, [currentPage]);

  const load = async () => {
    setLoading(true);

    try {
      const { data: { result, total }} = await getOrderStatus({
        orderId: order.id,
        page: currentPage,
        perPage: PER_PAGE
      });

      setHistory(result);
      setTotalPages(Math.ceil(total/PER_PAGE));
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load History data', false);
    } finally {
      setLoading(false);
    }
  }

  //#region table

  const columns = [
    {
      Header: 'Order',
      accessor: 'orderId'
    },
    {
      Header: 'Item ID',
      accessor: 'orderItemId'
    },
    {
      Header: 'User',
      accessor: 'userId'
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => 
      <div style={{ width: 'max-content' }}>
        <Status statusNumber={row?.original?.status} isFilter={false} />
      </div>
    },
    {
      Header: 'Comment',
      accessor: 'comment'
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ row }) => <>{dateToString(row?.original?.createdAt)}</>
    }
  ]

  //#endregion table

  return (
    <div className='order-history'>
      {loading
      ? <Loader width={200} height={200} />
      : (
          <div style={{ overflow: 'auto' }}>
            <Table
              columns={columns}
              data={history}
            />
          </div>
      )}
      <Pagination page={currentPage} setPage={setCurrentPage} totalPages={totalPages}/>
    </div>
  )
}

export default OrderDetailsHistory;