import React from 'react';
import classNames from 'classnames';
import IconEtsy from 'src/redesign/components/Icon/Etsy';
import IconShopify from 'src/redesign/components/Icon/Shopify';
import IconStorage from 'src/redesign/components/Icon/Storage';
import { STORE } from 'src/redesign/constants';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const StoreNavigation = ({ isShopifyConnected, isEtsyConnected, selectedTab, setSelectedTab }) => {

  const isStorageSelected = selectedTab === STORE.storage;
  const isShopifySelected = selectedTab === STORE.shopify;
  const isEtsySelected = selectedTab === STORE.etsy;

  return (
    <div className='icon-button-navigation'>
      <div className={classNames('icon-button', {'icon-button-selected': isStorageSelected})} onClick={() => setSelectedTab(STORE.storage)}>
        <IconStorage width={22} height={20} color={isStorageSelected ? colors.white : colors.primary} />
          <div className={classNames('icon-button-text', {'icon-button-text-selected': isStorageSelected})}>
            Storage
          </div>
      </div>

      {isShopifyConnected && <div className={classNames('icon-button', {'icon-button-selected': isShopifySelected})} onClick={() => {
        setSelectedTab(STORE.shopify);
      }}>
        <IconShopify width={23} height={26}/>
          <div className={classNames('icon-button-text', {'icon-button-text-selected': isShopifySelected})}>
            Shopify
          </div>
      </div>}

      {isEtsyConnected && <div className={classNames('icon-button', {'icon-button-selected': isEtsySelected})} onClick={() => {
        setSelectedTab(STORE.etsy);
      }}>
        <IconEtsy width={50} height={28}/>
          <div className={classNames('icon-button-text', {'icon-button-text-selected': isEtsySelected})}>
            Etsy
          </div>
      </div>}
    </div>
  )
}

export default StoreNavigation;