import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import {Button, TextField} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SelectControl from "../components/controls/SelectControl";
import ReCAPTCHA from "react-google-recaptcha";
import {getCountryList} from "../services/CountryService";
import { getShoppingCartItems, calculateShoppingCartSummary } from "../services/ShoppingCart";
import { createOrder, checkoutOrder } from "../services/OrderService";
import Validators from "../constants/ValidatorTypes";
import { getDraftItems } from "../services/ShoppingCart";
import AttributeType from "../constants/AttributeType";

class DraftDetails extends Page {

    params = [ { name: 'page', default: 1 },
               { name: 'perPage', default: 12 }];

    constructor(props) {
        super(props);

        this.state = {
            draftId: props.match.params.id,
            page : 1,
            perPage : 12,
            draftItems: []
        }

        this.onPageClick = this.onPageClick.bind(this);
        this.onForward = this.onForward.bind(this);
        this.onBack = this.onBack.bind(this);
    }

    fetchData(){
        this.getDraftItems(this.state.page, this.state.perPage);
    }

    getDraftItems(page, perPage){
        getDraftItems({
            page : page, 
            perPage : perPage,
            draftId: this.state.draftId
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                draftItems: response.data.result,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                page : response.data.page
            })
        });
    }

    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return;
        }

        if(this.state.page > 5){
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.page + 4) < this.state.totalPages)){
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page == i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    onPageClick(i){
        this.state.page = i;
        this.getDraftItems(i, this.state.perPage);
    }

    onBack(){
        this.state.page = Number(this.state.page) - 1;
        this.getDraftItems(this.state.page, this.state.perPage);
    }

    onForward(){
        this.state.page = Number(this.state.page) + 1;
        this.getDraftItems(this.state.page, this.state.perPage);
    }

    renderAttributes(attributes){
        let result = [];
        for(let item of attributes)
        {  
            result.push(
                <dev className="attribute">
                    <div className="left">{ item.attribute_value.attribute.name }:</div>
                    <div className="right">{ item.attribute_value.attribute.attribute_type == AttributeType.STRING  ?  
                        item.attribute_value.name : <div className="rect" style={{backgroundColor: item.attribute_value.name}}></div> }</div>
                </dev>
            );
        }

        return result;

    }

    renderDraftItems() {
        let result = [];
        
        for(let item of this.state.draftItems)
        {
            result.push(
                <div className="item">
                    <div className="name">{item.customer_product_variation.product_variation.product.name}</div>
                    <img src={item.customer_product_variation_images[0].merged_image}></img>
                    { this.renderAttributes(item.product_variation_attributes)}
                    <span className={'cont'}><span>Name:</span>{item.draft.name}</span>
                    <span className={'cont'}><span>Price:</span>{item.draft.price}</span>
                    <span className={'cont'}><span>Quantity:</span>{item.draft.quantity}</span>
                </div>
            );
        }

        return result;
    }

    render() {
        return (
            <div id="draft-details">
                {/* <div className="options">
                    <Button className="active">Items</Button>
                    <Button>Shipping & Billing</Button>
                </div> */}
                <div className="row">
                    { this.renderDraftItems() }
                </div>

                <div className={'pagination'}>
                    {
                        this.state.page > 1 &&
                        <img onClick={this.onBack} src={'/images/leftFilter.png'} />
                    }
                    <div className={'pages'}>
                        {this.renderPagination()}
                    </div>
                    {
                        this.state.page < this.state.totalPages &&
                        <img onClick={this.onForward} src={'/images/rightFilter.png'} />
                    }
                </div>

            </div>
        );
    }

}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(DraftDetails)));