import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {addEtsyOauthParams} from "../services/UserService";
import {refreshLocalStorage} from "../base/OAuth";
import BlueLoader from "../components/BlueLoader";

class EtsyConnected extends Page {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        }

        const urlParams = Object.fromEntries((new URLSearchParams(window.location.search)).entries());

        if (urlParams.error != null) {
            window.location.replace('/');
        }

        addEtsyOauthParams(urlParams).then(response => {
            if (response.ok) {
                this.setState({
                    loading: false
                })
            }
        }).then(() => {
            refreshLocalStorage();
        })
    }

    render() {
        return <div className={'installation-successful'}>
            {
                this.state.loading &&
                <BlueLoader />
            }
            {
                !this.state.loading &&
                <>
                    <img src={'/images/installation-succesfull.png'} />
                    <h3>Connection successful</h3>
                    <p>You have connected Surge Print account to Etsy store: start exporting, importing and ordering through your Store.</p>
                    <Button onClick={() => this.props.history.push('/home')}>Start Designing</Button>
                </>
            }
        </div>
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(EtsyConnected)));
