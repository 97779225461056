import React from 'react';
import classNames from 'classnames';

import BasicSelectInput from '../../SelectInput';

import './style.scss';

const SelectInput = (props) => {
  return (
    <BasicSelectInput {...props} className={classNames('sidepane-select', props.className)} containerClassName='sidepane-select-container' caretClassName='sidepane-select-caret' />
  );
}

export default SelectInput;