import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "@material-ui/core";
import strings from '../localization';
import TextField from '@material-ui/core/TextField';
import AddCategory from "../components/AddCategory";
import {
    getRootCategories, getCategoryByParent, getAllCategories,
    uploadCategoryImage, addCategory, setTrending,
    updateCategory, deleteCategory, uploadCategoryGalleryImage, deleteCategoryGalleryImage, getCategoryGalleryImages
} from "../services/CategoryService";
import { getImage } from '../services/ImageService';
import Validators from "../constants/ValidatorTypes";
import {withSnackbar} from "notistack";
import ReactDOM from 'react-dom';

class Category extends Page {

    params = [ { name: 'page', default: 1 },
               { name: 'perPage', default: 12 }];

    validationList = {
        name: [ {type: Validators.REQUIRED } ],
        categoryImage: [ {type: Validators.REQUIRED } ]
    };

    constructor(props) {
        super(props);

        this.state = {
            siderbar: false,
            display: 'none',
            data: {
                categoryImage: null,
                parentCategory: null,
                name: null,
                description: null,
                searchTerm: null,
                id: null,
                gallery: []
            },
            rootCategories: [],
            selectedCategory: props.match.params.id ? props.match.params.id : -1,
            page : 1,
            perPage : 12,
            categories: [],
            type: "Category",
            allCategories: [],
            dropdownOpen: null,
            disabled : false
        }

        this.props.changeFullScreen(false);
        this.openSiderbar = this.openSiderbar.bind(this);
        this.onDropPicture = this.onDropPicture.bind(this);
        this.getCategoryByParent = this.getCategoryByParent.bind(this);
        this.getAllCategories = this.getAllCategories.bind(this);
        this.submited = this.submited.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.onPageClick = this.onPageClick.bind(this);
        this.onForward = this.onForward.bind(this);
        this.onBack = this.onBack.bind(this);
        this.goBack = this.goBack.bind(this);
        this.getRootCategories = this.getRootCategories.bind(this);
        this.categorySearch = this.categorySearch.bind(this);
        this.setTrending = this.setTrending.bind(this);
        this.openDropdown = this.openDropdown.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
        this.addGalleryImage = this.addGalleryImage.bind(this);
        this.onDropGalleryPicture = this.onDropGalleryPicture.bind(this);
        this.deleteGalleryPicture = this.deleteGalleryPicture.bind(this);
        this.doesHaveAdminPermissions();
    }

    componentDidMount() {
        this.getRootCategories();
        this.getAllCategories();
        document.addEventListener('click', this.handleClickOutside, true);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.state,
            selectedCategory: nextProps.match.params.id,
            page: 1
        });
        setTimeout(() => {  
            this.fetchData();
        }, 100);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
 
        if (this.state.dropdownOpen) {
            if(event.target.className != "opt" && event.target.className != "dropdown-opt"){
                event.stopPropagation();
                this.setState({
                    ...this.state,
                    dropdownOpen: false
                });
            }
        }
    }

    getRootCategories() {
        getRootCategories().then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                rootCategories: response.data.categories
            })

        });
    }

    getAllCategories(){
        getAllCategories().then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                allCategories: response.data.categories
            })

            console.log(response.data.categories);

        });
    }

    fetchData(){
        this.getCategories(this.state.page, this.state.perPage);
    }

    categorySearch(){

        this.setState({
            ...this.state,
            page: 1
        });
        this.getCategories(1, this.state.perPage);
    }


    getCategories(page, perPage){
        if (this.state.disabled) {
            return;
        }

        this.state.disabled = true;

        getCategoryByParent({
            id: this.state.selectedCategory,
            page : page, 
            perPage : perPage,
            name: (this.state.data.searchTerm == "" || this.state.data.searchTerm  == undefined) ? null : this.state.data.searchTerm,
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                categories: response.data.result,
                type: response.data.type,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                page : response.data.page,
                disabled : false
            })
        });
    }

    openSiderbar(){
        this.setState({
            ...this.state,
            siderbar: true,
            display: 'block',
            data: {
                ...this.state.data,
                gallery: []
            }
        });
    }

    goBack(){
        this.setState({
            ...this.state,
            siderbar: false,
            display: 'none',
            data: {
                ...this.state.data,
                categoryImage: null,
                parentCategory: null,
                name: null,
                id: null
            }
        });
    }


    onDropPicture(acceptedFile) {
        let formData = new FormData();
        formData.append('image', acceptedFile[0]);

        uploadCategoryImage(formData).then(response => {

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    categoryImage: response.data,
                }
            })


        });
    }

    onDropGalleryPicture(acceptedFile, i) {
        let formData = new FormData();
        formData.append('image', acceptedFile[0]);

        uploadCategoryGalleryImage(formData).then(response => {

            let gallery = this.state.data.gallery;
            gallery[i] = response.data;

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    gallery: gallery,
                }
            })

        });
    }

    deleteGalleryPicture(event, img, i) {
        event.stopPropagation();

        deleteCategoryGalleryImage(img).then((response) => {
            let gallery = this.state.data.gallery;
            gallery.splice(i, 1);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    gallery: gallery
                }
            })
        })
    }

    renderRootCategories(){
        let result = [];

        for(let item of this.state.rootCategories)
        {   result.push(
                <div className={this.state.selectedCategory == item.id ? "category active" : "category"} onClick={ () => this.getCategoryByParent(item.id) }>{ item.name }</div>
            );
        }

        return result;
    }

    showImage(item){
        if(this.state.type == "Category"){
            return  <img src={item.category_image != undefined ? getImage(item.category_image) : "/images/no-image.png"}></img>;
        }else{
            return <img src={item.product_image != undefined ? getImage(item.product_image) : "/images/no-image.png"}></img>;
        }
    }

    getParentNameOrProductCategory(item) {

        if(this.state.type == "Category"){
            return <div className="category">{ item.parent_category ? item.parent_category.name : "" }</div>;
        }else{
            return  <div className="category">{ item.category.name }</div>;
        }
    }

    setTrending(item){

        if(window.event.srcElement.nodeName != "IMG") { return; }

        setTrending({
            id: item.id
        }).then(response => {

            item.in_trending = !item.in_trending;
             
            this.setState({
                ...this.state
            });

        });
    }

    openDropdown(id) {
        if(this.state.dropdownOpen != null){
            this.setState({
                ...this.state,
                dropdownOpen: null
            })
        }else{
            this.setState({
                ...this.state,
                dropdownOpen: id
            })
        }
       
    }

    editItem(item){

        if(this.state.type == "Category"){

            getCategoryGalleryImages({id: item.id}).then(response => {
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        parentCategory: item.parent_category,
                        categoryImage: item.category_image,
                        name: item.name,
                        description: item.description,
                        id: item.id,
                        gallery: response.data
                    },
                    siderbar: true,
                    display: 'block',
                    dropdownOpen: null
                });
            });

        }else{
            this.props.history.push('/old/products/' + item.id + '/1');
        }

    }

    deleteItem(id){
        this.setState({
            ...this.state,
            dropdownOpen: null
        });

        if(this.state.type == "Category"){
            deleteCategory({
                id: id
            }).then(response => {
    
                if(!response.ok) {
                    this.props.enqueueSnackbar("Category can't be deleted", {variant: 'error'});
                    return;
                }
    
                setTimeout(() => {  
                    this.fetchData();
                    this.getRootCategories();
                    this.getAllCategories();
                }, 1000);
    
            });
        }
       
    }



    renderCategory() {
        let result = [];
        var i = 0;
        
        for(let item of this.state.categories)
        {   
            i++;
            result.push(
                <div className="category-details" onClick={ () => this.state.type == "Category" ? this.getCategoryByParent(item.id) : "" }>
                    <div className="image">
                        { this.showImage(item) }
                    </div>
                    <div className="category-name">{ item.name }</div>
                    { this.getParentNameOrProductCategory(item) }
                    <div className="number">${this.state.type == "Category" ? item.min_price : item.price }</div>
                    <div className="something"></div>
                    <div className="start-at">
                        {this.state.type === 'Category' && (
                            (item.in_trending ? <img className="trending" src="/images/star1.png" onClick={ () => this.setTrending(item) }></img> :
                                                <img className="trending" src="/images/star2.png" onClick={ () => this.setTrending(item) }></img>
                            )
                        )}
                    </div>
                    <div className="options">
                        <div className="opt" onClick={ () => this.openDropdown(item.id) }><img src="/images/tackice.png"></img></div>
                        <div className={ this.state.dropdownOpen == item.id ? "dropdown active" : "dropdown" }>
                            <div className="dropdown-opt" onClick={ () => this.editItem(item) }>Edit</div>
                            {
                                this.state.type == "Category" && 
                                <div className="dropdown-opt" onClick={ () => this.deleteItem(item.id) }>Delete</div>
                            } 
                        </div>
                    </div>
                </div>
            );
        }

        return result;
    }

    getCategoryByParent(id){

        if(window.event.srcElement.nodeName != "DIV" || window.event.target.className == "opt" 
            || window.event.target.className == "dropdown-opt" || window.event.target.className == "options"
            || window.event.target.className == "start-at") { return; }

        this.setState({
           ...this.state,
           selectedCategory: id,
           page: 1,
           dropdownOpen: null
        });
        setTimeout(() => {  
            this.fetchData();
        }, 100);
    }

    submited(){
        if(!this.validate()) {
            return;
        }

        if(this.state.data.id == null){
            this.addCategory();
        }else{
            this.updateCategory();
        }

        setTimeout(() => {
            this.getAllCategories();
        }, 500)
    }

    addCategory(){

        addCategory(this.state.data).then(response => {
            this.setState({
                ...this.state,
                siderbar: false,
                display: 'none',
                data: {
                    ...this.state.data,
                    categoryImage: null,
                    parentCategory: null,
                    name: null,
                    description: null,
                    id: null,
                    gallery: []
                },
            });

            setTimeout(() => {
                this.fetchData();
                this.getRootCategories();
            }, 1000);
        });
    }

    updateCategory(){
        updateCategory(this.state.data).then(response => {

            this.setState({
                ...this.state,
                siderbar: false,
                display: 'none',
                data: {
                    ...this.state.date,
                    categoryImage: null,
                    parentCategory: null,
                    name: null,
                    description: null,
                    id: null
                },
            });

            setTimeout(() => {  
                this.fetchData();
                this.getRootCategories();
            }, 1000);
        });
    }

    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return;
        }

        if(this.state.page > 5){
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.page + 4) < this.state.totalPages)){
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page == i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    onPageClick(i){
        this.state.page = i;
        this.getCategories(i, this.state.perPage);
    }

    onBack(){
        this.state.page = Number(this.state.page) - 1;
        this.getCategories(this.state.page, this.state.perPage);
    }

    onForward(){
        this.state.page = Number(this.state.page) + 1;
        this.getCategories(this.state.page, this.state.perPage);
    }

    addGalleryImage() {
        let data = this.state.data;
        data.gallery.push('');
        this.setState({
            data: data
        })
    }

    render() {

        return (
            <div id="category">

                <div id="category-overlay" className='overlay' style={{display: this.state.display}} onClick={ () => this.goBack() }></div>
                <div id="siderbar" style={{opacity : this.state.display == "block" ? "1" : "0",
                        right : this.state.display == "block" ? "0" : "-492px",
                        height: document.getElementById("root").offsetHeight + 114 + 'px'}}>
                    {/*<div className={"scroll-wrapper"}>*/}
                        {
                            this.state.siderbar &&
                            <AddCategory
                                data={this.state.data}
                                errors = {this.state.errors}
                                onChange={this.changeData}
                                goBack={this.goBack}
                                submited={this.submited}
                                onDropPicture={this.onDropPicture}
                                categories={this.state.allCategories}
                                addGalleryImage={this.addGalleryImage}
                                onDropGalleryPicture={this.onDropGalleryPicture}
                                deleteGalleryPicture={this.deleteGalleryPicture}
                            />
                        }
                    {/*</div>*/}
                </div>

                <div className="search">
                    <TextField
                        className={"search-field"}
                        autoFocus
                        name='searchTerm'
                        onChange={ this.changeData }
                        margin="normal"
                        value={ this.state.data.searchTerm }
                        placeholder = { "Start typing to search ..." }
                        variant="outlined"
                    />
                    <Button onClick={ () => this.categorySearch() }>{ strings.category.search }</Button>
                </div>
                <div className="button-new">
                    <Button className={"new-product"} onClick={ () => this.props.history.push('/products')}>{ strings.category.createNewProduct }</Button>
                    <Button className={"new-category"} onClick={ () => this.openSiderbar()}>{ strings.category.createNewCategory }</Button>
                </div>

                <div className="categories">
                    <div className={this.state.selectedCategory == -1 ? "category active" : "category"} onClick={ () => this.getCategoryByParent(-1) }>{ strings.category.allCategories }</div>
                    { this.renderRootCategories() }
                </div>
                <div className={'helper'}>
                    <div className="category-list">
                        <div className="header">
                            <div className="image">{ strings.category.image }</div>
                            <div className="category-name">{ this.state.type == "Category" ? "Category Name" : "Product name" }</div>
                            <div className="category">{ this.state.type == "Category" ? "Parent Category" : "Category" }</div>
                            <div className="number">{ strings.category.startAt }</div>
                            <div className="something"></div>
                            <div className="start-at">{ strings.category.trending }</div>
                            <div className="options"></div>
                        </div>

                        { this.renderCategory() }
                    </div>
                </div>

                <div className={'pagination'}>
                    {
                        this.state.page > 1 &&
                        <img onClick={this.onBack} src={'/images/leftFilter.png'} />
                    }
                    <div className={'pages'}>
                        {this.renderPagination()}
                    </div>
                    {
                        this.state.page < this.state.totalPages &&
                        <img onClick={this.onForward} src={'/images/rightFilter.png'} />
                    }
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Category)));
