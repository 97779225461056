import React from 'react';
import { number, oneOfType, string } from 'prop-types';

const IconBox = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11.2576L19 19.2576L20.22 16.5176L2.22 8.51758L1 11.2576ZM21.11 39.8876V17.8876H18.11V39.8876H21.11ZM20.22 19.2576L38.22 11.2576L37 8.51758L19 16.5176L20.22 19.2576Z" fill={color}/>
    <path d="M1.61035 31.1077V10.6677C1.61041 10.4358 1.67769 10.2088 1.80405 10.0143C1.93041 9.81976 2.11044 9.66604 2.32235 9.57171L19.1224 2.10371C19.276 2.03533 19.4422 2 19.6104 2C19.7785 2 19.9447 2.03533 20.0984 2.10371L36.8983 9.57171C37.1103 9.66604 37.2903 9.81976 37.4167 10.0143C37.543 10.2088 37.6103 10.4358 37.6104 10.6677V31.1077C37.6103 31.3397 37.543 31.5666 37.4167 31.7611C37.2903 31.9557 37.1103 32.1094 36.8983 32.2037L20.0984 39.6717C19.9447 39.7401 19.7785 39.7754 19.6104 39.7754C19.4422 39.7754 19.276 39.7401 19.1224 39.6717L2.32235 32.2037C2.11044 32.1094 1.93041 31.9557 1.80405 31.7611C1.67769 31.5666 1.61041 31.3397 1.61035 31.1077Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.6104 5.8877L27.8983 13.5717C28.1103 13.666 28.2903 13.8197 28.4167 14.0143C28.543 14.2088 28.6103 14.4357 28.6104 14.6677V21.8877" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

IconBox.propTypes = {
  width: oneOfType([ number, string ]).isRequired,
  height: oneOfType([ number, string ]).isRequired,
  color: string.isRequired,
}

export default IconBox;