import React from 'react';
import { number, string } from 'prop-types';

const IconCheckmarkBold = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.4699 3.63078C20.8599 4.02078 20.8599 4.64078 20.4699 5.03078L9.12995 16.3708C8.73995 16.7608 8.11995 16.7608 7.72995 16.3708L3.52995 12.1708C3.13995 11.7808 3.13995 11.1608 3.52995 10.7708C3.91995 10.3808 4.53995 10.3808 4.92995 10.7708L8.42995 14.2708L19.07 3.63078C19.4599 3.24078 20.08 3.24078 20.4699 3.63078ZM18.36 1.51078L8.42995 11.4408L5.63995 8.65078C4.85995 7.87078 3.58995 7.87078 2.80995 8.65078L1.40995 10.0508C0.629951 10.8308 0.629951 12.1008 1.40995 12.8808L7.00995 18.4808C7.78995 19.2608 9.05995 19.2608 9.83995 18.4808L22.59 5.74078C23.37 4.96078 23.37 3.69078 22.59 2.91078L21.19 1.51078C20.4 0.730781 19.14 0.730781 18.36 1.51078Z" fill={color}/>
  </svg>
);

IconCheckmarkBold.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired
}

export default IconCheckmarkBold;