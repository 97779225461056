import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { v4 as uuid } from 'uuid';

import { CATEGORIES_TABS } from 'src/redesign/constants';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const AllCategories = ({ history, searchboxText, className, isInApp = true, location: { state: { importedProduct } = {}} }) => {
  const [isHovered, setIsHovered] = React.useState(null);

  return (
    <div className={classNames('all-categories-container', className)}>
        {CATEGORIES_TABS.filter(x => {
          const name = x.text?.toLowerCase();
          if(x.key !== 'all' && name.includes(searchboxText))
            return true;
          return;
        }).map(x => (
          <div className='category' onClick={() => history.push(isInApp ? x.to : x.to.replace('-in-app', '-website'), { importedProduct })} key={uuid()} 
          onMouseLeave={() => setIsHovered(null)} onMouseEnter={() => setIsHovered(x?.key)}>
            <div className='category-image-container'>
              <img alt={getImageAlt()} className='category-image' src={x.image}/>
              <div className='category-text'>
                {x.text}
              </div>
            </div>
              
            <div className={classNames('category-choose-text', {'category-choose-text-hover': isHovered === x.key})}>
              Choose a Category
            </div>
          </div>
        ))}
    </div>
  );
}



export default withRouter(AllCategories);