import React from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router-dom";
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Loader from 'src/redesign/components/Loader';
import Button from 'src/redesign/components/Buttons/SubmitPrimary';
import ImagePicker from 'src/redesign/components/ImagePicker';
import IconCollapse from 'src/redesign/components/Icon/Collapse';
import IconExpand from 'src/redesign/components/Icon/Expand';
import MyArtworksDialog from 'src/redesign/components/Dialog/MyArtworks';
import Editor from './components/Editor';
import InfoImportedProduct from 'src/redesign/components/InfoImportedProduct';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { createImage, getProductVariationImages, getProductVariationsForAttributes } from 'src/services/admin/ProductVariationService';
import { getCustomerArtworkImageById } from 'src/services/UserService';
import { calculateXY } from './helpers';
import useMobile from 'src/redesign/hooks/useMobile';

const ImageEditor = ({ history, location: { state }}) => {
  const { isMobile } = useMobile();
  // handle importedProduct from state
  const importedProduct = state.importedProduct;
  const attributeValues = state.attributeValues;
  const stateCustomerTemplateId = state.customerTemplateId;
  const [isLoading, setIsLoading] = React.useState(false);
  const [variations, setVariations] = React.useState([]);
  const [mmMockups, setMmMockups] = React.useState([]);
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const [product, setProduct] = React.useState(null);
  
  const [isSaving, setIsSaving] = React.useState(false);
  const [selectedMockupId, setSelectedMockupId] = React.useState(null);
  const [isLeftSectionExpanded, setIsLeftSectionExpanded] = React.useState(false);
  const [customerTemplateId, setCustomerTemplateId] = React.useState(null);
  const [isArtworkDialogOpen, setIsArtworkDialogOpen] = React.useState(false);
  const [artwork, setArtwork] = React.useState(null);
  const [loadingArtworkImage, setLoadingArtworkImage] = React.useState(false);

  const categoryId = product?.category?.id;
  const artworkImage = artwork ? process.env.REACT_APP_baseUrl + artwork?.customer_artwork_image_thumbnail?.path : null;
  const artworkWidth = artwork?.original_width ?? 500;
  const artworkHeight = artwork?.original_height ?? 500;

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, []);

  //#region load

  async function load() {
    setIsLoading(true);
    try {
      const { data } = await getProductVariationsForAttributes({
        attributes: state.attributeValues,
        productId: state.product.id,
        templateId: state.templateId ?? null,
        check: false,
        importedProduct: state.importedProduct?.id ?? null
      });

      let cpvs = data.approved;

      const { data: dataImages } = await getProductVariationImages({ variationIds: cpvs.map(x => x.customerProductVariation.product_variation.id) });

      const variations = cpvs.map(v => {
        const variation = v.customerProductVariation.product_variation;
        return {
          productVariationId: variation.id,
          productVariationImageId: dataImages.find(x => x.product_variation_id === variation.id)?.images?.[0]?.product_variation_image?.id,
          customerProductVariationId: v.customerProductVariation.id
        };
      });

      setVariations(variations);
      setProduct(cpvs?.[0]?.variation?.product);
      setSelectedMockupId(cpvs?.[0]?.variation?.media_modifier_mockups?.[0]?.id);
      setCustomerTemplateId(data.template.id);
      setBackgroundImage(dataImages?.[0]?.images?.[0]?.product_variation_image);
      setMmMockups(data.approved?.[0]?.variation?.media_modifier_mockups ?? [])
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load product variations!', false);
    } finally {
      setIsLoading(false);
    }
  }

  async function onArtworkSelect(id) {
    setLoadingArtworkImage(true);
    try {
      setIsArtworkDialogOpen(false);
      const { data } = await getCustomerArtworkImageById(id);
      setArtwork(data);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to select image!', false);
    } finally {
      setLoadingArtworkImage(false);
      setIsArtworkDialogOpen(false);
    }
  }
  
  //#endregion load

  //#region save

  const onSaveChanges = async (thumbnailX = 0, thumbnailY = 0, thumbnailWidth, thumbnailHeight, rotation, stageScale) => {
    if(!backgroundImage) return;
    setIsSaving(true);

    const { x, y } = calculateXY(thumbnailX, thumbnailY, rotation, stageScale, artwork, backgroundImage); 

    // calculate real width and height, getting the ratio of original and rendered zone
    // const width = thumbnailWidth * (originalDropzoneHeight/dropzoneHeight);
    // const height = thumbnailHeight * (originalDropzoneHeight/dropzoneHeight);

    const data = {
      x: Math.floor(x),
      y: Math.floor(y),
      scale: stageScale,
      rotate: -rotation,
      // width,
      // height,
      customerArtworkImageId: artwork.id,
      selectedMockupId: selectedMockupId,
      productVariationData: variations,
    }

    try {
      const response = await createImage(data);

      if(response.data === 'failure') return;

      const prodVarIds = variations.map(x => x.productVariationId);
      const templateId = stateCustomerTemplateId ?? customerTemplateId;

      if(importedProduct !== null) {
        localStorage.setItem('selectedTemplateId', templateId);
        history.push(
          '/import-products/' + localStorage.getItem('storeImportedFrom') + '/' + importedProduct.id,
          { customerTemplate: templateId }
        );
      } else {
        history.push('/template-edit/' + templateId, {
          prodVarIds: prodVarIds,
          importedProduct: importedProduct,
          attributeValues: attributeValues,
          product: product
        })
      }
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to save changes!', false);
    } finally {
      setIsSaving(false);
    }
  }

  //#endregion save

  return (
    <ApplicationPage selectedTab='product-catalog' className='image-editor-container'>
      <ApplicationPageContent title='' className='image-editor'>
        {isLoading ? <Loader className='image-editor-loader-container' color={colors.primary} width={400} height={400} /> : (
        <>
          {importedProduct && (
            <div className='imported-product-container'>
              <InfoImportedProduct {...importedProduct} />
            </div>
          )}
          <div className='image-editor-container'>
            {!isLeftSectionExpanded && <div className='mockup-container'>
              {mmMockups.length > 0 && (
                <div className='mockup-container-top'>
                  <div className='mockup-container-title'>
                    Choose your mockup to render
                  </div>
                  <ImagePicker onSelect={id => setSelectedMockupId(id)} selectedImage={selectedMockupId} images={mmMockups} />
                </div>
              )}
              <Button
                text='Previous Step'
                className='previous-step-button'
                onClick={() => history.push(`/product-selection/${categoryId}`, {
                  importedProduct
                })}
              />
            </div>}
            <div className='editor-container'>
              <div className='editor-container-title'>
                {product?.name}
              </div>
              <div className='editor-container-content'>
                <div
                  className={classNames('editor-container-expandable', {'editor-container-expandable-disabled': isMobile})}
                  onClick={() => setIsLeftSectionExpanded(prev => !prev)}
                >
                  {isLeftSectionExpanded ? <IconCollapse width={32} height={32} color={colors.gray6d} /> : <IconExpand width={32} height={32} color={colors.gray6d} />}
                </div>
                <Button onClick={() => setIsArtworkDialogOpen(true)} text='Upload artwork' width={140} loading={loadingArtworkImage} disabled={loadingArtworkImage}/>
                <Editor
                  isLeftSectionExpanded={isLeftSectionExpanded}
                  backgroundImage={backgroundImage}
                  imageSrc={artworkImage}
                  originalImageWidth={artworkWidth}
                  originalImageHeight={artworkHeight}
                  onSaveChanges={onSaveChanges}
                  isSaving={isSaving}
                />
              </div>
            </div>
          </div>
        </>
        )}
        {isArtworkDialogOpen && (
          <MyArtworksDialog
            onClose={() => setIsArtworkDialogOpen(false)}
            onArtworkSelect={onArtworkSelect}
          />
        )}
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default withRouter(ImageEditor);