import React from 'react';
import AttributeType from 'src/constants/AttributeType';
import { mapFilters } from 'src/redesign/helpers';
import ColorAttribute from 'src/redesign/components/ColorAttribute';
import StandardAttribute from 'src/redesign/components/StandardAttribute';
import Expandable from 'src/redesign/components/Expandable';
import Loader from 'src/redesign/components/Loader';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const FilterPanel = ({ filters, selectedFilters, setSelectedFilters, brandFilters: initialBrandFilters, selectedBrandFilters, setSelectedBrandFilters , loading, isDataLoading }) => {
  const filtersGrouped = mapFilters(filters);
  const brandFilters = [... initialBrandFilters];

  const isFilterSelected = id => selectedFilters.some(x => x === id);
  const onFilterClick = id => isFilterSelected(id)
    ? setSelectedFilters(selectedFilters.filter(x => x !== id))
    : setSelectedFilters([...selectedFilters, id]);

  const isBrandSelected = id => selectedBrandFilters.some(x => x === id);
  const onBrandFilterClick = id => isBrandSelected(id)
    ? setSelectedBrandFilters(selectedBrandFilters.filter(x => x !== id))
    : setSelectedBrandFilters([...selectedBrandFilters, id]);

  const renderAttribute = ({ name, id, customerColorName }, type, onClick, isSelected) => (
    type === AttributeType.STRING ? (
        <StandardAttribute
          key={id}
          name={name}
          selected={isSelected(id)}
          onClick={() => onClick(id)}
          disabled={isDataLoading}
        />
      )
      : (
        <ColorAttribute
          key={id}
          className='filter-value'
          noSelectedStyle
          color={name}
          colorName={customerColorName}
          selected={isSelected(id)}
          onClick={() => onClick(id)}
          disabled={isDataLoading}
        />
  ));

  return <div className='filter-panel'>
    
    {loading ? (
      <Loader
        width={100}
        height={100}
        color={colors.primary}
      />
     )
      : 
      <>
      <div className='filter-container' key='brand-filters'>
        <div className='filter-title'>
          Brand
        </div>
        <div className='filter-values'>
          {brandFilters.length <= 5  ? (
            brandFilters.map((x) => renderAttribute(x, AttributeType.STRING, onBrandFilterClick, isBrandSelected))
          ) : (
            <>
              {brandFilters.splice(0, 5).map((x) => renderAttribute(x, AttributeType.STRING, onBrandFilterClick, isBrandSelected))}
              <Expandable className='filter-values' labelClassName='expandable-label'>
                {brandFilters.slice(5).map((x) => renderAttribute(x, AttributeType.STRING, onBrandFilterClick, isBrandSelected))}
              </Expandable>
            </>
          )}
        </div>
      </div>
      {filtersGrouped.map(({ name, values, type }, i) => (
      <div className='filter-container' key={i}>
        <div className='filter-title'>
          {name}
        </div>
        <div className='filter-values'>
          {values.length <= 5
          ? 
            values.map((x, i) => renderAttribute(x, type, onFilterClick, isFilterSelected))
          : 
            <>
              {values.slice(0, 5).map((x) => renderAttribute(x, type, onFilterClick, isFilterSelected))}
              <Expandable className='filter-values' labelClassName='expandable-label'>
                {values.slice(5).map((x) => renderAttribute(x, type, onFilterClick, isFilterSelected))}
              </Expandable>
            </>
          }
        </div>
      </div>
      ))}
      </>
      }
  </div>
}

export default FilterPanel;