import React, {Component} from 'react'

import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import {Button, TextField} from "@material-ui/core";
import SelectControl from "../controls/SelectControl";
import Validators from "../../constants/ValidatorTypes";
import {getCountryList} from "../../services/CountryService";
import { setBusinessInformation } from "../../services/BusinessInformation";
import {getUserFromLocalStorage} from "../../base/OAuth";

class BusinessInformation extends Page {

    validationList = {
        businessAddress: [ {type: Validators.REQUIRED } ],
        company: [ {type: Validators.REQUIRED } ],
        city: [ {type: Validators.REQUIRED } ],
        stateProvince: [ {type: Validators.REQUIRED } ],
        country: [ {type: Validators.REQUIRED } ],
        postalCode: [ {type: Validators.REQUIRED } ],
        phoneNumber: [ {type: Validators.REQUIRED } ],
        email: [ {type: Validators.REQUIRED } ],
    };

    constructor(props) {
        super(props);

        const user = getUserFromLocalStorage();

        this.state = {
            customer : user.customer,
            data: {
                businessAddress : user.customer ? user.customer.business_information.business_address : '',
                company: user.customer ? user.customer.company_name : '',
                addressLineTwo: user.customer ? user.customer.business_information.address_line_two : '',
                city: user.customer ? user.customer.business_information.city : '',
                stateProvince: user.customer ? user.customer.business_information.state_province : '',
                country: user.customer ? user.customer.business_information.country : '',
                postalCode: user.customer ? user.customer.business_information.postal_code : '',
                phoneNumber: user.customer ? user.customer.business_information.phone_number : '',
                email: user.customer ? user.customer.business_information.email : ''
            },
            countries: [],
        }

        this.setBusinessInformation = this.setBusinessInformation.bind(this);

    }

    componentDidMount(){
        this.getCountryList();
    }

    getCountryList(){
        getCountryList().then(response => {
           
             this.setState({
                 ...this.state,
                countries: response.data.countries
            })
        });
    }

    setBusinessInformation(){

        if (!this.validate()) {
            this.props.enqueueSnackbar("Please fill all required fields!", {variant: 'error'});
            return;
        }

        setBusinessInformation(this.state.data).then(response => {
           
            if(!response.ok) {
                return;
            }
            
            window.location.reload();
            this.props.enqueueSnackbar('Successfully saved!', { variant: 'success' });
       });
    }

    render() {

        return (
            <div id="business-information">
                <div className="content">
                    <div className="my-profile-header">Bussines information</div>
                    <TextField
                        required
                        className={"search-field"}
                        autoFocus
                        name='company'
                        onChange={ this.changeData }
                        margin="normal"
                        value={ this.state.data.company }
                        placeholder = { "Company name" }
                        InputLabelProps={{ shrink: true }}
                        label="Company name"
                        variant="outlined"
                    />
                    <TextField
                        required
                        className={"search-field"}
                        name='businessAddress'
                        onChange={ this.changeData }
                        margin="normal"
                        value={ this.state.data.businessAddress }
                        InputLabelProps={{ shrink: true }}
                        label="Address line 1"
                        placeholder = { "Address Line 1" }
                        variant="outlined"
                    />
                    <TextField
                        className={"search-field"}
                        name='addressLineTwo'
                        onChange={ this.changeData }
                        margin="normal"
                        value={ this.state.data.addressLineTwo }
                        InputLabelProps={{ shrink: true }}
                        label="Address line 2"
                        placeholder = { "Address Line 2" }
                        variant="outlined"
                    />
                    <div className="row">
                        <TextField
                            required
                            className={"search-field"}
                            name='city'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.city }
                            InputLabelProps={{ shrink: true }}
                            label="City"
                            placeholder = { "City" }
                            variant="outlined"
                        />
                        <TextField
                            required
                            className={"search-field"}
                            name='stateProvince'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.stateProvince }
                            InputLabelProps={{ shrink: true }}
                            label="State/Province/Region"
                            placeholder = { "State/Province/Region" }
                            variant="outlined"
                        />
                    </div>
                    <div className="row">
                        <SelectControl
                            required
                            className='payment-country'
                            options={this.state.countries}
                            valueKey = { 'id' }
                            onChange={ this.changeData }
                            nameKey= { 'name' }
                            name = {'country'}
                            selected={this.state.data.country}
                            placeholder = {'Country'}
                        />
                        <TextField
                            required
                            className={"search-field"}
                            name='postalCode'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.postalCode }
                            InputLabelProps={{ shrink: true }}
                            label="Postal code"
                            placeholder = { "Postal Code" }
                            variant="outlined"
                        />
                    </div>
                    <div className="row">
                        <TextField
                            required
                            className={"search-field"}
                            name='phoneNumber'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.phoneNumber }
                            InputLabelProps={{ shrink: true }}
                            label="Phone number"
                            placeholder = { "Phone Number" }
                            variant="outlined"
                        />
                        <TextField
                            required
                            className={"search-field"}
                            name='email'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.email }
                            InputLabelProps={{ shrink: true }}
                            label="Email address"
                            placeholder = { "Your Email Address" }
                            variant="outlined"
                        />
                    </div>
                    <div className="row">
                        <Button onClick={ () => this.setBusinessInformation() }>Save changes</Button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessInformation)));
