const OrderDetailPage = {
    SUMMARY: 0,
    ITEM: 1,
    STATUS: 2,
    BILLING: 3,
    SHIPMENT: 4,
    HISTORY: 5,
    PRINT_READY: 6
};

export default OrderDetailPage;