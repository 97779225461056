import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";

export async function resetPasswordRequest(data) {
    return await request('/api/user/password/reset', data, HttpMethod.POST);
}

export async function connectEtsy() {
    return await request('/api/user/etsy/connect', [], HttpMethod.POST);
}

export async function addEtsyOauthParams(data) {
    return await request('/api/user/etsy/authorization-code', data, HttpMethod.POST);
}

export async function disconnectShopify() {
    return await request('/api/user/shopify/disconnect', [], HttpMethod.POST);
}

export async function disconnectEtsy() {
    return await request('/api/user/etsy/disconnect', [], HttpMethod.POST);
}

export async function addShopifyToken(data) {
    return await request('/api/user/add_shopify_token', data, HttpMethod.POST);
}

export async function resetPassword(data) {
    return await request('/api/user/password/reset_form', data, HttpMethod.POST);
}

export async function registration(data) {
    return await request('/api/user/register', data, HttpMethod.POST);
}

export async function getCustomersArtwork(data) {
    //deprecated
    return await request('/api/get_customers_artwork', data, HttpMethod.POST);
}
export async function getCustomersArtworkThumbnails(data) {
    return await request('/api/get_customers_artwork_thumbnails', data, HttpMethod.POST);
}

export async function deleteCustomerArtworkImage(data) {
    return await request('/api/delete_customer_artwork_image/' + data, [], HttpMethod.DELETE);
}
export async function getCustomerArtworkImageById(data) {
    return await request('/api/get_customer_artwork_image/' + data, [], HttpMethod.GET);
}

export async function getCurrentUserAction() {
    return await request('/api/user/current', [], HttpMethod.GET);
}

export async function setCustomerProfileInfo(data) {
    return await request('/api/set_customer_profile_info', data, HttpMethod.POST);
}

export async function setStoreUrl(data) {
    return await request('/api/user/set_store_url', data, HttpMethod.POST);
}

export async function changeUserPassword(data) {
    return await request('/api/user/change-password', data, HttpMethod.POST);
}

export async function changeHoldCustomization() {
    return await request('/api/change_hold_customization', [], HttpMethod.PUT);
}

export async function getCustomers(data) {
    return await request('/api/get_user_customers', data, HttpMethod.GET);
}

export async function getAdmins(data) {
    return await request('/api/get_user_admins', data, HttpMethod.GET);
}

export async function createAdmin(data) {
    return await request('/api/create_admin', data, HttpMethod.POST);
}

export async function deleteUser(data) {
    return await request('/api/delete_user', data, HttpMethod.PUT);
}

export async function getAllCustomers() {
    return await request('/api/get_all_customers', [], HttpMethod.GET);
}

export async function deactivateUser(data = []) {
    return await request('/api/user/deactivate', data, HttpMethod.PUT);
}

export async function submitContactForm(data) {
    return await request('/api/submit_contact_form', data, HttpMethod.POST);
}

export async function deleteShopifyCollection(data) {
    return await request('/api/delete_shopify_collection', data, HttpMethod.POST);
}

export async function deleteCustomerProductVariation(id, templateId) {
    return await request('/api/delete_customer_product_variation/'+id+'/'+templateId, [], HttpMethod.GET);
}

export async function uploadProfileImage(data) {
    return await request('/api/user/profile_image/upload', data, HttpMethod.POST);
}
