import React, {Component} from 'react'

import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import { getOrderStatus } from "../../services/OrderService";
import { getOrderStatusString } from "../../constants/OrderStatus";
import {dateToString} from "../../util/DateUtil";

class History extends Page {

    constructor(props) {
        super(props);

        this.state = {
            order: this.props.order,
            page : 1,
            perPage : 12,
        }

        this.onPageClick = this.onPageClick.bind(this);
        this.onForward = this.onForward.bind(this);
        this.onBack = this.onBack.bind(this);
    }

    componentDidMount() {}

    fetchData(){
        this.getOrderStatus(this.state.page, this.state.perPage);
    }

    getOrderStatus(page, perPage){
        getOrderStatus({
            orderId: this.state.order.id,
            page : page, 
            perPage : perPage,
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                items: response.data.result,
                type: response.data.type,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                page : response.data.page
            })
        });
    }

    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return;
        }

        if(this.state.page > 5){
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.page + 4) < this.state.totalPages)){
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page == i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    onPageClick(i){
        this.state.page = i;
        this.getOrderStatus(i, this.state.perPage);
    }

    onBack(){
        this.state.page = Number(this.state.page) - 1;
        this.getOrderStatus(this.state.page, this.state.perPage);
    }

    onForward(){
        this.state.page = Number(this.state.page) + 1;
        this.getOrderStatus(this.state.page, this.state.perPage);
    }

    renderItems() {
        let result = [];
        
        for(let item of this.state.items) {
            result.push(
                <div className="history-item">
                    <div className="order-id1">{item.orderId}</div>
                    <div className="item-id1">{ item.orderItemId ?? '' }</div>
                    <div className="user">{item.userId ?? '-'}</div>
                    <div className="status">{ getOrderStatusString(item.status) }</div>
                    <div className="comment">{ item.comment }</div>
                    <div className="date">{ dateToString(item.createdAt) }</div>
                </div>
            );
        }


        return result;
    }


    render() {

        if(!this.state.items){
            return "";
        }

        return (
            <div id="history">
                <div className="history-header">
                   <div className="order-id1">Order ID</div>
                   <div className="item-id1">Item ID</div>
                   <div className="user">User</div>
                   <div className="status">Status</div>
                   <div className="comment">Comment</div>
                   <div className="date">Date</div>
                </div>
               

                { this.renderItems() }

                <div className={'pagination'}>
                    {
                        this.state.page > 1 &&
                        <img onClick={this.onBack} src={'/images/leftFilter.png'} />
                    }
                    <div className={'pages'}>
                        {this.renderPagination()}
                    </div>
                    {
                        this.state.page < this.state.totalPages &&
                        <img onClick={this.onForward} src={'/images/rightFilter.png'} />
                    }
                </div>

            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(History)));
