const OrderEnum = {
    NewOrder: 0,
    NeedsCustomization: 1,
    Pending: 4,
    ReadyForPrint: 9,
    Cancel: 7,
    InProduction: 10,
    ManuallyInProduction: 11,
    Shipped: 15,
    Issues: -1,
    GarmentsReceived: 30,
    PartiallyReceived: 31
};

export default OrderEnum;