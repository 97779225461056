export const calculateXY = (thumbnailX, thumbnailY, rotation, scale, artwork, backgroundImage) => {
  const originalDropzoneWidth = backgroundImage.real_dropzone_width;
  const originalDropzoneHeight = backgroundImage.real_dropzone_height;

  const dropzoneWidth = backgroundImage.dropzone_width;
  const dropzoneHeight = backgroundImage.dropzone_height;

  const originalWidth = artwork.original_width;
  const originalHeight = artwork.original_height;

  let centerX, centerY;

  switch(rotation) {
    case 0:
      centerX = 0;
      centerY = 0;
      break;
    case 90:
      centerX = - (originalHeight * scale);
      centerY = 0;
      break;
    case 180:
      centerX = - (originalWidth * scale);
      centerY = - (originalHeight * scale);
      break;
    case 270:
      centerX = 0;
      centerY = - (originalWidth * scale);
      break;
  }

  // calculating real x and y, getting the ratio of original and rendered zone
  const x = (originalDropzoneWidth * (thumbnailX/dropzoneWidth)) + centerX;
  const y = (originalDropzoneHeight * (thumbnailY/dropzoneHeight)) + centerY;

  return { x, y };
}