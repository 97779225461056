import React from 'react';

import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const PrintingMethodsContainer = ({ imageText, title, children }) => (
  <div className="printing-methods-container">
    <div className='printing-methods-image-container'>
      <Image alt={getImageAlt()} className='printing-methods-image' src='/images/printing-methods.png' />
      <div className='printing-methods-text-container'>
        <h1 className='printing-methods-text'>
          {imageText}
        </h1>
      </div>
    </div>
    <h2 className='printing-methods-title'>
      {title}
    </h2>
    {children}
  </div>
)

export default PrintingMethodsContainer;