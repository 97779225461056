import React from 'react';
import classNames from 'classnames';

import './style.scss';

const GroupBy = ({ options, selectedGroupBy, setSelectedGroupBy }) => {

  return (
    <div className='group-by-navigation'>
      {options.map(x => (
        <div className={classNames('group-by-option', {'group-by-option-selected': selectedGroupBy === x?.key})} onClick={() => setSelectedGroupBy(x?.key)}>
          {x?.text}
        </div>
      ))}
    </div>
  )
}

export default GroupBy;