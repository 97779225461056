import React from 'react';
import { oneOfType, number, string } from 'prop-types';

const IconCheckmark = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 6.13809L5.10357 9.24167L12.3452 2" stroke={color} strokeWidth="2.48286" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

IconCheckmark.propTypes = {
  width: oneOfType([number, string]).isRequired,
  height: oneOfType([number, string]).isRequired,
  color: string.isRequired
}

export default IconCheckmark;