import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { number } from 'prop-types';

import PriceInput from 'src/redesign/components/Form/Sidepane/PriceInput';
import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import * as Validators from 'src/redesign/util/validators';
import { editMultiVariationPrice } from 'src/services/admin/ProductVariationService';

import './style.scss';

const ProductVariationPrice = ({ price, vendorPrice, productVariationIds, onLoad }) => {

  const onUpdatePrice = async (values) => {
    await editMultiVariationPrice({
      ids: productVariationIds,
      ...values
    });
    onLoad();
  }

  return (
    <FinalForm
      onSubmit={onUpdatePrice}
      initialValues={{
        price,
        vendorPrice
      }}
      render={({ handleSubmit, valid, submitting }) => (
        <form onSubmit={handleSubmit} className='edit-variation-price-content-container'>
          <PriceInput
            name='price'
            placeholder='Price'
            label='Price'
            containerClassName='edit-variation-price-container'
            labelClassName='edit-variation-price-label'
            className='edit-variation-price-input'
            validate={Validators.required(' ')}
          />
          <PriceInput
            name='vendorPrice'
            label='Vendor Price'
            placeholder='Vendor Price'
            containerClassName='edit-variation-price-container'
            labelClassName='edit-variation-price-label'
            className='edit-variation-price-input'
            validate={Validators.required(' ')}
          />
          <SubmitPrimaryButton
            text='Save'
            width={224}
            className='edit-variation-price-submit'
            type='submit'
            loading={submitting}
            disabled={!valid || submitting}
          />
        </form>
      )}
    />
  )
}

ProductVariationPrice.propTypes = {
  price: number,
  vendorPrice: number
}

export default ProductVariationPrice;