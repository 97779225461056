import React from 'react';
import strings from '../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../functions/Validation";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const RegistrationForm = ({
    onSubmit,
    onChange,
    errors,
    data,
    keyPress,
    countries,
    handleSelectChange,
    changeCheckBox,
    setData,
    props
}) => (
    <form id="registration-form" onSubmit={ onSubmit } action = "#">
        {/*<div className="registration-logo"></div>*/}
        <div className="registration-title">{ strings.registration.signUp }</div>
        <TextField
            className={"text-field"}
            error={ hasError(errors, 'firstName') }
            helperText={ getError(errors, 'firstName') }
            autoFocus
            name='firstName'
            onChange={ onChange }
            onKeyPress={ keyPress }
            value={ data.firstName }
            placeholder = { strings.registration.fistName }
            label={ strings.registration.fistName }
            variant="outlined"
        />

        <TextField
            className={"text-field"}
            error={ hasError(errors, 'lastName') }
            helperText={ getError(errors, 'lastName') }
            fullWidth
            name='lastName'
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            value={ data.lastName }
            placeholder = { strings.registration.lastName }
            label={ strings.registration.lastName }
            variant="outlined"
        />

        <TextField
            className={"text-field"}
            error={ hasError(errors, 'email') }
            helperText={ getError(errors, 'email') }
            fullWidth
            name='email'
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            value={ data.email }
            placeholder = { strings.registration.email }
            label={ strings.registration.email }
            variant="outlined"
        />

        <TextField
            error={ hasError(errors, 'password') }
            helperText={ getError(errors, 'password') }
            name='password'
            type='password'
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            value={ data.password }
            placeholder = { strings.login.password }
            label={ strings.login.password }
            variant="outlined"
        />


        <FormControl variant="outlined" className="select-margin">
            <InputLabel id="demo-simple-select-outlined-label">{ strings.registration.country }</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={data.country}
                onChange={handleSelectChange}
                label={ strings.registration.country }
                name={"country"}
                error={ hasError(errors, 'country') }
                helperText={ getError(errors, 'country') }
            >
                {countries}
            </Select>
        </FormControl>

        <TextField
            className={"text-field"}
            error={ hasError(errors, 'companyName') }
            helperText={ getError(errors, 'companyName') }
            fullWidth
            name='companyName'
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            value={ data.companyName }
            placeholder = { strings.registration.companyName }
            label={ strings.registration.companyName }
            variant="outlined"
        />

        <TextField
            className={"text-field"}
            error={ hasError(errors, 'city') }
            helperText={ getError(errors, 'city') }
            fullWidth
            name='city'
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            value={ data.city }
            placeholder = { strings.registration.city }
            label={ strings.registration.city }
            variant="outlined"
        />

        <TextField
            className={"text-field"}
            error={ hasError(errors, 'phoneNumber') }
            helperText={ getError(errors, 'phoneNumber') }
            fullWidth
            name='phoneNumber'
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            value={ data.phoneNumber }
            placeholder = { strings.registration.phoneNumber }
            label={ strings.registration.phoneNumber }
            variant="outlined"
        />

        <TextField
            className={"text-field"}
            error={ hasError(errors, 'storeUrl') }
            helperText={ getError(errors, 'storeUrl') }
            fullWidth
            name='storeUrl'
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            value={ data.storeUrl }
            placeholder = { strings.registration.storeUrl }
            label={ strings.registration.storeUrl }
            variant="outlined"
        />

        <div className="terms">
            <Checkbox
                checked={data.agree}
                onChange={changeCheckBox}
                name="agree"
                color="primary"
            />
            <div className="terms-text" style={{ color: data.agreeColorRed ? "red" : "" }}>{ strings.login.text1 } <a style={{ cursor: "pointer" }} onClick={ () => { setData(); props.history.push('/terms-of-service'); }}>{ strings.login.terms }</a> and <a style={{ cursor: "pointer" }} onClick={ () => { setData(); props.history.push('/privacy-policy'); }}>{ strings.login.policy }</a>.</div>
        </div>

        <div className='submit-container'>
            <Button className={'login-button'} onClick={ () => this.goToLogin() }>{ strings.registration.login }</Button>

            <Button variant="contained" color="primary" onClick={ onSubmit }>
                { strings.registration.signUp }
            </Button>
        </div>
    </form>
);

export default RegistrationForm;
