import React from 'react';
import {Alert} from "@mui/lab";

import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const InfoImportedProduct = ({ image, name, variants }) => {

  return (
    <Alert severity="info" className='info-imported-product' icon={<Image alt={getImageAlt()} src={image} className='alert-image'/>}>
      You are creating template for imported product. <br/>
      Name: {name}<br />
      Variants ({variants.length}): {variants.map(x => x.attributes).join(', ')}</Alert>
  )
}

export default InfoImportedProduct;