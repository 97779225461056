import React from 'react';
import { oneOfType, number, string } from 'prop-types';

const IconTool = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.6763 6.98219L31.0163 1.32219C30.8313 1.13679 30.6115 0.98969 30.3696 0.889328C30.1276 0.788965 29.8683 0.737305 29.6063 0.737305C29.3444 0.737305 29.085 0.788965 28.8431 0.889328C28.6011 0.98969 28.3814 1.13679 28.1963 1.32219L19.2163 10.3022L11.4363 2.52219C9.87633 0.962193 7.33633 0.962193 5.77633 2.52219L1.97633 6.32219C0.416328 7.88219 0.416328 10.4222 1.97633 11.9822L9.75633 19.7622L0.736328 28.7822V37.2622H9.21633L18.2563 28.2222L26.0363 36.0022C27.9363 37.9022 30.4963 37.2022 31.6963 36.0022L35.4963 32.2022C37.0563 30.6422 37.0563 28.1022 35.4963 26.5422L27.7163 18.7622L36.6963 9.78219C37.0635 9.40747 37.2675 8.90268 37.2637 8.37807C37.26 7.85345 37.0488 7.35164 36.6763 6.98219ZM4.81633 9.14219L8.59633 5.34219L11.1363 7.88219L8.77633 10.2622L11.5963 13.0822L13.9763 10.7022L16.3763 13.1022L12.5763 16.9022L4.81633 9.14219ZM7.55633 33.2622H4.73633V30.4422L23.9563 11.2222L26.5563 13.8222L26.7763 14.0422L7.55633 33.2622ZM27.7363 29.2222L30.1163 26.8422L32.6563 29.3822L28.8563 33.1822L21.0763 25.4022L24.8763 21.6022L27.2763 24.0022L24.8963 26.3822L27.7363 29.2222ZM29.6163 11.2222L26.7963 8.40219L29.6163 5.58219L32.4363 8.40219L29.6163 11.2222Z" fill={color}/>
  </svg>
);

IconTool.propTypes = {
  width: oneOfType([ number, string ]).isRequired,
  height: oneOfType([ number, string ]).isRequired,
  color: string.isRequired,
}

export default IconTool;