import React from 'react';
import { number, string } from 'prop-types';

const IconChevronLeft = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5 1L1 5L5 9" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

IconChevronLeft.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired
}

export default IconChevronLeft;