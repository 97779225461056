import React from 'react';
import { number, string } from 'prop-types';

const IconCart = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.19063 8.25H10.4996H18.0561C18.3973 8.25 18.6383 8.58435 18.5304 8.90811L17.2056 12.8825C16.9334 13.6991 16.1691 14.25 15.3083 14.25H10.9405C10.08 14.25 9.3159 13.6996 9.04341 12.8833L7.71636 8.90833C7.60826 8.58453 7.84926 8.25 8.19063 8.25Z" fill="#E4E6F1"/>
  <path d="M9 17C9 16.4477 9.44772 16 10 16C10.5523 16 11 16.4477 11 17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17Z" fill={color}/>
  <path d="M15 17C15 16.4477 15.4477 16 16 16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18C15.4477 18 15 17.5523 15 17Z" fill={color}/>
  <path d="M5.25 6.75H6.40088C6.61065 6.75 6.79811 6.88093 6.87032 7.07788L9.01913 12.9385C9.30797 13.7263 10.0578 14.25 10.8969 14.25H15.3086C16.1694 14.25 16.9337 13.6991 17.206 12.8825L18.5307 8.90811C18.6387 8.58435 18.3977 8.25 18.0564 8.25H10.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

IconCart.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired
}

export default IconCart;