import React, {Component} from 'react'

import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import {Button, TextField} from "@material-ui/core";
import SelectControl from "../controls/SelectControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ChangeStatusButton from "../../constants/ChangeStatusButton";
import { getOrderStatusString } from "../../constants/OrderStatus";
import OrderStatus from "../../constants/OrderStatus";
import update from 'immutability-helper';
import {changeOrderItemStatus, updateShopifyStatus} from "../../services/OrderService";
import Validators from "../../constants/ValidatorTypes";
import {getUserFromLocalStorage} from "../../base/OAuth";
import UserType from '../../constants/UserType';
import OrderEnum from "../../constants/OrderEnum";
import {getPrimaryMethod} from "../../services/BillingInformation";

class Status extends Page {

    validationList = {
        currentStatus: [ {type: Validators.REQUIRED } ],
        comment: [ {type: Validators.REQUIRED } ],
    };

    constructor(props) {
        super(props);

        this.state = {
            order: this.props.order,
            orderItems: this.props.order.items,
            activeButton: ChangeStatusButton.ORDER,
            selectedItems: {},
            selectedAll: false,
            data: {
                currentStatus: this.props.order.status,
                comment: ""
            },
            userType: getUserFromLocalStorage().user_type
        }

        this.selectAll = this.selectAll.bind(this);
        this.selectOne = this.selectOne.bind(this);
        this.changeData = this.changeData.bind(this);
        this.setNewState = this.setNewState.bind(this);
        this.changeDataStatus = this.changeDataStatus.bind(this);
    }

    componentDidMount(){
        let selectedItems = {};
        this.state.orderItems.forEach(item => {
            selectedItems[item.id] = false;
        });

        this.setState({
            ...this.state,
            selectedItems: selectedItems
        })
    }



    changeDataStatus(event, data = 'data') {
        if((this.state.data.currentStatus === 9 || this.state.data.currentStatus.value === 29) && (event.target.value.value === 0 || event.target.value.value === 1)){
            return
        }
        if((this.state.data.currentStatus === 29 || this.state.data.currentStatus.value === 29) && (event.target.value.value !== 7)){
            return
        }
        if(this.state.data.currentStatus === 7 || this.state.data.currentStatus.value === 7){
            return
        }

        this.setState({
            [data]: update(this.state[data], { [event.target.name]: {$set: event.target.value} })
        });
        
    }

    clickButton(){
        if(this.state.activeButton == ChangeStatusButton.ORDER){
            this.setState({
                ...this.state,
                activeButton:  this.state.userType === UserType.ADMIN ? ChangeStatusButton.ITEM : ChangeStatusButton.ORDER,
            });
        }else{
            this.setState({
                ...this.state,
                activeButton: this.state.activeButton == ChangeStatusButton.ORDER ? ChangeStatusButton.ITEM : ChangeStatusButton.ORDER
            });
        }
    }

    selectAll(){
        let selectedItems = {};
        this.state.orderItems.forEach(item => {
            if(this.state.selectedAll){
                selectedItems[item.id] = false;
            }else{
                selectedItems[item.id] = true;
            }
        });

        this.setState({
            ...this.state,
            selectedItems: selectedItems,
            selectedAll: !this.state.selectedAll
        })
    }

    selectOne(id){
        let selectedItems = this.state.selectedItems;
        selectedItems[id] = !selectedItems[id];
        this.setState({
            ...this.state,
            selectedItems: selectedItems
        })
    }

    setNewState(){

        if(!this.validate()) {
            this.props.enqueueSnackbar("You need to entered required fields!", {variant: 'error'});
            return;
        }

        if(this.state.activeButton == ChangeStatusButton.ORDER){
            changeOrderItemStatus({
                orderId: this.state.order.id,
                status: this.state.data.currentStatus.value,
                comment: this.state.data.comment
            }).then(response => {

                if(!response.ok) {
                    this.props.enqueueSnackbar("Customer has no billing information", {variant: 'error'});
                    return;
                }

                var orderItems = this.state.orderItems;
                for(let i = 0; i < orderItems.length; i++){
                    orderItems[i].status = this.state.data.currentStatus.value;
                }

                this.setState({
                    ...this.state,
                    orderItems: orderItems
                });



                this.props.enqueueSnackbar("Successfully changed status!", {variant: 'success'});
    
            });
        }else{
            let num = 0;
            for(let [key, value] of Object.entries(this.state.selectedItems)){
                if(value){
                    num ++;
                    changeOrderItemStatus({
                        orderItemId: Number(key),
                        orderId: this.state.order.id,
                        status: this.state.data.currentStatus.value,
                        comment: this.state.data.comment
                    }).then(response => {

                        if(!response.ok) {
                            return;
                        }
                    });
                }
            }

            if(num != 0){
                this.updateOrderItemStatus();
                setTimeout(() => {
                    this.props.enqueueSnackbar("Successfully changed status!", {variant: 'success'});
                }, 300);
            }else{
                this.props.enqueueSnackbar("You need to select item!", {variant: 'error'});
            }
        }

        if (this.state.data && this.state.data.currentStatus && this.state.data.currentStatus.name === 'Shipped'){
            updateShopifyStatus({id : this.state.order.id}).then(response => {
                if (!response.ok){
                    return
                }
            })
        }
    }

    updateOrderItemStatus(){

        let items = this.state.orderItems;
        for(let [key, value] of Object.entries(this.state.selectedItems)){
            if(value){
                for(let item of items){
                    if(item.id == Number(key)){
                        item.status = this.state.data.currentStatus.value;
                        break;
                    }
                }
            }
        }

        this.setState({
            ...this.state,
            orderItems: items
        });
    }

    getAvailableStatuses() {
        if (this.state.order.status === OrderEnum.NeedsCustomization) {
            return [
                {
                    value: 7,
                    name: 'Canceled'
                },
                {
                    value: 9,
                    name: "Ready For Print"
                }
            ]
        } else if (this.state.order.status === OrderEnum.NewOrder) {
            return [
                {
                    value: 7,
                    name: 'Canceled'
                },
                {
                    value: 9,
                    name: "Ready For Print"
                }
            ]
        }
        else {
            return [
                {
                    value: 3,
                    name: 'Needs Manual Approval'
                },
                {
                    value: 4,
                    name: 'Pending'
                },
                {
                    value: 5,
                    name: 'Test'
                },
                {
                    value: 6,
                    name: 'Hold'
                },
                {
                    value: 7,
                    name: 'Canceled'
                },
                {
                    value: 8,
                    name: 'Ready For Accounting'
                },
                {
                    value: 9,
                    name: 'Ready For Print'
                },
                {
                    value: 10,
                    name: 'In Production'
                },
                {
                    value: 11,
                    name: 'Manually In Production'
                },
                {
                    value: 12,
                    name: 'Printing'
                },
                {
                    value: 13,
                    name: 'Cancel Refunded'
                },
                {
                    value: 14,
                    name: 'Billed Refunded'
                },
                {
                    value: 15,
                    name: 'Shipped'
                },
                {
                    value: 16,
                    name: 'Out For Delivery'
                },
                {
                    value: 17,
                    name: 'Delivered'
                },
                {
                    value: 18,
                    name: 'Pre Payment'
                },
                {
                    value: 19,
                    name: 'Payment Issue'
                },
                {
                    value: 20,
                    name: 'Order Status'
                },
                {
                    value: 21,
                    name: 'Image Issue'
                },
                {
                    value: 22,
                    name: 'Hold By Image Issue'
                },
                {
                    value: 23,
                    name: 'Address Issue'
                },
                {
                    value: 24,
                    name: 'Order Data Issue'
                },
                {
                    value: 25,
                    name: 'Vendor API Issue'
                },
                {
                    value: 26,
                    name: 'Shipping Issue'
                },
                {
                    value: 27,
                    name: 'Unfinished'
                },
                {
                    value: 28,
                    name: 'Draft'
                },
                {
                    value: 29,
                    name: "Payment Failed"
                }
            ]
        }

    }

    renderItems() {
        let result = [];
        
        for (let item of this.state.orderItems) {
            result.push(
                <div className="ored-item">
                    <div className="check">
                        <Checkbox
                            checked={this.state.selectedItems[item.id]}
                            onChange={() => this.selectOne(item.id)}
                            name="saveAddress"
                            color="primary"
                        />
                    </div>

                    <div className="item">
                        <img src={item.image ?? "/images/no-image.png"}></img>
                    </div>

                    <div className="status">{ getOrderStatusString(item.status) }</div>
                    <div className="name">{ item.sku }</div>
                </div>
            );
        }
        return result;
    }

    render() {

        return (
            <div id="status">
                <div className="row">
                   <Button className={this.state.activeButton == ChangeStatusButton.ORDER ? "change-button active" : "change-button"}
                        onClick={() => this.clickButton()}>Change Order Status</Button>
                    {
                        this.state.userType === UserType.ADMIN && this.state.data.currentStatus >= 9 &&
                        <Button className={this.state.activeButton == ChangeStatusButton.ITEM ? "change-button active" : "change-button"}
                                onClick={() => this.clickButton()}>Change Item Status</Button>
                    }

                </div>

                <div className="row"> 
                    <div className="change-status">
                        <div className="details-header">
                            {
                                this.state.activeButton == ChangeStatusButton.ORDER &&
                                "Change Order Status"
                            }
                            {
                                this.state.activeButton == ChangeStatusButton.ITEM &&
                                "Change Item Status"
                            }
                        </div>
                        <div className="status">
                            <div className="name">Update Status</div>
                            <SelectControl
                                className='select'
                                options={this.getAvailableStatuses()}
                                valueKey = { 'value' }
                                onChange={ this.changeDataStatus }
                                nameKey= { 'name' }
                                name = {'currentStatus'}
                                selected={this.state.data.currentStatus}
                                placeholder = {'Status'}
                                disabled = {
                                    (
                                        (this.state.order.status === OrderEnum.ReadyForPrint) ||
                                        (this.state.order.status === OrderEnum.Pending)
                                    ) &&
                                    this.state.userType === UserType.CUSTOMER
                                }
                            />
                        </div>
                        <div className="comment">
                            <div className="name">Comments</div>
                            <TextField
                                name='comment'
                                onChange={ this.changeData }
                                margin="normal"
                                type='text'
                                className="comment-text"
                                multiline
                                value={ this.state.data.comment }
                                rows={5}
                                variant="outlined"
                                disabled={this.state.order.status === OrderEnum.ReadyForPrint && this.state.userType === UserType.CUSTOMER}

                            />
                        </div>
                        {/*{*/}
                        {/*    (this.state.order.status == OrderEnum.NewOrder || this.state.order.status == OrderEnum.NeedsCustomization) &&*/}
                        {
                            !(this.state.order.status === OrderEnum.ReadyForPrint && this.state.userType === UserType.CUSTOMER) &&
                            <Button className="change-status-button" onClick={ () => this.setNewState() }>Change Status</Button>
                        }
                        {/*{*/}
                        {/*    (this.state.order.status === OrderEnum.ReadyForPrint && this.state.userType === UserType.CUSTOMER) &&*/}
                        {/*    <p style={{color: "red"}}>You can't change status in ready for printing status!</p>*/}
                        {/*}*/}

                    </div>
                    
                    {
                        this.state.activeButton == ChangeStatusButton.ITEM &&
                        <div className="select-item">
                            <div className="select-item-header">
                                <div className="check">
                                    <Checkbox
                                        checked={this.state.selectedAll}
                                        onChange={this.selectAll}
                                        name="saveAddress"
                                        color="primary"
                                    />
                                </div>
                                <div className="item">Item</div>
                                <div className="status">Status</div>
                                <div className="name">Name</div>
                            </div>
                            { this.renderItems() }
                        </div>
                    }
               </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Status)));
