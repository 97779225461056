import React from 'react';
import { number, string } from 'prop-types';

const IconVisitLink = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H7.16667C7.625 2.16667 8 1.79167 8 1.33333C8 0.875 7.625 0.5 7.16667 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8.83333C15.5 8.375 15.125 8 14.6667 8C14.2083 8 13.8333 8.375 13.8333 8.83333V13C13.8333 13.4583 13.4583 13.8333 13 13.8333ZM9.66667 1.33333C9.66667 1.79167 10.0417 2.16667 10.5 2.16667H12.6583L5.05 9.775C4.725 10.1 4.725 10.625 5.05 10.95C5.375 11.275 5.9 11.275 6.225 10.95L13.8333 3.34167V5.5C13.8333 5.95833 14.2083 6.33333 14.6667 6.33333C15.125 6.33333 15.5 5.95833 15.5 5.5V1.33333C15.5 0.875 15.125 0.5 14.6667 0.5H10.5C10.0417 0.5 9.66667 0.875 9.66667 1.33333Z" fill={color}/>
  </svg>
);

IconVisitLink.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconVisitLink;