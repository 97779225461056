import React from 'react';

import Page from 'src/redesign/components/Page';
import CustomerStory from 'src/redesign/components/CustomerStory';

const CustomerStoryTeeCommerce = () => (
  <Page>
    <CustomerStory
      image='/images/customer-story-teecommerce.png'
      imageDescription='TeeCommerce Team'
      COO='Collin Lynch'
      cooContact='978-500-4917'
      logo='/images/tee-commerce.png'
      site='https://www.teecommerce.shop/'
    />
  </Page>
);

export default CustomerStoryTeeCommerce;