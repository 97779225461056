import React from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { editBillingInformation } from 'src/services/BillingInformation';
import { mapBusinessInformation, mapUpdatedBusinessInformation } from './helpers';
import { chargeOrderDeclined } from 'src/services/OrderService';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import Form from 'src/redesign/components/Form/Form';
import SelectInput from 'src/redesign/components/Form/SelectInput';
import TextInput from 'src/redesign/components/Form/TextInput';
import CardInput from 'src/redesign/components/Form/CardInput';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import * as Validators from 'src/redesign/util/validators';

import './style.scss';

const BillingInformation = ({ billingInformation, countries, countryOptions, onUpdate }) => {
  const [form, setForm] = React.useState({});
  const [editMode, setEditMode] = React.useState(false);
  const [chargeDeclinedInProgress, setChargeDeclinedInProgress] = React.useState(false);

  const { openSnackBar } = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  
  React.useEffect(() => {
    setForm(mapBusinessInformation(billingInformation));
  }, []);

  const save = async newBillingInformation => {
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if(result.error) {
      // handle error
      openSnackBar('Changing card failed!', false);
    } else {
      try {
        const { token } = result;
        const billingInformation = { 
          ...newBillingInformation,
          country: countries.find(x => x.id == newBillingInformation.country),
          tokenId: token.id,
          expiration: token.card.exp_month,
          expiration_year: token.card.exp_year,
          lastFourDigits: token.card.last4
        };
  
        await editBillingInformation(billingInformation);
  
        setForm(billingInformation);
        setEditMode(false);
  
        onUpdate(mapUpdatedBusinessInformation(billingInformation));
  
        openSnackBar('Successfully saved!');
      }
      catch(error) {
        console.error(error);
        openSnackBar('saving billing information failed!', false);
      }
    }
  }

  const chargeDeclined = async () => {
    setChargeDeclinedInProgress(true);
    try {
      await chargeOrderDeclined();
    } catch (error) {
      openSnackBar('Failed to charge declined!', false);
    } finally {
      setChargeDeclinedInProgress(false);
    }
  }

  return <div className='billing-information'>
      <Form initialValues={form} title='Billing information' onSubmit={save} submitButtonText='Save Changes' showSubmit={editMode}>
        <TextInput label='Name on card' name='nameOnCard' placeholder='Enter your name on card' validate={Validators.required('Name on card is required')} disabled={!editMode}/>
        {editMode
          ? 
              <CardInput />
          : <div className="card-row">
              <TextInput label='Card Number' name='lastFourDigits' disabled={true}/>
            </div>
        }
        <TextInput label='Billing Address' name='billingAddress' placeholder='Enter your billing address' validate={Validators.required('Billing address is required')} disabled={!editMode}/>
        <SelectInput
          name="country"
          label="Country"
          options={countryOptions}
          placeholder="Choose your country"
          disabled={!editMode}
        />
        <TextInput label='City' name='city' placeholder='New York' validate={Validators.required('City is required')} disabled={!editMode}/>
        <TextInput label='State/Province/Region' name='stateProvince' placeholder='State/Province/Region' disabled={!editMode}/>
        <TextInput label='Postal code' name='postal' placeholder='Postal code' validate={Validators.required('Postal code is required')} disabled={!editMode}/>
      </Form>
      {!editMode && (
        <div className='edit-button-container'>
          <SubmitButton text='Edit' className='submit-button' width={162} onClick={() => setEditMode(true)} />
        </div>
      )}
    <div className='reinitiate-container'>
      To reinitiate charging of the orders in payment failed
      <SubmitButton text='CLICK HERE' width={142} className='button' disabled={chargeDeclinedInProgress} loading={chargeDeclinedInProgress} onClick={chargeDeclined}/>
    </div>
  </div>
}

export default BillingInformation;