import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Loader from 'src/redesign/components/Loader';
import Pagination from 'src/redesign/components/Pagination';
import Table from 'src/redesign/components/Table';
import Searchbox from 'src/redesign/components/Searchbox';
import PrimarySubmitButton from 'src/redesign/components/Buttons/SubmitPrimary';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import ToggleSwitch from 'src/redesign/components/Form/ToggleSwitch';
import Menu from 'src/redesign/components/Menu';
import IconEdit from 'src/redesign/components/Icon/Edit';
import IconTrash from 'src/redesign/components/Icon/Trash';
import Image from 'src/redesign/components/Image';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import CreateEditCategorySidepane from './components/CreateEditCategorySidepane';
import { mapCategory, mapProduct } from './helpers';

import { addCategory, deleteCategory, getCategoryByParent, getRootCategories, setTrending, updateCategory, uploadCategoryImage } from 'src/services/CategoryService';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const PER_PAGE = 4;
const CATEGORY_TYPE = {
  Category: 'Category',
  Product: 'Product'
}

const ProductsPage = ({ history, match: { params: { id }} }) => {
  const [rootCategoriesLoading, setRootCategoriesLoading] = React.useState(false);
  const [categoriesLoading, setCategoriesLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState(null);

  const [rootCategories, setRootCategories] = React.useState([]);
  const [products, setProducts] = React.useState([]);

  const [isAddSidepaneOpen, setIsAddSidePaneOpen] = React.useState(false);
  const [categoryToEdit, setCategoryToEdit] = React.useState(null);

  const [selectedCategory, setSelectedCategory] = React.useState(id);
  const [selectedCategoryType, setSelectedCategoryType] = React.useState(CATEGORY_TYPE.Category);

  const { openSnackBar } = useSnackbar();

  const loading = rootCategoriesLoading || categoriesLoading;

  React.useEffect(() => {
    loadRootCategories();
  }, []);

  const loadRootCategories = async () => {
    setRootCategoriesLoading(true);

    try {
      const { data: rootCategories } = await getRootCategories();
      // const { data: allCategories } = await getAllCategories();

      setRootCategories(rootCategories.categories);
      load(-1, 1, null);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load categories', false);
    } finally {
      setRootCategoriesLoading(false);
    }
  }

  const load = async (category, page, term) => {
    setCategoriesLoading(true);

    try {
      const { data } = await getCategoryByParent({
        id: category,
        page,
        perPage: PER_PAGE,
        name: term == "" ? null : term
      });

      setProducts(
        data.type == CATEGORY_TYPE.Category
        ? data.result.map(mapCategory)
        : data.result.map(mapProduct)
      );
      setSelectedCategory(category);
      setCurrentPage(page);
      setSearchTerm(term);
      setSelectedCategoryType(data.type);
      setTotalPages(Math.ceil(data.total/PER_PAGE));
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load products', false);
    } finally {
      setCategoriesLoading(false);
    }
  }

  const toggleTrendingCategory = async (id, inTrending) => {
    try {
      await setTrending({
        id
      });

      setProducts(prev => prev.map(x => x.id == id ? { ...x, in_trending: !inTrending } : x));
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to change trending of category', false);
    }
  }

  const onAddEditCategory = async values => {
    setSaving(true);

    let image = categoryToEdit?.categoryImage;
    const file = document.getElementById('categoryImage')?.files?.[0];

    if(file) {
      const formData = new FormData();
      formData.append('image', file);
      const { data } = await uploadCategoryImage(formData);
      
      image = data;
    }

    try {
      const obj = {
        id: categoryToEdit?.id ?? null,
        gallery: [],
        name: values.name,
        description: values.description,
        categoryImage: image,
        parentCategory: rootCategories.find(x => x.id == values.parentCategory),
        seoTitle: values.seoTitle,
        seoDescription: values.seoDescription
      };
      await (isAddSidepaneOpen ? addCategory(obj) : updateCategory(obj));

      setTimeout(() => {
        load(selectedCategory, 1);
        loadRootCategories();
      }, 1000);
      isAddSidepaneOpen ? setIsAddSidePaneOpen(false) : setCategoryToEdit(null);
    } catch(error) {
      console.error(error);
      openSnackBar(`Failed to ${isAddSidepaneOpen ? 'add' : 'edit'} category`, false);
    } finally {
      setSaving(false);
    }
  }

  const onProductDelete = async id => {
    try {
      await deleteCategory({ id });

      openSnackBar('Category successfully deleted');
      setTimeout(() => load(selectedCategory, 1), 1000);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to delete category', false);
    }
  }

  const onCategoryClick = (id) => {
    history.push(`/category/${id}`);
    load(id, 1, null);
    setSelectedCategory(id);
  }

  const onRowClick = (id) => {
    if(selectedCategoryType == CATEGORY_TYPE.Category) {
      load(id, 1, null);
    } else {
      history.push(`/product/about/${id}`);
    }
  }

  const onEditClick = (category) => {
    if(selectedCategoryType == CATEGORY_TYPE.Category) {
      setCategoryToEdit(category);
    } else {
      history.push(`/product/about/${category.id}`);
    }
  }

  const columns = [
    {
      Header: 'Image',
      accessor: 'image',
      Cell: ({ row: { original: { image } }}) => <Image alt={getImageAlt()} style={{ width: 43, height: 53, borderRadius: 2, objectFit: 'contain' }} src={image} />
    },
    {
      Header: 'Category Name',
      accessor: 'name'
    },
    {
      Header: 'Parent Category',
      accessor: 'description',
      Cell: ({ row: { original: { parentCategoryName } }}) => <>{parentCategoryName}</>
    },
    {
      Header: 'Start at',
      accessor: 'price',
      Cell: ({ row: { original: { price } }}) => 
      <div style={{ color: colors.green }}>
        ${price}
      </div>
    },
    {
      Header: 'Trending',
      accessor: 'in_trending',
      Cell: ({ row: { original: { id, trending }}}) => 
      <div onClick={() => toggleTrendingCategory(id, trending)}>
        <ToggleSwitch className='products-toggle-switch' checked={trending} onChange={()=>{}}/>
      </div>
    },
    {
      Header: '',
      accessor: 'deleted',
      Cell: ({ row: { original }}) => (
        <Menu
          width={4}
          height={16}
          className='products-menu'
          options={[
            {
              key: 'edit',
              text: <>
                Edit
                <IconEdit width={24} height={24} color={colors.primary} />
              </>,
              onSelect: () => onEditClick(original)
            },
            {
              key: 'delete',
              text: <>
                Delete
                <IconTrash width={24} height={24} color={colors.primary} />
              </>,
              onSelect: () => onProductDelete(original.id)
            }
          ]}
        />
      )
    }
  ];

  return (
    <ApplicationPage selectedTab='admin'>
      <ApplicationPageContent title='Products'>
        <div className='products-page'>
          <Searchbox
            placeholder='Search'
            className='products-searchbox-container'
            onChange={term => load(selectedCategory, currentPage, term)}
            disabled={loading}
          />
          <div className='products-actions'>
            <SecondaryButton
              text='Create new Product'
              onClick={() => history.push('/product/about/-1')}
              disabled={loading}
            />
            <PrimarySubmitButton
              text='Create new Category'
              onClick={() => setIsAddSidePaneOpen(true)}
              disabled={loading}
            />
          </div>
          {rootCategoriesLoading
          ? <Loader width={50} height={50} />
          : (
            <div className='products-root-categories'>
              <div
                className={classNames('products-category', {'products-category-selected': selectedCategory == '-1'})}
                onClick={() => onCategoryClick(-1)}
              >
                All Categories
              </div>
              {rootCategories.map(x => (
                <div
                  className={classNames('products-category', {'products-category-selected': selectedCategory == x.id})}
                  onClick={() => onCategoryClick(x.id)}
                >
                  {x.name}
                </div>
              ))}
            </div>
          )}
          {categoriesLoading
          ? <Loader width={200} height={200} />
          : (
            <div>
              <Table
                columns={columns}
                data={products}
                onRowClick={({ id }) => onRowClick(id)}
              />
              <Pagination page={currentPage} setPage={page => load(selectedCategory, page, searchTerm)} totalPages={totalPages}/>
            </div>
          )}
        </div>
        {(isAddSidepaneOpen || categoryToEdit != null) && (
          <CreateEditCategorySidepane
            title={isAddSidepaneOpen ? 'Create new product category' : 'Edit product category'}
            initialValues={categoryToEdit}
            parentCategories={rootCategories.map(x => ({ key: x.id, text: x.name }))}
            onSubmit={onAddEditCategory}
            onClose={() => isAddSidepaneOpen
              ? setIsAddSidePaneOpen(false)
              : setCategoryToEdit(null)}
            saving={saving}
          />
        )}
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default withRouter(ProductsPage);