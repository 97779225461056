import React from 'react';
import { MuiColorInput as MuiColorPicker } from "mui-color-input";

import './style.scss';

const ColorPicker = ({ onChange, value }) => (
  <div className='color-picker-container'>
    <MuiColorPicker
      name='color'
      on
      onChange={onChange}
      style={{
        width: 29,
        minWidth: 29,
        height: 29,
      }}
      value={value}
      className='color-picker'
    />
  </div>
)

export default ColorPicker;