import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "@material-ui/core";
import strings from '../localization';
import { Link } from "react-router-dom";

class StartingDesigning extends Page {

    constructor(props) {
        super(props);

        this.props.changeFullScreen(true);
    }

    componentDidMount() {
    }

    goToLogin() {
        this.props.history.push('/login');
    }

    goToSignUp() {
        this.props.history.push('/registration')
    }

    render() {

        return (
            <div id="home">
                <div className="top">
                    <div className="left-top">
                        <div className="logo"></div>
                    </div>
                    <div className="center-top">
                        <div className="menu">
                            <div className="option"><Link to={'/home'}>{ strings.home.home }</Link></div>
                            <div className="option">{ strings.home.howItWorks }</div>
                            <div className="option">{ strings.home.products }</div>
                            <div className="option">{ strings.home.about }</div>
                        </div>
                        <div className="title">{ strings.home.title }</div>
                    </div>
                    <div className="right-top">
                        <Button onClick={ () => this.goToLogin()}>{ strings.home.loginButton }</Button>
                    </div>
                </div>
                <div className="bottom">
                    <div className="left-bottom">
                        
                    </div>
                    <div className="center-bottom">
                        <div className="image"></div>
                        <div className="text">{ strings.home.text }</div>
                        <Button onClick={ () => this.goToSignUp()}>{ strings.home.signUp }</Button>
                    </div>
                    <div className="right-bottom">
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers };
}

// export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartingDesigning));
