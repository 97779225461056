import React from 'react';
import ReactDOM from 'react-dom';
import { bool, oneOf, string } from 'prop-types';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import SuccessShieldIcon from 'src/redesign/components/Icon/SuccessShieldIcon';
import FailShieldIcon from 'src/redesign/components/Icon/FailShieldIcon';

const Snackbar = ({ text, type }) => {
  const isSuccess = type === 'success';

  return ReactDOM.createPortal(
    <div id='snackbar' className={'snackbar'
      }>
      <div className='icon-container' style={{ backgroundColor: isSuccess ? colors.green : colors.red }}>
        {isSuccess ? <SuccessShieldIcon width={18} height={22} color="white"/> : <FailShieldIcon width={24} height={24} color="white"/>}
      </div>
      <div className='text-container'>
        <div className='title'>
          {isSuccess ? 'Success' : 'Error'}
        </div>
        <div className='text'>
          {text}
        </div>
      </div>
    </div>, document.getElementById('snackbar-root')
  )
}

Snackbar.propTypes = {
  text: string,
  type: oneOf(['success', 'error']),
  isActive: bool,
};

export default Snackbar;