import React from 'react'

const MockupLoader = () => {
  

return (<div className="refresh-mockups-loader">
        <div className="refresh-mockups-loader__container">
            <div className="refresh-mockups-loader-holder"> 
               <div className="refresh-mockups-loader-holder__title"> Refreshing mockups... </div>
                <img className="refresh-mockups-loader-holder__img" src={'/images/loadersp.gif'} /> </div>
        </div>
    </div>
    );
  };
  
  export default MockupLoader