import React from 'react';
import { Field } from 'react-final-form';
import { func, string } from 'prop-types';

import classNames from 'classnames';

import './style.scss';

const SelectInput = ({
  label,
  name,
  options,
  validate,
  disabled,
  containerClassName,
  className,
  caretClassName,
  caretColor = 'none'
}) => {
  const selectRef = React.useRef();

  return (
    <div className={classNames("select-input", containerClassName)}>
      {label && (
        <div className="label">
          {label}
        </div>
      )}
      <Field
        name={name}
        validate={validate}
        type='select'
        render={({ input }) => (
            <select
              {...input}
              disabled={disabled}
              className={classNames('input', className)}
            >
              <option disabled hidden />
              {options.map((o) => (
                <option key={o.key} value={o.key} label={o.text} className={classNames("option", {'selected-option': input.value == o.key})}>
                  {o.text}
                </option>
              ))}
            </select>
          )
        }
      />
      <div className={classNames("caret", caretClassName)}>
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill={caretColor}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5 5L9 1"
            stroke="black"
            strokeOpacity="0.5"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  )
}

SelectInput.propTypes = {
  label: string,
  name: string,
  validate: func,
  onRenderOption: func
}

export default SelectInput;