import React from 'react';
import classNames from 'classnames';
import * as Actions from 'src/actions/Actions';
import IconHome from '../Icon/Home';
import { LogoNavbarCollapsed, LogoNavbarExpanded } from '../Logo';
import Tab from '../Tab';
import MenuState from 'src/constants/MenuState';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import IconOrders from 'src/redesign/components/Icon/Orders';
import IconPlaceOrder from 'src/redesign/components/Icon/PlaceOrder';
import IconLibrary from 'src/redesign/components/Icon/Library';
import IconCatalog from 'src/redesign/components/Icon/Catalog';
import IconWarehouse from 'src/redesign/components/Icon/Warehouse';
import IconAffiliate from 'src/redesign/components/Icon/Affiliate';
import IconSettings from 'src/redesign/components/Icon/Settings';
import IconStores from 'src/redesign/components/Icon/Stores';
import IconChevronLeft from 'src/redesign/components/Icon/Chevron/Left';
import IconChevronRight from 'src/redesign/components/Icon/Chevron/Right';
import useMobile from 'src/redesign/hooks/useMobile';
import Menu from "src/redesign/components/Menu";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import IconMenu from 'src/redesign/components/Icon/Menu';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { getUserFromLocalStorage } from 'src/base/OAuth';
import { ADMIN_TABS, CATEGORIES_TABS } from 'src/redesign/constants';

const Navbar = ({ menu: { state: menuState }, changeMenuState, selectedTab }) => {
  const { isMobile } = useMobile();
  const isExpanded = menuState === MenuState.FULL;
  const user = getUserFromLocalStorage();
  const isAdmin = user?.roles?.includes('SUPER_ADMIN');

  const onToggleExpandClick = 
    () => isExpanded ? changeMenuState(MenuState.SHORT) : changeMenuState(MenuState.FULL);

  const tabs = (
    <div className='navbar'>
      <div className="logo">
        {isExpanded ? <LogoNavbarExpanded /> : <LogoNavbarCollapsed />}
        {isExpanded && (
        <div className="title">
          Surge print
        </div>
        )}
      </div>
      <div className="content">
        <div className="tabs">
          <Tab text='Home' to='/home' icon={<IconHome width={20} height={20} color={colors.primary}/>} isExpanded={isExpanded} isSelected={selectedTab === 'home'}/>

          <Tab text='Orders' to='/orders' icon={<IconOrders width={20} height={20} color={selectedTab === 'orders' ? colors.primary : colors.white} secondaryColor={selectedTab === 'orders' ? colors.white : colors.primary}/>} isExpanded={isExpanded} isSelected={selectedTab === 'orders'}/>

          <Tab text='Place an Order' to='/place-an-order' icon={<IconPlaceOrder width={20} height={20} color={selectedTab === 'place-an-order' ? colors.white : colors.primary}/>} isExpanded={isExpanded} isSelected={selectedTab === 'place-an-order'}/>

          <Tab text='Product Library' to='/product-templates/storage'icon={<IconLibrary width={20} height={20} color={selectedTab === 'product-templates' ? colors.white : colors.primary}/>} isExpanded={isExpanded} isSelected={selectedTab === 'product-templates'}/>

          <Tab text='Product Catalog' to='/product-catalog-in-app' icon={<IconCatalog width={20} height={20} color={colors.primary}/>} isExpanded={isExpanded} tabs={CATEGORIES_TABS.map(x => ({ text: x.text, to: `/product-catalog-in-app/${x.key}`}))} isSelected={selectedTab === 'product-catalog'}/>

          <Tab text='Warehouse PRO' to='/warehouse' icon={<IconWarehouse width={20} height={20} color={selectedTab === 'warehouse' ? colors.white : colors.primary}/>} isExpanded={isExpanded} isSelected={selectedTab === 'warehouse'}/>

          <Tab text='Affiliate' to='/associate' icon={<IconAffiliate width={24} height={24} color={selectedTab === 'affiliate' ? colors.white : colors.primary}/>} isExpanded={isExpanded} isSelected={selectedTab === 'affiliate'}/>

          {isAdmin ? (
            <Tab text='Admin' to='/admins' icon={<IconSettings width={20} height={20} color={colors.primary} />} isExpanded={isExpanded} tabs={ADMIN_TABS} isSelected={selectedTab === 'admin'}/>
          )
          : (
            <>
              <Tab text='Settings' to='/settings/my-profile' icon={<IconSettings width={20} height={20} color={colors.primary}/>} isExpanded={isExpanded} isSelected={selectedTab === 'settings'}/>

              <Tab text='Stores' to='/stores' icon={<IconStores width={20} height={20} color={selectedTab === 'stores' ? colors.white : colors.primary}/>} isExpanded={isExpanded} isSelected={selectedTab === 'stores'}/>
            </>
          )}
        </div>
        {!isMobile && (
          <div className={classNames("collapsible", {'collapsible-collapsed': !isExpanded})} onClick={onToggleExpandClick}>
            {isExpanded ? (
              <IconChevronLeft width={6} height={12} color={colors.primary}/>
            )
            : (
              <IconChevronRight width={6} height={12} color={colors.primary}/>
            )}
            {isExpanded && 'Collapse'}
          </div>
        )}
      </div>
    </div>
  )

  return (
    isMobile ? (
      <div className='navbar-menu' onClick={onToggleExpandClick}>
        <MenuRoundedIcon width={40} height={40} style={{ width: 40, height: 40, color: colors.primary}} color={colors.primary} />
        {isExpanded && (
          <div className='navbar-mobile-container'>
            {tabs}
          </div>
        )}
      </div>
    )
    :
    tabs
  );
};

// TODO: need to create new store for the redesign
// it will be with React context since it allows multiple stores (redux is always 1)
function mapDispatchToProps(dispatch)
{
    return bindActionCreators({ changeMenuState: Actions.changeMenuState }, dispatch);
}

function mapStateToProps({ menuReducers })
{
    return {
        menu: menuReducers,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
