import React from 'react';
import { number, string } from 'prop-types';

const IconPlaceOrder = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 22 21" fill="white" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_154_4695)">
    <path d="M12.8053 5.93307H0.394031C0.262695 5.93307 0.131358 5.8622 0.06569 5.74409C2.17929e-05 5.62598 -0.0218676 5.48425 0.0438006 5.34252L1.97007 0.73622C2.03574 0.594488 2.16707 0.5 2.3203 0.5H11.2512C11.4044 0.5 11.5576 0.61811 11.6233 0.783465L13.1774 5.36614C13.1993 5.41339 13.1993 5.48425 13.1993 5.5315C13.1993 5.74409 13.0242 5.93307 12.8053 5.93307ZM0.985045 5.1063H12.2581L10.9885 1.32677H2.58297L0.985045 5.1063Z" fill={color}/>
    <path d="M6.58871 5.93307C6.36982 5.93307 6.1947 5.74409 6.1947 5.50787V0.925197C6.1947 0.688976 6.36982 0.5 6.58871 0.5C6.80761 0.5 6.98272 0.688976 6.98272 0.925197V5.5315C6.98272 5.74409 6.80761 5.93307 6.58871 5.93307Z" fill={color}/>
    <path d="M12.8053 18.5H0.394009C0.175115 18.5 0 18.311 0 18.0748V5.53149C0 5.29527 0.175115 5.10629 0.394009 5.10629H12.8053C13.0242 5.10629 13.1993 5.29527 13.1993 5.53149V7.27952C13.1993 7.49212 13.068 7.65747 12.871 7.70472C10.6164 8.08267 8.93087 10.2087 8.93087 12.6653C8.93087 15.122 10.6164 17.248 12.871 17.626C13.068 17.6496 13.1993 17.8386 13.1993 18.0512V18.122C13.1993 18.311 13.0242 18.5 12.8053 18.5ZM0.766129 17.6732H10.7696C10.288 17.3661 9.82834 16.9646 9.45622 16.4921C8.60253 15.4291 8.14286 14.059 8.14286 12.6653C8.14286 11.248 8.60253 9.90157 9.45622 8.83858C10.2224 7.87007 11.273 7.20865 12.4113 6.94881V5.93306H0.766129V17.6732Z" fill={color}/>
    <path d="M13.5714 18.5C10.5725 18.5 8.14282 15.878 8.14282 12.6417C8.14282 9.40553 10.5725 6.78348 13.5714 6.78348C16.5702 6.78348 19 9.40553 19 12.6417C19 15.878 16.5702 18.5 13.5714 18.5ZM13.5714 7.61025C11.0103 7.61025 8.90895 9.85434 8.90895 12.6417C8.90895 15.4055 10.9884 17.6732 13.5714 17.6732C16.1325 17.6732 18.2338 15.4291 18.2338 12.6417C18.2338 9.87797 16.1543 7.61025 13.5714 7.61025Z" fill={color}/>
    <path d="M13.5714 16.3976C13.3525 16.3976 13.1774 16.2087 13.1774 15.9724V9.28741C13.1774 9.05119 13.3525 8.86221 13.5714 8.86221C13.7903 8.86221 13.9654 9.05119 13.9654 9.28741V15.9961C13.9654 16.2323 13.7903 16.3976 13.5714 16.3976Z" fill="#00F2E2"/>
    <path d="M15.5196 11.815C15.4102 11.815 15.3226 11.7677 15.2351 11.6968L13.5715 9.87795L11.9079 11.6732C11.7547 11.8386 11.5139 11.8386 11.3606 11.6732C11.2074 11.5079 11.2074 11.248 11.3606 11.0827L13.3088 8.98031C13.462 8.81495 13.7028 8.81495 13.856 8.98031L15.8042 11.0827C15.9574 11.248 15.9574 11.5079 15.8042 11.6732C15.7166 11.7677 15.6072 11.815 15.5196 11.815Z" fill="#00F2E2"/>
    </g>
    <defs>
    <clipPath id="clip0_154_4695">
    <rect width="19" height="18" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
    </defs>
  </svg>
);

IconPlaceOrder.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconPlaceOrder;