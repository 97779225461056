import React from 'react';
import { func, string } from 'prop-types';

import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import Image from 'src/redesign/components/Image';
import { WaveUp } from 'src/redesign/components/Wave/Up';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss'; 

const LandingHeader = ({ title, subtitle, onStartDesigningClick, image }) => (
  <div className='landing-header-container'>
    <WaveUp width='100%' height="100.966px" color={colors.primary} />
    <div className='landing-header'>
      <div className='landing-header-left'>
        <h1 className='landing-header-title'>
          {title}
        </h1>
        <div className='landing-header-subtitle'>
          {subtitle}
        </div>
        <SecondaryButton
          text='Start Designing'
          width={164}
          onClick={onStartDesigningClick}
        />
      </div>
      <div className='landing-header-right'>
        <Image alt={getImageAlt()} src={image} className='landing-header-image'/>
      </div>
    </div>
  </div>
);

LandingHeader.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  image: string.isRequired,
  onStartDesigningClick: func.isRequired
}

export default LandingHeader;