export const mapLoadedUser = user => ({
  firstName: user?.first_name,
  lastName: user?.last_name,
  email: user?.email,
  country: user?.customer ? user?.customer?.country.id : null,
  currentPassword: null,
  newPassword: null,
  confirmPassword: null,
  customization: user?.customer ? user?.customer?.hold_customization : '',
  storeUrl: user?.customer ? user?.customer?.store_url_profile : '',
  deactivateProfile: false,
});

export const mapUpdatedUser = ({ firstName, lastName, email, country, customization, storeUrl }) => ({
  first_name: firstName,
  last_name: lastName,
  email,
  country,
  hold_customization: customization,
  store_url_profile: storeUrl,
})