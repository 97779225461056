import React from 'react';
import classNames from 'classnames';

import IconCheckmarkBold from 'src/redesign/components/Icon/CheckmarkBold';
import Menu from 'src/redesign/components/Menu';
import CustomTooltip from 'src/redesign/components/CustomTooltip';
import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/redesign/../scss/_colors.scss';

const Store = ({ image, name, connected = false, onClickConnect, onClickDisconnect }) => {

  return (
    <div className='store'>
      <div className='image-container'>
        <Image alt={getImageAlt()} src={image} className='image' />
      </div>
      {connected ? (
        <div className={classNames('connect-button', 'connected-button')}>
          <IconCheckmarkBold width={22} height={18} color={colors.white} /> Connected
        </div>
      ) : (
        <div className='connect-button' onClick={onClickConnect}>
          Connect Store
        </div>
      )}
      {connected && (
        <Menu
          options={[{
            key: 'disconnect',
            text: 'disconnect',
            onSelect: onClickDisconnect
          }]}
        />
      )}
      {name && (
          <CustomTooltip text={name} className='store-name'>
            {name}
          </CustomTooltip>
      )}
    </div>
  )
}

export default Store;