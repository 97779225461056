import {withRouter} from "react-router-dom";
import React from 'react'
import FormComponent from "../../common/FormComponent";
import {Button, TextField } from "@mui/material";
import { MuiColorInput as ColorPicker } from "mui-color-input";
import CreatableSelect from "react-select/creatable";
import {
    createAttribute, createAttributeValue,
    deleteAttributeValue,
    getAttributes,
    getAttributeValues,
    updateAttributeValue
} from "../../services/admin/AttributeService";
import AttributeType from "../../constants/AttributeType";
import {withSnackbar} from "notistack";


class AddAttributeEditProduct extends FormComponent{

    constructor(props) {
        super(props);

        this.state = {
            radioValue : 0,
            valueCount : 1,
            colorValueCount : 1,
            values : [],
            colorValues : [],
            attributes : [],
            data : {},
            attribute : {},
            changingColor : false,
            page : 1,
            perPage : 6,
            attributeId : props.editAttribute ? props.editAttribute.id : '',
            cc : 1,
            attributeName : props.editAttribute ? props.editAttribute.name : '',
        };

        this.handleRadio = this.handleRadio.bind(this);
        this.addValue = this.addValue.bind(this);
        this.handleCreateSelect = this.handleCreateSelect.bind(this);
        this.addColorValue = this.addColorValue.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    getAttributes() {
        getAttributes(this.state.radioValue).then(response => {
            if (!response.ok){
                return ;
            }

            let attributes = [];

            response.data.forEach((item) => {
                attributes.push({value: item.id, label: item.name,type : item.attribute_type});
            })

            this.setState({
                ...this.state,
                attributes : attributes
            })
        })
    }

    componentDidMount() {
        this.getAttributes();

        if (this.props.editAttribute && this.props.editAttribute.id){
            this.getAttributeValues({value : this.props.editAttribute.id, type : this.props.editAttribute.attribute_type});
        }
    }

    handleRadio(value){
        this.setState({
            ...this.state,
            radioValue : value,
            valueCount : 1,
            colorValueCount : 1,
            values : [],
            colorValues : [],
            page: 1,
            total : 0,
            totalPages : 1,
            attributeName : undefined,
            attributeId : undefined
        }, ()=> {
            this.getAttributes();
        })
    }

    removeValue(i){
        let values = this.state.values;

        if (!values[i].id) {
            values.splice(i, 1);

            this.setState({
                ...this.state,
                values : values,
                valueCount : this.state.valueCount - 1
            });
        } else {
            deleteAttributeValue(values[i].id).then(response => {
                if (!response.ok){
                    this.props.enqueueSnackbar('Attribute value already assigned to product', {variant: 'error'});
                    return ;
                }

                values.splice(i, 1);

                this.setState({
                    ...this.state,
                    values : values,
                    valueCount : this.state.valueCount - 1
                });
            })
        }
    }

    removeColorValue(id, idx = null){
        let values = this.state.colorValues;
        let toSend = [];

        values.forEach((item, index) => {
            if (index != idx){
                toSend.push(item);
            }
        });

        this.setState({
            ...this.state,
            colorValues : toSend,
            colorValueCount : this.state.colorValueCount - 1
        }, () => {

            if(id == undefined){ return; }

            deleteAttributeValue(id).then(response => {
                if (!response.ok){
                    return ;
                }

                this.state.colorValues.forEach((item, index) => {
                    document.getElementById('color-' + index).value = item.name;
                    document.getElementById('color-' + index).style.color = this.state.colorValues[index];
                })
            })
        });
    }

    changeValue(e, i){

        let values = this.state.values;

        for (let k = 0; k < this.state.valueCount; k ++){
            if (values[i]){
                values[i].name = e.target.value;
                values[i].notSaved = true;
            }else {
                values.push([]);
                values[i].name = e.target.value;
                values[i].notSaved = true;
            }
        }
        this.setState({
            ...this.state,
            values : values
        });
    }

    changeColor(color, i){
        if (color === undefined){
            return ;
        }

        let values = this.state.colorValues;

        for (let k = 0; k < this.state.colorValueCount; k ++){
            if (values[i]){
                values[i].name = color;
                values[i].notSaved = true;
            }else {
                values.push([]);
                values[i].name = color;
                values[i].notSaved = true;
            }
        }


        this.setState({
            ...this.state,
            colorValues : values
        }, ()=> {
            document.getElementById('color-' + i).value = color;
        })
    }

    renderStringValueFields(){
        let toRender = [];

        for (let i = 0; i < this.state.valueCount; i++) {
            toRender.push(
                <div className={'value-container'} key={i}>
                    <TextField className={this.state.attributeId ? '' : 'disabled'}
                               value={this.state.values.length > 0 ? (this.state.values[i].name ? this.state.values[i].name : '') : ''}
                               onChange={(e) => this.changeValue(e, i)}
                               id="standard-basic"/>
                    <label onClick={() => {
                        this.removeValue(i)
                    }} className={this.state.attributeId ? 'remove' : 'remove disabled'}>-</label>
                    {
                        this.state.values[i] && this.state.values[i].notSaved &&
                        <Button className={'save-edit'} onClick={(e)=> this.saveEditValue(i, e, 'string')}>Save</Button>
                    }
                </div>
            );
        }

        return toRender;
    }

    saveEditValue(i, e, type){
        e.stopPropagation();

        if (type === 'string'){
            updateAttributeValue({attributeId: this.state.attributeId, id : this.state.values[i].id ? this.state.values[i].id : 'new', name : this.state.values[i].name, customer_color_name : ''}).then(response => {
                if (!response.ok){
                    return ;
                }

                let values = this.state.values;

                values[i] = response.data;
                values[i].notSaved = false;

                this.setState({
                    ...this.state,
                    values : values
                });
            })
        }else {
            updateAttributeValue({attributeId: this.state.attributeId, id : this.state.colorValues[i].id ? this.state.colorValues[i].id : 'new', name : this.state.colorValues[i].name ? this.state.colorValues[i].name : '', customer_color_name : this.state.colorValues[i].customer_color_name ? this.state.colorValues[i].customer_color_name : ''}).then(response => {
                if (!response.ok){
                    return ;
                }

                let values = this.state.colorValues;

                values[i] = response.data;
                values[i].notSaved = false;

                this.setState({
                    ...this.state,
                    colorValues : values
                });
            })
        }
    }

    changeCustomName(e, indx){
        let values = this.state.colorValues;

        values.forEach((item, index) => {
            if (index !== indx){
                return
            }

            item.customer_color_name = e.target.value;
            item.notSaved = true;
        });

        this.setState({
            ...this.state,
            colorValues : values
        });
    }

    renderColorValueFields(){
        let toRender = [];

        for (let i = 0; i < this.state.colorValueCount; i++) {
            toRender.push(
                <div key={i} className={'value-container'}>
                    <ColorPicker
                        id={'color-' + i}
                        name='color'
                        className={this.state.attributeId ? '' : 'disabled'}
                        onChange={color => this.changeColor(color, i)}
                        setValue={this.state.colorValues.length > 0 ? (this.state.colorValues[i].name ? this.state.colorValues[i].name : '') : ''}
                    />
                    {
                        this.state.colorValues[i] &&
                        <div style={{backgroundColor: this.state.colorValues.length > 0 ? (this.state.colorValues[i].name ? this.state.colorValues[i].name : '') : ''}} className={'square color-' + i}></div>
                    }
                    <div className={'vertical-line'}></div>
                    {
                        !this.state.colorValues[i] &&
                        <img className={'icon'} src={'/images/color-picker.png'} />
                    }
                    <input onChange={(e) =>
                        this.changeCustomName(e, i)}
                           value={this.state.colorValues[i] ? (this.state.colorValues[i].customer_color_name ? this.state.colorValues[i].customer_color_name : '') : ''}
                           className={'custom-name'}
                           name={'customerColorName-' + i}
                           type={'text'} />
                    <label onClick={() => this.removeColorValue(this.state.colorValues[i].id, i)} className={this.state.attributeId ? 'remove' : 'remove disabled'}>-</label>
                    {
                        (this.state.colorValues[i] && this.state.colorValues[i].notSaved) &&
                        <Button className={'save-edit'} onClick={(e)=> this.saveEditValue(i, e, 'color')}>Save</Button>
                    }
                </div>
            );
        }

        return toRender;
    }

    addValue(newOrNot = false){
        if (this.state.values.length < 6){
            let values = this.state.values;

            if (!newOrNot){
                values.push([]);
            }

            this.setState({
                ...this.state,
                valueCount : values.length,
                values : values,
            });

            return
        }

        if (this.state.total%6 === 0 || this.state.values.length === 6){
            createAttributeValue({id : this.state.attributeId}).then(response =>{
                if (!response.ok){
                    return ;
                }

                let totalPages = this.state.totalPages;

                this.state.page = totalPages + 1;
                this.state.totalPages = totalPages + 1;
                this.state.total = this.state.total + 1;
                this.state.values = [];
                this.state.values.push(response.data);
                this.state.valueCount = 1;
                console.log('b')

                this.addValue(true);
            })
        }else if(this.state.values.length < 6 && !newOrNot){
            let values = this.state.values;
            // values.push([]);
            console.log('c')
            console.log(newOrNot)

            this.setState({
                ...this.state,
                valueCount : values.length,
                values : values,
            });
        }else if(this.state.values.length < 6 && newOrNot){
            let values = this.state.values;
            // values.push({name: "", id: null});
            console.log('d')

            this.setState({
                ...this.state,
                valueCount : values.length,
                values : values,
            });
        }else{
            let plus = 0;
            console.log('e')

            if(this.state.values.length === 6) {
                plus = 1;
                this.state.page = this.state.totalPages;
            }


            setTimeout(()=> {
                getAttributeValues(this.state.attributeId, this.state.page).then(response => {
                    if (!response.ok){
                        return ;
                    }

                    let values = [];

                    if (this.state.radioValue === AttributeType.STRING){
                        response.data.result.forEach(item => {
                            values.push({name : item.name, id: item.id});
                        })

                        if (plus !== 0){
                            values.push([]);
                        }

                        this.setState({
                            ...this.state,
                            attributeName : {label : this.state.attributeName},
                            values : values,
                            valueCount : values.length,
                            attributeId : this.state.attributeId,
                            totalPages : this.state.totalPages,
                            page : this.state.page,
                            total : this.state.total
                        })
                    }else {
                        response.data.result.forEach((item, i) => {
                            values.push({name : item.name, id: item.id});
                        })

                        if (plus !== 0){
                            values.push([]);
                        }

                        this.setState({
                            ...this.state,
                            attributeName : {label : this.state.attributeName},
                            colorValues : values,
                            colorValueCount : values.length,
                            attributeId : this.state.attributeId,
                            totalPages : this.state.totalPages,
                            page : this.state.page,
                            total : this.state.total
                        }, () => {
                            this.state.colorValues.forEach((item, index) => {
                                document.getElementById('color-' + index).value = item.name;
                            })
                        })
                    }
                })
            }, 1000)
        }

    }

    addColorValue(newOrNot = false){


        if (this.state.colorValues.length < 6){
            let values = this.state.colorValues;
            console.log('a')
            if (!newOrNot){
                values.push([]);
            }

            this.setState({
                ...this.state,
                colorValueCount : values.length,
                colorValues : values,
            });

            return
        }

        if (this.state.total%6 === 0  || this.state.colorValues.length === 6){
            console.log('b')
            createAttributeValue({id : this.state.attributeId}).then(response =>{
                if (!response.ok){
                    return ;
                }

                let totalPages = this.state.totalPages;

                this.state.page = totalPages + 1;
                this.state.totalPages = totalPages + 1;
                this.state.total = this.state.total + 1;
                this.state.colorValues = [];
                this.state.colorValues.push(response.data);
                this.state.colorValueCount = 1;
                this.addColorValue(true);
            })
        }else if(this.state.colorValues.length < 6 && !newOrNot){
            let values = this.state.colorValues;
            values.push([]);
            console.log('c')
            this.setState({
                ...this.state,
                colorValueCount : values.length,
                colorValues : values
            });
        }else if(this.state.colorValues.length < 6 && newOrNot){
            let values = this.state.colorValues;
            console.log('d')
            console.log(newOrNot)

            this.setState({
                ...this.state,
                colorValueCount : values.length,
                colorValues : values
            });
        }else{
            let plus = 0;
            console.log('e')

            if(this.state.colorValues.length === 6) {
                plus = 1;
                this.state.page = this.state.totalPages;
            }


            setTimeout(()=> {
                getAttributeValues(this.state.attributeId, this.state.page).then(response => {
                    if (!response.ok){
                        return ;
                    }

                    let values = [];

                    if (this.state.radioValue === AttributeType.STRING){
                        response.data.result.forEach(item => {
                            values.push({name : item.name, id: item.id});
                        })

                        if (plus !== 0){
                            values.push([]);
                        }

                        this.setState({
                            ...this.state,
                            attributeName : {label : this.state.attributeName},
                            values : values,
                            valueCount : values.length,
                            attributeId : this.state.attributeId,
                            totalPages : this.state.totalPages,
                            page : this.state.page,
                            total : this.state.total
                        })
                    }else {
                        response.data.result.forEach((item, i) => {
                            values.push({name : item.name, id: item.id});
                        })

                        if (plus !== 0){
                            values.push([]);
                        }

                        this.setState({
                            ...this.state,
                            attributeName : {label : this.state.attributeName},
                            colorValues : values,
                            colorValueCount : values.length,
                            attributeId : this.state.attributeId,
                            totalPages : this.state.totalPages,
                            page : this.state.page,
                            total : this.state.total
                        }, () => {
                            this.state.colorValues.forEach((item, index) => {
                                document.getElementById('color-' + index).value = item.name;
                            })
                        })
                    }
                })

            }, 1000)
        }
    }

    handleCreateSelect(e){
        if (e.__isNew__){
            createAttribute({name: e.value, type: this.state.radioValue, attributeValues : []}).then(response => {
                if (!response.ok){
                    return ;
                }

                if (this.state.radioValue === AttributeType.STRING){
                    this.setState({
                        ...this.state,
                        attributeName : e,
                        attributeId : response.data.id,
                        valueCount : 1,
                        values : [[]],
                        page : 1,
                        totalPages : 1,
                        total : 0
                    })
                }else {
                    this.setState({
                        ...this.state,
                        attributeName : e,
                        attributeId : response.data.id,
                        colorValueCount : 1,
                        colorValues : [[]],
                        page : 1,
                        totalPages : 1,
                        total : 0
                    }, () => {
                        document.getElementById('color-0').value = '';

                    })
                }
            })
        }else {
            this.state.page = 1;
            this.getAttributeValues(e);
        }
    }

    getAttributeValues(e){
        getAttributeValues(e.value, this.state.page).then(response => {
            if (!response.ok){
                return ;
            }

            let values = [];

            if (e.type === AttributeType.STRING){
                response.data.result.forEach(item => {
                    values.push({name : item.name, id: item.id});
                })

                this.setState({
                    ...this.state,
                    attributeName : e,
                    values : values,
                    valueCount : values.length,
                    attributeId : e.value,
                    totalPages : Math.ceil(response.data.total/ response.data.perPage),
                    page : +response.data.page,
                    total : response.data.total,
                    radioValue : e.type
                }, ()=> {
                    this.getAttributes();
                })
            }else {
                response.data.result.forEach((item, i) => {
                    values.push({name : item.name, id: item.id, customer_color_name : item.customer_color_name});
                })

                this.setState({
                    ...this.state,
                    attributeName : e,
                    colorValues : values,
                    colorValueCount : values.length,
                    attributeId : e.value,
                    totalPages : Math.ceil(response.data.total/ response.data.perPage),
                    page : +response.data.page,
                    total : response.data.total,
                    radioValue : e.type
                }, () => {
                    this.state.colorValues.forEach((item, index) => {
                        document.getElementById('color-' + index).value = item.name;
                    })
                    this.getAttributes();
                })
            }
        })
    }

    onPageClick(i){
        this.state.page = i;
        this.getAttributeValues({value : this.state.attributeId, label : this.state.attributeName.label, type : this.state.radioValue});
    }

    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return ;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page === i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    render() {
        return (
            <div className={'add-attribute edit'}>
                <p className={'title'}>Add new attribute</p>
                <hr />
                <div className={'fields'}>
                    <div className={'field'} onClick={() => this.handleRadio(0)}>
                        <div className={this.state.radioValue === 0 ? 'square active' : 'square'}></div><label>String</label>
                    </div>
                    <div className={'field'} onClick={() => this.handleRadio(1)}>
                        <div className={this.state.radioValue === 1 ? 'square active' : 'square'}></div><label>Color</label>
                    </div>
                </div>
                {
                    this.state.radioValue === 0 &&
                    <div className={'string-field-container'}>
                        <CreatableSelect
                            className={'select main'}
                            options={this.state.attributes}
                            placeholder={'Name'}
                            onChange={this.handleCreateSelect}
                            value={this.state.attributes.find(option => option.value === this.state.attributeId)}
                        />

                        <div className={'values'}>
                            {this.renderStringValueFields()}
                        </div>

                        <div className={'pagination'}>
                            {this.renderPagination()}
                        </div>
                        <Button className={this.state.attributeId ? '' : 'disabled'} variant="contained" color="primary" onClick={() => this.addValue(false)}>
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.39 4.886H7.658V0.206H5.474V4.886H0.742V6.94H5.474V11.594H7.658V6.94H12.39V4.886Z" fill="white"/>
                            </svg>
                        </Button>
                    </div>
                }
                {
                    this.state.radioValue === 1 &&
                    <div className={'color-field-container'}>
                        <CreatableSelect
                            className='select'
                            options={this.state.attributes}
                            placeholder={'Name'}
                            onChange={this.handleCreateSelect}
                            value={this.state.attributes.find(option => option.value === this.state.attributeId)}
                        />
                        <div className={'values'}>
                            {this.renderColorValueFields()}
                        </div>
                        <Button className={this.state.attributeId ? '' : 'disabled'} variant="contained" color="primary" onClick={() => this.addColorValue()}>
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.39 4.886H7.658V0.206H5.474V4.886H0.742V6.94H5.474V11.594H7.658V6.94H12.39V4.886Z" fill="white"/>
                            </svg>
                        </Button>
                        <div className={'pagination'}>
                            {this.renderPagination()}
                        </div>
                    </div>
                }
                <div className={'buttons'}>
                    {/* <p className={this.state.attributeId ? 'delete' : 'delete disabled'} onClick={() => {
                        this.props.deleteAttribute(this.state.attributeId);
                    }}>
                        Delete
                    </p> */}
                    <Button onClick={() => {
                        this.props.close()
                    }} className={'save'} variant="contained" color="primary">
                        Done
                    </Button>
                </div>
            </div>
        );
    }
}

export default withSnackbar(withRouter(AddAttributeEditProduct));
