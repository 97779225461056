import React from 'react';
import {Button, TextField} from "@material-ui/core";

import strings from '../localization';
import {getError, hasError} from "../functions/Validation";
import SelectControl from './controls/SelectControl';
import Dropzone from 'react-dropzone';
import { getImage } from '../services/ImageService';


const AddAdmin = ({
    onChange,
    data,
    errors,
    submited,
    goBack,
}) => {

    return(
        <div id="add-category">
            <div className="header">
            Add new admin
                <span className={'exit'} onClick={goBack}>✕</span>
            </div>
            <div className="form">
                <div className="content">
                    <TextField
                        name='email'
                        onChange={ onChange }
                        className={"category-name"}
                        type='text'
                        value={ data.email }
                        error={ getError(errors, 'email') }
                        helperText={ getError(errors, 'email') }
                        placeholder = { "Email" }
                        variant="outlined"
                    />

                    <TextField
                        name='firstName'
                        onChange={ onChange }
                        className={"category-name"}
                        type='text'
                        value={ data.firstName }
                        error={ getError(errors, 'firstName') }
                        helperText={ getError(errors, 'firstName') }
                        placeholder = { "First name" }
                        variant="outlined"
                    />

                    <TextField
                        name='lastName'
                        onChange={ onChange }
                        className={"category-name"}
                        type='text'
                        value={ data.lastName }
                        error={ getError(errors, 'lastName') }
                        helperText={ getError(errors, 'lastName') }
                        placeholder = { "Last name" }
                        variant="outlined"
                    />

                    <TextField
                        name='password'
                        onChange={ onChange }
                        className={"category-name"}
                        type='password'
                        value={ data.password }
                        error={ getError(errors, 'password') }
                        helperText={ getError(errors, 'password') }
                        placeholder = { "Password" }
                        variant="outlined"
                    />

                    <div className="buttons">
                        <Button className="save" onClick={submited}>{ strings.category.save }</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddAdmin;