import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "@material-ui/core";
import strings from '../localization';
import TextField from '@material-ui/core/TextField';
import AddVendor from "../components/AddVendor";
import { getRootCategories, getCategoryByParent, getAllCategories, 
         uploadCategoryImage, addCategory, setTrending,
         updateCategory, deleteCategory } from "../services/CategoryService";
import { getImage } from '../services/ImageService';
import Validators from "../constants/ValidatorTypes";
import {withSnackbar} from "notistack";
import { getVendors,
        createVendor,
        updateVendor,
        deleteVendor } from "../services/VendorService";
import {dateToString} from "../util/DateUtil";
import ReactDOM from 'react-dom';

class Warehouse extends Page {


    constructor(props) {
        super(props);

        this.state = {
    
        }
    }



   

    render() {

        return (
            <div id="partner-warehouse">
                <div className="header">Store your products at our partner warehouses</div>
                <div className="paragraf">
                    Game-changer! Surge: Print on Demand is the only platform that allows its customers to store items on 3 continents in 4 modern warehouses and link them with 40+ printing facilities across the world!
                </div>
                <div className="paragraf">
                    Cut the long delivery times for your drop shipped products. Let us fulfill and ship all your orders under your own brand!
                </div>
                <div className="paragraf">
                    You can store these products in one of 4 modern warehouses located in the US, China, EU, and Non-EU strategic locations and you'll never again need to worry about production delays, holiday cut-offs, and out of stock nightmares! We can also store your handmade items or items which were produced by your vendors.
                </div>

                <div className="row">
                    <div className="item">
                        <img src="/images/warehouse1.png"></img>
                        <div className="item-header">Warehouses in the USA and EU/Non-EU</div>
                        <div className="item-text">Sell globally and reach buyers all over the world</div>
                    </div>
                    <div className="item">
                        <img src="/images/warehouse2.png"></img>
                        <div className="item-header">Same-day shipping</div>
                        <div className="item-text">Orders placed by 12 PM ship out on the some day</div>
                    </div>
                    <div className="item">
                        <img src="/images/warehouse3.png"></img>
                        <div className="item-header">Automatic order fulfillment</div>
                        <div className="item-text">We integrate with top ecommerce platforms</div>
                    </div>
                </div>

                <div className="how-work">How does it work?</div>
        <div className="answer"><div className="dot"><div className="number">1</div></div><div className="answer-text">Click <div className="blue">&nbsp;Add new product&nbsp;</div> and tell us about the items you want to store</div></div>
                <div className="answer"><div className="dot"><div className="number">2</div></div><div className="answer-text">Wait for us to review them, we’ll email you in 2 business days to let you know if they're approved or declined</div></div>
                <div className="answer"><div className="dot"><div className="number">3</div></div><div className="answer-text">Ship us your products after we've approved them for warehousing</div></div>
                <div className="answer"><div className="dot"><div className="number">4</div></div><div className="answer-text">Click <div className="blue">&nbsp;Add new shipment&nbsp;</div> to let us know that the shipment's on its way</div></div>
                <div className="answer"><div className="dot"><div className="number">5</div></div><div className="answer-text">Hold on until we receive, register, and stock your products—we’ll email you once that’s done</div></div>
                <div className="answer"><div className="dot"><div className="number">6</div></div><div className="answer-text">Add warehousing product to your store - go to <div className="blue">&nbsp;Product Hub {">"} export warehousing product to Shopify&nbsp;</div> or go to Place an Order and choose from Warehouse Items to submit an order manually.</div></div>
                <div className="answer"><div className="dot"><div className="number">7</div></div><div className="answer-text">Start selling and manage your warehoused stock under <div className="blue">&nbsp;Warehouse {">"} Inventory&nbsp;</div> </div></div>
            
                <p className="contact">Contact your dedicated Account Manager to Unlock this Feature or <p className="blue">&nbsp;support@surgeprint.com&nbsp;</p> for more information about the pricing and product storing requirements.</p>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Warehouse)));
