import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import MultiSelectInput from 'src/redesign/components/Form/Sidepane/MultiSelectInput';
import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import Loader from 'src/redesign/components/Loader';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getMediaModifierMockups } from 'src/services/MediaModifierService';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import { syncProductVariationsWithMockups } from 'src/services/admin/ProductVariationService';

const ProductVariationMediaModifier = ({ selectedMockups, productVariationIds, onLoad }) => {
  const [loading, setLoading] = React.useState(false);
  const [mmMockupOptions, setMmMockupOptions] = React.useState([]);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadMmMockups();
  }, []);

  const loadMmMockups = async () => {
    setLoading(true);
    try {
      const { data } = await getMediaModifierMockups();

      setMmMockupOptions(data.data.map(x => ({ key: x.id, text: x.name })))
    } catch (error){
      openSnackBar('Failed to load media modifier mockups', false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const onUpdateMediaModifier = async ({ selectedOptions }) => {
    await syncProductVariationsWithMockups({
      productVariationIds,
      selectedMockupIds: selectedOptions,
    })
    onLoad();
  }

  return (
    loading
    ? 
    <Loader width={100} height={100} color={colors.primary} className='edit-variation-media-modifier-loader' />
    :
    <FinalForm
      onSubmit={onUpdateMediaModifier}
      initialValues={{ selectedOptions: selectedMockups }}
      render={({ handleSubmit, valid, submitting }) => (
        <form onSubmit={handleSubmit} className='edit-variation-media-modifier'>
          <div className='edit-variation-media-modifier-title'>
            Choose <b>Media Modifier</b> mockups to be used for selected SKUs
          </div>
          <MultiSelectInput
            options={mmMockupOptions}
            name='selectedOptions'
          />
          <SubmitPrimaryButton
            text='Save'
            width={224}
            type='submit'
            loading={submitting}
            disabled={!valid || submitting || productVariationIds.length == 0}
          />
        </form>
      )}
    />
  )
}

ProductVariationMediaModifier.propTypes = {

}

export default ProductVariationMediaModifier;