import React from 'react';
import Overview from 'src/redesign/components/Overview';
import Status from 'src/redesign/components/Status';

import colors from 'src/scss/_colors.scss';
import { dateToString } from 'src/util/DateUtil';
import { getOrderSourceString } from 'src/constants/OrderSource';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getAllVendors } from 'src/services/VendorService';
import SelectInput from 'src/redesign/components/Form/SelectInput';
import { saveVendor } from 'src/services/OrderService';
import { getUserFromLocalStorage } from 'src/base/OAuth';
import { v4 as uuid } from 'uuid';

import './style.scss';

const OrderSummaryDetails = ({ order, onUpdate }) => {
  const { id, status, totalPrice, quantity, orderSource, createdAt, vendor, totalWeight, items = [] } = order;
  const user = getUserFromLocalStorage();
  const isAdmin = user?.roles?.includes('SUPER_ADMIN');

  const [vendorOptions, setVendorOptions] = React.useState([]);
  const [vendors, setVendors] = React.useState([]);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadVendors();
  }, []);

  const loadVendors = async () => {
    try {
      const { data: { vendors } } = await getAllVendors();

      setVendors(vendors);
      setVendorOptions(vendors.map(x => ({ key: x.id, text: x.name })));
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load vendors!', false);
    }
  }

  const save = async ({ vendor }) => {
    try {
      const newVendor = vendors.find(x => x.id === parseInt(vendor));
      saveVendor({
        orderId: id,
        vendor: newVendor
      });
      
      onUpdate({ ...order, vendor: newVendor.name })
      openSnackBar('Successfully changed vendor');
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to save vendor!', false);
    }
  }

  const vendorMaybe = isAdmin 
  ? {
    name: 'Vendor',
    value: vendor ? vendor.name : '-',
    edit: <SelectInput
      name="vendor"
      options={vendorOptions}
      placeholder="Vendor"
    />
    }
  : {};

  return (
    <Overview
      items={[
        {
          name: 'Status',
          value: <div className='status-container'>
            {_.uniq(items.map(x => x?.status)).map(x => <Status key={uuid()} statusNumber={x} />)}
          </div>
        },
        {
          name: 'Order Total',
          value: <>USD <span style={{ color: colors.green }}>{totalPrice && totalPrice.toFixed(2)}</span></>
        },
        {
          name: 'Items in Order',
          value: quantity
        },
        {
          name: 'Order Source',
          value: getOrderSourceString(orderSource)
        },
        {
          name: 'Total weight',
          value: totalWeight
        },
        {
          name: 'Order date',
          value: dateToString(createdAt)
        },
        {...vendorMaybe},
        {
          name: 'Credit Terms',
          value: 'No'
        }
      ]}
      showEditButton={isAdmin}
      onSubmit={save}
    />
  )
}

export default OrderSummaryDetails;