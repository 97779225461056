import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import Sidepane from 'src/redesign/components/Sidepane';
import TextInput from 'src/redesign/components/Form/Sidepane/TextInput';
import SelectInput from 'src/redesign/components/Form/Sidepane/SelectInput';
import PrimarySubmitButton from 'src/redesign/components/Buttons/SubmitPrimary';
import Image from 'src/redesign/components/Image';
import * as Validators from 'src/redesign/util/validators';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const CreateEditCategorySidepane = ({
  title,
  onClose,
  onSubmit,
  initialValues,
  parentCategories,
  saving
}) => {
  const [image, setImage] = React.useState(initialValues?.image);

  const onFilePick = event => {
    const file = event.target.files[0];
    setImage(file);
    var oFReader = new FileReader();
    oFReader.readAsDataURL(document.getElementById("categoryImage").files[0]);

    oFReader.onload = function (oFREvent) {
        document.getElementById("categoryPreview").src = oFREvent.target.result;
    };
  }

  return (
    <Sidepane
      title={title}
      onClose={onClose}
      content={
        <FinalForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form id="addEditCategory" className='category-form' onSubmit={handleSubmit}>
              <TextInput
                name='name'
                label='Product Name'
                placeholder='Name'
                className='category-form-input'
                containerClassName='category-form-input-container'
                validate={Validators.required(' ')}
              />
              <TextInput
                name='description'
                label='Product Description'
                className='category-description'
                containerClassName='category-form-input-container'
                type='textarea'
                placeholder='Enter description'
              />
              <TextInput
                name='seoTitle'
                label='Seo Title'
                placeholder='Seo Title'
                className='category-form-input'
                containerClassName='category-form-input-container'
              />
              <TextInput
                name='seoDescription'
                label='Seo Description'
                placeholder='Seo Description'
                type='textarea'
                className='category-description'
                containerClassName='category-form-input-container'
              />
              <div className='category-category-column'>
                <div className='category-category-label'>
                  Select category
                </div>
                <SelectInput
                  name='parentCategory'
                  options={parentCategories}
                  placeholder='Select...'
                />
              </div>
              <div className='upload-image-container'>
                <div className='upload-image-label'>
                  Upload image
                </div>
                <input type='file' id='categoryImage' name='categoryImage' onChange={onFilePick} />
                <Image alt={getImageAlt()} id='categoryPreview' className='category-image' src={image ? image : '/images/image-placeholder.png'} />
              </div>
            </form>
          )}
        />
      }
      actions={
        <PrimarySubmitButton
          text='Save'
          onClick={() =>
            document.getElementById('addEditCategory')
            .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
          }
          width={160}
          height={40}
          disabled={saving}
          loading={saving}
        />
      }
    />
  )
}

export default CreateEditCategorySidepane;