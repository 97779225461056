import React, {Component} from 'react'


class Footer extends Component {


    render() {

        return (
               <footer>
               </footer>
        );
    }
}

export default Footer;