import React from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Switch
} from "@mui/material";
import TextField from '@material-ui/core/TextField';
import {withSnackbar} from "notistack";
import { confirm } from "react-confirm-box";
import SelectControl from "../components/controls/SelectControl";
import {
    uploadExportCustomerTemplateImage,
    getShopifyCollections,
    getOtherVariation,
    getCustomerTemplate,
    saveCustomerTemplate,
    syncWithStores
} from '../services/admin/ProductVariationService';
import { getError } from '../functions/Validation';
import { Delete as DeleteIcon, Edit as EditIcon, Check as CheckIcon, Info as InfoIcon } from '@mui/icons-material';
import Store from "../constants/Store";
import {getActiveStores} from "../base/OAuth";
import {Alert} from "@mui/lab";
import Helmet from 'react-helmet';

class ExportProduct extends Page {
    constructor(props) {
        super(props);

        const stores = getActiveStores();

        this.state = {
            storage: {
                name: null,
                description: null,
                image: null,
            },
            shopify: {
                id: null,
                name: null,
                description: null,
                tags: [],
                options: [],
                variations: [],
                earned: 0.00,
                isDraft: false,
                isPublished: false,
                isImported: false
            },
            etsy: {
                id: null,
                name: null,
                description: null,
                tags: [],
                options: [],
                variations: [],
                earned: 0.00,
                isDraft: false,
                isPublished: false,
                isImported: false
            },
            uploadText: 'Save',
            variations: [],
            templateForEditId: this.props.match.params.id ? this.props.match.params.id : null,
            customerProductVariationsIds: this.props.history.location.state ? this.props.history.location.state.customerProductVariations : null,
            lastPage: this.props.history.location.state ? (!!this.props.history.location.state.lastPage) : false,
            uploadedImageId: null,
            uploadedImage: null,
            customerProductVariations: [],
            activeTab: Store.storage,
            importExportDialog: false,
            data: {
                name: null,
                description: null,
                optionsEtsy: [],
                options: [],
                newTag: null,
                newOption: null,
                shopify: stores.shopify,
                collection: null,
                earned: 0.00,
                earnedEtsy: 0.00,
                draft: false,
                storage: true,
                etsy: stores.etsy,
                sku: null,
                itemEarned: 0.00,
                itemEarnedEtsy: 0.00,
                newImage: false
            },
            errors: [],
            removedTagsEtsy: [],
            addTag: false,
            addOption: false,
            collections: [],
            minPrice: 0,
            maxPrice: 0,
            editGlobalPrice: false,
            exportProductVariations: [],
            editVariation: null,
            attributes: [],
            loading: false,
            loader: true,
            popup: false,
            otherVariations: [],
            selectedVariation: {},
            customerStores: {
                etsy: stores.etsy,
                shopify: stores.shopify
            }
        }

        this.uploadPicture = this.uploadPicture.bind(this);
        this.toggleImportExportDialog = this.toggleImportExportDialog.bind(this);
        this.saveCustomerTemplate = this.saveCustomerTemplate.bind(this);
        this.populateStoreFromResponse = this.populateStoreFromResponse.bind(this);
        this.getExportCustomerTemplate = this.getExportCustomerTemplate.bind(this);
        this.uploadCustomerVariationPicture = this.uploadCustomerVariationPicture.bind(this);
        this.saveVariation = this.saveVariation.bind(this);
        this.saveOnSelectChange = this.saveOnSelectChange.bind(this);
        this.changeEtsy = this.changeEtsy.bind(this);
        this.activeTab = this.activeTab.bind(this);

    }

    componentDidMount() {
        if (this.state.customerStores.shopify) {
            this.getShopifyCollections();
        }

        this.getExportCustomerTemplate(this.state.templateForEditId);
    }

    toggleImportExportDialog() {
        this.setState({
            ...this.state,
            importExportDialog: !this.state.importExportDialog
        })
    }

    uploadCheck(store) {
        if (store === Store.shopify) {
            if (this.state.shopify.isPublished === false && this.state.shopify.isImported === true) {
                this.toggleImportExportDialog();
                return;
            }
        } else if (store === Store.etsy) {
            if (this.state.etsy.isPublished === false && this.state.etsy.isImported === true) {
                this.toggleImportExportDialog();
                return;
            }
        }

        this.saveCustomerTemplate(true);
    }

    errorMessage(message) {
        this.props.enqueueSnackbar(message, {variant: 'error'});
    }

    errorMessageOnSave(message) {
        this.setState({
            ...this.state,
            loading: false,
        });

        this.errorMessage(message);
    }

    storageValidation() {
        if (!this.state.storage.name) {
            this.errorMessageOnSave("Name field is required.");
            return false;
        }

        if (!this.state.storage.description) {
            this.errorMessageOnSave("Description field is required.");
            return false;
        }

        return true;
    }

    shopifyValidation() {
        if (this.state.shopify.options.length === 0) {
            this.errorMessageOnSave("You must add at least one option for Shopify!");
            return false;
        }

        let earningError = false;
        this.state.shopify.variations.forEach((element) => {
            if (element.earned < 0) {
                earningError = true;
            }
        });

        if (earningError) {
            this.errorMessageOnSave("You must have positive earnings!");
            return false;
        }

        if (this.state.shopify.name === null || this.state.shopify.name === '') {
            this.errorMessageOnSave("Name is required field!");
            return false;
        }

        return true;
    }

    etsyValidation() {
        let earningError = false;
        this.state.etsy.variations.forEach((element) => {
            if (element.earned < 0) {
                earningError = true;
            }
        });

        if (this.state.etsy.options.length === 0) {
            this.errorMessageOnSave("You must add at least one option for Etsy!");
            return false;
        }

        if (earningError) {
            this.errorMessageOnSave("You must have positive earnings!");
            return false;
        }

        if (this.state.etsy.name === null || this.state.etsy.name === '') {
            this.errorMessageOnSave("Name is required field!");
            return false;
        }

        return true;
    }

    saveCustomerTemplate(sync = false) {
        this.setState({
            ...this.state,
            loading: true,
            importExportDialog: false
        });

        let data = {
            storage: this.state.storage
        };

        if (!this.storageValidation()) {
            return;
        }

        if (this.state.shopify && (this.state.shopify.id || (this.state.shopify.id == null && this.state.shopify.isPublished))) {
            if (!this.shopifyValidation()) {
                return;
            }

            data.shopify = { ...this.state.shopify, collection: this.state.data.collection };
        }

        if (this.state.etsy && (this.state.etsy.id || (this.state.etsy.id == null && this.state.etsy.isPublished))) {
            if (!this.etsyValidation()) {
                return;
            }

            data.etsy = this.state.etsy;
        }

        if (sync) {
            syncWithStores(this.state.templateForEditId, data).then((response) => {
                if (!response.ok) {
                    this.errorMessageOnSave("Something went wrong with syncing, try again.");
                    return;
                }

                this.props.history.push('/product-templates');
                this.props.enqueueSnackbar("Successfully synced with store!", {variant: 'success'});
            });
        } else {
            saveCustomerTemplate(this.state.templateForEditId, data).then((response) => {

                if (!response.ok) {
                    this.errorMessageOnSave("Something went wrong with syncing, try again.");
                    return;
                }

                this.props.history.push('/product-templates');
                this.props.enqueueSnackbar("Successfully saved!", {variant: 'success'});
            });
        }
    }

    getExportCustomerTemplate() {
        getCustomerTemplate(this.state.templateForEditId).then(response => {
            let activeTab = Store.storage;
            const data = response.data;
            const shopifyData = data.shopify;
            const etsyData = data.etsy;
            const variations = data.storage;
            let minPrice = Infinity;
            let maxPrice = 0;

            const storeVariations = variations.map((element) => {
                if (minPrice > element.price) {
                    minPrice = element.price;
                }

                if (maxPrice < element.price) {
                    maxPrice = element.price;
                }

                return {
                    id: 0, // should be null, but it's like this until editVariation isn't deleted from the code
                    productVariation: element.productVariation,
                    price: element.price,
                    image: element.image,
                    earned: element.earned
                }
            });

            const storage = {
                name: data.name,
                description: data.description,
                image: data.image,
                variations: variations
            };

            let shopify = null;
            let etsy = null;

            //> shopify //
            if (shopifyData === null) {
                if (this.state.customerStores.shopify) {
                    shopify = {
                        ...this.state.shopify,
                        name: storage.name,
                        description: storage.description,
                        variations: storeVariations,
                        options: data.attributes.slice(0, 3)
                    }
                }
            } else {
                shopify = this.populateStoreFromResponse(Store.shopify, shopifyData);
            }
            //< shopify //

            //> etsy //
            if (etsyData === null) {
                if (this.state.customerStores.etsy) {
                    etsy = {
                        ...this.state.etsy,
                        name: storage.name,
                        description: storage.description,
                        variations: storeVariations,
                        options: (data.attributes.length <= 2) ? data.attributes.slice(0, 2) : []
                    }
                }
            } else {
                etsy = this.populateStoreFromResponse(Store.etsy, etsyData);
            }
            //< etsy //

            if (shopify?.isPublished) {
                activeTab = Store.shopify;
            } else if (etsy?.isPublished) {
                activeTab = Store.etsy;
            }

            this.setState({
                storage: storage,
                shopify: shopify,
                etsy: etsy,
                activeTab: activeTab,
                variations: data.variations,
                minPrice: parseFloat(minPrice),
                maxPrice: parseFloat(maxPrice),
                attributes: data.attributes,
                data: {
                    ...this.state.data,
                    shopify: shopify?.isPublished ?? false,
                    etsy: etsy?.isPublished ?? false
                },
                uploadText: (shopify?.isPublished || etsy?.isPublished) ? 'Save to stores' : 'Save',
                loader: false,
            })
        })
    }

    populateStoreFromResponse(store, response) {
        if (store === Store.shopify) {
            return {
                id: response.id,
                name: response.name,
                description: response.description,
                earned: response.earned,
                isDraft: response.isDraft,
                isPublished: response.isPublished,
                isImported: response.isImported,
                tags: response.tags.map((element) => element.name),
                options: response.options.map((element) => {
                    let attribute = element.attribute;
                    return {
                        id: attribute.id,
                        name: attribute.name
                    }
                }),
                variations: response.customerProductVariationShopify.map((element) => {
                    return {
                        id: element.id,
                        productVariation: {
                            id: element.customerProductVariation.productVariation.id,
                            name: element.customerProductVariation.productVariation.name
                        },
                        price: element.price,
                        earned: element.earned,
                        image: element.image
                    }
                }),
            }
        }

        if (store === Store.etsy) {
            return {
                id: response.id,
                name: response.name,
                description: response.description,
                earned: response.earned,
                isDraft: response.isDraft,
                isPublished: response.isPublished,
                isImported: response.isImported,
                tags: response.tags.map((element) => element.name),
                options: response.options.map((element) => {
                    let attribute = element.attribute;
                    return {
                        id: attribute.id,
                        name: attribute.name
                    }
                }),
                variations: response.customerProductVariationEtsy.map((element) => {
                    return {
                        id: element.id,
                        productVariation: {
                            id: element.customerProductVariation.productVariation.id,
                            name: element.customerProductVariation.productVariation.name
                        },
                        price: element.price,
                        earned: element.earned,
                        image: element.image
                    }
                }),
            }
        }
    }

    getShopifyCollections() {
        getShopifyCollections().then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                ...this.state,
                collections: response.data
            });
        })
    }

    uploadPicture(event) {
        if (!event.target.files[0]){
            return
        }

        let file = event.target.files[0];

        let formData = new FormData();

        formData.append('image', file);

        uploadExportCustomerTemplateImage(formData).then(response => {
            if (!response.ok){
                return;
            }

            this.setState({
                ...this.state,
                storage: {
                    ...this.state.storage,
                    image: response.data.path
                }
            });
        })
    }

    uploadCustomerVariationPicture(sku, event, store) {
        if (!event.target.files[0]){
            return
        }

        let file = event.target.files[0];

        let formData = new FormData();

        formData.append('image', file);

        uploadExportCustomerTemplateImage(formData).then(response => {
            if (!response.ok) {
                return
            }

            let variations = this.state[store].variations;

            for (let variation of variations) {

                if (variation.sku === sku){
                    variation.image = response.data.image.url;
                    break;
                }
            }

            this.setState({
                [store]: {
                    ...this.state[store],
                    variations: variations
                }
            })
        })
    }

    openUploadImage(){
        document.getElementById("customer-tempalte-image").click();
    }

    openUploadCustomerVariationImage(){
        document.getElementById("customer-product-variation-image").click();
    }

    openTextFieldTag(){
        this.setState({
            ...this.state,
            addTag: !this.state.addTag
        })
    }

    openTextFieldOption(store) {
        let limit = 1;
        if (store === Store.etsy) {
            limit = 2
        } else if (store === Store.shopify) {
            limit = 3;
        }

        if (this.state[store].options.length !== limit) {
            this.setState({
                ...this.state,
                addOption: !this.state.addOption
            })
        }

    }

    tagEntered(store, event) {
        if (event.key === 'Enter') {
            let tags = this.state[store].tags;

            tags.push(this.state.data.newTag);

            this.setState({
                ...this.state,
                [store]: {
                  ...this.state[store],
                  tags: tags,
                },
                addTag: !this.state.addTag,
                data: {
                    ...this.state.data,
                    newTag: null,
                    tags: tags
                }
            })
        }
    }

    optionEntered(event){
        if(event.key === 'Enter') {


            let options = this.state.data.options;

            options.push(this.state.data.newOption);

            this.setState({
                ...this.state,
                addOption: !this.state.addOption,
                data: {
                    ...this.state.data,
                    newOption: null,
                    options: options
                }

            })
        }
    }

    renderTags(store) {

        let result = [];
        let index = 0;

        for (let item of this.state[store].tags) {
            let i = index;
            result.push(
                <Chip label={item} variant="outlined" size="small" onDelete={() => this.removeTag(store, i) } />
            );

            index++;
        }

        return result;
    }

    renderOptions(store) {
        let result = [];
        let list = Object.values(this.state[store].options);
        let index = 0;

        for (let item of list) {
            let i = index;
            result.push(
                <Chip label={item.name} variant="outlined" size="small" onDelete={() => this.removeOption(i, store) } />
            );

            index++;
        }

        return result;
    }

    removeTag(store, index) {
        let tags = this.state[store].tags;
        tags.splice(index,1);

        this.setState({
            [store]: {
                ...this.state[store],
                tags: tags
            }
        });
    }

    removeOption(index, store) {
        let options = this.state[store].options;
        options.splice(index, 1);

        this.setState({
            ...this.state,
            [store]: {
                ...this.state[store],
                options: options
            }
        });
    }

    changeShopify() {
        this.setState({
            activeTab: this.state.data.shopify ? Store.storage : Store.shopify,
            shopify: {
                ...this.state.shopify,
                isPublished: !this.state.data.shopify
            },
            data: {
                ...this.state.data,
                shopify: !this.state.data.shopify
            },
            uploadText: this.state.data.etsy || !this.state.data.shopify ? 'Save to store' : 'Save'
        })
    }

    changeEtsy() {
        this.setState({
            activeTab: this.state.data.etsy ? Store.storage : Store.etsy,
            etsy: {
                ...this.state.etsy,
                isPublished: !this.state.data.etsy
            },
            data: {
                ...this.state.data,
                etsy: !this.state.data.etsy
            },
            uploadText: !this.state.data.etsy || this.state.data.shopify ? 'Save to store' : 'Save'
        })
    }

    changeStorage() {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                storage: !this.state.data.storage
            }
        })
    }

    changeDraft(store) {
        this.setState({
            [store]: {
                ...this.state[store],
                isDraft: !this.state[store].isDraft
            }
        })
    }

    changeGlobalPrice(store = null) {
        if (!store) {
            this.setState({
                ...this.state,
                editGlobalPrice: !this.state.editGlobalPrice
            });
            return;
        }

        let value = this.state.data.earned;

        if (value < 0) {
            this.errorMessage("Earning cannot be negative!");
            return;
        }

        let variations = this.state[store].variations;

        this.setState({
            ...this.state,
            editGlobalPrice: !this.state.editGlobalPrice,
            [store]: {
                ...this.state[store],
                earned: value,
                variations: variations.map((variation) => {
                    return {
                        ...variation,
                        earned: value
                    }
                })
            },
            data: {
                ...this.state.data,
                earned: 0,
            }
        })
    }

    renderFilters(){
        let result = [];

        let index = 0;
        let list = Object.values(this.state.data.options);
        for(let item of list)
        {
            let i = index;
            result.push(
                <div className="product-filter">{item.name}</div>
            );

            index++;
        }

        return result;
    }

    async deleteProductVariation(index, store) {
        window.scrollTo({top: 0, behavior: 'smooth'});

        const result = await confirm('Are you sure?');

        if (result) {
            let variations = this.state[store].variations;
            variations.splice(index, 1);

            this.setState({
                [store]: {
                    ...this.state[store],
                    variations: variations
                }
            })
        }
    }

    renderAllVariants() {
        let result = [];

        for (let item of this.state.variations) {
            result.push(
                <div className="product-variation">
                    <img src={item.image}/>
                    <div className="product-variation-sku-all">{ item.customerProductVariation.productVariation.name }</div>
                </div>
            );
        }

        return result;
    }

    renderProductVariation(store) {
        let result = [];
        let variations = [];

        let index = 0;

        if (store === Store.shopify) {
            variations = this.state.shopify.variations;
        } else if (store === Store.etsy) {
            variations = this.state.etsy.variations;
        } else if (store === Store.storage) {
            variations = this.state.storage.variations;
        }

        if (!variations) {
            return;
        }

        for (let item of variations) {
            let i = index;
            result.push(
                <div className="product-variation">
                    <img src={item.image}/>
                    {/*{*/}
                    {/*    this.state.editVariation == item.sku &&*/}
                    {/*    <div className="container-image" onClick={() => this.openUploadCustomerVariationImage()}>*/}
                    {/*        <img src={process.env.REACT_APP_baseUrl + item.image}/>*/}
                    {/*        <div className="middle">*/}
                    {/*            <div className="text">Change</div>*/}
                    {/*        </div>*/}
                    {/*        <input style={{display: "none"}} id="customer-product-variation-image" type={'file'} name={'artwork'} className="upload" onChange={(e) => this.uploadCustomerVariationPicture(item.sku, e, Store.shopify)} accept="image/x-png,image/gif,image/jpeg"/>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    <div className="product-variation-sku">{ item.productVariation.name }</div>
                    <div className="summ-price">
                        <div className="summ-name">Surge print price</div>
                        <div className="amount">$ {parseFloat(item.price).toFixed(2)}</div>
                    </div>
                    <div className="character">+</div>
                    {
                        this.state.editVariation !== item.productVariation.name &&
                        <div className="summ-price">
                            <div className="summ-name">Expected to be earned</div>
                            <div className="amount">${parseFloat(item.earned ? item.earned : 0).toFixed(2)}</div>
                        </div>
                    }
                    {
                        this.state.editVariation === item.productVariation.name &&
                        <TextField
                            name='itemEarned'
                            onChange={ this.changeData }
                            className={"edit-price"}
                            type='number'
                            InputProps={{ inputProps: {min: 0, step: 0.1} }}
                            value={ this.state.data.itemEarned }
                            variant="outlined"
                        />
                    }
                    <div className="character">=</div>
                    <div className="summ-price">
                        <div className="summ-name">End buyer price</div>
                        <div className="amount">${parseFloat(parseFloat(item.price) + parseFloat(item.earned ? item.earned : 0)).toFixed(2)}</div>
                    </div>
                    <div className="edit-delete-options">
                        {
                            this.state.editVariation !== item.productVariation.name &&
                            <>
                                <a onClick={ () => this.editVariation(item) }><EditIcon /></a>
                                {
                                    store !== Store.storage &&
                                    <a className="delete-option" onClick={ () => this.deleteProductVariation(i, store) }><DeleteIcon /></a>
                                }
                            </>
                        }
                        {
                            this.state.editVariation === item.productVariation.name &&
                            <a onClick={ () => this.saveVariation(item.productVariation.name, store) }><CheckIcon /></a>
                        }
                    </div>
                </div>
            );

            index++;
        }

        return result;
    }

    editVariation(item) {

        if (item.earned < 0) {
            this.errorMessage("Something went wrong with syncing, try again.");
            return;
        }

        this.setState({
            ...this.state,
            editVariation: item.productVariation.name,
            data: {
                ...this.state.data,
                sku: item.productVariation.name,
                itemEarned: item.earned
            }
        })
    }

    saveVariation(sku, store) {

        let variations = this.state[store].variations;

        for (let variation of variations) {

            if (variation.productVariation.name == sku){
                variation.earned = this.state.data.itemEarned ? this.state.data.itemEarned : 0;

                if (variation.earned < 0) {
                    this.errorMessage("Earning cannot be negative!");
                    return;
                }

                break;
            }
        }

        this.setState({
            ...this.state,
            [store]: {
                ...this.state[store],
                variations: variations
            },
            editVariation: null,
        });
    }

    saveOnSelectChange(e, store) {
        let options = [];

        if (store === Store.etsy) {
            options = this.state.etsy.options;
        } else if (store === Store.shopify) {
            options = this.state.shopify.options;
        }

        let added = false;
        options.forEach(element => {
            if(element.name === e.target.value.name){
                added = true;
            }
        });

        if (!added) {
            options.push(e.target.value);
        } else {
            this.setState({
                ...this.state,
                addOption: false,
            });

            this.props.enqueueSnackbar("Option already added!", {variant: 'error'});
            return;
        }

        this.setState({
            ...this.state,
            addOption: false,
            data: {
                ...this.state.data,
                //newOption: null,
                options: options
            },
            [store]: {
                ...this.state[store],
                options: options
            }
        })
    }

    cancelPopup(){
        this.setState({
            ...this.state,
            popup: false
        })
    }

    openPopup(){
        let variations = {};

        this.state.exportProductVariations.forEach(element => {
            variations[element.id] = true;
        });

        getOtherVariation({
            id: this.state.customerProductVariations[0].customer_template.id,
            variationIds: variations
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.state.otherVariations = response.data;
            let selectedVariations = {}
            this.state.otherVariations.forEach(element => {
                selectedVariations[element.id] = false;
            });
            this.state.selectedVariation = selectedVariations;
            this.setState({
                ...this.state,
                popup: true
            })
        });
    }

    checkVariation(e){
        this.state.selectedVariation[e.target.value] = !this.state.selectedVariation[e.target.value];
    }

    addNewVariations(){
        let result = [];
        this.state.otherVariations.forEach(item => {
            if(this.state.selectedVariation[item.id]){
                let object = {
                    "id": item.id,
                    "image" : item.customer_product_variation_images[0].merged_image,
                    "imageUpdate" : false,
                    "earned" : 0.00,
                    "price": item.product_variation.price,
                    "sku": item.product_variation.name,
                    "customerProductVariationId": item.id,
                    "newImage" : false,
                }

                result.push(object);
            }
        });

        let variations = this.state.exportProductVariations;
        variations = variations.concat(result);

        this.setState({
            ...this.state,
            exportProductVariations: variations,
            popup: false,
            selectedVariations: {}
        });

    }

    renderOtherVariations(){
        let toRender = [];

        this.state.otherVariations.forEach(item => {
            toRender.push(
                <div key={item.id} className={'variation'}>
                    <Checkbox
                        key={item.id}
                        onChange={ (e) => this.checkVariation(e) }
                        margin="normal"
                        value={item.id}
                        name="variationSelect"
                        //checked={this.state.selectedVariations.includes(item.id)}
                        //className={this.state.data.showCheckboxes ? '' : 'disabled'}
                    />
                    <p>{item.product_variation.name}</p>
                </div>
            );
        });

        return toRender;
    }

    activeTab(tab){
        this.setState({
            ...this.state,
            activeTab: tab
        })
    }

    // TODO: Deprecated
    // copy(){
    //
    //     let variations = this.state.exportProductVariations;
    //
    //     for(let variation of variations){
    //         variation.earnedEtsy = variation.earned;
    //         variation.skuEtsy = variation.sku;
    //     }
    //
    //     this.setState({
    //         ...this.state,
    //         exportProductVariations : variations,
    //         data : {
    //             ...this.state.data,
    //             nameEtsy : this.state.data.name,
    //             descriptionEtsy : this.state.data.description,
    //             optionsEtsy : this.state.data.options,
    //             tagsEtsy : this.state.data.tags,
    //             earnedEtsy : this.state.data.earned
    //         }
    //     })
    // }


    render() {
        if(!this.state.customerProductVariations){
            return "";
        }

        return (
            <div id="export-product">
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                {
                    this.state.loader &&
                    <div className={'loader-cont'}>
                        <img src={'/images/loader.gif'} />
                    </div>
                }
                {
                    this.state.popup &&
                    <div className="variations-popup">
                        <div className="variations-popup-overlay"/>
                        <div className="variations-popup-content">
                            <div className="header">
                                Other variations
                            </div>
                            <div className="other-variations">

                                { this.renderOtherVariations() }

                            </div>
                            <div className="cacel-add-buttons">
                                <Button className="cancel" onClick={ () => this.cancelPopup() }>Cancel</Button>
                                <Button className="done" onClick={ () => this.addNewVariations() }>Add</Button>
                            </div>

                        </div>
                    </div>
                }
                <div className={'tab'}>
                    {
                        this.state.data.storage &&
                        <span className={this.state.activeTab === Store.storage ? 'active' : ''} onClick={()=>this.activeTab(Store.storage)}>Storage</span>
                    }
                    {
                        this.state.customerStores.shopify && this.state.data.shopify &&
                        <span className={this.state.activeTab === Store.shopify ? 'active' : ''} onClick={()=>this.activeTab(Store.shopify)}>Shopify</span>
                    }
                    {
                        this.state.customerStores.etsy && this.state.data.etsy &&
                        <span className={this.state.activeTab === Store.etsy ? 'active' : ''} onClick={()=>this.activeTab(Store.etsy)}>Etsy</span>
                    }
                </div>
                
                {
                    this.state.activeTab !== '' ?
                    <div className="export-header">Product Details</div> :
                    <div className="export-header">Please go to <span className="btn-light" onClick={() => {this.props.history.push('/stores') }}>Stores tab</span> and connect your online shop to SurgePrint in order to export Products</div>
                }
                {
                    this.state.activeTab === Store.shopify &&
                    <div className={'shopify'}>
                        <Dialog
                            open={this.state.importExportDialog}
                            onClose={() => this.state.importedProduct = false}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                You are going to override listing completely!
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Complete listing would be updated on your shopify store (name, description, tags, options, prices). Do you agree?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.toggleImportExportDialog} color="primary">Disagree</Button>
                                <Button onClick={() => this.saveCustomerTemplate(true)} color="primary" autoFocus>
                                    Agree
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <div className="export-details">
                            <div className="export-details-left">
                                <div className="export-image">
                                    <div className="template-global-image">
                                        <div className="product-header">
                                            Product
                                            <input style={{display: "none"}} id="customer-tempalte-image" type={'file'}
                                                   name={'artwork'} className="upload"
                                                   onChange={(e) => this.uploadPicture(e)}
                                                   accept="image/x-png,image/gif,image/jpeg"/>
                                            <a onClick={() => this.openUploadImage()} className="upload-image">Upload your own Mockup</a>
                                        </div>
                                        <img className="product-image" src={this.state.storage.image} />
                                    </div>
                                    <div className="platform-exporting">
                                        <div className="product-header">
                                            Sales channels
                                        </div>
                                        {
                                            this.state.customerStores.shopify &&
                                            <div className="platform-div">
                                                <label className="container">Shopify
                                                    <input type="checkbox" onChange={() => this.changeShopify()} checked={this.state.data.shopify} />
                                                    <span className="checkmark" />
                                                </label>
                                            </div>
                                        }
                                        {
                                            this.state.customerStores.etsy &&
                                            <div className="platform-div">
                                                <label className="container">Etsy
                                                    <input type="checkbox" onChange={() => this.changeEtsy()} checked={this.state.data.etsy} />
                                                    <span className="checkmark" />
                                                </label>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="product-info">
                                    <div className="product-header">
                                        Product Info:
                                    </div>

                                    <TextField
                                        required
                                        name='name'
                                        onChange={(e) => this.updateCustomerTemplateData(e, Store.shopify)}
                                        type='text'
                                        label="Name"
                                        value={this.state.shopify.name}
                                        error={getError(this.state.errors, 'name')}
                                        helperText={getError(this.state.errors, 'name')}
                                        placeholder={"Name"}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />

                                    <TextField
                                        required
                                        name='description'
                                        onChange={(e) => this.updateCustomerTemplateData(e, Store.shopify)}
                                        label="Description"
                                        type='text'
                                        value={this.state.shopify.description}
                                        error={getError(this.state.errors, 'description')}
                                        helperText={getError(this.state.errors, 'description')}
                                        placeholder={"Description"}
                                        variant="outlined"
                                        multiline
                                        rows={6}
                                        InputLabelProps={{ shrink: true }}
                                    />

                                    <div className="export-tags">
                                        <div className="tags-container">
                                            {this.renderTags(Store.shopify)}
                                        </div>

                                        {
                                            this.state.addTag &&
                                            <TextField
                                                name='newTag'
                                                onChange={this.changeData}
                                                onKeyPress={(e) => this.tagEntered(Store.shopify, e)}
                                                className={"tag-input"}
                                                type='text'
                                                value={this.state.data.newTag}
                                                variant="outlined"
                                                id="tag-input"
                                                placeholder="Enter tag name"
                                            />
                                        }

                                        {
                                            !this.state.addTag &&
                                            <Button className="add-more-tags" onClick={() => this.openTextFieldTag()}>+
                                                Add more tags</Button>
                                        }
                                    </div>

                                    <div className="export-tags">
                                        <div className="tags-container">
                                            {this.renderOptions(Store.shopify)}
                                        </div>

                                        {
                                            this.state.addOption &&
                                            <SelectControl
                                                className='select'
                                                options={this.state.attributes}
                                                valueKey={'id'}
                                                onChange={(e) => this.saveOnSelectChange(e, Store.shopify)}
                                                nameKey={'name'}
                                                name={'newTag'}
                                                selected={this.state.shopify.options}
                                                placeholder={'Choose Options'}
                                            />
                                        }
                                        {
                                            !this.state.addOption &&
                                            <Button className="add-more-tags"
                                                    onClick={() => this.openTextFieldOption(Store.shopify)}>+ Add more options</Button>
                                        }

                                    </div>
                                    {
                                        this.state.shopify.options.length === 3 &&
                                        <div style={{marginTop: 5}}>
                                            <Alert severity="info" icon={<InfoIcon />}>
                                                Shopify has a limit of 3 product options.
                                            </Alert>
                                        </div>
                                    }

                                    <SelectControl
                                        className='select'
                                        options={this.state.collections}
                                        valueKey={'id'}
                                        onChange={this.changeData}
                                        nameKey={'title'}
                                        name={'collection'}
                                        selected={this.state.data.collection}
                                        placeholder={'Collection'}
                                        disabled={this.state.templateForEditId != null}
                                    />
                                    <br/>
                                    <span style={{color: '#8D8D8D'}}>
                                Note: your Manual collections are listed here, if you want to export your product to Automatic collection simply leave the Collection column empty and based on your Automatic collection conditions we will update the right collection.
                            </span>
                                    <span style={{color: '#8D8D8D'}}>
                                For example, if the condition for one of your Automatic collections named Apparel is Tag equals Shirts, add tag Shirts and leave Collection field empty - we will automatically update the Apparel collection.
                            </span>

                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.shopify.isDraft}
                                            onChange={() => this.changeDraft(Store.shopify)}
                                            color="primary"
                                        />
                                    }
                                    label="Hide in store"
                                />

                                </div>
                                <div className="product-template-price">
                                    <div className="product-header">
                                        Product template price:
                                        {
                                            !this.state.editGlobalPrice &&
                                            <Button color="primary" variant="contained" size="small" onClick={() => this.changeGlobalPrice()}>Edit</Button>
                                        }
                                        {
                                            this.state.editGlobalPrice &&
                                            <Button color="primary" variant="contained" size="small" onClick={() => this.changeGlobalPrice(Store.shopify)}>Save</Button>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="summ-price">
                                            <div className="summ-name">Surge print price</div>
                                            <div className="amount">
                                                {
                                                    this.state.minPrice === this.state.maxPrice &&
                                                    <>
                                                        ${this.state.minPrice.toFixed(2)}
                                                    </>
                                                }
                                                {
                                                    this.state.minPrice !== this.state.maxPrice &&
                                                    <>
                                                        ${this.state.minPrice.toFixed(2)} - ${this.state.maxPrice.toFixed(2)}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="character">+</div>
                                        {
                                            !this.state.editGlobalPrice &&
                                            <div className="summ-price">
                                                <div className="summ-name">Expected to be earned</div>
                                                <div className="amount">${parseFloat(this.state.shopify.earned).toFixed(2)}</div>
                                            </div>
                                        }
                                        {
                                            this.state.editGlobalPrice &&
                                            <TextField
                                                name='earned'
                                                onChange={this.changeData}
                                                className={"edit-global-price"}
                                                type='number'
                                                InputProps={{ inputProps: {min: 0, step: 0.1} }}
                                                value={this.state.data.earned}
                                                variant="outlined"
                                            />
                                        }

                                        <div className="character">=</div>
                                        <div className="summ-price">
                                            <div className="summ-name">End buyer price</div>

                                            <div className="amount">
                                                {
                                                    this.state.minPrice === this.state.maxPrice &&
                                                    <>
                                                        ${(this.state.minPrice + parseFloat(this.state.shopify.earned ?? 0)).toFixed(2)}
                                                    </>
                                                }
                                                {
                                                    this.state.minPrice !== this.state.maxPrice &&
                                                    <>
                                                        ${(this.state.minPrice + parseFloat(this.state.shopify.earned ?? 0)).toFixed(2)} -
                                                        ${(this.state.minPrice + parseFloat(this.state.shopify.earned ?? 0)).toFixed(2)}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="product-variation-price">
                                    <div className="product-header">
                                        Variants and pricing:

                                        {/*{*/}
                                        {/*    !this.state.lastPage &&*/}
                                        {/*    <Button className="add-new-variations" onClick={() => this.openPopup()}>Add new</Button>*/}
                                        {/*}*/}

                                    </div>

                                    {this.renderProductVariation(Store.shopify)}
                                </div>
                            </div>
                        </div>
                         <div className="fixed-footer">
                            {
                                !this.state.loading &&
                                <Button className="publish-to-store"
                                        onClick={() => this.uploadCheck()}
                                >
                                    {this.state.uploadText}
                                </Button>
                            }
                            {
                                this.state.loading &&
                                <Button className="publish-to-store loading"
                                        style={{marginLeft: this.state.lastPage ? 0 : "auto"}}><img src="/images/mladen.gif"/> Loading ...</Button>
                            }
                        </div>
                    </div>
                }
                {
                    this.state.activeTab === Store.etsy &&
                    <div className={'etsy'}>

                        <div className="export-details">
                            <div className="export-details-left">
                                <div className="export-image">
                                    <div className="template-global-image">
                                        <div className="product-header">
                                            Product
                                            <input style={{display: "none"}} id="customer-tempalte-image" type={'file'}
                                                   name={'artwork'} className="upload"
                                                   onChange={(e) => this.uploadPicture(e)}
                                                   accept="image/x-png,image/gif,image/jpeg"/>
                                            <a onClick={() => this.openUploadImage()} className="upload-image">Upload your own Mockup</a>
                                        </div>
                                        <img className="product-image" src={this.state.storage.image} />
                                    </div>
                                    <div className="platform-exporting">
                                        <div className="product-header">
                                            Sales channels
                                        </div>
                                        {
                                            this.state.customerStores.shopify &&
                                            <div className="platform-div">
                                                <label className="container">Shopify
                                                    <input type="checkbox" onChange={() => this.changeShopify()} checked={this.state.data.shopify} />
                                                    <span className="checkmark" />
                                                </label>
                                            </div>
                                        }
                                        {
                                            this.state.customerStores.etsy &&
                                            <div className="platform-div">
                                                <label className="container">Etsy
                                                    <input type="checkbox" onChange={() => this.changeEtsy()} checked={this.state.data.etsy} />
                                                    <span className="checkmark" />
                                                </label>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="product-info">
                                    <div className="product-header">
                                        Product Info:
                                    </div>

                                    <TextField
                                        required
                                        name='name'
                                        onChange={(e) => this.updateCustomerTemplateData(e, Store.etsy)}
                                        label='Name'
                                        type='text'
                                        value={this.state.etsy.name}
                                        error={getError(this.state.errors, 'name')}
                                        helperText={getError(this.state.errors, 'name')}
                                        placeholder={"Name"}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />

                                    <TextField
                                        required
                                        name='description'
                                        onChange={(e) => this.updateCustomerTemplateData(e, Store.etsy)}
                                        label='Description'
                                        type='text'
                                        value={this.state.etsy.description}
                                        error={getError(this.state.errors, 'description')}
                                        helperText={getError(this.state.errors, 'description')}
                                        placeholder={"Description"}
                                        variant="outlined"
                                        multiline
                                        rows={6}
                                        InputLabelProps={{ shrink: true }}
                                    />

                                    <div className="export-tags">
                                        <div className="tags-container">
                                            {this.renderTags(Store.etsy)}
                                        </div>

                                        {
                                            this.state.addTag &&
                                            <TextField
                                                name='newTag'
                                                onChange={this.changeData}
                                                onKeyPress={(e) => this.tagEntered(Store.etsy, e)}
                                                className={"tag-input"}
                                                type='text'
                                                value={this.state.data.newTag}
                                                variant="outlined"
                                                id="tag-input"
                                                placeholder="Enter tag name"
                                            />
                                        }

                                        {
                                            !this.state.addTag &&
                                            <Button className="add-more-tags" onClick={() => this.openTextFieldTag()}>+
                                                Add more tags</Button>
                                        }
                                    </div>

                                    <div className="export-tags">
                                        <div className="tags-container">
                                            {this.renderOptions(Store.etsy)}
                                        </div>

                                        {
                                            this.state.addOption &&
                                            <SelectControl
                                                className='select'
                                                options={this.state.attributes}
                                                valueKey={'id'}
                                                onChange={(e) => this.saveOnSelectChange(e, Store.etsy)}
                                                nameKey={'name'}
                                                name={'newTag'}
                                                selected={this.state.etsy.options}
                                                placeholder={'Choose Options'}
                                            />
                                        }
                                        {
                                            !this.state.addOption &&
                                            <Button className="add-more-tags"
                                                    onClick={() => this.openTextFieldOption(Store.etsy)}>+ Add more options</Button>
                                        }

                                    </div>
                                    {
                                        this.state.etsy.options.length === 2 &&
                                        <div style={{marginTop: 5}}>
                                            <Alert severity="info" icon={<InfoIcon />}>
                                                Etsy has a limit of 2 product options.
                                            </Alert>
                                        </div>
                                    }

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.etsy.isDraft}
                                                onChange={() => this.changeDraft(Store.etsy)}
                                                color="primary"
                                            />
                                        }
                                        label="Hide in store"
                                    />
                                </div>

                                <div className="product-template-price">
                                    <div className="product-header">
                                        Product template price:
                                        {
                                            !this.state.editGlobalPrice &&
                                            <Button color="primary" variant="contained" size="small" onClick={() => this.changeGlobalPrice()}>Edit</Button>
                                        }
                                        {
                                            this.state.editGlobalPrice &&
                                            <Button color="primary" variant="contained" size="small" onClick={() => this.changeGlobalPrice(Store.etsy)}>Save</Button>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="summ-price">
                                            <div className="summ-name">Surge print price</div>
                                            <div className="amount">
                                                {
                                                    this.state.minPrice === this.state.maxPrice &&
                                                    <>
                                                        ${this.state.minPrice.toFixed(2)}
                                                    </>
                                                }
                                                {
                                                    this.state.minPrice !== this.state.maxPrice &&
                                                    <>
                                                        ${this.state.minPrice.toFixed(2)} - ${this.state.maxPrice.toFixed(2)}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="character">+</div>
                                        {
                                            !this.state.editGlobalPrice &&
                                            <div className="summ-price">
                                                <div className="summ-name">Expected to be earned</div>
                                                <div className="amount">${parseFloat(this.state.etsy.earned).toFixed(2)}</div>
                                            </div>
                                        }
                                        {
                                            this.state.editGlobalPrice &&
                                            <TextField
                                                name='earned'
                                                onChange={this.changeData}
                                                className={"edit-global-price"}
                                                type='number'
                                                InputProps={{ inputProps: {min: 0, step: 0.1} }}
                                                value={this.state.data.earned}
                                                variant="outlined"
                                            />
                                        }

                                        <div className="character">=</div>
                                        <div className="summ-price">
                                            <div className="summ-name">End buyer price</div>

                                            <div className="amount">
                                                {
                                                    this.state.minPrice === this.state.maxPrice &&
                                                    <>
                                                        ${(this.state.minPrice + parseFloat(this.state.etsy.earned ?? 0)).toFixed(2)}
                                                    </>
                                                }
                                                {
                                                    this.state.minPrice !== this.state.maxPrice &&
                                                    <>
                                                        ${(this.state.minPrice + parseFloat(this.state.etsy.earned ?? 0)).toFixed(2)} -
                                                        ${(this.state.minPrice + parseFloat(this.state.etsy.earned ?? 0)).toFixed(2)}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="product-variation-price">
                                    <div className="product-header">
                                        Variants and pricing:

                                        {/*{*/}
                                        {/*    !this.state.lastPage &&*/}
                                        {/*    <Button className="add-new-variations" onClick={() => this.openPopup()}>Add new</Button>*/}
                                        {/*}*/}

                                    </div>

                                    {this.renderProductVariation(Store.etsy)}

                                </div>
                            </div>
                        </div>

                        {/*<div className="product-variation-price">*/}
                        {/*    <div className="product-header">*/}
                        {/*        SKU:*/}
                        {/*        {this.renderFilterEtsy()}*/}

                        {/*        {*/}
                        {/*            !this.state.lastPage &&*/}
                        {/*            <Button className="add-new-variations" onClick={() => this.openPopup()}>Add new</Button>*/}
                        {/*        }*/}

                        {/*    </div>*/}

                        {/*    {this.renderProductVariationEtsy()}*/}

                        {/*</div>*/}
                        <div className="fixed-footer">
                            {
                                !this.state.loading &&
                                <Button className="publish-to-store"
                                        onClick={() => this.uploadCheck()}
                                >
                                    {this.state.uploadText}
                                </Button>
                            }
                            {
                                this.state.loading &&
                                <Button className="publish-to-store loading"
                                        style={{marginLeft: this.state.lastPage ? 0 : "auto"}}><img src="/images/mladen.gif"/> Loading ...</Button>
                            }
                        </div>
                    </div>
                }
                {
                    this.state.activeTab === Store.storage &&
                    <div className={'storage'}>

                        <div className="export-details">
                            <div className="export-details-left">
                                <div className="export-image">
                                    <div className="template-global-image">
                                        <div className="product-header">
                                            Product
                                            <input style={{display: "none"}} id="customer-tempalte-image" type={'file'}
                                                   name={'artwork'} className="upload"
                                                   onChange={(e) => this.uploadPicture(e)}
                                                   accept="image/x-png,image/gif,image/jpeg"/>
                                            <a onClick={() => this.openUploadImage()} className="upload-image">Upload your own Mockup</a>
                                        </div>
                                        <img className="product-image" src={this.state.storage.image}/>
                                    </div>
                                    <div className="platform-exporting">
                                        <div className="product-header">
                                            Sales channels
                                        </div>
                                        {
                                            this.state.customerStores.shopify &&
                                            <div className="platform-div">
                                                <label className="container">Shopify
                                                    <input type="checkbox" onChange={() => this.changeShopify()} checked={this.state.data.shopify} />
                                                    <span className="checkmark" />
                                                </label>
                                            </div>
                                        }
                                        {
                                            this.state.customerStores.etsy &&
                                            <div className="platform-div">
                                                <label className="container">Etsy
                                                    <input type="checkbox" onChange={() => this.changeEtsy()} checked={this.state.data.etsy} />
                                                    <span className="checkmark" />
                                                </label>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="product-info">
                                    <div className="product-header">
                                        Product Info:
                                    </div>

                                    <TextField
                                        required
                                        name='name'
                                        onChange={(e) => this.updateCustomerTemplateData(e, Store.storage)}
                                        label="Name"
                                        type='text'
                                        value={this.state.storage.name}
                                        error={getError(this.state.errors, 'name')}
                                        helperText={getError(this.state.errors, 'name')}
                                        placeholder={"Name"}
                                        className="element"
                                        style={{ marginTop: '10px'}}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <br/>

                                    <TextField
                                        required
                                        name='description'
                                        onChange={(e) => this.updateCustomerTemplateData(e, Store.storage)}
                                        label="Description"
                                        type='text'
                                        value={this.state.storage.description}
                                        error={getError(this.state.errors, 'description')}
                                        helperText={getError(this.state.errors, 'description')}
                                        placeholder={"Description"}
                                        InputLabelProps={{ shrink: true }}
                                        multiline
                                        className="element"
                                        style={{ marginTop: '10px'}}
                                        rows={6}
                                    />
                                </div>

                                <div className="product-template-price">
                                    <div className="product-header">
                                        Product template price:
                                        {
                                            !this.state.editGlobalPrice &&
                                            <Button color="primary" variant="contained" size="small" onClick={() => this.changeGlobalPrice()}>Edit</Button>
                                        }
                                        {
                                            this.state.editGlobalPrice &&
                                            <Button color="primary" variant="contained" size="small" onClick={() => this.changeGlobalPrice(Store.storage)}>Save</Button>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="summ-price">
                                            <div className="summ-name">Surge print price</div>
                                            <div className="amount">
                                                {
                                                    this.state.minPrice === this.state.maxPrice &&
                                                    <>
                                                        ${this.state.minPrice.toFixed(2)}
                                                    </>
                                                }
                                                {
                                                    this.state.minPrice !== this.state.maxPrice &&
                                                    <>
                                                        ${this.state.minPrice.toFixed(2)} - ${this.state.maxPrice.toFixed(2)}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="character">+</div>
                                        {
                                            !this.state.editGlobalPrice &&
                                            <div className="summ-price">
                                                <div className="summ-name">Expected to be earned</div>
                                                <div className="amount">${parseFloat(this.state.storage.earned ?? 0).toFixed(2)}</div>
                                            </div>
                                        }
                                        {
                                            this.state.editGlobalPrice &&
                                            <TextField
                                                name='earned'
                                                onChange={this.changeData}
                                                className={"edit-global-price"}
                                                type='number'
                                                InputProps={{ inputProps: {min: 0, step: 0.1} }}
                                                value={this.state.data.earned}
                                                variant="outlined"
                                            />
                                        }

                                        <div className="character">=</div>
                                        <div className="summ-price">
                                            <div className="summ-name">End buyer price</div>

                                            <div className="amount">
                                                {
                                                    this.state.minPrice === this.state.maxPrice &&
                                                    <>
                                                        ${(this.state.minPrice + parseFloat(this.state.storage.earned ?? 0)).toFixed(2)}
                                                    </>
                                                }
                                                {
                                                    this.state.minPrice !== this.state.maxPrice &&
                                                    <>
                                                        ${(this.state.minPrice + parseFloat(this.state.storage.earned ?? 0)).toFixed(2)} -
                                                        ${(this.state.minPrice + parseFloat(this.state.storage.earned ?? 0)).toFixed(2)}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="product-variation-price">
                                    <div className="product-header">
                                        Variants and pricing:

                                        {/*{*/}
                                        {/*    !this.state.lastPage &&*/}
                                        {/*    <Button className="add-new-variations" onClick={() => this.openPopup()}>Add new</Button>*/}
                                        {/*}*/}

                                    </div>

                                    {this.renderProductVariation(Store.storage)}
                                </div>
                            </div>
                        </div>
                        <div className="fixed-footer">
                            {
                                !this.state.loading &&
                                <Button className="publish-to-store"
                                        onClick={() => this.uploadCheck()}
                                >
                                    {this.state.uploadText}
                                </Button>
                            }
                            {
                                this.state.loading &&
                                <Button className="publish-to-store loading"
                                        style={{marginLeft: this.state.lastPage ? 0 : "auto"}}><img src="/images/mladen.gif"/> Loading ...</Button>
                            }
                        </div>
                    </div>
                }

            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ExportProduct)));
