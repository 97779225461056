import React from 'react';
import classNames from 'classnames';
import IconCheckmark from 'src/redesign/components/Icon/Checkmark';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ColorAttribute = ({ className, color, colorName, selected, noSelectedStyle = false, onClick, disabled }) => {
  return (
    <div className={classNames('attribute-value-color', {'attribute-color-selected': !noSelectedStyle && selected }, {'attribute-value-color-disabled': disabled}, className)} onClick={() => disabled ? undefined : onClick()}>
      <div className='attribute-color-circle' style={{ backgroundColor: color }}>
        {selected && <IconCheckmark width={14} height={10} color={colors.blackDark}/>}
      </div>
      <div className={classNames('attribute-color-text', {'attribute-color-text-selected': !noSelectedStyle && selected})}>
        {colorName}
      </div>
    </div>
  )
}

export default ColorAttribute;