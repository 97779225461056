import React from 'react';
import { number, string } from 'prop-types';

const IconEdit = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75 17.25H17.25" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.75 14.25V11.25L13.3969 5.07786C13.594 4.89486 13.9005 4.90053 14.0907 5.0907L15.9093 6.9093C16.0995 7.09947 16.1051 7.406 15.9221 7.60308L9.75 14.25H6.75Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

);

IconEdit.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconEdit;