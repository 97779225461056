import React from 'react';

import Accordion from 'src/redesign/components/Accordion';
import LogoWhite from 'src/redesign/components/Logo/LogoWhite';

import './style.scss';

const FAQ = () => {
  const listItemStyle = { marginLeft: 28 };
  const ACCORDION_CONTENTS = [
    {
      title: '1. What is your Standard Production time?',
      description: 'Our standard turnaround time is 4 business days, guaranteed. Note that turnaround time for your print job begins once your order has been placed and your print-ready files have been uploaded and order status changed to In Production.'
    },
    {
      title: '2. Is turnaround time same as shipping time?',
      description: 'Turnaround time refers to our production turnaround time, which does not include shipping time. In addition, turnaround time begins calculating once your order has been placed and your print-ready file has been uploaded and status changed to In Production.'
    },
    {
      title: '3. What shipping options you offer?',
      description: <div>
        We offer 4 different Shipping options.
        <br />
        <br />
        Standard Shipping method is up to 7 business days for domestic orders.Expedited shipping is 3-5 business days for domestic orders.
        <br />
        <br />
        Overnight shipping is 1-2 business days for domestic orders.International orders on average need 12 business days to arrive due to the current situation. Please contact support for more information. For our top products we provide International Expedites shipping method as well.
      </div>
    },
    {
      title: '4. Do you offer white label fulfillment?',
      description: 'Yes, we can customize shipping labels with your company information, so packages arrive as if you sent them directly to your customers.'
    },
    {
      title: '5. Can I ship orders internationally?',
      description: 'Yes. Orders can be shipped to most countries internationally. Shipping costs are calculated at quote time. Keep in mind transit times are generally longer than domestic shipments by 5 business days'
    },
    {
      title: '6. Can I change my shipping method after the        order has been submitted?',
      description: 'Yes, if the order hasn’t been fulfilled. To change shipping preferences, visit the Orders section of your account dashboard and click on the order number. To change the shipping method, click on the Edit Shipping button on the top of the order page. To change the shipping address, go to the right side of the page.'
    },
    {
      title: '7. Do you provide tracking links for my orders?',
      description: 'Yes, we do.Tracking numbers are included in the shipping notification emails we send, as well as in the Orders section of your account dashboard. Tracking numbers are generated when a shipping label is printed.Keep in mind the tracking information may not reflect any action for up to 24 hours after a label has been printed.'
    },
    {
      title: '8. When should I contact support if there is a problem with my order?',
      description: 'Please contact us as soon as possible. We will make it right if something unexpected happened. Any and all claims that are requesting reprints, refunds, returns, and/ or for any reason must be submitted within 4 weeks of receiving your order or your expected delivery date.'
    },
    {
      title: '9. How do you handle Order Cancellations and Address Changes?',
      description: 'We cannot make any cancellations or address changes for any orders after your order has reached production. This is due to the nature of creating customized products that are created by our vendors once it has reached this step in the process. Our vendors typically begin production of your item within 24 hours of your order being submitted and we cannot guarantee that the manufacturer will see our request to cancel or change the address.'
    },
    {
      title: '10. What is your Reprint & Refund Policy?',
      description: <div>
        While we want every order to be perfect, mistakes can happen from time to time. We can offer reprints and refunds for your orders if there are order mistakes. If you are submitting a reprint or refund request, please be sure to include photo evidence of your product in your order. Depending on the circumstances of the mistake, the cost to cover the reprint or refund will be either from us or you. Let's take a look at the scenarios when you can request a reprint/refund and who covers the cost.
        <br/>
        <br/>
        When Surge Print covers the Reprint/Refund cost:
        <li style={listItemStyle}>
          If there is a manufacturing issue with your product in your order.
        </li>
        <li style={listItemStyle}>
          If the product in your order is broken or damaged in the mail.
        </li>
        <li style={listItemStyle}>
          If you receive the wrong product in your order.
        </li>
        <li style={listItemStyle}>
          The order is lost in transit and the initial shipping address for the order doesn't need to be changed.
        </li>
        <li style={listItemStyle}>
          The order is lost in transit and the actual shipping time exceeds the general shipping timeframe for orders.
        </li>
        <br/>
        <br/>
        When Surge Print doesn't cover the Reprint/Refund cost:
        <br/>
        <li style={listItemStyle}>
          If you or your customer don't like the product.
        </li>
        <li style={listItemStyle}>
          If you make any mistakes while creating your product such as submitting the wrong image.
        </li>
        <li style={listItemStyle}>
          If you or your customer selects the wrong size when ordering the product.
        </li>
        <li style={listItemStyle}>
          The order is lost in transit and the initial shipping address is incorrect and you need to change the address,
        </li>
        <li style={listItemStyle}>
          The tracking for your order shows delivered but you have not received the package.
        </li>
        <br/>
        <br/>
        Lost in TransitPackages are considered lost in transit when the products in the order do not arrive at the shipping address provided within the maximum shipping/transit timeframes. A package is considered lost in transit if the shipping timeframe exceeds the maximum shipping/transit timeframe.
        <br/>
        <br/>
        Please note that there may be variances in ship dates and these times are subject to change.
      </div>
    },
    {
      title: '11. What is your Return Policy?',
      description: <div>
        SurgePrint does not accept returned orders. There are a couple of scenarios in which an order may be returned though.
        <br/>
        <br/>
        Some of our vendors will accept returned orders if the return address is the vendor address.
        <br/>
        <br/>
        You as a partner can accept returns if the return address is your address. With this process, you can decide what to do with the returned item. In this scenario, returned packages are only applied to US return addresses.
      </div>
    },
    {
      title: '12. What is your Exchange Policy?',
      description: 'At the moment, SurgePrint does not offer exchanges for products that are ordered. Due to the one-off and highly personalized nature of print on demand, we do not maintain stock to exchange items.'
    },
    {
      title: '13. Do you offer Bulk Discounts?',
      description: 'Yes! SurgePrint can provide bulk discounts for either product or shipping costs.'
    }
  ]

  return (
    <div className='faq-content'>
      <div className='title-container'>
        <LogoWhite
          style={{
            position: 'absolute',
            top: 90,
            left: 0
          }}
        />
        <h1 className='faq-title'>
          Frequently asked questions
        </h1>
      </div>
      <div className='faq-questions'>
        {ACCORDION_CONTENTS.map(({ title, description }, index) => (
          <Accordion
            key={index}
            title={title}
            description={description}
          />
        ))}
      </div>
      <div className='faq-contact'>
        <div className='faq-contact-title'>
          How to contact Support?
        </div>
        <div className='faq-mailto'>
          Please email us at
          <a href="mailto:support@surgeprint.com">support@surgeprint.com</a>
        </div>
        <LogoWhite
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0
          }}
        />
      </div>
    </div>
  )
}

export default FAQ;