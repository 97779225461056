import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

import './style.scss'

const Wave = ({ width, height, color, className }) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 1440 105" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M-978.361 52.3036C-1092.29 78.4553 -1206.23 104.607 -1434.09 104.607H388.827H2211.74C1983.88 104.607 1869.95 78.4553 1756.01 52.3035C1642.08 26.1518 1528.15 0 1300.29 0C1072.42 0 958.488 26.1518 844.556 52.3036C730.624 78.4553 616.691 104.607 388.827 104.607C160.962 104.607 47.0294 78.4553 -66.903 52.3036C-180.835 26.1518 -294.767 0 -522.632 0C-750.497 0 -864.429 26.1518 -978.361 52.3036Z" fill={color}/>
    </svg>
  );
}

Wave.propTypes = {
  width: string,
  height: string,
  color: string,
  className: string,
}

export const WaveUp = props => <Wave className={classNames('wave-up', props.className)} {...props} />;
export const WaveDown = props => <Wave className={classNames('wave-down', props.className)} {...props} />