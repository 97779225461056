import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MenuState } from '../../reducers/constants';
import SecondaryButton from '../Buttons/Secondary';
import IconCart from '../Icon/Cart';
import Avatar from 'src/redesign/components/Avatar';

import { withRouter } from 'react-router';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ApplicationPageContent = ({ title, children, menu: { state: menuState }, history, className }) => {
  const [cartColor, setCartColor] = React.useState(colors.primary);

  return (
    <div className="application-page-content">
      <div className='main-title-container'>
        <div className='main-title'>
          {title}
        </div>
        <div className='main-buttons-container'>
          <div onMouseEnter={() => setCartColor(colors.secondary)} onMouseLeave={() => setCartColor(colors.primary)}>
            <SecondaryButton text={
              <div className='cart-content'>
                <IconCart width={24} height={24} color={cartColor}/>
                Cart
              </div>
            } onClick={()=> history.push('/cart')} className='cart-button'/>
          </div>
          <Avatar />
        </div>
      </div>
      <div className={classNames("content", className)}>
        {children}
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({ }, dispatch);
}

function mapStateToProps({ menuReducers })
{
    return {
        menu: menuReducers,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationPageContent));
