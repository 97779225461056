import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "@material-ui/core";
import strings from '../localization';
import { getTrendingCategory } from "../services/CategoryService";
import { getImage } from '../services/ImageService';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CategoryPopup from "../components/CategoryPopup";
import {getBrandsForCategory} from "../services/admin/BrandService";
import {withSnackbar} from "notistack";
import {getUserFromLocalStorage} from "../base/OAuth";
import UserType from "../constants/UserType";
import Helmet from 'react-helmet';

class UserHome extends Page {

    constructor(props) {
        super(props);

        this.state = {
            viewCategory : {},
            owlItems : 6,
            userType: getUserFromLocalStorage().user_type,
            name: getUserFromLocalStorage().user_type == UserType.CUSTOMER ? getUserFromLocalStorage().first_name + " " + getUserFromLocalStorage().last_name : "Admin"
        }

        this.props.changeFullScreen(false);

        this.closePopup = this.closePopup.bind(this);
        this.reportWindowSize = this.reportWindowSize.bind(this);
    }

    componentDidMount(){

        this.reportWindowSize();
        window.addEventListener("resize", this.reportWindowSize);

        getTrendingCategory().then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                categories: response.data.categories
            })

        });
    }

    renderCategories() {
        let result = [];

        for(let item of this.state.categories)
        {   
            result.push(
                <div key={item.id} className="category-item">
                    <div className="header">{ item.name }</div>
                    <div className="start-price">{ strings.trending.startingAt } ${ item.min_price }</div>
                    <img src={getImage(item.category_image)}></img>
                    <Button className="view-details" onClick={() => {
                        this.setState({
                            ...this.state,
                            viewCategory : item
                        })
                    }}>{ strings.trending.viewDetails }</Button>
                    <Button className="select-product" onClick={() => {

                        if(this.state.userType == UserType.ADMIN){
                            this.props.enqueueSnackbar("Admin can't create customer product variation!", {variant: 'error'});
                            return;
                        }

                        getBrandsForCategory(item.id).then(response => {
                            if (!response.ok){
                                return
                            }

                            if(response.data.length !== 0){
                                this.props.history.push('/product-selection/' + item.id);
                            }else {
                                this.props.enqueueSnackbar("There are no products for this category", {variant: 'error'});
                            }
                        })
                         }}>{ strings.trending.selectProduct }</Button>
                </div>
            );
        }

        return result;
    }

    closePopup(){
        this.setState({
            ...this.state,
            viewCategory : {}
        })
    }

    reportWindowSize(){
        if (window.innerWidth <= 768 && this.state.owlItems !== 1) {
            this.setState({
                ...this.state,
                owlItems: 1
            })
        }else if (window.innerWidth <= 992 && window.innerWidth > 768 && this.state.owlItems !== 2){
            this.setState({
                ...this.state,
                owlItems : 2
            })
        }else if (window.innerWidth <= 1220 && window.innerWidth > 992 && this.state.owlItems !== 4){
            this.setState({
                ...this.state,
                owlItems : 4
            })
        }else if (window.innerWidth > 1220 && this.state.owlItems !== 6){
            this.setState({
                ...this.state,
                owlItems : 6
            })
        }
    }

    render() {

        if(!this.state.categories){
            return "";
        }

        return (
            <div id="user-home" className={'container'}>
                 <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                <div className="title">{ strings.trending.trending }</div>
                <div className="trending-categories">
                        <OwlCarousel 
                            margin={10}
                            items={this.state.owlItems}
                            nav
                        >
                           { this.renderCategories() }
                        </OwlCarousel>
                </div>
                {
                    this.state.viewCategory.id &&
                    <CategoryPopup closePopup={this.closePopup} category={this.state.viewCategory} />
                }
                <div className="header">Welcome, "{ this.state.name }"</div>
                <div className="paragraf">
                    Surge Print on Demand has 200+ items for you to personalize and add to your store. <b>The Product Catalog</b> is where you can select a product, upload your custom artwork, and edit the design to your liking. You can then input all the information needed to either Publish it to your store, or save it in Storage for later. You can see our best-sellers above and start creating right away!
                </div>
                <div className="paragraf">
                    <b>The Place an Order</b> tab is where you can create an order from scratch, select an already Saved product from your Storage or simply select a Draft order which was previously created and save time on adding item quantities, customer and shipping information again!
                </div>
                <div className="paragraf">
                    <b>The Orders Tab</b> is where you will find information regarding your orders - this is where Live Status updates happen. You will also have the ability to Edit your Order or get shipping updates and tracking links.
                    <b>The Product Library</b> is home for your Products. In this Tab you will see all products you have created as well as products which were imported from Shopify. Here you will also have an option to quickly add products to your Cart.
                </div>
                <div className="paragraf">
                    <b>The WarehousePRO</b> - Are you looking to store your products in one of our modern warehouses? We got your back!
                    Contact your Account Manager and we will create a personalized inventory dashboard for you!
                    <b>The Affiliate Center</b>- Let's partner and earn together! Surge Print will send you a commission for every order your referrals create. We create custom commissions for every partner and every product in order to ensure that no other print on demand company can match our percentages!
                </div>
                {
                    this.state.userType == UserType.CUSTOMER &&
                    <div className="row">
                        {/*<div className="item">*/}
                        {/*    <div className="item-header">Next step</div>*/}
                        {/*    <div className="item-content">*/}
                        {/*        <img src="/images/user-home-left.png"></img>*/}
                        {/*        <div className="next-step-header">Connect your store and start selling</div>*/}
                        {/*        <div className="next-step">It will let you publish your designs to a store</div>*/}
                        {/*        <input className="next-step-input" type="text"></input>*/}
                        {/*        <Button className="connect-store">Connect store</Button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="item">
                            <div className="item-header">Set up Your Account</div>
                            <div className="item-content">
                                <img className="item-right" src="/images/user-home-right.png"></img>
                                <div className="next-step-header">Set up your account</div>
                                <div className="next-step">Input your billing information so your orders can go directly into production.</div>
                                <Button className="billing-info" onClick={ () => this.props.history.push("/settings/billing") }>Submit billing information</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserHome)));
