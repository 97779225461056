import {withRouter} from "react-router-dom";
import React from 'react'
import FormComponent from "../../common/FormComponent";
import {createProduct, generateProductVariations, uploadProductImage} from "../../services/admin/ProductService";
import {Button, TextField} from "@material-ui/core";
import CreatableSelect from 'react-select/creatable';
import SelectControl from "../controls/SelectControl";
import {getCategories} from "../../services/CategoryService";
import AddAttribute from "./AddAttribute";
import {createBrand, getBrands} from "../../services/admin/BrandService";
import {
    createAttribute,
    deleteAttribute,
    getAttributeValuesForAttribute,
    updateAttribute
} from "../../services/admin/AttributeService";
import AttributeType from "../../constants/AttributeType";
import {withSnackbar} from "notistack";
import {isNumeric} from "../../util/DataValidation";


class AboutProduct extends FormComponent{

    constructor(props) {
        super(props);

        this.state = {
            showAddAttribute : false,
            data : {

            },
            brands : [],
            brand : {},
            attributeNames : [],
            attributeValues : []
        };

        this.onUpload = this.onUpload.bind(this);
        this.saveProduct = this.saveProduct.bind(this);
        this.deleteAttribute = this.deleteAttribute.bind(this);
        this.saveAttribute = this.saveAttribute.bind(this);
        this.handleCreateSelect = this.handleCreateSelect.bind(this);
        this.toggleAttribute = this.toggleAttribute.bind(this);
    }

    doesHaveChildAndParent(){

    }

    componentDidMount() {
        getCategories().then(response => {
            if (!response.ok){
                return ;
            }


            let categories = response.data;
            let toRender = [];

            categories.forEach(firstItem => {
                let add = true;

                categories.forEach(item => {
                    if (item.parent_category && item.parent_category.id === firstItem.id){
                        add = false
                    }
                })

                if (add){
                    toRender.push(firstItem)
                }
            })

            this.setState({
                ...this.state,
                categories : toRender
            })
        })

        getBrands().then(response =>{
            if (!response.ok){
                return ;
            }

            let brands = response.data;
            let toSend = [];

            brands.forEach(item => {
                toSend.push({
                   value : item.id,
                   label : item.name
                });
            })

            this.setState({
               ...this.state,
               brands : toSend
            });
        })
    }

    onUpload(event) {

        let formData = new FormData();
        let acceptedFiles;

        acceptedFiles = event.target.files[0];

        formData.append('image', acceptedFiles);
        event.target.value = null;

        uploadProductImage(formData).then(response => {
            if (!response.ok){
                return;
            }

            this.setState({
                ...this.state,
                image : response.data
            })
        })
    }

    toggleAttribute(){
        this.setState({
            ...this.state,
            showAddAttribute : !this.state.showAddAttribute
        })
    }

    saveAttribute(name, attributes, type){
        if(!Array.isArray(attributes)){
            return
        }

        getAttributeValuesForAttribute({
            new : true,
            attributeId : attributes[0].id,
            alreadyHave : []
        }).then(response => {
            if (!response.ok || response.data.length === 0){
                return
            }

            let returnedValues = response.data;

            let names = this.state.attributeNames;
            let values = this.state.attributeValues;

            let index = names.indexOf(response.data[0].attribute.name);

            if (!names.includes(response.data[0].attribute.name)){
                names.push(response.data[0].attribute.name);
            }

            let currentOnesIds = [];

            if (isNumeric(index) && values[index]){
                values[index].attributeValues.forEach(item => {
                    currentOnesIds.push(item.id);
                })

                returnedValues.forEach(returnedValue => {
                    if (!currentOnesIds.includes(returnedValue.id)){
                        currentOnesIds.push(returnedValue.id);
                        values[index].attributeValues.push(returnedValue)
                    }
                })
            }else {
                values.push({attributeValues: response.data, type: type, new : true})
            }

            console.log(values)
            console.log(names)

            this.setState({
                ...this.state,
                attributeNames : names,
                attributeValues : values,
                showAddAttribute : false
                // newAttributeNames : newNames,
                // newAttributeValues : newValues
            })
        });

    }

    removeAttributeValue(i, id){
        let allValues = this.state.attributeValues;

        let values = this.state.attributeValues[i].attributeValues;
        let type = this.state.attributeValues[i].type;
        let toSend = [];

        values.forEach(item => {
            if (item.id === id){
                return
            }

            toSend.push(item);
        });

        allValues[i].attributeValues = toSend;
        allValues[i].type = type;

        this.setState({
            ...this.state,
            attributeValues : allValues
        })

    }

    handleCreateSelect(e){
        if (e.__isNew__){
            createBrand({name: e.value}).then(response => {
                if (!response.ok){
                    return ;
                }

                let brands = this.state.brands;
                let brand = {label : response.data.name, value: response.data.id};

                brands.push(brand);


                this.setState({
                    ...this.state,
                    brands : brands,
                    brand : brand
                })
            })
        }else {
            this.setState({
                ...this.state,
                brand : e
            })
        }
    }

    renderAttributeValues(i){
        let toRender = [];
        let values = this.state.attributeValues[i].attributeValues;

        if (this.state.attributeValues[i].type === AttributeType.STRING){
            values.forEach((item) => {
                toRender.push(
                    <label className={'attribute-value'}>{item.name}<label onClick={() => this.removeAttributeValue(i, item.id)} className={'x'}>✕</label></label>
                );
            });
        }else {
            values.forEach((item) => {
                toRender.push(
                    <label className={'attribute-value'}><div style={{backgroundColor: item.name}} className={'circle'}/>{item.customer_color_name}<label onClick={() => this.removeAttributeValue(i, item.id)} className={'x'}>✕</label></label>
                );
            });
        }

        return toRender;
    }

    renderAttributes(){
        let toRender = [];

        this.state.attributeNames.forEach((item, index) => {
            toRender.push(
                <div className={'attribute'}>
                    <label className={'attribute-name'}><label>{item}<label onClick={() => this.removeAttribute(index, item.id)} className={'x'}>✕</label></label></label>
                    <div className={'attribute-values'}>
                        {this.renderAttributeValues(index)}
                    </div>
                </div>
            );
        });

        return toRender;
    }

    removeAttribute(i, id){
        let attributeNames = this.state.attributeNames;
        let attributeValues = this.state.attributeValues;

        let toRenderNames = [];
        let toRenderValues = [];

        attributeNames.forEach((item, index) => {
            if (index === i){
                return ;
            }

            toRenderNames.push(item)
        });

        attributeValues.forEach((item, index) => {
            if (index === i){
                return ;
            }

            toRenderValues.push(item)
        });

        this.setState({
           ...this.state,
            attributeNames : toRenderNames,
            attributeValues : toRenderValues
        });
    }

    deleteAttribute(id){
        deleteAttribute(id).then(response => {
            if (!response.ok){
                return ;
            }

            this.toggleAttribute();

        })
    }

    isInternationalExpeditedPriceValid() {
        return !((this.state.data.intOtherExpedited && !this.state.data.intFirstExpedited) ||
            (!this.state.data.intOtherExpedited && this.state.data.intFirstExpedited)
        );
    }

    isVendorInternationalExpeditedPriceValid() {
        return !((this.state.data.vendorIntOtherExpedited && !this.state.data.vendorIntFirstExpedited) ||
            (!this.state.data.vendorIntOtherExpedited && this.state.data.vendorIntFirstExpedited)
        );
    }

    isValid(){
        return !(!this.state.data.productName || !this.state.data.productDescription ||
            !this.state.data.price || !this.state.data.vendorPrice || !this.state.brand.value || !this.state.data.category ||
            !this.state.attributeValues || !this.state.image ||
            !this.state.data.usFirstStandard || !this.state.data.usFirstExpedited || !this.state.data.usFirstOvernight ||
            !this.state.data.usOtherStandard || !this.state.data.usOtherExpedited || !this.state.data.usOtherOvernight ||
            !this.state.data.intFirstStandard || !this.state.data.intOtherStandard ||
            !this.state.data.vendorUsFirstStandard || !this.state.data.vendorUsFirstExpedited || !this.state.data.vendorUsFirstOvernight ||
            !this.state.data.vendorUsOtherStandard || !this.state.data.vendorUsOtherExpedited || !this.state.data.vendorUsOtherOvernight ||
            !this.state.data.vendorIntFirstStandard || !this.state.data.vendorIntOtherStandard);
    }

    saveProduct(){
        if (!this.isValid()){
            this.props.enqueueSnackbar('All fields are required', {variant: 'error'});
            return;
        }

        if (!this.isInternationalExpeditedPriceValid() || !this.isVendorInternationalExpeditedPriceValid()) {
            this.props.enqueueSnackbar('International expedited is not valid', {variant: 'error'});
            return;
        }

        let product = {
            name : this.state.data.productName,
            description : this.state.data.productDescription,
            price : this.state.data.price,
            vendorPrice: this.state.data.vendorPrice,
            brandId : this.state.brand.value,
            categoryId : this.state.data.category.id,
            attributeValues : this.state.attributeValues,
            imageId : this.state.image.id,
            usFirstStandard: this.state.data.usFirstStandard,
            usFirstExpedited: this.state.data.usFirstExpedited,
            usFirstOvernight: this.state.data.usFirstOvernight,
            usOtherStandard: this.state.data.usOtherStandard,
            usOtherExpedited: this.state.data.usOtherExpedited,
            usOtherOvernight: this.state.data.usOtherOvernight,
            intFirstStandard: this.state.data.intFirstStandard,
            intFirstExpedited: this.state.data.intFirstExpedited,
            intOtherStandard: this.state.data.intOtherStandard,
            intOtherExpedited: this.state.data.intOtherExpedited,

            vendorUsFirstStandard: this.state.data.vendorUsFirstStandard,
            vendorUsFirstExpedited: this.state.data.vendorUsFirstExpedited,
            vendorUsFirstOvernight: this.state.data.vendorUsFirstOvernight,
            vendorUsOtherStandard: this.state.data.vendorUsOtherStandard,
            vendorUsOtherExpedited: this.state.data.vendorUsOtherExpedited,
            vendorUsOtherOvernight: this.state.data.vendorUsOtherOvernight,
            vendorIntFirstStandard: this.state.data.vendorIntFirstStandard,
            vendorIntFirstExpedited: this.state.data.vendorIntFirstExpedited,
            vendorIntOtherStandard: this.state.data.vendorIntOtherStandard,
            vendorIntOtherExpedited: this.state.data.vendorIntOtherExpedited,
        };

        createProduct(product).then(response => {
            if (!response.ok){
                return ;
            }

            this.setState({
                ...this.state,
                product : response.data
            }, () => {
                generateProductVariations({id : this.state.product.id, image : this.state.image.id}).then(response => {
                    if (!response.ok){
                        return ;
                    }

                    this.props.enqueueSnackbar('Successfully saved', {variant: 'success'});

                    this.props.history.push('/old/products/' + this.state.product.id + '/1');
                });
            })
        })
    }

    render() {
        return (
            <div className={'about-product'}>
                <div className={'white'}>
                    <div className={'fields'}>
                        <div className={'field-container'}>
                            <label>Image</label>
                            <div className={'field'}>
                                <div className={'image-container'}>
                                    {
                                        this.state.image &&
                                        <img className={'product-image'} src={process.env.REACT_APP_baseUrl + this.state.image.url} alt={'Product Image'} />
                                    }
                                    <div className={'upload'}>
                                        <p>Upload Image</p>
                                        <input type={'file'} onChange={this.onUpload}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'field-container'}>
                            <label>Product Name</label>
                            <TextField
                                name='productName'
                                onChange={ this.changeData }
                                margin="normal"
                                type='text'
                                value={ this.state.data.productName }
                                placeholder={'Product Name'}
                            />
                        </div>
                        <div className={'field-container'}>
                            <label>Product Category</label>
                            <SelectControl
                                className='select'
                                options={this.state.categories}
                                valueKey = { 'id' }
                                onChange={ this.changeData }
                                nameKey= { 'name' }
                                name = {'category'}
                                selected={this.state.data.category}
                            />
                        </div>
                        <div className={'field-container'}>
                            <label>Brand</label>
                            <CreatableSelect
                                className='select'
                                options={this.state.brands}
                                placeholder={'Brand'}
                                onChange={this.handleCreateSelect}
                                value={this.state.brands.find(option => option.value === this.state.brand.value)}
                            />
                        </div>
                        <div className={'field-container'}>
                            <label>Description</label>
                            <TextField
                                name='productDescription'
                                onChange={ this.changeData }
                                margin="normal"
                                type='text'
                                value={ this.state.data.productDescription }
                                placeholder={'Description'}
                                multiline
                                rows={4}
                            />
                        </div>
                        <div className={'field-container'}>
                            <label>Attributes</label>
                            <div>
                                <p className={'new'} onClick={this.toggleAttribute}>Add new attribute</p>
                                <div className={'attributes'}>
                                    {this.renderAttributes()}
                                </div>
                            </div>
                        </div>
                        <div className={'field-container'}>
                            <label>Price</label>
                            <TextField
                                name='price'
                                onChange={ this.changeData }
                                margin="normal"
                                type='number'
                                value={ this.state.data.price }
                                placeholder={'Price'}
                            />
                        </div>
                        <div className={'field-container'}>
                            <label>Vendor price</label>
                            <TextField
                                name='vendorPrice'
                                onChange={ this.changeData }
                                margin="normal"
                                type='number'
                                value={ this.state.data.vendorPrice }
                                placeholder={'Vendor price'}
                            />
                        </div>

                        <div className={'shipping-container'}>
                            <label>Shipping prices</label>
                            <div className={'container-price'}>
                                <div className={'sub-container'}>
                                    <p className={'title'}>US</p>
                                    <div className={'hr'}/>
                                    <div className={'pricing-field-container'}>
                                        <label>First</label>
                                        <div>
                                            <label>Standard</label>
                                            <TextField
                                                name='usFirstStandard'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.usFirstStandard }
                                            />
                                        </div>
                                        <div>
                                            <label>Expedited</label>
                                            <TextField
                                                name='usFirstExpedited'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.usFirstExpedited }
                                            />
                                        </div>
                                        <div>
                                            <label>Overnight</label>
                                            <TextField
                                                name='usFirstOvernight'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.usFirstOvernight }
                                            />
                                        </div>
                                    </div>
                                    <div className={'pricing-field-container'}>
                                        <label>Each Additional</label>
                                        <TextField
                                            name='usOtherStandard'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.usOtherStandard }
                                        />
                                        <TextField
                                            name='usOtherExpedited'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.usOtherExpedited }
                                        />
                                        <TextField
                                            name='usOtherOvernight'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.usOtherOvernight }
                                        />
                                    </div>
                                </div>
                                <div className={'sub-container'}>
                                    <p className={'title'}>International</p>
                                    <div className={'hr'}></div>
                                    <div className={'pricing-field-container'}>
                                        <label>First</label>
                                        <div>
                                            <label>Standard</label>
                                            <TextField
                                                name='intFirstStandard'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.intFirstStandard }
                                            />
                                        </div>
                                        <div>
                                            <label>Expedited (optional)</label>
                                            <TextField
                                                name='intFirstExpedited'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.intFirstExpedited }
                                            />
                                        </div>
                                    </div>
                                    <div className={'pricing-field-container'}>
                                        <label>Each Additional</label>
                                        <TextField
                                            name='intOtherStandard'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.intOtherStandard }
                                        />
                                        <TextField
                                            name='intOtherExpedited'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.intOtherExpedited }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'shipping-container'}>
                            <label>Vendor shipping prices</label>
                            <div className={'container-price'}>
                                <div className={'sub-container'}>
                                    <p className={'title'}>US</p>
                                    <div className={'hr'}/>
                                    <div className={'pricing-field-container'}>
                                        <label>First</label>
                                        <div>
                                            <label>Standard</label>
                                            <TextField
                                                name='vendorUsFirstStandard'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.vendorUsFirstStandard }
                                            />
                                        </div>
                                        <div>
                                            <label>Expedited</label>
                                            <TextField
                                                name='vendorUsFirstExpedited'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.vendorUsFirstExpedited }
                                            />
                                        </div>
                                        <div>
                                            <label>Overnight</label>
                                            <TextField
                                                name='vendorUsFirstOvernight'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.vendorUsFirstOvernight }
                                            />
                                        </div>
                                    </div>
                                    <div className={'pricing-field-container'}>
                                        <label>Each Additional</label>
                                        <TextField
                                            name='vendorUsOtherStandard'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.vendorUsOtherStandard }
                                        />
                                        <TextField
                                            name='vendorUsOtherExpedited'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.vendorUsOtherExpedited }
                                        />
                                        <TextField
                                            name='vendorUsOtherOvernight'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.vendorUsOtherOvernight }
                                        />
                                    </div>
                                </div>
                                <div className={'sub-container'}>
                                    <p className={'title'}>International</p>
                                    <div className={'hr'}></div>
                                    <div className={'pricing-field-container'}>
                                        <label>First</label>
                                        <div>
                                            <label>Standard</label>
                                            <TextField
                                                name='vendorIntFirstStandard'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.vendorIntFirstStandard }
                                            />
                                        </div>
                                        <div>
                                            <label>Expedited (optional)</label>
                                            <TextField
                                                name='vendorIntFirstExpedited'
                                                onChange={ this.changeData }
                                                margin="normal"
                                                type='number'
                                                value={ this.state.data.vendorIntFirstExpedited }
                                            />
                                        </div>
                                    </div>
                                    <div className={'pricing-field-container'}>
                                        <label>Each Additional</label>
                                        <TextField
                                            name='vendorIntOtherStandard'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.vendorIntOtherStandard }
                                        />
                                        <TextField
                                            name='vendorIntOtherExpedited'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            type='number'
                                            value={ this.state.data.vendorIntOtherExpedited }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Button onClick={this.saveProduct} className={'submit'} variant="contained" color="primary">
                            Save
                        </Button>
                    </div>
                </div>
                {
                    this.state.showAddAttribute &&
                    <AddAttribute deleteAttribute={this.deleteAttribute} onSave={this.saveAttribute} />
                }
                {
                    this.state.showAddAttribute &&
                    <div onClick={this.toggleAttribute} className={'bg'}></div>
                }
            </div>
        );
    }
}

export default withSnackbar(withRouter(AboutProduct));