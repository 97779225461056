import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { v4 as uuid } from 'uuid';
import { LinkHeader as Link } from '../Link';
import { withRouter } from 'react-router';
import IconChevronRight from 'src/redesign/components/Icon/Chevron/Right';
import IconHome from 'src/redesign/components/Icon/Home';
import Loader from 'src/redesign/components/Loader';
import StructuredData from 'src/redesign/components/StructuredData';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const Breadscrumb = ({ links, loading, history }) => {
  const appUrl = process.env.REACT_APP_baseUrl;
  const breadscrumbStructuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": 
      [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": appUrl
      },
      ...links.map((x, i) =>
      ({
        "@type": "ListItem",
        "position": i + 2,
        "name": x.text,
        "item": appUrl.replace('api.', '').slice(0, appUrl.length - 1) + x.to
      })
    )]
  }

  return <div className="breadscrumb">
    <IconHome width={22} height={22} color={colors.primary} style={{ cursor: 'pointer' }} onClick={() => history.push('/home')}/>
    {loading ? (
      <Loader
        width={25}
        height={25}
        color={colors.primary}
      />
    ) :
    <>
      <StructuredData
        data={breadscrumbStructuredData}
      />
      {links.map((x, i) => (
        <div key={uuid()} className="link">
          <IconChevronRight width={10} height={15} color={colors.primary} strokeWidth={4} />
          <Link key={uuid() } {...x} className={i === (links.length - 1) ? 'text-active' : 'breadsrcumb-text'}/>
        </div>
      ))}
    </>
    }
  </div>
};

Breadscrumb.propTypes = {
  links: arrayOf(shape({
    to: string,
    text: string,
  }))
}

export default withRouter(Breadscrumb);