import React from 'react';

import './style.scss';

// imam values, da prosledim i newValues
// koje ce renderujem posle obicni values u niz a to su dodate preko '+'

// kad nije new product da imam '+' i da imam edit
// '+' klik - kad dodajem samo ga dodam u newAttributes
// edit klik - edtujem ceo atribut i vratim i izmenim ove koje imaju isti id

const AboutProductAttribute = ({ name, onDeleteAttribute, type, values, onDeleteValue, isNew }) => {
  const getValueDisplayText = value => type ? value.customer_color_name : value.name;

  return (
    <div className='about-product-attribute-row' key={`attribute-${name}`}>
      <div className='about-product-attribute-name-container'>
        <div className='about-product-attribute-name'>
          {name}
          {isNew && (
            <div className='about-product-attribute-name-delete' onClick={onDeleteAttribute}>
              ✕
            </div>
          )}
        </div>
      </div>
  
      <div className='about-product-attribute-value-row'>
        {values.map(x => (
          <div className='about-product-attribute-value'>
            {x.customer_color_name && (
              <div className='about-product-attribute-value-color' style={{ backgroundColor: x.name }}/>
            )}
            {getValueDisplayText(x)}
            {isNew && (
              <div className='about-product-attribute-value-delete' onClick={() => onDeleteValue(x)}>
                ✕
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}


export default AboutProductAttribute;