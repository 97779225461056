import React from 'react';
import { number, string } from 'prop-types';

const IconVerticalAlignment = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 0V19" stroke={color} strokeOpacity="0.7"/>
    <rect x="12" y="3.5" width="5" height="10" transform="rotate(90 12 3.5)" fill="white"/>
    <rect x="11.5" y="4" width="4" height="9" transform="rotate(90 11.5 4)" stroke={color} strokeOpacity="0.7"/>
    <rect x="14" y="10.5" width="6" height="14" transform="rotate(90 14 10.5)" fill="white"/>
    <rect x="13.5" y="11" width="5" height="13" transform="rotate(90 13.5 11)" stroke={color} strokeOpacity="0.7"/>
  </svg>
);
IconVerticalAlignment.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconVerticalAlignment;