import React from 'react';
import { v4 as uuid } from 'uuid';
import { withRouter } from 'react-router';
import Breadscrumb from 'src/redesign/components/Breadcrumb';
import Loader from 'src/redesign/components/Loader';
// import Searchbox from 'src/redesign/components/Searchbox';
import Page from 'src/redesign/components/Page';
import { CATEGORIES_TABS } from 'src/redesign/constants';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getCategoriesTree, getSubCategoriesFilters } from 'src/services/CategoryService';
import ProductItem from 'src/redesign/components/ProductItem';
import FilterPanel from 'src/redesign/components/FilterPanel';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import { mapBrandsFromFilters, mapCategories, mapNameToUrl } from 'src/redesign/helpers';

const ProductCatalogPageCategory = ({ match: { params: { tab }} }) => {
  const [loading, setLoading] = React.useState(false);
  const [filtersLoading, setFiltersLoading] = React.useState(false);
  // const [searchboxText, setSearchboxText] = React.useState('');

  const [categories, setCategories] = React.useState([]);
  const [filteredCategories, setFilteredCategories] = React.useState([]);
  const [topCategories, setTopCategories] = React.useState([]);

  const [filters, setFilters] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [brandFilters, setBrandFilters] = React.useState([]);
  const [brandSelectedFilters, setBrandSelectedFilters] = React.useState([]);

  const { openSnackBar } = useSnackbar();

  const selectedTab = CATEGORIES_TABS.find(x => x.key === tab);
  const selectedCategory = topCategories.find(x => x.name === selectedTab.text);
  const tabName = selectedCategory?.name;
  const tabDescription = selectedTab?.description;

  // React.useEffect(() => {
  //   setFilteredCategories(categories.filter(x => {
  //     const name = x?.name?.toLowerCase();
  //     return name.includes(searchboxText);
  //   }));
  // }, [searchboxText]);

  React.useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setFiltersLoading(true);
    setLoading(true);
    try {
      const { data } = await getCategoriesTree();

      const topCategories = data.filter(x => !x.parent_category);
      const selectedParentCategoryId = topCategories.find(x => x.name === selectedTab.text)?.id;
      const categories = data.filter(x => x?.parent_category?.id === selectedParentCategoryId);

      const { data: filters } = await getSubCategoriesFilters({
        categories: categories.map(x => x.id)
      });

      const mappedCategories = mapCategories(filters, categories);
      const brands = mapBrandsFromFilters(filters);

      setTopCategories(topCategories);
      setFilters(filters);
      setBrandFilters(brands);
      setCategories(mappedCategories);
      setFilteredCategories(mappedCategories);
    } catch {
      console.error(error);
      openSnackBar('Failed to load filters', false);
    } finally {
      setFiltersLoading(false);
      setLoading(false);
    }
  }

  function filterByAttributes(selectedFilters) {
    setSelectedFilters(selectedFilters);
    setFilteredCategories(
      categories.filter(x =>
        selectedFilters.every(f => x.attributeValues.includes(f)) &&
        brandSelectedFilters.every(f => x.brands.some(b => b.id == f))
      )
    );
  }

  function filterByBrand(selectedBrands) {
    setBrandSelectedFilters(selectedBrands);
    setFilteredCategories(
      categories.filter(x =>
        selectedBrands.every(f => x.brands.some(b => b.id == f)) &&
        selectedFilters.every(f => x.attributeValues.includes(f))
      )
    )
  }

  return <Page
    seoTitle={selectedTab.seoTitle}
    seoDescription={selectedTab.seoDescription}
  >
    <div className='product-catalog-category'>
      <div className='product-catalog-breadscrumb-searchbox-container'>
        <Breadscrumb
          links={[
            {
              to: '/product-catalog-website', text: 'Catalog',
            },
            {
              to: `/product-catalog-website/${tab}`, text: tabName ?? '',
            }
          ]}
        />
        {/* <Searchbox
          placeholder='Type to search'
          className='product-catalog-category-all-search-box'
          inputClassName='product-catalog-category-all-search-input'
          onChange={setSearchboxText}
        /> */}
      </div>
      <h1 className='catalog-category-name'>
          {tabName}
      </h1>
      {tabDescription && (
        <div className='catalog-category-description'>
          {tabDescription}
        </div>
      )}
      <div className='catalog-category-product-container'>
        <FilterPanel
          loading={filtersLoading}
          isDataLoading={loading}
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={selectedFilters => 
            filterByAttributes(selectedFilters)
          }
          brandFilters={brandFilters}
          selectedBrandFilters={brandSelectedFilters}
          setSelectedBrandFilters={selectedBrandFilters =>
            filterByBrand(selectedBrandFilters)
          }
        />
        {loading ? (
          <Loader
            width={300}
            height={300}
            color={colors.primary}
          />
        ) : (
          <div className='catalog-category-product-list-container'>
            {filteredCategories.map(x => <ProductItem key={uuid()} name={x?.name} price={`$${x?.min_price} - $${x?.max_price}`} url={process.env.REACT_APP_baseUrl + x?.category_image?.url} to={`/product-catalog-category/${mapNameToUrl(x.name)}/${x.id}`} />)}
          </div>
        )}
      </div>
     </div>
  </Page>
}

export default withRouter(ProductCatalogPageCategory);