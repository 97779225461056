import React from 'react';
import classNames from 'classnames';
import { bool, number, string } from 'prop-types';

import './style.scss';

const StepNumber = ({ number, completed, className }) => (
  <div className={classNames('step-number', {'step-number-completed' : completed}, className)}>
    {number}
  </div>
)

StepNumber.propTypes = {
  number: number,
  completed: bool,
  className: string
}

export default StepNumber;