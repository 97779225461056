import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { checkPath, checkIsAdminPage } from '../route';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {isUserLocked, isUserLoggedIn, getUserFromLocalStorage} from "./OAuth";

class AuthWrapper extends Component {

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        // if (this.props.location.pathname !== prevProps.location.pathname) {
        //     window.scrollTo(0, 0);
        // }
    }

    checkIsAdminPage(path){
        if(path.includes("/old/category") || path.includes("/old/admins")){
            return true;
        }

        return false;
    }

    checkPermission() {

        let needAuth = checkPath(this.props.location.pathname);

        if(needAuth && isUserLocked()) {

            this.props.history.push({
                pathname: '/lock',
                state   : { redirectUrl: this.props.location.pathname }
            });
            return false;
        }
        else if(needAuth && !isUserLoggedIn()) {

            this.props.history.push({
                pathname: '/login',
                state   : { redirectUrl: this.props.location.pathname }
            });

            return false;
        }

        if(isUserLoggedIn()){
            let adminPage = this.checkIsAdminPage(this.props.location.pathname);
            let userRole = getUserFromLocalStorage().roles[0];

            if(userRole == "ROLE_CUSTOMER" && adminPage){
                this.props.history.push('/home');
            }
        }
    
        return true;
    }

  

    render() {

        if(!this.checkPermission()) {
            return '';
        }

        const {children} = this.props;

        return (
            <React.Fragment>
                { children }
            </React.Fragment>
        )
    }
}



function mapDispatchToProps(dispatch)
{
    return bindActionCreators({}, dispatch);
}

function mapStateToProps({ authReducers })
{
    return {
        auth: authReducers,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthWrapper));
