import React from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import CreatableSelect from "react-select/creatable";

import './style.scss';

const CreatableSelectCustom = ({
  options,
  value,
  placeholder,
  className,
  onChange
 }) => (
  <CreatableSelect
    options={options}
    value={value}
    placeholder={placeholder}
    className={classNames('creatable-select-style', className)}
    onChange={onChange}
    formatOptionLabel={(value) => <div className='creatable-select-option'>{value.label}</div>}
  />
);

CreatableSelectCustom.propTypes = {
  options: arrayOf(shape({
    value: number.isRequired,
    label: string.isRequired,
    type: number.isRequired
  })).isRequired,
  value: shape({
    value: number.isRequired,
    label: string.isRequired,
    type: number.isRequired
  }),
  placeholder: string,
  className: string,
  onChange: func.isRequired
}

export default CreatableSelectCustom;