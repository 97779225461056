import React from 'react';

const IconUS = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2259_14277)">
    <path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="#F0F0F0"/>
    <path d="M12.4336 13.0002H25.9988C25.9988 11.8269 25.8424 10.6902 25.5509 9.60889H12.4336V13.0002Z" fill="#D80027"/>
    <path d="M12.4336 6.2175H24.0907C23.2949 4.91892 22.2774 3.77111 21.0911 2.82617H12.4336V6.2175Z" fill="#D80027"/>
    <path d="M12.9985 25.9999C16.0581 25.9999 18.8702 24.9424 21.0908 23.1738H4.90625C7.12691 24.9424 9.93903 25.9999 12.9985 25.9999Z" fill="#D80027"/>
    <path d="M1.90748 19.7829H24.0914C24.7302 18.7404 25.2257 17.6009 25.5516 16.3916H0.447266C0.773129 17.6009 1.2686 18.7404 1.90748 19.7829Z" fill="#D80027"/>
    <path d="M6.02184 2.03013H7.20652L6.10457 2.8307L6.52549 4.12608L5.42359 3.32551L4.32169 4.12608L4.68528 3.00701C3.71505 3.8152 2.86467 4.76206 2.16389 5.81709H2.54348L1.84204 6.32668C1.73276 6.50899 1.62795 6.69419 1.5275 6.88213L1.86245 7.91304L1.23754 7.459C1.0822 7.78812 0.940113 8.12464 0.812398 8.46818L1.18143 9.60406H2.54348L1.44153 10.4046L1.86245 11.7L0.760551 10.8994L0.100496 11.379C0.0344297 11.9101 0 12.451 0 13H13C13 5.82034 13 4.97392 13 0C10.4319 0 8.03791 0.744961 6.02184 2.03013ZM6.52549 11.7L5.42359 10.8994L4.32169 11.7L4.74261 10.4046L3.64066 9.60406H5.00271L5.42359 8.30868L5.84447 9.60406H7.20652L6.10457 10.4046L6.52549 11.7ZM6.10457 6.61766L6.52549 7.91304L5.42359 7.11247L4.32169 7.91304L4.74261 6.61766L3.64066 5.81709H5.00271L5.42359 4.52171L5.84447 5.81709H7.20652L6.10457 6.61766ZM11.1885 11.7L10.0866 10.8994L8.98473 11.7L9.40565 10.4046L8.3037 9.60406H9.66575L10.0866 8.30868L10.5075 9.60406H11.8696L10.7676 10.4046L11.1885 11.7ZM10.7676 6.61766L11.1885 7.91304L10.0866 7.11247L8.98473 7.91304L9.40565 6.61766L8.3037 5.81709H9.66575L10.0866 4.52171L10.5075 5.81709H11.8696L10.7676 6.61766ZM10.7676 2.8307L11.1885 4.12608L10.0866 3.32551L8.98473 4.12608L9.40565 2.8307L8.3037 2.03013H9.66575L10.0866 0.734754L10.5075 2.03013H11.8696L10.7676 2.8307Z" fill="#0052B4"/>
    </g>
    <defs>
    <clipPath id="clip0_2259_14277">
    <rect width="26" height="26" fill="white"/>
    </clipPath>
    </defs>
</svg>
);

export default IconUS;