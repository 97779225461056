import React from 'react';
import { useDropzone } from "react-dropzone";

import IconArrowUp from 'src/redesign/components/Icon/Arrow/ArrowUp';
import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const UploadFile = ({ setFiles, children, className, image, accept = '*' }) => {
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
    },
    [setFiles]
  );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept });

    return (
      <div
        {...getRootProps()}
        className={className}
      >
        {children ? (
          <>
            <input {...getInputProps()} className='input' />
            {children}
          </>
        ) : (
          <>
            {image ? (
              <Image alt={getImageAlt()} className='input' src={image} />
            ) : (
              <div className='input'>
                {!isDragActive && (
                  <div className='icon'>
                    <IconArrowUp width={20} height={22} color={colors.primary} />
                  </div>
                )}
              </div>
            )}
            <input {...getInputProps()} className='input' />
            <div className='text'>
              <div className="main">
                Upload a photo
              </div>
              <div className="description">
                Recommended size is 150x 150 pixels
              </div>
            </div>
          </>
        )}
      </div>
    )
}

export default UploadFile;