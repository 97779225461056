import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "@material-ui/core";
import strings from '../localization';
import {withSnackbar} from "notistack";
import { getUserDrafts, 
        deleteDraft,
        addDraftToShoppingCart } from "../services/ShoppingCart";
import {dateToString} from "../util/DateUtil";
import ReactDOM from 'react-dom';

class OrderList extends Page {

    params = [ { name: 'page', default: 1 },
               { name: 'perPage', default: 12 }];


    constructor(props) {
        super(props);

        this.state = {
            page : 1,
            perPage : 12,
            drafts: [],
            dropdownOpen: null,
            disabled : false
        }

        this.onPageClick = this.onPageClick.bind(this);
        this.onForward = this.onForward.bind(this);
        this.onBack = this.onBack.bind(this);
    }

    fetchData(){
        this.getUserDrafts(this.state.page, this.state.perPage);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
 
        if (this.state.dropdownOpen) {
            if(event.target.className != "opt" && event.target.className != "dropdown-opt"){
                event.stopPropagation();
                this.setState({
                    ...this.state,
                    dropdownOpen: false
                });
            }
        }
    }

    getUserDrafts(page, perPage){
        if (this.state.disabled) {
            return;
        }

        this.state.disabled = true;

        getUserDrafts({
            page : page, 
            perPage : perPage,
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                drafts: response.data.result,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                page : response.data.page,
                disabled : false
            })
        });
    }

    deleteItem(id){
        this.setState({
            ...this.state,
            dropdownOpen: null
        });

        deleteDraft({
            draftId: id
        }).then(response => {

            if(!response.ok) {
                this.props.enqueueSnackbar("Draft can't be deleted", {variant: 'error'});
                return;
            }

            setTimeout(() => {  
                this.fetchData();
            }, 1000);

        });
    }

    addDraftToShoppingCart(id){
        addDraftToShoppingCart({
            draftId: id
        }).then(response => {

            if(!response.ok) {
                return;
            }

            setTimeout(() => {  
                this.props.history.push('/cart');
            }, 1000);

        });
    }

    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return;
        }

        if(this.state.page > 5){
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.page + 4) < this.state.totalPages)){
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page == i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    onPageClick(i){
        this.state.page = i;
        this.getUserDrafts(i, this.state.perPage);
    }

    onBack(){
        this.state.page = Number(this.state.page) - 1;
        this.getUserDrafts(this.state.page, this.state.perPage);
    }

    onForward(){
        this.state.page = Number(this.state.page) + 1;
        this.getUserDrafts(this.state.page, this.state.perPage);
    }

    openDropdown(id) {
        if(this.state.dropdownOpen != null){
            this.setState({
                ...this.state,
                dropdownOpen: null
            })
        }else{
            this.setState({
                ...this.state,
                dropdownOpen: id
            })
        }
       
    }

    renderDrafts() {
        let result = [];

        for(let item of this.state.drafts)
        {
            result.push(
                <div className="order">
                    <div className="name">
                        { item.name }
                    </div>
                    <div className="date">
                        { dateToString(item.date_created) }
                    </div>
                    <div className="number">
                        { item.quantity }
                    </div>
                    <div className="price">
                        ${item.price.toFixed(2)}
                    </div>
                    <div className="button">
                        <Button onClick={ () => this.addDraftToShoppingCart(item.id) }>Add to cart</Button>
                    </div>
                    <div className="options">
                        <div className="opt" onClick={ () => this.openDropdown(item.id) }><img src="/images/tackice.png"></img></div>
                        <div className={ this.state.dropdownOpen == item.id ? "dropdown active" : "dropdown" }>
                            <div className="dropdown-opt" onClick={ () => this.deleteItem(item.id) }>Delete</div>
                            <div className="dropdown-opt" onClick={ ()=> this.props.history.push('/draft-details/'+ item.id) }>See more</div>
                        </div>
                    </div>
                </div>
            );
        }

        return result;
    }

    render() {

        return (
            <div className={'cont'}>
                <div id="order-list">
                    <div className={'helper'}>
                        <div className="header">
                            <div className="name">Name</div>
                            <div className="date">Date</div>
                            <div className="number">Number of product</div>
                            <div className="price">Price</div>
                            <div className="button"></div>
                        </div>
                        <div className={'drafts'}>
                            { this.renderDrafts() }
                        </div>
                    </div>
                    <div className={'pagination'}>
                        {
                            this.state.page > 1 &&
                            <img onClick={this.onBack} src={'/images/leftFilter.png'} />
                        }
                        <div className={'pages'}>
                            {this.renderPagination()}
                        </div>
                        {
                            this.state.page < this.state.totalPages &&
                            <img onClick={this.onForward} src={'/images/rightFilter.png'} />
                        }
                    </div>
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderList)));
