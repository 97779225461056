import React from 'react';

import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Loader from 'src/redesign/components/Loader';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Image from 'src/redesign/components/Image';
import { connectEtsy } from 'src/redesign/../services/UserService';
import { withRouter } from 'react-router';
import { getUserFromLocalStorage } from 'src/base/HTTP';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const EtsyConnectStore = ({ history }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const user = getUserFromLocalStorage();
  if(user?.customer?.user_customer_etsy)
    history.push('/stores');

  const { openSnackBar } = useSnackbar();

  const onClickConnect = async () => {
    setIsLoading(true);
    try {
      const { data } = await connectEtsy();
      window.open(data, "_self");

    } catch(error) {
      console.error(error);
      openSnackBar('Failed to connect etsy', false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <ApplicationPage selectedTab='stores'>
      <ApplicationPageContent title='Sales Channels'>
        <div className='setup-instructions-etsy'>
          <Image alt={getImageAlt()} src='/images/etsy.png' className='image'/>
          <div className='text'>
            After clicking {isLoading ? <Loader width={24} height={24} color={colors.primary} className='loader'/> : <a onClick={onClickConnect}>HERE</a>}, you will be prompted to an Etsy page like the one bellow, where you can give us access to your shop, after clicking on the Allow Access button on the Etsy page, you will be returned to Surge Print
          </div>
          <Image alt={getImageAlt()} src='/images/etsy-connect.png' className='etsy-connect-image' />
          <div className='text'>
            That's it!
            <br />
            <br />
            You have successfully connected your Etsy store to Surge Print and now you can submit orders and import & export products.
          </div>
        </div>
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default withRouter(EtsyConnectStore);