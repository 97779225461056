import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import IconExit from 'src/redesign/components/Icon/Exit';
import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import ArtworkThumbnail from './components/ArtworkThumbnail';
import FullScreenImageDialog from './components/FullScreenImage';
import Pagination from 'src/redesign/components/Pagination';
import Loader from 'src/redesign/components/Loader';
import UploadFile from 'src/redesign/components/UploadFile';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getBase64 } from 'src/services/ImageService';
import { deleteCustomerArtworkImage, getCustomersArtworkThumbnails } from 'src/services/UserService';
import { uploadArtworkImage } from 'src/services/admin/ProductVariationService';

import './style.scss';
import colors from 'src/scss/main.scss';

const PER_PAGE = 10;

const MyArtworksDialog = ({ onClose, onArtworkSelect }) => {
  const [loading, setLoading] = React.useState(true);
  const [uploadingImage, setUploadingImage] = React.useState(false);
  const [artworks, setArtworks] = React.useState([]);
  const [fullScreenImage, setFullScreenImage] = React.useState(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const { openSnackBar } = useSnackbar();


  React.useEffect(() => {
    load(currentPage);
  }, [currentPage]);

  const load = async (currentPage) => {
    setLoading(true);
    try {
      const { data: { total, result, page }} = await getCustomersArtworkThumbnails({ page: currentPage, perPage: PER_PAGE });

      setCurrentPage(page);
      setTotalPages(Math.ceil(total / PER_PAGE));
      setArtworks(result);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load Thumbnails!', false);
    } finally {
      setLoading(false);
    }
  }

  const uploadImage = async files => {
    const file = files?.[0];

    if(files.length === 0) {
      openSnackBar('This image is not allowed. Allowed are images with extension: .jpg, .jpeg, .png', false);
      return;
    }

    if(file.size >= 10485760 * 5) {
      openSnackBar('This image size is larger than 50MB. Please choose a smaller file or resize the image.', false);
      return;
    }

    setUploadingImage(true);

    try {
       let formData = new FormData();

      const base64 = getBase64(file);
      formData.append('image', file);
      formData.append('base64', base64);

      await uploadArtworkImage(formData);
      setCurrentPage(1);
      setTimeout(() => load(1), 800);

      openSnackBar('Successfully uploaded image!');
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to upload image!', false);
    } finally {
      setUploadingImage(false);
    }
  }

  const removeImage = async id => {
    try {
      await deleteCustomerArtworkImage(id);
      setTimeout(() => load(1), 800);

      openSnackBar('Successfully deleted artwork image');
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to remove image!', false);
    }
  }

  return ReactDOM.createPortal(<div className='my-artworks-dialog'>
    <div className='my-arworks-dialog-container'>

      <div className='my-artworks-dialog-title-container'>
        <div className='my-arworks-dialog-title'>
          My Artworks
        </div>
        <div className='my-artworks-dialog-exit' onClick={onClose}>
          <IconExit width={13.5} height={13.5} color={colors.primary}/>
        </div>
      </div>
    
    <UploadFile setFiles={uploadImage} accept='.jpg, .jpeg, .png' className='my-artworks-upload-button'>
      <SubmitPrimaryButton text='Upload New Artwork' onClick={() => {}} loading={uploadingImage} disabled={uploadingImage} width={186}/>
    </UploadFile>
    
      {loading ? (
        <div className={classNames({'my-artworks-list-loading': currentPage === 1 && totalPages === 1})}>
          <Loader width={254.6} height={254.6} color={colors.primary}/>
        </div>
      ) : (
        <div className='my-artworks-list'>
          {artworks.length > 0 ? artworks.map(x => {
            const image = process.env.REACT_APP_baseUrl + x?.customer_artwork_image_thumbnail?.path;
            return (
              <ArtworkThumbnail key={x?.id} onSelect={() => onArtworkSelect(x?.id)} name={x?.name} image={image} type={x?.type} original_width={x?.original_width} original_height={x?.original_height} onRemove={() => removeImage(x?.id)} onFullScreen={() => setFullScreenImage(image)}/>
            );
          }) : <div className='no-artworks-text'>No artworks in library, click on upload new artworks to upload</div>}
        </div>
        )}
    
      <Pagination 
        page={currentPage}
        setPage={setCurrentPage}
        totalPages={totalPages}
        className='my-arworks-pagination'
      />

      {fullScreenImage && (
        <FullScreenImageDialog
          image={fullScreenImage}
          onClose={() => setFullScreenImage(null)}
        />
      )}
      
    </div>
  </div>, document.getElementById('dialog-root'));
}

export default MyArtworksDialog;