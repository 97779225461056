import React from 'react';
import { setCustomerProfileInfo } from 'src/services/UserService';
import Form from 'src/redesign/components/Form/Form';
import SelectInput from 'src/redesign/components/Form/SelectInput';
import TextInput from 'src/redesign/components/Form/TextInput';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import * as Validators from 'src/redesign/util/validators';
import { mapLoadedUser, mapUpdatedUser } from './helpers';

const FormPersonalInfo = ({ user, countryOptions, countries, onUpdate }) => {
  const [form, setForm] = React.useState({});

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    setForm(mapLoadedUser(user));
  }, [user]);

  const saveUser = async (newUser) => {
    try {
      const user = { ...newUser, country: countries.find(x => x.id === newUser.country) };
      await setCustomerProfileInfo(user);
      onUpdate(mapUpdatedUser(user));
      openSnackBar('Successfully saved!');
    } catch {
      openSnackBar('Changing personal info failed!', false);
    }
  }
  
  return (
    <Form initialValues={form} title='Personal info' onSubmit={saveUser} submitButtonText="Save Changes">
      <TextInput label="First name" name="firstName" placeholder="Emily" validate={Validators.required('First name is required.')}/>
      <TextInput label="Last name" name="lastName" placeholder="Baker" validate={Validators.required('Last name is required.')}/>
      <TextInput label="Email" name="email" placeholder="emily.baker@surgeprint.com" validate={Validators.emailFormatValid('Email format is not valid.')}/>
      <SelectInput
        name="country"
        label="Country"
        options={countryOptions}
        placeholder="Choose your country"
      />
      <TextInput label="Store URL" name="storeUrl" placeholder="oliviapenelope.com/" validate={Validators.required('Store URL is required.')}/>
    </Form>
  )
};

export default FormPersonalInfo;