import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';

import './style.scss';

const NotFoundPage = () => (
  <div className='not-found-page'>
    <Helmet>
        <meta name="robots" content="noindex, follow" />
    </Helmet>
    <h1 className='not-found-title'>Not found!</h1>
    <Link to='/'>
      <SubmitPrimaryButton text='Return to homepage' />
    </Link>
  </div>
)

export default NotFoundPage;