import React, {Component} from 'react'

import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import { getOrderStatusString } from "../../constants/OrderStatus";
import {dateToString} from "../../util/DateUtil";
import {Button, TextField} from "@material-ui/core";
import Page from "../../common/Page";
import { changeShippingInfo,
         saveVendor } from "../../services/OrderService";
import {getCountryList} from "../../services/CountryService";
import SelectControl from "../controls/SelectControl";
import Validators from "../../constants/ValidatorTypes";
import { getAllVendors } from "../../services/VendorService";
import {getOrderSourceString} from "../../constants/OrderSource";
import {getUserFromLocalStorage} from "../../base/OAuth";
import UserType from "../../constants/UserType";

class Summary extends Page {

    validationList = {
        shippingAddress: [ {type: Validators.REQUIRED } ],
        shippingCity: [ {type: Validators.REQUIRED } ],
        shippingCountry: [ {type: Validators.REQUIRED } ],
        shippingEmail: [ {type: Validators.REQUIRED } ],
        shippingPhone: [ {type: Validators.REQUIRED } ],
        shippingFirstName: [ {type: Validators.REQUIRED } ],
        shippingLastName: [ {type: Validators.REQUIRED } ],
        shippingState: [ {type: Validators.REQUIRED } ],
        shippingPostalCode: [ {type: Validators.REQUIRED } ]
    };

    constructor(props) {
        super(props);

        this.state = {
            order: this.props.order,
            shippingEditing: false,
            detailsEditing: false,
            data: {
                shippingAddress: this.props.order.address,
                shippingAddress2: this.props.order.addressLineTwo,
                shippingState: this.props.order.state,
                shippingPostalCode: this.props.order.postalCode,
                shippingCity: this.props.order.city,
                shippingCountry: this.props.order.country,
                shippingEmail: this.props.order.email,
                shippingFirstName: this.props.order.firstName,
                shippingLastName: this.props.order.lastName,
                shippingPhone: this.props.order.phoneNumber,
                orderId: this.props.order.id,
                vendor: this.props.order.vendor
            },
            countries: [],
            vendors: [],
            userType: getUserFromLocalStorage().user_type
        }

        this.save = this.save.bind(this);
    }

    componentDidMount(){
        this.getCountryList();
        this.getAllVendors();
    }

    getAllVendors(){
        getAllVendors().then(response => {
           
            this.setState({
                ...this.state,
                vendors: response.data.vendors
           })
       });
    }

    getCountryList(){
        getCountryList().then(response => {
           
             this.setState({
                 ...this.state,
                countries: response.data.countries
            })
        });
    }

    shippingEdit(){
        this.setState({
            ...this.state,
            shippingEditing: true
        })
    }

    detailsEdit(){
        this.setState({
            ...this.state,
            detailsEditing: true
        });
    }

    save(){

        if(!this.validate()) {
            this.props.enqueueSnackbar("You need to entered required fields!", {variant: 'error'});
            return;
        }


        changeShippingInfo(this.state.data).then(response => {

            if(!response.ok) {
                return;
            }

            this.state.order.country = this.state.data.shippingCountry;
            this.state.order.address = this.state.data.shippingAddress;
            this.state.order.city = this.state.data.shippingCity;
            this.state.order.phone_number = this.state.data.shippingPhone;
            this.state.order.email = this.state.data.shippingEmail;
            this.state.order.first_name = this.state.data.shippingFirstName;
            this.state.order.last_name = this.state.data.shippingLastName;
            this.state.order.address_line_two = this.state.data.shippingAddress2;
            this.state.order.state = this.state.data.shippingState;
            this.state.order.postal_code = this.state.data.shippingPostalCode;

            this.setState({
                ...this.state,
                shippingEditing: false,
            });

        });
    }

    saveVendor(){
        if(this.state.data.vendor == null){
            this.props.enqueueSnackbar("You need to select vendor!", {variant: 'error'});
            return;
        }

        saveVendor({
            orderId: this.state.data.orderId,
            vendor: this.state.data.vendor
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.state.order.vendor = this.state.data.vendor;

            this.setState({
                ...this.state,
                detailsEditing: false
            });

        });
    }

    render() {

        return (
            <div>
                <div className="row">

                    <div className="details">
                        <div className="details-header">
                            Details
                        </div>
                        <div className="info">
                            <div className="left">
                                Status
                            </div>
                            <div className="right">
                                { getOrderStatusString(this.state.order.status) } 
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Order Total
                            </div>
                            <div className="right">
                                { this.state.order.totalPrice }
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Item in Order
                            </div>
                            <div className="right">
                                { this.state.order.quantity }
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Order source
                            </div>
                            <div className="right">
                                { getOrderSourceString(this.state.order.orderSource) }
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Order Date
                            </div>
                            <div className="right">
                                { dateToString(this.state.order.dateCreated) }
                            </div>
                        </div>
                        {
                            !this.state.detailsEditing &&
                            <div className="info">
                                <div className="left">
                                    Vendor
                                </div>
                                <div className="right">
                                    { this.state.order.vendor ? this.state.order.vendor.name : "-"}
                                </div>
                            </div>
                        }

                        {
                            this.state.detailsEditing && 
                            <div className="info">
                                <div className="left">
                                    Vendor
                                </div>
                                <div className="right">
                                    <SelectControl
                                        className='select'
                                        options={this.state.vendors}
                                        valueKey = { 'id' }
                                        onChange={ this.changeData }
                                        nameKey= { 'name' }
                                        name = {'vendor'}
                                        selected={this.state.data.vendor}
                                        placeholder = {'Vendor'}
                                    />
                                </div>
                            </div>
                        }

                        <div className="info">
                            <div className="left">
                                Credit terms
                            </div>
                            <div className="right">
                                No
                            </div>
                        </div>

                        {
                            !this.state.detailsEditing && this.state.userType === UserType.ADMIN &&
                            <Button className="edit-button" onClick={ () => this.detailsEdit() }>Edit</Button>
                        }
                        {
                            this.state.detailsEditing && 
                            <Button className="edit-button" onClick={ () => this.saveVendor() }>Save</Button>
                        }
                    </div>


                    <div className="details">
                        <div className="details-header">
                            Partner info
                        </div>
                        <div className="info">
                            <div className="left">
                                Partner Name
                            </div>
                            <div className="right">
                                { this.state.order.user.fullName }
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Partner ID
                            </div>
                            <div className="right">
                                { this.state.order.user.id }
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Partner Order ID
                            </div>
                            <div className="right">
                                { this.state.order.id }
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Email
                            </div>
                            <div className="right">
                                { this.state.order.user.email }
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Phone
                            </div>
                            <div className="right">
                                { this.state.order.phoneNumber }
                            </div>
                        </div>
                    </div>

                    <div className="details">
                        <div className="details-header">
                            Shipping Address
                        </div>
                        
                        {
                            this.state.shippingEditing && 
                            <div className="info">
                                <div className="left">
                                    Address line 1
                                </div>
                                <div className="right">
                                    <TextField
                                        className="sku-input"
                                        name={"shippingAddress"}
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingAddress }
                                        placeholder={'Address line 1'}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.shippingEditing &&
                            <div className="info">
                                <div className="left">
                                    Address line 2
                                </div>
                                <div className="right">
                                    <TextField
                                        className="sku-input"
                                        name={"shippingAddress2"}
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingAddress2 }
                                        placeholder={'Address line 2'}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.shippingEditing &&
                            <div className="info">
                                <div className="left">
                                    State/Region
                                </div>
                                <div className="right">
                                    <TextField
                                        className="sku-input"
                                        name={"shippingState"}
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingState }
                                        placeholder={'State/Region'}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.shippingEditing &&
                            <div className="info">
                                <div className="left">
                                    ZIP/Postal Code
                                </div>
                                <div className="right">
                                    <TextField
                                        className="sku-input"
                                        name={"shippingPostalCode"}
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingPostalCode }
                                        placeholder={'ZIP/Postal Code'}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        }
                        
                        {
                            this.state.shippingEditing && 
                            <div className="info">
                                <div className="left">
                                    City
                                </div>
                                <div className="right">
                                    <TextField
                                        className="sku-input"
                                        name={"shippingCity"}
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingCity }
                                        placeholder={'City'}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.shippingEditing && 
                            <div className="info input">
                                <div className="left">
                                    Country
                                </div>
                                <div className="right">
                                    <SelectControl
                                        className='select'
                                        options={this.state.countries}
                                        valueKey = { 'id' }
                                        onChange={ this.changeData }
                                        nameKey= { 'name' }
                                        name = {'shippingCountry'}
                                        selected={this.state.data.shippingCountry}
                                        placeholder = {'Country'}
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.shippingEditing && 
                            <div className="info">
                                <div className="left">
                                    Email
                                </div>
                                <div className="right">
                                    <TextField
                                        className="sku-input"
                                        name={"shippingEmail"}
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingEmail }
                                        placeholder={'Email'}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.shippingEditing &&
                            <div className="info">
                                <div className="left">
                                    First Name
                                </div>
                                <div className="right">
                                    <TextField
                                        className="sku-input"
                                        name={"shippingFirstName"}
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingFirstName }
                                        placeholder={'First Name'}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.shippingEditing &&
                            <div className="info">
                                <div className="left">
                                    Last Name
                                </div>
                                <div className="right">
                                    <TextField
                                        className="sku-input"
                                        name={"shippingLastName"}
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingLastName }
                                        placeholder={'Last Name'}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.shippingEditing && 
                            <div className="info">
                                <div className="left">
                                    Phone
                                </div>
                                <div className="right">
                                    <TextField
                                        className="sku-input"
                                        name={"shippingPhone"}
                                        onChange={ this.changeData }
                                        margin="normal"
                                        type='text'
                                        value={ this.state.data.shippingPhone }
                                        placeholder={'Phone'}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        }
                        
                        {
                            !this.state.shippingEditing && 
                            <div className="info">
                                <div className="left">
                                    Address Line 1
                                </div>
                                <div className="right">
                                    { this.state.order.address }, { this.state.order.city }, {this.state.order.country ? this.state.order.country.name : ''}
                                </div>
                            </div>
                        }

                        {
                            !this.state.shippingEditing &&
                            <div className="info">
                                <div className="left">
                                    Address Line 2
                                </div>
                                {
                                    this.state.order.addressLineTwo &&
                                    <div className="right">
                                        {this.state.order.addressLineTwo}, {this.state.order.city}, {this.state.order.country ? this.state.order.country.name : ''}
                                    </div>
                                }
                                {
                                    !this.state.order.addressLineTwo &&
                                    <div className="right">
                                        -
                                    </div>
                                }
                            </div>
                        }

                        {
                            !this.state.shippingEditing &&
                            <div className="info">
                                <div className="left">
                                    State/Region
                                </div>
                                <div className="right">
                                    { this.state.order.state }
                                </div>
                            </div>
                        }

                        {
                            !this.state.shippingEditing &&
                            <div className="info">
                                <div className="left">
                                    ZIP/Postal Code
                                </div>
                                <div className="right">
                                    { this.state.order.postalCode }
                                </div>
                            </div>
                        }

                        {
                            !this.state.shippingEditing && 
                            <div className="info">
                                <div className="left">
                                    Email
                                </div>
                                <div className="right">
                                    { this.state.order.email }
                                </div>
                            </div>
                        }

                        {
                            !this.state.shippingEditing &&
                            <div className="info">
                                <div className="left">
                                    First name
                                </div>
                                <div className="right">
                                    { this.state.order.firstName }
                                </div>
                            </div>
                        }

                        {
                            !this.state.shippingEditing &&
                            <div className="info">
                                <div className="left">
                                    Last name
                                </div>
                                <div className="right">
                                    { this.state.order.lastName }
                                </div>
                            </div>
                        }

                        {
                            !this.state.shippingEditing && 
                            <div className="info">
                                <div className="left">
                                    Phone
                                </div>
                                <div className="right">
                                    { this.state.order.phoneNumber }
                                </div>
                            </div>
                        }
                        {
                            !this.state.shippingEditing &&
                            <Button className="edit-button" onClick={ () => this.shippingEdit() }>Edit</Button>
                        }
                        {
                            this.state.shippingEditing && 
                            <Button className="edit-button" onClick={ () => this.save() }>Save</Button>
                        }

                    </div>
                </div>

                <div className="row">
                    <div className="details">
                        <div className="details-header">
                            Billing Address
                        </div>

                        <div className="info">
                            <div className="left">
                                Address
                            </div>
                            {
                                this.state.order.billingInformation &&
                                <div className="right">
                                    { this.state.order.billingInformation.billingAddress }, { this.state.order.billingInformation.city }, {this.state.order.billingInformation.country ? this.state.order.billingInformation.country.name : ""}
                                </div>
                            }
                            {
                                !this.state.order.billingInformation &&
                                <div className="right" style={{ textDecoration : "underline"}}>
                                    <a href="/settings/billing">Edit billing information</a>
                                </div>
                            }
                            
                        </div>

                        {/* <Button className="edit-button">Edit</Button> */}
                    </div>
                </div>

            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Summary)));
