import React from 'react';

import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Loader from 'src/redesign/components/Loader';
import Pagination from 'src/redesign/components/Pagination';
import Table from 'src/redesign/components/Table';
import Searchbox from 'src/redesign/components/Searchbox';
import PrimarySubmitButton from 'src/redesign/components/Buttons/SubmitPrimary';
import Menu from 'src/redesign/components/Menu';
import IconEdit from 'src/redesign/components/Icon/Edit';
import IconTrash from 'src/redesign/components/Icon/Trash';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import CreateEditMockupSidepane from './components/CreateEditSidepane';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import { createMockup, deleteMockup, editMockup, getMediaModifierMockups } from 'src/services/MediaModifierService';

const PER_PAGE = 8;

const MediaModifierMockupsPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState(null);

  const [mmMockups, setMMMockups] = React.useState([]);

  const [isAddSidepaneOpen, setIsAddSidePaneOpen] = React.useState(false);
  const [mockupToEdit, setMockupToEdit] = React.useState(null);
  const [saving, setSaving] = React.useState(false);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, [currentPage, searchTerm]);

  const load = async () => {
    setLoading(true);

    try {
      const { data } = await getMediaModifierMockups({ page: currentPage, perPage: PER_PAGE });

      setMMMockups(data.data.map((x) => ({ ...x, layers: JSON.stringify(x.layers) })));
      setTotalPages(Math.ceil(data.paginationInfo.total/PER_PAGE));
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load Media Modifier Mockups', false);
    } finally {
      setLoading(false);
    }
  }

  const onAddEditMockup = async (values) => {
    setSaving(true);

    const formData = new FormData();
    const fileInput = document.getElementById('mockupMainImage');
    const file = fileInput.files[0];
    
    formData.append('name', values.name);
    formData.append('mediaModifierId', values.mediaModifierId);
    formData.append('useColorFromVariation', values.useColorFromVariation);
    formData.append('scenes', values.scenes ? JSON.stringify(values.scenes): []);
    formData.append('layers', values.layers);
    formData.append('mockupMainImage', file);
    
    try {
      await (isAddSidepaneOpen ? createMockup(formData) : editMockup(mockupToEdit.id, formData));

      openSnackBar(`Mockup successfully ${isAddSidepaneOpen ? 'added' : 'edited'}`);
      setTimeout(load, 1000);
      setMockupToEdit(null);
      setIsAddSidePaneOpen(false);
    } catch(error) {
      console.error(error);
      openSnackBar(`Failed to ${isAddSidepaneOpen ? 'add' : 'edit'} mockup`, false);
    } finally {
      setSaving(false);
    }
  }

  const onDeleteMockup = async (id) => {
    try {
      await deleteMockup(id);

      openSnackBar('Mockup successfully deleted');
      setTimeout(load, 1000);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to delete mockup', false);
    }
  }

  const columns = [
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'Media Modifier ID',
      accessor: 'mediaModifierId'
    },
    {
      Header: 'Mockup Name',
      accessor: 'name'
    },
    {
      Header: 'Use color from variation',
      accessor: 'useColorFromVariation'
    },
    {
      Header: 'Layers',
      accessor: 'layers'
    },
    {
      Header: '',
      accessor: 'scenes',
      Cell: ({ row: { original }}) => (
        <Menu
          width={4}
          height={16}
          options={[
            {
              key: 'edit',
              text: <>
                Edit
                <IconEdit width={24} height={24} color={colors.primary} />
              </>,
              onSelect: () => setMockupToEdit(original)
            },
            {
              key: 'delete',
              text: <>
                Delete
                <IconTrash width={24} height={24} color={colors.primary} />
              </>,
              onSelect: () => onDeleteMockup(original.id)
            }
          ]}
        />
      )
    }
  ];

  return (
    <ApplicationPage selectedTab='admin'>
      <ApplicationPageContent title='MM Mockups'>
        <div className='mmm-page'>
          {/*
            TODO: search not implemented on the backend
          */}
          <Searchbox
            placeholder='Search'
            className='mm-searchbox-container'
            onChange={setSearchTerm}
            disabled={loading}
          />
          <PrimarySubmitButton
            text='Add new mockup'
            onClick={() => setIsAddSidePaneOpen(true)}
            className='mm-add-button'
            disabled={loading}
          />
          {loading
          ? <Loader width={200} height={200} color={colors.primary} />
          : (
            <div>
              <Table
                columns={columns}
                data={mmMockups}
              />
              <Pagination page={currentPage} setPage={setCurrentPage} totalPages={totalPages}/>
            </div>
          )}
        </div>
        {(isAddSidepaneOpen || mockupToEdit != null) && (
          <CreateEditMockupSidepane
            title={isAddSidepaneOpen ? 'Add new mockup' : 'Edit mockup'}
            initialValues={{ ...mockupToEdit, scenes: mockupToEdit?.scenes.map(x => x.id) ?? []}}
            onSubmit={onAddEditMockup}
            onClose={() => isAddSidepaneOpen
              ? setIsAddSidePaneOpen(false)
              : setMockupToEdit(null)}
            saving={saving}
          />
        )}
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default MediaModifierMockupsPage;