import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({

    en: {
        sidebar : {
            home : 'Home',
            orders : 'Orders',
            placeAnOrder : 'Place an Order',
            stores : 'Stores',
            productTemplates : 'Product Library',
            productCatalog : 'Product Catalog',
            accessories : 'Accessories',
            cloths : 'Cloths',
            homeAndLiving : 'Home & Living',
            settings : 'Settings',
            admin : 'Admin',
            users : 'Users',
            vendors : 'Vendors',
            mediaModifierMockups : 'MM Mockups',
            spDocuments: 'Documents',
            partners : 'Partners',
            products : 'Products',
            warehouse : 'WarehousePRO',
            associate : 'Affiliate',
            setup : 'Setup Instructions',
            allCategories : 'All categories',
            admins: "Admins",
            cart: "Cart",
            logout: "Logout"
        },

        table: {
            actions: 'Actions',
            view: 'View',
            edit: 'Edit',
            delete: 'Delete',
            confirmDelete: 'Confirm delete',
            no: 'No',
            yes: 'Yes',
            search: 'Search'
        },

        header: {
            lock: 'Lock',
            logout: 'Logout'
        },

        filter: {
            search: 'Search'
        },

        validation: {
            RequiredErrorMessage: 'required',
            MinLengthErrorMessage: 'Minimal length is ',
            MaxLengthErrorMessage: 'Maximal length is ',
            EmailErrorMessage: 'Please enter valid email',
            PasswordErrorMessage: 'Password must contain at least 6 letters, one upper case, one lower case and one number.',
            UserExistsErrorMessage: 'User with this email address already exists',
            OldPasswordDidNotMatch: 'Old password did not match',
            PasswordsNotEqual: 'Passwords do not match',
            notNumber: 'Not number'
        },

        notFound: {
            notFound: 'Not found!',
            dashboard: 'Dashboard'
        },

        forbidden: {
            forbidden: 'Forbidden!',
            dashboard: 'Dashboard'
        },

        error: {
            error: 'Error!',
            dashboard: 'Dashboard'
        },

        login: {
            email: 'Email',
            password: 'Password',
            login: 'Login',
            wrongCredentials: 'Wrong Credentials',
            forgotPassword: 'Forgot your password?',
            title: "Log in to your account",
            registrationButton: "SIGN UP",
            text1: "I agree to Surge Print",
            terms: "Terms of Service",
            policy: "Privacy Policy"
        },

        lock: {
            password: 'Password',
            login: 'Login',
            wrongCredentials: 'Wrong Credentials',
            unlock: 'Unlock'
        },

        userList: {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            isDeleted: 'Is deleted',
            dateCreated: 'Date Created',
            pageTitle: 'Users',
            enabled: 'Enabled',
            userDelete: 'User deleted',
            userRestored: 'User restored'
        },

        userForm: {
            email: 'Email',
            firstName: 'First name',
            lastName: 'Last name',
            ok: 'Ok',
            cancel: 'Cancel'
        },

        addUser: {
            pageTitle: 'Add user',
            errorAddClub: 'Error adding user',
            clubAdded: 'User added',
            errorAddingUser: 'Error adding user',
            userAdded: 'User added'
        },

        resetPassword: {
            email: 'Email',
            resetPassword: 'Reset password',
            password: 'Password',
            passwordRepeat: 'Password repeat'
        },
        
        home: {
            home: 'Home',
            howItWorks: 'How it works',
            products: 'Products',
            about: "About",
            title: "Create and sell custom products online",
            loginButton: "LOGIN",
            text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
            signUp: "SING UP"
        },

        registration: {
            login: "LOGIN",
            signUp: "Sign up",
            fistName: "First name",
            lastName: "Last name",
            phoneNumber: "Phone number",
            email: "Email",
            country: "Country",
            companyName: "Company name",
            city: "City",
            platformUsed: "Platform used",
            storeUrl: "Store URL"
        },

        userHome: {
            profile: "Profile"
        },

        category: {
            search: "Search",
            createNewProduct: "Create new product",
            createNewCategory: "Create new category",
            allCategories: "All categories",
            image: "Image",
            numberOfProducts: "Number of products",
            startAt: "Start At",
            addProductCategory: "Add Product Category",
            uploadMainImage: "Upload main image",
            uploadGalleryImage: "Upload gallery image",
            save: "Save",
            trending: "Trending",
            addGalleryImage: "Add image"
        },

        trending: {
            trending: "Trending",
            selectProduct: "Select Product",
            viewDetails: "View Details",
            startingAt: "Starting at"
        },

        menu: {
            options: "Options",
            logout: "Logout",
            cart: "Cart",
            Home: 'Home',
            Products: 'Products',
            Services: 'Services',
            OnlineGoods: 'Online Goods',
            EmeraldDragon: 'Emerald Dragon',
            Features: 'Features'
        },

        orderStatus: {
            newOrder: "New",
            needsCustomization: "Needs Customization",
            needsManualApproval: "Needs Manual Approval",
            pending: "Pending",
            hold: "Hold",
            canceled: "Canceled",
            test: "Test",
            unfinished: "Unfinished",
            draft: "Draft",
            cardDeclined: "Payment Failed",
            readyForPrint: "Ready For Print",
            readyForImageDI: "Ready For Image DI",
            readyForAccounting: "Ready For Accounting",
            inProduction: "In Production",
            manuallyInProduction: "Manually In Production",
            printing: "Printing",
            cancelRefunded: "Cancel Refunded",
            billedRefunded: "Billed Refunded",
            shipped: "Shipped",
            outForDelivery: "outForDelivery",
            delivered: "Delivered",
            prePayment: "Pre Payment",
            paymentIssue: "Payment Issue",
            orderStatus: "Order Status",
            imageIssue: "Image Issue",
            holdByImageIssue: "Hold By Image Issue",
            addressIssue: "Address Issue",
            orderDataIssue: "Order Data Issue",
            vendorApiIssue: "Vendor API Issue",
            shippingIssue: "Shipping Issue",
            garmentsReceived: "Garments Received",
            partiallyReceived: "Partially Received"
        },

        shippingMethod: {
            standard: "Standard",
            expedited: "Expedited",
            evernight: "Overnight"
        },
        mediaModifier: {
            label: 'Media Modifier',
            editSelectedMockupsForVariationLabel: 'Choose Media Modifier mockups to be used for selected skus'
        },
        productVariationSelection: {
            guidelinesLabel: 'Learn more about guidelines',
            downloadDesignTemplateLabel: 'Download template'
        }
    }
});

export default strings;
