import React from 'react';
import { number, string, secondaryColor } from 'prop-types';

const IconOrders = ({ width, height, color, secondaryColor }) => (
  <svg width={width} height={height} viewBox="0 0 22 21" fill={secondaryColor ? secondaryColor : 'white'} xmlns="http://www.w3.org/2000/svg">
    <path d="M14.2478 18.5H4.4808C3.78316 18.5 3.18518 17.9457 3.18518 17.2989V5.56525H15.7427C16.4404 5.56525 17.0383 6.11959 17.0383 6.76633V15.913C17.0383 17.2989 15.8424 18.5 14.2478 18.5Z" fill={secondaryColor ? secondaryColor : 'white'}/>
    <path d="M3.18518 5.56525V5.19025H2.81018V5.56525H3.18518ZM14.2478 18.125H4.4808V18.875H14.2478V18.125ZM4.4808 18.125C3.963 18.125 3.56018 17.7123 3.56018 17.2989H2.81018C2.81018 18.179 3.60331 18.875 4.4808 18.875V18.125ZM3.56018 17.2989V5.56525H2.81018V17.2989H3.56018ZM3.18518 5.94025H15.7427V5.19025H3.18518V5.94025ZM15.7427 5.94025C16.2605 5.94025 16.6633 6.35295 16.6633 6.76633H17.4133C17.4133 5.88624 16.6202 5.19025 15.7427 5.19025V5.94025ZM16.6633 6.76633V15.913H17.4133V6.76633H16.6633ZM16.6633 15.913C16.6633 17.074 15.6535 18.125 14.2478 18.125V18.875C16.0312 18.875 17.4133 17.5238 17.4133 15.913H16.6633Z" fill={color}/>
    <path d="M14.3473 18.4076C15.8334 18.4076 17.0382 17.2907 17.0382 15.913C17.0382 14.5353 15.8334 13.4185 14.3473 13.4185C12.8611 13.4185 11.6564 14.5353 11.6564 15.913C11.6564 17.2907 12.8611 18.4076 14.3473 18.4076Z" stroke={color} strokeWidth="0.75" strokeMiterlimit="10"/>
    <path d="M13.0946 15.5598L13.8772 16.5707L15.6218 15.0544" stroke="#00F2E2" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.65 8.98371V4.64133C13.65 4.17937 13.5503 3.71742 13.2513 3.25546C12.8527 2.51633 11.9557 1.50003 10.0621 1.50003" stroke="#00F2E2" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.474 8.98371V4.64133C6.474 4.17937 6.57366 3.71742 6.87265 3.25546C7.2713 2.51633 8.26793 1.50003 10.0619 1.50003" stroke="#00F2E2" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

IconOrders.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
  secondaryColor: string
}

export default IconOrders;