import React from 'react';
import { number, string } from 'prop-types';

const IconVisibility = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 20 13" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99992 0.333344C5.83325 0.333344 2.27492 2.92501 0.833252 6.58334C2.27492 10.2417 5.83325 12.8333 9.99992 12.8333C14.1666 12.8333 17.7249 10.2417 19.1666 6.58334C17.7249 2.92501 14.1666 0.333344 9.99992 0.333344ZM9.99992 10.75C7.69992 10.75 5.83325 8.88334 5.83325 6.58334C5.83325 4.28334 7.69992 2.41668 9.99992 2.41668C12.2999 2.41668 14.1666 4.28334 14.1666 6.58334C14.1666 8.88334 12.2999 10.75 9.99992 10.75ZM9.99992 4.08334C8.61658 4.08334 7.49992 5.20001 7.49992 6.58334C7.49992 7.96668 8.61658 9.08334 9.99992 9.08334C11.3833 9.08334 12.4999 7.96668 12.4999 6.58334C12.4999 5.20001 11.3833 4.08334 9.99992 4.08334Z" stroke="white"/>
  </svg>
);

IconVisibility.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconVisibility;