import React from 'react';
import { number, string } from 'prop-types';

const IconWarehouse = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 22 21" fill="white" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_154_4738)">
    <path d="M14.9941 10.4883H5.82843C5.59341 10.4883 5.4054 10.3002 5.4054 10.0652V0.899533C5.4054 0.664516 5.59341 0.476501 5.82843 0.476501H14.9941C15.2291 0.476501 15.4171 0.664516 15.4171 0.899533V10.0652C15.4171 10.3002 15.2291 10.4883 14.9941 10.4883ZM6.25146 9.66569H14.5946V1.32256H6.25146V9.66569Z" fill={color}/>
    <path d="M12.0564 5.50588C11.9388 5.50588 11.8448 5.45887 11.7508 5.38837L10.4112 4.04877L9.07163 5.38837C8.95412 5.50588 8.76611 5.55288 8.6251 5.48237C8.46058 5.41187 8.36658 5.27086 8.36658 5.10635V0.923032C8.36658 0.688014 8.55459 0.5 8.78961 0.5H12.0564C12.2914 0.5 12.4794 0.688014 12.4794 0.923032V5.08284C12.4794 5.24736 12.3854 5.41187 12.2209 5.45887C12.1504 5.48237 12.1034 5.50588 12.0564 5.50588ZM9.21264 1.32256V4.07227L10.1292 3.1557C10.1997 3.08519 10.3172 3.03819 10.4347 3.03819C10.5522 3.03819 10.6462 3.08519 10.7403 3.1557L11.6568 4.07227V1.32256H9.21264Z" fill={color}/>
    <path d="M10.4113 20.5H0.423032C0.188014 20.5 0 20.312 0 20.077V10.0887C0 9.85372 0.188014 9.66571 0.423032 9.66571H10.4113C10.6463 9.66571 10.8343 9.85372 10.8343 10.0887V20.077C10.8343 20.312 10.6463 20.5 10.4113 20.5ZM0.822562 19.654H9.98825V10.4883H0.822562V19.654Z" fill={color}/>
    <path d="M7.9201 17.7973C7.68508 17.7973 7.49707 17.6093 7.49707 17.3743V17.1628C7.49707 16.9278 7.68508 16.7397 7.9201 16.7397C8.15512 16.7397 8.34313 16.9278 8.34313 17.1628V17.3743C8.31963 17.6093 8.15512 17.7973 7.9201 17.7973Z" fill={color}/>
    <path d="M7.9201 19.2074C7.68508 19.2074 7.49707 19.0194 7.49707 18.7844V18.3613C7.49707 18.1263 7.68508 17.9383 7.9201 17.9383C8.15512 17.9383 8.34313 18.1263 8.34313 18.3613V18.7844C8.31963 19.0194 8.15512 19.2074 7.9201 19.2074Z" fill={color}/>
    <path d="M9.16571 19.2544C8.93069 19.2544 8.74268 19.0664 8.74268 18.8314V18.6199C8.74268 18.3849 8.93069 18.1968 9.16571 18.1968C9.40073 18.1968 9.58874 18.3849 9.58874 18.6199V18.8314C9.58874 19.0664 9.40073 19.2544 9.16571 19.2544Z" fill={color}/>
    <path d="M9.16571 18.0558C8.93069 18.0558 8.74268 17.8678 8.74268 17.6328V17.1863C8.74268 16.9513 8.93069 16.7632 9.16571 16.7632C9.40073 16.7632 9.58874 16.9513 9.58874 17.1863V17.6093C9.58874 17.8678 9.40073 18.0558 9.16571 18.0558Z" fill={color}/>
    <path d="M5.40545 17.7973C5.17044 17.7973 4.98242 17.6093 4.98242 17.3743V17.1628C4.98242 16.9278 5.17044 16.7397 5.40545 16.7397C5.64047 16.7397 5.82849 16.9278 5.82849 17.1628V17.3743C5.82849 17.6093 5.64047 17.7973 5.40545 17.7973Z" fill={color}/>
    <path d="M5.40545 19.2074C5.17044 19.2074 4.98242 19.0194 4.98242 18.7844V18.3613C4.98242 18.1263 5.17044 17.9383 5.40545 17.9383C5.64047 17.9383 5.82849 18.1263 5.82849 18.3613V18.7844C5.82849 19.0194 5.64047 19.2074 5.40545 19.2074Z" fill={color}/>
    <path d="M6.6745 19.2074C6.43948 19.2074 6.25146 19.0194 6.25146 18.7844V18.5729C6.25146 18.3379 6.43948 18.1498 6.6745 18.1498C6.90951 18.1498 7.09753 18.3379 7.09753 18.5729V18.7844C7.07403 19.0194 6.88601 19.2074 6.6745 19.2074Z" fill={color}/>
    <path d="M6.6745 18.0088C6.43948 18.0088 6.25146 17.8208 6.25146 17.5858V17.1628C6.25146 16.9278 6.43948 16.7397 6.6745 16.7397C6.90951 16.7397 7.09753 16.9278 7.09753 17.1628V17.5858C7.07403 17.8208 6.88601 18.0088 6.6745 18.0088Z" fill={color}/>
    <path d="M4.15985 19.2074C3.92483 19.2074 3.73682 19.0194 3.73682 18.7844V18.5729C3.73682 18.3379 3.92483 18.1498 4.15985 18.1498C4.39487 18.1498 4.58288 18.3379 4.58288 18.5729V18.7844C4.58288 19.0194 4.39487 19.2074 4.15985 19.2074Z" fill={color}/>
    <path d="M4.15985 18.0088C3.92483 18.0088 3.73682 17.8208 3.73682 17.5858V17.1628C3.73682 16.9278 3.92483 16.7397 4.15985 16.7397C4.39487 16.7397 4.58288 16.9278 4.58288 17.1628V17.5858C4.58288 17.8208 4.39487 18.0088 4.15985 18.0088Z" fill={color}/>
    <path d="M12.5029 7.80904C12.2678 7.80904 12.0798 7.62103 12.0798 7.38601V7.1745C12.0798 6.93948 12.2678 6.75146 12.5029 6.75146C12.7379 6.75146 12.9259 6.93948 12.9259 7.1745V7.38601C12.9024 7.62103 12.7144 7.80904 12.5029 7.80904Z" fill={color}/>
    <path d="M12.5029 9.21917C12.2678 9.21917 12.0798 9.03115 12.0798 8.79614V8.3731C12.0798 8.13809 12.2678 7.95007 12.5029 7.95007C12.7379 7.95007 12.9259 8.13809 12.9259 8.3731V8.79614C12.9024 9.03115 12.7144 9.21917 12.5029 9.21917Z" fill={color}/>
    <path d="M13.7485 9.24264C13.5135 9.24264 13.3254 9.05462 13.3254 8.81961V8.60809C13.3254 8.37307 13.5135 8.18506 13.7485 8.18506C13.9835 8.18506 14.1715 8.37307 14.1715 8.60809V8.81961C14.1715 9.05462 13.9835 9.24264 13.7485 9.24264Z" fill={color}/>
    <path d="M13.7485 8.04406C13.5135 8.04406 13.3254 7.85604 13.3254 7.62103V7.19799C13.3254 6.96298 13.5135 6.77496 13.7485 6.77496C13.9835 6.77496 14.1715 6.96298 14.1715 7.19799V7.62103C14.1715 7.85604 13.9835 8.04406 13.7485 8.04406Z" fill={color}/>
    <path d="M9.98822 7.80904C9.7532 7.80904 9.56519 7.62103 9.56519 7.38601V7.1745C9.56519 6.93948 9.7532 6.75146 9.98822 6.75146C10.2232 6.75146 10.4112 6.93948 10.4112 7.1745V7.38601C10.4112 7.62103 10.2232 7.80904 9.98822 7.80904Z" fill={color}/>
    <path d="M9.98822 9.21917C9.7532 9.21917 9.56519 9.03115 9.56519 8.79614V8.3731C9.56519 8.13809 9.7532 7.95007 9.98822 7.95007C10.2232 7.95007 10.4112 8.13809 10.4112 8.3731V8.79614C10.4112 9.03115 10.2232 9.21917 9.98822 9.21917Z" fill={color}/>
    <path d="M11.2573 9.21914C11.0222 9.21914 10.8342 9.03113 10.8342 8.79611V8.58459C10.8342 8.34957 11.0222 8.16156 11.2573 8.16156C11.4923 8.16156 11.6803 8.34957 11.6803 8.58459V8.79611C11.6568 9.03113 11.4688 9.21914 11.2573 9.21914Z" fill={color}/>
    <path d="M11.2573 8.02056C11.0222 8.02056 10.8342 7.83255 10.8342 7.59753V7.1745C10.8342 6.93948 11.0222 6.75146 11.2573 6.75146C11.4923 6.75146 11.6803 6.93948 11.6803 7.1745V7.59753C11.6568 7.83255 11.4688 8.02056 11.2573 8.02056Z" fill={color}/>
    <path d="M8.74261 9.21914C8.50759 9.21914 8.31958 9.03113 8.31958 8.79611V8.58459C8.31958 8.34957 8.50759 8.16156 8.74261 8.16156C8.97763 8.16156 9.16564 8.34957 9.16564 8.58459V8.79611C9.16564 9.03113 8.97763 9.21914 8.74261 9.21914Z" fill={color}/>
    <path d="M8.74261 8.02056C8.50759 8.02056 8.31958 7.83255 8.31958 7.59753V7.1745C8.31958 6.93948 8.50759 6.75146 8.74261 6.75146C8.97763 6.75146 9.16564 6.93948 9.16564 7.1745V7.59753C9.16564 7.83255 8.97763 8.02056 8.74261 8.02056Z" fill={color}/>
    <path d="M17.0858 17.7973C16.8507 17.7973 16.6627 17.6093 16.6627 17.3743V17.1628C16.6627 16.9278 16.8507 16.7397 17.0858 16.7397C17.3208 16.7397 17.5088 16.9278 17.5088 17.1628V17.3743C17.4853 17.6093 17.2973 17.7973 17.0858 17.7973Z" fill={color}/>
    <path d="M17.0858 19.2074C16.8507 19.2074 16.6627 19.0194 16.6627 18.7844V18.3613C16.6627 18.1263 16.8507 17.9383 17.0858 17.9383C17.3208 17.9383 17.5088 18.1263 17.5088 18.3613V18.7844C17.4853 19.0194 17.2973 19.2074 17.0858 19.2074Z" fill={color}/>
    <path d="M18.3314 19.2544C18.0963 19.2544 17.9083 19.0664 17.9083 18.8314V18.6199C17.9083 18.3849 18.0963 18.1968 18.3314 18.1968C18.5664 18.1968 18.7544 18.3849 18.7544 18.6199V18.8314C18.7544 19.0664 18.5664 19.2544 18.3314 19.2544Z" fill={color}/>
    <path d="M18.3314 18.0558C18.0963 18.0558 17.9083 17.8678 17.9083 17.6328V17.1863C17.9083 16.9513 18.0963 16.7632 18.3314 16.7632C18.5664 16.7632 18.7544 16.9513 18.7544 17.1863V17.6093C18.7544 17.8678 18.5664 18.0558 18.3314 18.0558Z" fill={color}/>
    <path d="M14.5711 17.7973C14.3361 17.7973 14.1481 17.6093 14.1481 17.3743V17.1628C14.1481 16.9278 14.3361 16.7397 14.5711 16.7397C14.8061 16.7397 14.9941 16.9278 14.9941 17.1628V17.3743C14.9941 17.6093 14.8061 17.7973 14.5711 17.7973Z" fill={color}/>
    <path d="M14.5711 19.2074C14.3361 19.2074 14.1481 19.0194 14.1481 18.7844V18.3613C14.1481 18.1263 14.3361 17.9383 14.5711 17.9383C14.8061 17.9383 14.9941 18.1263 14.9941 18.3613V18.7844C14.9941 19.0194 14.8061 19.2074 14.5711 19.2074Z" fill={color}/>
    <path d="M15.8167 19.2074C15.5817 19.2074 15.3937 19.0194 15.3937 18.7844V18.5729C15.3937 18.3379 15.5817 18.1498 15.8167 18.1498C16.0517 18.1498 16.2397 18.3379 16.2397 18.5729V18.7844C16.2397 19.0194 16.0517 19.2074 15.8167 19.2074Z" fill={color}/>
    <path d="M15.8167 18.0088C15.5817 18.0088 15.3937 17.8208 15.3937 17.5858V17.1628C15.3937 16.9278 15.5817 16.7397 15.8167 16.7397C16.0517 16.7397 16.2397 16.9278 16.2397 17.1628V17.5858C16.2397 17.8208 16.0517 18.0088 15.8167 18.0088Z" fill={color}/>
    <path d="M13.3255 19.2074C13.0905 19.2074 12.9025 19.0194 12.9025 18.7844V18.5729C12.9025 18.3379 13.0905 18.1498 13.3255 18.1498C13.5605 18.1498 13.7485 18.3379 13.7485 18.5729V18.7844C13.7485 19.0194 13.5605 19.2074 13.3255 19.2074Z" fill={color}/>
    <path d="M13.3255 18.0088C13.0905 18.0088 12.9025 17.8208 12.9025 17.5858V17.1628C12.9025 16.9278 13.0905 16.7397 13.3255 16.7397C13.5605 16.7397 13.7485 16.9278 13.7485 17.1628V17.5858C13.7485 17.8208 13.5605 18.0088 13.3255 18.0088Z" fill={color}/>
    <path d="M3.76031 15.4941C3.71331 15.4941 3.6663 15.4941 3.6193 15.4706C3.45479 15.4236 3.33728 15.2591 3.33728 15.0711V10.0887C3.33728 9.85372 3.52529 9.66571 3.76031 9.66571H7.09756C7.33258 9.66571 7.52059 9.85372 7.52059 10.0887V15.0711C7.52059 15.2591 7.40309 15.4001 7.23857 15.4706C7.07406 15.5412 6.88605 15.4706 6.76854 15.3296L5.40544 13.661L4.06583 15.3296C3.99533 15.4471 3.87782 15.4941 3.76031 15.4941ZM5.40544 12.5799C5.52294 12.5799 5.64045 12.6269 5.73446 12.7444L6.65103 13.896V10.5118H4.15984V13.896L5.07641 12.7444C5.17042 12.6269 5.28793 12.5799 5.40544 12.5799Z" fill={color}/>
    <path d="M19.577 20.5H10.4113C10.1763 20.5 9.98828 20.312 9.98828 20.077V10.0887C9.98828 9.85372 10.1763 9.66571 10.4113 9.66571H19.577C19.812 9.66571 20 9.85372 20 10.0887V20.077C20 20.312 19.812 20.5 19.577 20.5ZM10.8343 19.654H19.154V10.4883H10.8343V19.654Z" fill={color}/>
    <path d="M16.6627 15.4941C16.5452 15.4941 16.4277 15.4471 16.3337 15.3296L14.9941 13.661L13.6545 15.3296C13.537 15.4706 13.349 15.5177 13.1845 15.4706C13.02 15.4236 12.9025 15.2591 12.9025 15.0711V10.0887C12.9025 9.85372 13.0905 9.66571 13.3255 9.66571H16.6627C16.8978 9.66571 17.0858 9.85372 17.0858 10.0887V15.0711C17.0858 15.2591 16.9683 15.4001 16.8038 15.4706C16.7568 15.4941 16.7098 15.4941 16.6627 15.4941ZM14.9941 12.5799C15.1116 12.5799 15.2291 12.6269 15.3231 12.7444L16.2397 13.896V10.5118H13.7485V13.896L14.6651 12.7444C14.7591 12.6269 14.8766 12.5799 14.9941 12.5799Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_154_4738">
    <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
    </defs>
  </svg>
);

IconWarehouse.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired
}

export default IconWarehouse;