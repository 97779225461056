import React from 'react';
import { LazyLoadImage as Image } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyLoadImage = ({
  onClick,
  style,
  src,
  alt,
  className
}) => (
  <Image
    onClick={onClick}
    style={style}
    src={src}
    alt={alt}
    className={className}
    loading='lazy'
    effect='blur'
  />
);

export default LazyLoadImage;
