import React from 'react';
import {Link} from "react-router-dom";

import { LogoNewFooter } from 'src/redesign/components/Logo';
import IconInstagram from 'src/redesign/components/Icon/Social/Instagram';
import IconYoutube from 'src/redesign/components/Icon/Social/Youtube';
import IconStripe from 'src/redesign/components/Icon/Stripe';
import IconJCB from 'src/redesign/components/Icon/JCB';
import IconMaestro from 'src/redesign/components/Icon/Maestro';
import IconDiscover from 'src/redesign/components/Icon/Discover';
import IconUnionPay from 'src/redesign/components/Icon/UnionPay';
import IconAmericanExpress from 'src/redesign/components/Icon/AmericanExpress';
import IconMasterCard from 'src/redesign/components/Icon/Mastercard';
import IconPaypal from 'src/redesign/components/Icon/Paypal';
import IconVisa from 'src/redesign/components/Icon/Visa';
import IconDiners from 'src/redesign/components/Icon/Diners';
import IconFedex from 'src/redesign/components/Icon/Fedex';
import IconDPD from 'src/redesign/components/Icon/DPD';
import IconDHL from 'src/redesign/components/Icon/DHL';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const Footer = () => {

  return <div className="redesign-footer">
    <div className='footer-primary-container'>
      <div className='footer-primary-main-container'>
        <div className='footer-primary-logo-container'>
          <LogoNewFooter />
          <div className='footer-primary-logo-title'>
            Surge print
          </div>
        </div>
        <div className='footer-primary-main-description'>
          Your Premium Print on Demand partner. We create, sync and create custom workflows for you!
        </div>
        <div className='footer-primary-social-icons'>
          <a className='footer-primary-social-icon-wrapper' href='https://www.instagram.com/surge_print/' target="_blank">
            <IconInstagram width={32} height={32} color={colors.primary} />
          </a>
          <a className='footer-primary-social-icon-wrapper' href='https://www.youtube.com/channel/UC-WUrwOnSRK75C6fuDgcq0A' target="_blank">
            <IconYoutube width={32} height={32} color={colors.primary}/>
          </a>
        </div>
      </div>

      <div className='footer-primary-links-container'>
        <div className='footer-primary-links-title'>
          Integration
        </div>
        <div className='footer-primary-links'>
          <Link className='footer-primary-link' to='/integration-shopify'>Shopify</Link>
          <Link className='footer-primary-link' to='/integration-etsy'>Etsy</Link>
        </div>
      </div>

      <div className='footer-primary-links-container'>
        <div className='footer-primary-links-title'>
          Printing Methods
        </div>
        <div className='footer-primary-links'>
          <Link className='footer-primary-link' to='/printing-methods-dtg'>DTG printing</Link>
          <Link className='footer-primary-link' to='/printing-methods-embroidery'>Embroidery</Link>
        </div>
      </div>

      <div className='footer-primary-links-container'>
        <div className='footer-primary-links-title'>
          Start Selling
        </div>
        <div className='footer-primary-links'>
          <Link className='footer-primary-link' to='/custom-mugs'>Custom Mugs</Link>
          {/* TODO: connect links when pages are done */}
          <Link className='footer-primary-link' to='#'>Custom T-Shirts</Link>
          <Link className='footer-primary-link' to='/custom-blankets'>
            Custom Blankets
          </Link>
          <Link className='footer-primary-link' to='/custom-hoodies'>Custom Hoodies</Link>
          <Link className='footer-primary-link' to='#'>Custom Thumblers</Link>
        </div>
      </div>

      <div className='footer-primary-links-container'>
        <div className='footer-primary-links-title'>
          Discover
        </div>
        <div className='footer-primary-links'>
          <Link className='footer-primary-link' to='/why-us'>Why Us</Link>
          <Link className='footer-primary-link' to='#'>SurgePrint vs Printful</Link>
          <Link className='footer-primary-link' to='/printing-methods-screen-printing'>Screen Printing</Link>
          <Link className='footer-primary-link' to='/printing-methods-bulk-orders'>Bulk Orders</Link>
        </div>
      </div>

      <div className='footer-primary-links-container'>
        <div className='footer-primary-links-title'>
          Catalog
        </div>
        <div className='footer-primary-links'>
          <Link className='footer-primary-link' to='/product-catalog-website'>Categories</Link>
          <Link className='footer-primary-link' to='/product-catalog-website/accessories'>Category Accessories</Link>
          <Link className='footer-primary-link' to='/product-catalog-website/apparel'>Category Apparel</Link>
          <Link className='footer-primary-link' to='/product-catalog-website/drinkware'>Category Drinkware</Link>
          <Link className='footer-primary-link' to='/product-catalog-website/home-decor'>Category Home Decor</Link>
          <Link className='footer-primary-link' to='/product-catalog-website/wall-art'>Category Wall Art</Link>
        </div>
      </div>
    </div>


    <div className='icons-main-container'>
      <div className='icons-container'>
        <div className='icons-title'>Payment methods:</div>
        <div className='icons'>
          <IconStripe width={85} height={23} />
          <IconJCB width={36} height={24} />
          <IconMaestro width={36} height={24} />
          <IconDiscover width={36} height={24} />
          <IconUnionPay width={36} height={24} />
          <IconAmericanExpress width={36} height={24} />
          <IconMasterCard width={36} height={24} />
          <IconPaypal width={36} height={24} />
          <IconVisa width={36} height={24} />
          <IconDiners width={36} height={24} />
        </div>
      </div>
      <div className='icons-container'>
        <div className='icons-title'>Shipping carriers:</div>
        <div className='icons'>
          <IconFedex width={36} height={24} />
          <IconDPD width={36} height={24} />
          <IconDHL width={36} height={24} />
        </div>
      </div>
    </div>
    <div className='rights-container'>
      ©Surge Print. All rights reserved
    </div>
  </div>
}

export default Footer;