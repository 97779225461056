import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import TextInput from 'src/redesign/components/Form/Sidepane/TextInput';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Loader from 'src/redesign/components/Loader';
import Pagination from 'src/redesign/components/Pagination';
import Table from 'src/redesign/components/Table';
import Searchbox from 'src/redesign/components/Searchbox';
import PrimarySubmitButton from 'src/redesign/components/Buttons/SubmitPrimary';
import Sidepane from 'src/redesign/components/Sidepane';
import IconTrash from 'src/redesign/components/Icon/Trash';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import * as Validators from 'src/redesign/util/validators';

import { createAdmin, deleteUser, getAdmins } from 'src/services/UserService';
import { dateToString } from 'src/util/DateUtil';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const PER_PAGE = 15;

const AdminsPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState(null);
  
  const [admins, setAdmins] = React.useState([]);

  const [isAddSidepaneOpen, setIsAddSidePaneOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [deletingId, setDeletingId] = React.useState(null);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, [currentPage, searchTerm]);

  const load = async () => {
    setLoading(true);

    try {
      const { data } = await getAdmins({
        page: currentPage,
        perPage: PER_PAGE,
        searchTerm
      });

      setAdmins(data.result);
      setTotalPages(Math.ceil(data.total/PER_PAGE));
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load admins', false);
    } finally {
      setLoading(false);
    }
  }

  const onAddAdmin = async (values) => {
    setSaving(true);
    try {
      await createAdmin(values);
      
      openSnackBar('Admin successfully created');
      setIsAddSidePaneOpen(false);
      setTimeout(load, 1000);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to create admin', false);
    } finally {
      setSaving(false);
    }
  }

  const onDeleteAdmin = async (id) => {
    setDeletingId(id);
    try {
      await deleteUser({ id });

      openSnackBar('Admin successfully deleted');
      setTimeout(load, 1000);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to delete admin', false);
    } finally {
      setDeletingId(null);
    }
  }

  const columns = [
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'First Name',
      accessor: 'first_name'
    },
    {
      Header: 'Last Name',
      accessor: 'last_name'
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Date',
      accessor: 'date_created',
      Cell: ({ row: { original: { date_created }} }) => <>{dateToString(date_created)}</>
    },
    {
      Header: '',
      accessor: 'user_type',
      Cell: ({ row: { original: { id }} }) => 
        id == deletingId
          ? <Loader color={colors.primary} width={24} height={24} />
          : <div style={{ cursor: 'pointer' }} onClick={() => onDeleteAdmin(id)}>
              <IconTrash color={colors.primary} width={24} height={24} />
          </div>
    },
  ];

  return (
    <ApplicationPage selectedTab='admin'>
        <ApplicationPageContent title='Admins'>
          <div className='admins-page'>
            <Searchbox
              placeholder='Search'
              className='admins-searchbox-container'
              onChange={setSearchTerm}
              disabled={loading}
            />
            <PrimarySubmitButton
              text='Add new admin'
              onClick={() => setIsAddSidePaneOpen(true)}
              className='admins-add-button'
              disabled={loading}
            />
            {loading
            ? <Loader width={200} height={200} />
            : (
              <div>
                <Table
                  columns={columns}
                  data={admins}
                />
                <Pagination page={currentPage} setPage={setCurrentPage} totalPages={totalPages}/>
              </div>
            )}
          </div>
          {isAddSidepaneOpen && (
            <Sidepane
              title='Add new admin'
              content={
                <FinalForm onSubmit={onAddAdmin} render={({ handleSubmit }) => (
                  <form id='addAdminForm' className='admins-add-form' onSubmit={handleSubmit}>
                    <TextInput
                      name='email'
                      placeholder='Email'
                      validate={Validators.composeValidators(
                        Validators.required(' '),
                        Validators.emailFormatValid(' ')
                      )}
                    />
                    <TextInput
                      name='firstName'
                      placeholder='First name'
                      validate={Validators.required(' ')}
                    />
                    <TextInput
                      name='lastName'
                      placeholder='Last name'
                      validate={Validators.required(' ')}
                    />
                    <TextInput
                      name='password'
                      placeholder='Password'
                      type='password'
                      validate={Validators.required(' ')}
                    />
                  </form>
                )}
                />
              }
              actions={
                <PrimarySubmitButton
                  text='Save'
                  onClick={() => 
                    document.getElementById('addAdminForm')
                    .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                  }
                  width={160}
                  height={40}
                  disabled={saving}
                  loading={saving}
                />
              }
              onClose={() => setIsAddSidePaneOpen(false)}
            />
          )}
        </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default AdminsPage;