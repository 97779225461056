import React from 'react';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import TextCopy from 'src/redesign/components/TextCopy';
import Expandable from 'src/redesign/components/Expandable';
import Status from 'src/redesign/components/Status';
import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const OrderDetailsPrintReady = ({ order: { items } }) => {
  const noMockupUrl = "/images/no-image.png";
  const noArtworkUrl = "/images/no-image.png";

  const downloadImage = url => {
    const link = document.createElement('a');
    link.setAttribute('href', 'data:application/octet-stream,' + encodeURIComponent(url));
    link.setAttribute('download', url);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
  return (
    <div className='order-details-print-ready'>
      {items.map(i => (
        <div className='item-container'>
          <div className='left'>
            <div className='title'>
              Item: {i.id}
            </div>
            <div className='images-container'>
              <div className='images-container'>
                <div className='image-container'>
                  <Image alt={getImageAlt()} className='image' src={i.image ?? noMockupUrl} />
                  <SubmitButton className='download-button' text='Download Mockup' onClick={() => i.image ? downloadImage(i.image) : undefined} disabled={!i.image}/>
                </div>
                <div className='image-container'>
                  <Image alt={getImageAlt()} className='image' src={i.artwork ?? noArtworkUrl} />
                  <SubmitButton className='download-button' text='Download Design' onClick={() => i.artwork ? downloadImage(i.artwork) : undefined} disabled={!i.artwork}/>
                </div>
              </div>
            </div>
          </div>
          <div className='right'>
            <div className='title'>
              <span>
                Partner order ID:
              </span>
              <span>
                {i.id}
              </span>
            </div>
            <div className='item-description'>
              <div className='row'>
                <div className='row-title'>
                  Vendor Order ID:
                </div>
                {/* TODO: connect this with proper vendor id */}
                <div className='value'>
                  N/A
                </div>
              </div>
              <div className='row'>
                <div className='row-title'>
                  Status
                </div>
                <div className='value'>
                  <Status statusNumber={i.status} />
                </div>
              </div>
              <div className='row'>
                <div className='row-title'>
                  SKU:
                </div>
                <div className='value'>
                  <TextCopy text={i.sku} />
                </div>
              </div>
              <Expandable
              >
                {i.attributes.map(a => (
                  <div className='row'>
                    <div className='row-title'>
                      {a.name}
                    </div>
                    <div className='value'>
                      {a.value}
                    </div>
                  </div>
                ))}
              </Expandable>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default OrderDetailsPrintReady;