import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import {dateToString} from "../util/DateUtil";
import ReactDOM from 'react-dom';
import { getCustomers,
        deactivateUser,
        getCurrentUserAction} from "../services/UserService";
import {Button} from "@material-ui/core";
import {getUserFromLocalStorage, setImpersonatedUser, setUserToLocalStorage} from "../base/OAuth";
import history from '../history';
import Helmet from 'react-helmet';

class Users extends Page {

    params = [ { name: 'page', default: 1 },
               { name: 'perPage', default: 12 }];


    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            perPage: 15,
            customers: [],
            dropdownOpen: null,
            data: {
                searchTerm: null
            },
            userRole: null
        }

        this.onPageClick = this.onPageClick.bind(this);
        this.onForward = this.onForward.bind(this);
        this.onBack = this.onBack.bind(this);
        this.doesHaveAdminPermissions();
        this.keyPress = this.keyPress.bind(this);
    }

    fetchData(){
        this.getCustomers(this.state.page, this.state.perPage);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);

        this.setState({
            userRole: getUserFromLocalStorage().roles[0]
        })
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    keyPress(event) {

        if(event.key == 'Enter') {
            this.getCustomers(1, this.state.perPage);
        }
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
 
        if (this.state.dropdownOpen) {
            if(event.target.className != "opt" && event.target.className != "dropdown-opt"){
                event.stopPropagation();
                this.setState({
                    ...this.state,
                    dropdownOpen: false
                });
            }
        }
    }

    getCustomers(page, perPage){
        getCustomers({
            page : page, 
            perPage : perPage,
            searchTerm: this.state.data.searchTerm
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                customers: response.data.result,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                page : response.data.page
            })
        });
    }

    openDropdown(id,e) {
        e.stopPropagation();

        if(this.state.dropdownOpen != null){
            this.setState({
                ...this.state,
                dropdownOpen: null
            })
        }else{
            this.setState({
                ...this.state,
                dropdownOpen: id
            })
        }
       
    }

    editItem(item){

        this.setState({
            ...this.state,
            dropdownOpen: null
        });

        // if(this.state.type == "Category"){
        //     this.setState({
        //         ...this.state,
        //         data: {
        //             ...this.state.data,
        //             parentCategory: item.parent_category,
        //             categoryImage: item.category_image,
        //             name: item.name,
        //             id: item.id
        //         },
        //         siderbar: true,
        //         display: 'block',
        //         dropdownOpen: null
        //     });
        // }else{
        //     this.props.history.push('/old/products/' + item.id);
        // }

    }

    deleteItem(id){
        this.setState({
            ...this.state,
            dropdownOpen: null
        });

        // if(this.state.type == "Category"){
        //     deleteCategory({
        //         id: id
        //     }).then(response => {
    
        //         if(!response.ok) {
        //             this.props.enqueueSnackbar("Category can't be deleted", {variant: 'error'});
        //             return;
        //         }
    
        //         setTimeout(() => {  
        //             this.fetchData();
        //             this.getRootCategories();
        //         }, 1000);
    
        //     });
        // }
       
    }

    openOrderDetails(item){
        this.props.history.push('/order-details/' + item.id);
    }

    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return;
        }

        if(this.state.page > 5){
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.page + 4) < this.state.totalPages)){
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page == i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    onPageClick(i){
        this.state.page = i;
        this.getCustomers(i, this.state.perPage);
    }

    onBack(){
        this.state.page = Number(this.state.page) - 1;
        this.getCustomers(this.state.page, this.state.perPage);
    }

    onForward(){
        this.state.page = Number(this.state.page) + 1;
        this.getCustomers(this.state.page, this.state.perPage);
    }

    deactivateUser(id){
        deactivateUser({
            id: id
        }).then(response => {

            if(!response.ok) {
                return;
            }

            let customers = this.state.customers;

            for(let customer of customers){
                if(customer.id == id){
                    customer.active = !customer.active;
                    break;
                }
            }

            this.setState({
                ...this.state,
                customers: customers
            })
        });
    }

    getPlatforms(customer){
        let platforms = "";

        if(customer.shopify) {
            platforms = this.addNewPlatform(platforms, "Shopify");
        }
        if(customer.etsy){
            platforms = this.addNewPlatform(platforms, "Etsy");
        }
        if(customer.woo_commerce){
            platforms = this.addNewPlatform(platforms, "WooCommerce");
        }
        if(customer.magento){
            platforms = this.addNewPlatform(platforms, "Magento");
        }
        if(customer.amazon){
            platforms = this.addNewPlatform(platforms, "Amazon");
        }
        if(customer.ebay){
            platforms = this.addNewPlatform(platforms, "eBay");
        }
        if(customer.big_commerce){
            platforms = this.addNewPlatform(platforms, "BigCommerce");
        }
        if(customer.wayfair){
            platforms = this.addNewPlatform(platforms, "Wayfair");
        }
        if(customer.wix){
            platforms = this.addNewPlatform(platforms, "Wix");
        }
        if(customer.squarespace){
            platforms = this.addNewPlatform(platforms, "Squarespace");
        }
        if(customer.physical_retail){
            platforms = this.addNewPlatform(platforms, "Physical Retail");
        }
        if(customer.other){
            platforms = this.addNewPlatform(platforms, "Other");
        }

        return platforms;
    }

    addNewPlatform(platforms, platform){
        if(platforms == ""){
            platforms += platform
        }else{
            platforms += "/" + platform
        }

        return platforms
    }

    renderCustomers() {
        let result = [];
        
        for (let item of this.state.customers) {
            result.push(
                <div className="item" key={"item" + item.id}>
                    <div className="user-fist-name">{ item.first_name }</div>
                    <div className="user-last-name">{ item.last_name }</div>
                    <div className="user-phone">{ item.customer.business_information.phone_number }</div>
                    <div className="user-email">{ item.email }</div>
                    <div className="user-country">{ item.customer.store_url_profile }</div>
                    <div className="user-platform">{ this.getPlatforms(item.customer) }</div>
                    <div className="user-company">{ item.customer.company_name }</div>
                    <div className="user-date">{ dateToString(item.date_created) }</div>
                    <div className="user-action">
                        <div className={'table-control'}>
                            <label className="switch">
                                <input checked={item.active} onClick={ () => this.deactivateUser(item.id)} type="checkbox"/>
                                <span className={ item.item ? "slider round active" : "slider round"} />
                            </label>
                        </div>
                    </div>
                    {
                        (this.state.userRole === 'SUPER_ADMIN') &&
                        <div className="user-action">
                            <Button onClick={ () => this.impersonateUser(item)}>Login</Button>
                        </div>
                    }
                    {/* <div className="options">
                        <div className="opt" onClick={ (e) => this.openDropdown(item.id, e) }><img src="/images/tackice.png"></img></div>
                        <div className={ this.state.dropdownOpen == item.id ? "dropdown active" : "dropdown" }>
                            <div className="dropdown-opt" onClick={ () => this.deleteItem(item.id) }>Delete</div>
                        </div>
                    </div> */}
                </div>
            );
        }
        return result;
    }


    render() {

        return (
            <div id="orders">
               <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                <div className={'search'}>
                    <input className={'category-search'}
                        value={this.state.data.searchTerm}
                        onChange={this.changeData}
                        name={'searchTerm'}
                        placeholder={'Type to search...'}
                        onKeyPress={ this.keyPress }
                    />
                    <img src={'/images/search.png'} />
                </div>

                    <div className="list">
                        <div className={'list-helper'}>
                            <div className="header">
                                <div className="user-fist-name">First Name</div>
                                <div className="user-last-name">Last Name</div>
                                <div className="user-phone">Phone</div>
                                <div className="user-email">Email</div>
                                <div className="user-country">Store URL</div>
                                <div className="user-platform">Platform</div>
                                <div className="user-company">Company Name</div>
                                <div className="user-date">Date</div>
                                <div className="user-action">Activated</div>
                                {
                                    (this.state.userRole === 'SUPER_ADMIN') &&
                                    <div className="user-action">Login</div>
                                }
                            </div>
                            { this.renderCustomers() }
                        </div>
                    </div>


                <div className={'pagination'}>
                    {
                        this.state.page > 1 &&
                        <img onClick={this.onBack} src={'/images/leftFilter.png'} />
                    }
                    <div className={'pages'}>
                        {this.renderPagination()}
                    </div>
                    {
                        this.state.page < this.state.totalPages &&
                        <img onClick={this.onForward} src={'/images/rightFilter.png'} />
                    }
                </div>
            </div>
        );
    }

    async impersonateUser(user) {
        try {
            setImpersonatedUser(getUserFromLocalStorage());
            setUserToLocalStorage(user);
            const { data: { image }} = await getCurrentUserAction();

            setUserToLocalStorage({ ...user, image: process.env.REACT_APP_baseUrl + image });
            this.props.updateStorageUser();
            history.push("/home");
        } catch(error) {}
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        updateStorageUser: Actions.loadUser
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Users)));
