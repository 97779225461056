import React from 'react';
import { number, object, string } from 'prop-types';

const IconHome = ({ width, height, color, onClick, style = {} }) => (
  <svg width={width} height={height} style={{ minWidth: width, ...style }} onClick={onClick} viewBox="0 0 22 21" fill="white" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_202_482)">
      <path d="M18.6557 10.5295V20.5859H3.40234V10.5295" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.3759 12.8958H8.68262V20.5859H13.3759V12.8958Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.8292 11.121L11.0292 4.02244L2.22924 11.121L0.469238 8.75483L11.0292 0.473145L21.5892 8.75483L19.8292 11.121Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.33568 4.02245V2.2478H3.40234V6.38865" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_202_482">
        <rect width={width} height={height} fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

IconHome.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
  style: object
}

export default IconHome;