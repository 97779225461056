import React from 'react';

import './style.scss';

import ImageArea from '../ImageArea';
import EditVariationTabs from '../EditVariationTabs';
import ProductVariationShipping from '../tabs/ProductVariationShipping';
import ProductVariationDesignTemplates from '../tabs/ProductVariationDesignTemplates';
import ProductVariationInfo from '../tabs/ProductVariationInfo';
import ProductVariationMediaModifier from '../tabs/ProductVariationMediaModifier';
import ProductVariationPrice from '../tabs/ProductVariationPrice';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { deleteVariationImage, editMultiVariationInfo, uploadProductVariationImage } from 'src/services/admin/ProductVariationService';
import { getBase64 } from 'src/services/ImageService';
import { calculateHeightBasedOnWidthAndAspectRation } from 'src/util/UserUtil';

export const TABS = {
  SHIPPING: 'Shipping',
  DESIGN_TEMPLATES: 'Design Templates',
  INFO: 'Info',
  MEDIA_MODIFIER: 'Media Modifier',
  PRICE: 'Price'
}

const EditVariation = ({ selectedVariations = [], onLoad }) => {
  const [selectedTab, setSelectedTab] = React.useState(TABS.INFO);
  const [fileUploadInProgress, setFileUploadInProgress] = React.useState(false);
  const [deleteImageInProgress, setDeleteImageInProgress] = React.useState(false);

  const [firstSelectedVariation, setFirstSelectedVariation] = React.useState({});
  const [selectedVariationImage, setSelectedVariationImage] = React.useState({});

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    const firstSelectedVariation = selectedVariations.length > 0 ? selectedVariations[0] : {};
    setFirstSelectedVariation(firstSelectedVariation);
    setSelectedVariationImage(firstSelectedVariation?.images?.[0]);
  }, [selectedVariations]);

  const productVariationIds = selectedVariations.length > 0 ? selectedVariations.map(x => x.id) : [];
  const selectedVariationImages = firstSelectedVariation?.images ?? [];
  
  const initialImageWidth = selectedVariationImage?.dropzoneWidth ?? 340/2;
  const initialImageHeight = selectedVariationImage?.dropzoneHeight ?? 340/2;
  const initialImageX = selectedVariationImage?.dropzoneX ?? 0;
  const initialImageY = selectedVariationImage?.dropzoneY ?? 0;

  const [x, setX] = React.useState(initialImageX);
  const [y, setY] = React.useState(initialImageY);

  const [width, setWidth] = React.useState(initialImageWidth);
  const [height, setHeight] = React.useState(initialImageHeight);

  const rectRef = React.useRef();
  const transfRef = React.useRef();

  React.useEffect(() => {
    updateImageDimmensions({
      x: initialImageX,
      y: initialImageY,
      width: initialImageWidth ?? 340,
      height: initialImageHeight ?? 340
    });
  }, [selectedVariationImage]);

  const updateImageDimmensions = ({ x, y, width, height }) => {
    setX(x);
    setY(y);
    setWidth(width);
    setHeight(height);
  }

  const onUpdateVariationInfo = async ({ name, width: originalWidth, height: originalHeight, safeWidth: originalSafeWidth, safeHeight: originalSafeHeight }) => {
    if(originalWidth < originalSafeWidth || originalHeight < originalSafeHeight) {
      openSnackBar('Safe area cannot be bigger than print area!');
      return;
    }

    try {
      const rectScaleX = rectRef.current.attrs.scaleX ?? 1;
      const rectScaleY = rectRef.current.attrs.scaleY ?? 1;
      const calculatedHeight = calculateHeightBasedOnWidthAndAspectRation(
        originalWidth,
        originalHeight,
        rectRef.current.attrs.width * rectScaleX
      ) * (1/rectScaleX);
      const rectWidth = rectRef.current.attrs.width ? (rectRef.current.attrs.width * rectScaleX) : 100;
      // const rectHeight = calculatedHeight ? (calculatedHeight * rectScaleY) : 100;
      const rectHeight = rectRef.current.attrs.height ? (rectRef.current.attrs.height * rectScaleY) : 100;

      const originalSafeWidthRatio = (originalSafeWidth / originalWidth);
      const originalSafeHeightRatio = (originalSafeHeight / originalHeight);

      const safeWidth = originalSafeWidthRatio * rectWidth;
      const safeHeight = originalSafeHeightRatio  * rectHeight;

      const safeX = x + (rectWidth - safeWidth) / 2;
      const safeY = y + (rectHeight - safeHeight) / 2;

      const imageIds = getSelectedVariationsImageIds();

      const data = {
        ids: productVariationIds,
        width: rectWidth,
        height: rectHeight,
        rectWidth: rectWidth,
        rectHeight: rectHeight,
        realWidth: originalWidth,
        realHeight: originalHeight,
        safeWidth: originalSafeWidth,
        safeHeight: originalSafeHeight,
        x: x ?? 0,
        y: y ?? 0,
        safeX: safeX,
        safeY: safeY,
        data: {
          imageIds: imageIds,
          ids: productVariationIds,
          name,
          width: originalWidth, 
          height: originalHeight,
          safeWidth: originalSafeWidth,
          safeHeight: originalSafeHeight,
        }
      }
      await editMultiVariationInfo({ ...data, ids: productVariationIds });
      
      await onLoad();
    } catch(error) {
      openSnackBar('Failed to update image info', false);
      console.error(error);
    }
  }

  const onAddImage = async (files = []) => {
    if(files.length == 0) return;
    const file = files[0];
    
    setFileUploadInProgress(true);
    try {
      const formData = new FormData();
      const base64 = await getBase64(file);

      formData.append('image', file);
      formData.append('base64', base64);
      formData.append('numberOfVariations', selectedVariations.length);
      selectedVariations.forEach((x, index) => formData.append('variationId' + (index + 1), x.id));

      await uploadProductVariationImage(formData);

      await onLoad();
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to upload variation image', false);
    } finally {
      setFileUploadInProgress(false);
    }
  }

  const onDeleteImage = async () => {
    setDeleteImageInProgress(true);

    try {
      const selectedVariationsImages = getSelectedVariationsImageIds();

      selectedVariationsImages.forEach(async id =>  {
        await deleteVariationImage(id);
      });

      await onLoad();
    } catch (error) {
      console.error(error);
      openSnackBar('Failed to delete variation image', false);
    }
    finally {
      setDeleteImageInProgress(false);
    }
  }

  const getSelectedVariationsImageIds = () => {
    const selectedVariationIndex = selectedVariationImages.indexOf(selectedVariationImage);
    return selectedVariations.reduce((acc, x) => {
      const imagesIds = x.images.reduce((acc, image) => x.images.indexOf(image) == selectedVariationIndex ? [...acc, image.imageId] : acc,[]);

      return [...acc, ...imagesIds]
    }, []);
  }

  const getSelectedTabComponent = () => {
    switch(selectedTab) {
      case TABS.SHIPPING:
        return (
          <ProductVariationShipping
            productVariationIds={productVariationIds}
            onLoad={onLoad}
            id={firstSelectedVariation?.id}
          />
        );
      case TABS.DESIGN_TEMPLATES:
        return (
          <ProductVariationDesignTemplates
            productVariationIds={productVariationIds}
            onLoad={onLoad}
            selectedDocument={firstSelectedVariation?.document?.id}
          />
        );
      case TABS.INFO:
        return (
          <ProductVariationInfo
            onUpdateVariationInfo={onUpdateVariationInfo}
            name={selectedVariationImage?.name}
            width={selectedVariationImage?.realDropzoneWidth}
            height={selectedVariationImage?.realDropzoneHeight}
            safeWidth={selectedVariationImage?.safeWidth}
            safeHeight={selectedVariationImage?.safeHeight}
          />
        );        
      case TABS.MEDIA_MODIFIER:
        return (
          <ProductVariationMediaModifier
            productVariationIds={productVariationIds}
            onLoad={onLoad}
            selectedMockups={firstSelectedVariation?.mediaModifierMockups?.map((x) => x.id)}
          />
          );
      case TABS.PRICE:
        return (
          <ProductVariationPrice
            productVariationIds={productVariationIds}
            onLoad={onLoad}
            price={firstSelectedVariation.price}
            vendorPrice={firstSelectedVariation.vendorPrice}
          />
          );
    }
  }

  return (
    <div className='edit-variation-container'>
      <ImageArea
        rectRef={rectRef}
        transfRef={transfRef}
        {...selectedVariationImage}
        image={selectedVariationImage?.base64}
        x={x}
        y={y}
        width={width}
        height={height}
        updateImageDimmensions={updateImageDimmensions}
        selectedImageNumber={selectedVariationImages?.indexOf(selectedVariationImage) + 1}
        totalImageNumber={selectedVariationImages?.length}
        onLeftArrowClick={() => {
          const imagesLength = selectedVariationImages.length;
          if(imagesLength == 1) return;
          const selectedImageIndex = selectedVariationImages?.indexOf(selectedVariationImage);
          setSelectedVariationImage(selectedVariationImages[selectedImageIndex === 0 ? imagesLength - 1 : selectedImageIndex - 1])
        }}
        onRightArrowClick={() => {
          const imagesLength = selectedVariationImages.length;
          if(imagesLength == 1) return;
          const selectedImageIndex = selectedVariationImages?.indexOf(selectedVariationImage);
          setSelectedVariationImage(selectedVariationImages[
            selectedImageIndex + 1 == imagesLength
              ? 0
              : selectedImageIndex + 1 % imagesLength
            ])
        }}
        isAddImageDisabled={selectedVariations.length == 0}
        onAddImage={onAddImage}
        fileUploadInProgress={fileUploadInProgress}
        onDeleteImage={onDeleteImage}
        deleteImageInProgress={deleteImageInProgress}
      />
      <EditVariationTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {getSelectedTabComponent()}

    </div>
  )
}

export default EditVariation;