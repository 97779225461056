
export const mapCategory = category => ({
  image: process.env.REACT_APP_baseUrl + category?.category_image?.url,
  categoryImage: category?.category_image,
  id: category.id,
  name: category.name,
  description: category.description,
  parentCategoryName: category.parent_category?.name,
  parentCategory: category.parent_category?.id,
  price: category.min_price,
  trending: category.in_trending,
  seoTitle: category.seo_title,
  seoDescription: category.seo_description
});

export const mapProduct = product => ({
  image: process.env.REACT_APP_baseUrl + product?.product_image?.url,
  id: product.id,
  name: product.name,
  description: product.description,
  parentCategoryName: product.category?.name,
  parentCategory: product.category?.parent_category?.id,
  price: product.price,
  trending: null,
  seoTitle: product.seo_title,
  seoDescription: product.seo_description
})