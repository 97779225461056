import React from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

import CardSection from './CardSection';

export default function CheckoutForm({submitToken}) {
  const stripe = useStripe();
  const elements = useElements();



  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();


    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);

    const result = await stripe.createToken(card);

    if (result.error) {
      // Show error to your customer.
      submitToken(null);
    } else {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
      //stripeTokenHandler(result.token);
      //console.log(result.token);
      submitToken(result.token);
    }
  };

  return (
    <form onSubmit={handleSubmit} id="form">
      <CardSection/>
      <button disabled={!stripe} style={{display : "none"}} id="submit-card">Confirm order</button>
    </form>
  );
}