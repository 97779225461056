import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import Sidepane from 'src/redesign/components/Sidepane';
import TextInput from 'src/redesign/components/Form/Sidepane/TextInput';
import CheckboxInput from 'src/redesign/components/Form/Checkbox';
import MultiSelectInput from 'src/redesign/components/Form/Sidepane/MultiSelectInput';
import Tabs from 'src/redesign/components/Tabs';
import PrimarySubmitButton from 'src/redesign/components/Buttons/SubmitPrimary';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import IconTrash from 'src/redesign/components/Icon/Trash';
import Loader from 'src/redesign/components/Loader';
import * as Validators from 'src/redesign/util/validators';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import SelectInput from 'src/redesign/components/Form/Sidepane/SelectInput';
import Dropdown from 'src/redesign/components/Dropdown';
import { createAttribute, createAttributeValue, deleteAttribute, deleteAttributeValue, getAttributeValues, getAttributes, updateAttributeValue } from 'src/services/admin/AttributeService';
import CreatableSelect from "src/redesign/components/Form/CreatableSelect";
import Pagination from 'src/redesign/components/Pagination';

import { getMediaModifierMockups } from 'src/services/MediaModifierService';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import AttributeValues from '../AttributeValues';

const TABS = {
  STRING: 'string',
  COLOR: 'color'
}

const CreateAttributeSidepane = ({ onClose, onAdd, isAddAttributeLoading }) => {
  const [selectedTab, setSelectedTab] = React.useState(TABS.STRING);

  const [isAttributesLoading, setIsAttributesLoading] = React.useState(false);
  const [isAttributeDeleting, setIsAttributeDeleting] = React.useState(false);
  const [attributesOptions, setAttributesOptions] = React.useState([]);
  const [selectedAttribute, setSelectedAttribute] = React.useState(null);
  
  const [isAttributeValuesLoading, setIsAttributesValuesLoading] = React.useState(false);
  const [attributeValuesOptions, setAttributeValuesOptions] = React.useState([]);
  const [attributeValuesCurrentPage, setAttributeValuesCurrentPage] = React.useState(1);
  const [attributeValuesTotalPages, setAttributeValuesTotalPages] = React.useState(1);

  const selectedAttributeType = selectedTab == TABS.STRING ? 0 : 1;
  
  const { openSnackBar } = useSnackbar();

  // #region load

  React.useEffect(() => {
    load();
  }, [selectedAttributeType]);

  const load = async () => {
    setIsAttributesLoading(true);
    try {
      const { data } = await getAttributes(selectedAttributeType);

      const attributeOptions = data.map(x => ({ value: x.id, label: x.name, type: x.attribute_type }));
      setAttributesOptions(attributeOptions);
      setSelectedAttribute(null);
      setAttributeValuesOptions([]);
      setAttributeValuesTotalPages(1);
      setAttributeValuesCurrentPage(1);
    } catch(e) {
      console.error(e);
      openSnackBar('Failed to load attributes', false);
    } finally {
      setIsAttributesLoading(false);
    }
  }
  
  const loadAttributeValues = async (attribute, page) => {
    setIsAttributesValuesLoading(true);
    try {
      const { data } = await getAttributeValues(attribute.value, page);

      setSelectedAttribute(attribute);
      setAttributeValuesOptions(data.result.map(x => ({ name: x.name, id: x.id, customer_color_name: x.customer_color_name })));
      setAttributeValuesCurrentPage(page);
      setAttributeValuesTotalPages(Math.ceil(data.total/data.perPage));
    } catch(e) {
      console.error(e);
      openSnackBar('Failed to load attribute values', false);
    } finally {
      setIsAttributesValuesLoading(false);
    }
  }

  // #endregion load

  // #region save

  const onCreateSelectAttributeOption = async attribute => {
    if(attribute.__isNew__) {
      try {
        const { data } = await createAttribute({ name: attribute.value, type: selectedAttributeType, attributeValues: [] });

        loadAttributeValues({ value: data.id, label: data.name, type: selectedAttributeType }, 1);
      } catch(e) {
        console.error(e);
        openSnackBar('Failed to create attribute value', false);
      }
    } else {
      loadAttributeValues(attribute, 1);
    }
  }

  const onDeleteAttribute = async () => {
    setIsAttributeDeleting(true);
    try {
      const idToDelete = selectedAttribute.value;
      await deleteAttribute(idToDelete);
      
      setAttributesOptions(prev => prev.filter(x => x.value != idToDelete));
      setSelectedAttribute(null);
    } catch(e) {
      console.error(error);
      openSnackBar('Failed to delete attribute', false);
    } finally {
      setIsAttributeDeleting(false);
    }
  }

  const onAttributeValueChange = async attributeValue => {
    setIsAttributesLoading(true);
    try {
      const attributeId = selectedAttribute.value;
      await updateAttributeValue({ attributeId, id: attributeValue.id, name: attributeValue.name, customer_color_name: attributeValue.customer_color_name });

      setAttributeValuesOptions(prev => prev.map(x => x.id == attributeValue.id ? attributeValue : x));
    } catch(e) {
      console.error(e);
      openSnackBar('Failed to update attribute value', false);
    } finally {
      setIsAttributesLoading(false);
    }
  }

  const onAttributeValueAdd = async attributeValue => {
    setIsAttributesLoading(true);
    try {
      const attributeId = selectedAttribute.value;
      const { data } = await createAttributeValue({ id: attributeId });
      const newAttributeValueId = data.id;

      await updateAttributeValue({
        attributeId,
        id: newAttributeValueId,
        name: attributeValue.name,
        customer_color_name: attributeValue.customer_color_name,
        type: selectedAttributeType
      });

      setAttributeValuesOptions(prev => [...prev, { name: attributeValue.name, customer_color_name: attributeValue.customer_color_name, id: newAttributeValueId }]);
    } catch(e) {
      console.error(e);
      openSnackBar('Failed to add attribute value', false);
    } finally {
      setIsAttributesLoading(false);
    }
  }

  const onAttributeValueDelete = async attributeValue => {
    setIsAttributesLoading(true);
    try {
      const attributeValueId = attributeValue.id;
      const response = await deleteAttributeValue(attributeValueId);

      if(response.ok) {
        setAttributeValuesOptions(prev => prev.filter(x => x.id != attributeValueId));
      } else {
        openSnackBar('Attribute value already assigned to product', false);
      }
    } catch(e) {
      console.error(e);
      openSnackBar('Failed to delete attribute value', false);
    } finally {
      setIsAttributesLoading(false);
    }
  }

  // #endregion save

  return (
    <Sidepane
      title='Add new attribute'
      style={{
        width: 500
      }}
      className='create-attribute-sidepane'
      content={
        <div className='create-attribute-fields'>
          <Tabs
            first={{
              text: 'String',
              isSelected: selectedTab === TABS.STRING,
              onClick: () => setSelectedTab(TABS.STRING)
            }}
            second={{
              text: 'Color',
              isSelected: selectedTab === TABS.COLOR,
              onClick: () => setSelectedTab(TABS.COLOR)
            }}
            isLarge={false}
          />
          {isAttributesLoading ? (
          <Loader
              width={100}
              height={100}
              color={colors.primary}
          /> 
          )
          :
            <>
              <CreatableSelect
                options={attributesOptions}
                value={selectedAttribute}
                placeholder='Name'
                className='creatable-select-style'
                onChange={onCreateSelectAttributeOption}
              />
              {isAttributeValuesLoading ? (
                <Loader
                  width={50}
                  height={50}
                  color={colors.primary}
                />
              ) : (
                selectedAttribute != null ?
                <>
                  <AttributeValues
                    attributes={attributeValuesOptions}
                    onAttributeChange={onAttributeValueChange}
                    onAttributeAdd={onAttributeValueAdd}
                    onAttributeDelete={onAttributeValueDelete}
                    isColor={selectedTab == TABS.COLOR}
                  />
                  <Pagination
                    page={attributeValuesCurrentPage}
                    setPage={page => loadAttributeValues(selectedAttribute, page)}
                    totalPages={attributeValuesTotalPages}
                  />
                </> : null
              )}
            </>}
        </div>
      }
      actions={
        <div className='create-attribute-actions'>
          <SecondaryButton
            text={
              <>
                Delete  
                <IconTrash
                  width={24}
                  height={24}
                  color={colors.red}
                />
              </>
            }
            className='delete-attribute-button'
            onClick={onDeleteAttribute}
            loading={isAttributeDeleting}
            disabled={selectedAttribute == null || isAttributeDeleting}
            width={100}
          />
          <PrimarySubmitButton
            text='Add'
            onClick={(e) => {
              e.preventDefault();
              if(selectedAttribute == null) {
                openSnackBar('Please select attribute', false);
                return;
              }
              onAdd(attributeValuesOptions, selectedAttributeType, selectedAttribute.value);
            }}
            width={108}
            loading={isAddAttributeLoading}
            className='add-attribute-button'
          />
        </div>
      }
      onClose={onClose}
    />
  )
}

export default CreateAttributeSidepane;