import React from 'react';

import Page from 'src/redesign/components/Page';
import Stepper from 'src/redesign/components/Stepper';
import SignupLogin from 'src/redesign/components/SignupLogin';
import Image from 'src/redesign/components/Image';

import './style.scss';

const IntegrationEtsyPage = () => (
  <Page
    seoTitle='Print on Demand Etsy Integration | Surge Print'
  >
    <div className='integration-etsy-container'>
      <div className='integration-etsy-content'>
        <Image
          alt='integration-etsy-image'
          src='/images/integration-etsy.png'
          className='integration-etsy-image'
        />
        <h1 className='integration-etsy-title'>
          SurgePrint Integrates with Etsy for Seamless Print on Demand Fulfillment
        </h1>
        <div className='integration-etsy-description'>
          As a print on demand company, SurgePrint is dedicated to helping artists, designers, and entrepreneurs create and sell their own custom products without having to worry about production, inventory, or shipping. That's why we're excited to announce our integration with Etsy, one of the most popular online marketplaces for handmade and unique items.
          <br/>
          <br/>
          With this integration, our users can easily export their products from SurgePrint to their Etsy shop, or import their existing Etsy products to SurgePrint and automate the fulfillment process. Once a product has been exported to Etsy or imported from Etsy to SurgePrint, we take care of the printing, packaging, and shipping, so our users can focus on creating and promoting their products.
        </div>
        <div className='integration-etsy-subtitle'>
          Here are some of the benefits of using SurgePrint with Etsy:
        </div>
        <div className='integration-etsy-stepper-container'>
          <Stepper
            steps={[
              'Seamless Integration: Our direct integration with Etsy makes it easy for users to manage their print on demand products from within their Etsy shop. No more manual data entry or complicated CSV files - everything can be done with just a few clicks.',
              'Automated Fulfillment: Once an order is placed on Etsy, SurgePrint automatically receives the order details and begins the production process. We print the product, package it, and ship it directly to the customer, all without any intervention from the user.',
              'Tracking and Notifications: Our system automatically updates Etsy with the shipping details and tracking information, so the user and the customer can keep track of the order status and delivery.',
              'Quality Products: SurgePrint uses only the highest quality materials and printing methods, ensuring that every product looks great and lasts a long time.',
              'Competitive Pricing: Our prices are some of the best in the industry, and we offer discounts for bulk orders or enterprise clients. We also have a warehouse where users can store garments and other products, and we can print or decorate on them once an order is received.',
              `Dedicated Support: Our support team is available 24/7 to answer any questions or resolve any issues that users may have. We're committed to providing the best customer service in the industry.`
            ]}
          />
        </div>
        <div className='integration-etsy-description'>
          In conclusion, SurgePrint's integration with Etsy makes it easy for artists, designers, and entrepreneurs to create and sell their own custom products without any hassle. With our seamless integration, automated fulfillment, and quality products, users can focus on their creativity and leave the printing and shipping to us. Try SurgePrint today and see how easy it is to turn your ideas into reality!
        </div>
        <div className='integration-etsy-signup-container'>
          <SignupLogin />
        </div>
      </div>
    </div>
  </Page>
);

export default IntegrationEtsyPage;