import React from 'react';
import Page from 'src/redesign/components/Page';
import Breadscrumb from 'src/redesign/components/Breadcrumb';
import Searchbox from 'src/redesign/components/Searchbox';

import './style.scss';
import AllCategories from '../ProductCatalogInApp/components/AllCategories';

const ProductCatalogPage = () => {
  const [searchboxText, setSearchboxText] = React.useState('');

  return <Page
    seoTitle='Catalog of Products for Custom Printing | Surge Print'
    seoDescription={`Catalog of Products for Custom Printing. SurgePrint offers premium printing solutions for vibrant, impactful results. Explore our services now!`}
  >
    <div className="product-catalog">
      <Breadscrumb links={[{ to: '/product-catalog-website', text: 'Catalog' }]} />
      {/* <Searchbox
        placeholder='Type to search'
        className='product-catalog-all-search-box'
        inputClassName='product-catalog-all-search-input'
        onChange={setSearchboxText}
      /> */}
      <AllCategories searchboxText={searchboxText} className='product-catalog-all-container' isInApp={false}/>
    </div>
  </Page>
}

export default ProductCatalogPage;
