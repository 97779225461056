import React from 'react';
import ReactDOM from 'react-dom';
import IconExit from 'src/redesign/components/Icon/Exit';
import YoutubeVideo from 'src/redesign/components/YoutubeVideo';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const HowItWorksDialog = ({ onClose }) => (
  ReactDOM.createPortal(
    <div className='fullscreen-how-it-works'>
      <div className='fullscreen-how-it-works-dialog-content'>
        <div className='fullscreen-how-it-works-close' onClick={onClose}>
          <IconExit width={13.5} height={13.5} color={colors.primary}/>
        </div>

        <div className='fullscreen-how-it-works-dialog-container'>
            <div className="fullscreen-how-it-works-dialog-title">
              How it works?
            </div>
            <YoutubeVideo
              width={1083}
              height={600}
              embedId="zgIRE8N6zDE"
            />
          </div>
      </div>
    </div>
   , document.getElementById('dialog-root'))
);

export default HowItWorksDialog;