import React from 'react';
import { number, string } from 'prop-types';

const IconVerticalAlignment = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 10L0 10" stroke={color} strokeOpacity="0.7"/>
    <rect x="15.5" y="15" width="5" height="10" transform="rotate(-180 15.5 15)" fill="white"/>
    <rect x="15" y="14.5" width="4" height="9" transform="rotate(-180 15 14.5)" stroke={color} strokeOpacity="0.7"/>
    <rect x="8.5" y="17" width="6" height="14" transform="rotate(-180 8.5 17)" fill="white"/>
    <rect x="8" y="16.5" width="5" height="13" transform="rotate(-180 8 16.5)" stroke={color} strokeOpacity="0.7"/>
  </svg>
);
IconVerticalAlignment.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconVerticalAlignment;