import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { withRouter } from 'react-router';
import * as Validators from 'src/redesign/util/validators';
import TextInput from 'src/redesign/components/Form/TextInput';
import { LogoCreateAccount } from 'src/redesign/components/Logo';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import PrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const SignupLogin = ({ history, withImage = true }) => {

  return (
  <div className='create-account-container'>
    <div className='create-an-account'>
      <FinalForm
        onSubmit={v => console.log('submit', v)}
        render={({ handleSubmit, invalid, submitting }) => (
          <form className='create-an-account-content' onSubmit={handleSubmit}>
            <div className='create-an-account-logo'>
              <LogoCreateAccount />
            </div>
            <h3 className='create-an-account-title'>
              Create account for
            </h3>
            <div className='create-an-account-free'>
              FREE
            </div>
            <TextInput containerClassName='create-an-account-input-container' className='create-an-account-input' name='email' placeholder='Email' validate={Validators.composeValidators(Validators.required(' '), Validators.emailFormatValid(' '))} />
            <TextInput containerClassName='create-an-account-input-container' className='create-an-account-input' type='password' name='password' placeholder='Password' validate={Validators.required(' ')} />
            <div className='create-an-account-submit-container'>
              <PrimaryButton text='Sign up' onClick={() => history.push('/registration')} className='create-an-account-button' isResponsive/>
              <SecondaryButton text='Log in' onClick={() => history.push('/login')} isResponsive className='create-an-account-button'/>
            </div>
          </form>
          )
        }
      />

    </div>
    {withImage && (
      <Image alt={getImageAlt()} className='create-account-image' src='/images/landing-image.png' />
    )}
  </div>
  )
}

export default withRouter(SignupLogin);