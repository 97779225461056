import { OrderEnum } from "../../constants";

export function copyToClipboard(text) {
  var dummy = document.createElement("textarea");
  // to avoid breaking orgain page when copying more words
  // cant copy when adding below this code
  // dummy.style.display = 'none'
  document.body.appendChild(dummy);
  //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export const getAvailableStatuses = (status, isAdmin) => {
    if(isAdmin)
        return [
                {
                    key: 3,
                    text: 'Needs Manual Approval'
                },
                {
                    key: 4,
                    text: 'Pending'
                },
                {
                    key: 5,
                    text: 'Test'
                },
                {
                    key: 6,
                    text: 'Hold'
                },
                {
                    key: 8,
                    text: 'Ready For Accounting'
                },
                {
                    key: 30,
                    text: "Garments Received"
                },
                {
                    key: 31,
                    text: "Partially Received"
                },
                {
                    key: 9,
                    text: 'Ready For Print'
                },
                {
                    key: 10,
                    text: 'In Production'
                },
                {
                    key: 11,
                    text: 'Manually In Production'
                },
                {
                    key: 12,
                    text: 'Printing'
                },
                {
                    key: 13,
                    text: 'Cancel Refunded'
                },
                {
                    key: 14,
                    text: 'Billed Refunded'
                },
                {
                    key: 15,
                    text: 'Shipped'
                },
                {
                    key: 16,
                    text: 'Out For Delivery'
                },
                {
                    key: 17,
                    text: 'Delivered'
                },
                {
                    key: 7,
                    text: 'Canceled'
                },
                {
                    key: 18,
                    text: 'Pre Payment'
                },
                {
                    key: 19,
                    text: 'Payment Issue'
                },
                {
                    key: 20,
                    text: 'Order Status'
                },
                {
                    key: 21,
                    text: 'Image Issue'
                },
                {
                    key: 22,
                    text: 'Hold By Image Issue'
                },
                {
                    key: 23,
                    text: 'Address Issue'
                },
                {
                    key: 24,
                    text: 'Order Data Issue'
                },
                {
                    key: 25,
                    text: 'Vendor API Issue'
                },
                {
                    key: 26,
                    text: 'Shipping Issue'
                },
                {
                    key: 27,
                    text: 'Unfinished'
                },
                {
                    key: 28,
                    text: 'Draft'
                },
                {
                    key: 29,
                    text: "Payment Failed"
                }
            ];

    if (status === OrderEnum.NeedsCustomization) {
            return [
                {
                    key: 7,
                    text: 'Canceled'
                },
                {
                    key: 9,
                    text: "Ready For Print"
                }
            ]
        }
}

export const getIsStatusDisabled = (status, isAdmin) => {
    if(status === OrderEnum.NeedsCustomization || isAdmin)
        return false;
    return true;
}