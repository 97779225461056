import React from 'react';
import { node, string } from 'prop-types';

import './style.scss';

const FormSection = ({ title, children }) => {

  return (
    <div className='form-section'>
      <div className="title">{title}</div>
      <div className="children">{children}</div>
    </div>
  )
};

FormSection.propTypes = {
  title: string,
  children: node
};

export default FormSection;