import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconCrop from "src/redesign/components/Icon/Crop";
import IconRotate from "src/redesign/components/Icon/Rotate";
import IconTopAlignment from "src/redesign/components/Icon/TopAlignment";
import IconHorizontalAlignment from 'src/redesign/components/Icon/HorizontalAlignment';
import IconVerticalAlignment from 'src/redesign/components/Icon/VerticalAlignment';
import IconBottomAlignment from 'src/redesign/components/Icon/BottomAlignment';
import IconRightAlignment from 'src/redesign/components/Icon/RightAlignment';
import IconLeftAlignment from 'src/redesign/components/Icon/LeftAlignment';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const EditorControls = ({ onCrop, onRotate, onTopAlign, onBottomAlign, onRightAlign, onLeftAlign, onVerticalAlign, onHorizontalAlign }) => {
  const iconProps = {
    width: 20,
    height: 20,
    color: colors.blackDark
  }

  const controlItem = (tooltip, onClick, Icon) => (
    <Tooltip title={tooltip}>
      <div className='editor-item' onClick={onClick}>
        <Icon {...iconProps} />
      </div>
    </Tooltip>
  )

  return (
    <div className='editor-controls'>
        <div className='top-controls'>
          <div className='top-controls-section'>
            {/* TODO: uncomment later when working on crop refinement */}
            {/* {controlItem('Crop', onCrop, IconCrop)} */}
            {controlItem('Rotate', onRotate, IconRotate)}
          </div>
          <div className='top-controls-section'>
            {controlItem('Top alignment', onTopAlign, IconTopAlignment)}
            {controlItem('Bottom alignment', onBottomAlign, IconBottomAlignment)}
            {controlItem('Right alignment', onRightAlign, IconRightAlignment)}
            {controlItem('Left alignment', onLeftAlign, IconLeftAlignment)}
            {controlItem('Vertical alignment', onVerticalAlign, IconVerticalAlignment)}
            {controlItem('Horizontal alignment', onHorizontalAlign, IconHorizontalAlignment)}
          </div>
        </div>
      </div>
  )
}

export default EditorControls;