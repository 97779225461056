import React from 'react';
import { number } from 'prop-types';

const IconVisa = ({ width, height }) => (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 24">
    <g fill="none" fillRule="evenodd">
        <rect width={width} height={height} fill="#FFF" rx="4"/>
        <g fillRule="nonzero">
            <path fill="#004E8C" d="M2 6.813h31.934V2H2v4.813"/>
            <path fill="#FFA336" d="M2 21.986h31.934v-4.814H2v4.814"/>
            <g fill="#004E8C">
                <path d="M13.184 8.203h1.638l-4.445 7.468H8.444l-.175-5.909c-.008-.268-.015-.546-.113-.76a.961.961 0 0 0-.455-.47 1.548 1.548 0 0 0-.71-.16v-.17h3.189l.156 5.085 2.848-5.084M15.138 15.671l2.283-7.468H15.39l-2.283 7.468h2.031M16.318 15.039c.308.323.706.549 1.249.66.542.11 1.23.108 1.882-.008.653-.115 1.27-.342 1.705-.686.436-.343.691-.804.815-1.303.124-.499.118-1.035-.144-1.454-.262-.42-.778-.72-1.208-1.004-.43-.284-.773-.55-.916-.85a.822.822 0 0 1 .083-.848c.167-.214.442-.309.781-.33.34-.023.742.028 1.076.125.333.096.599.24.826.432l.677-1.118c-.314-.233-.672-.398-1.176-.52a5.892 5.892 0 0 0-1.723-.156 2.932 2.932 0 0 0-1.474.5 2.98 2.98 0 0 0-1.007 1.164c-.209.451-.254.936-.126 1.355.127.418.429.772.74 1.025.31.253.63.406.912.582.282.175.525.373.655.61s.149.513-.008.743c-.157.23-.49.412-.873.477-.383.064-.817.012-1.22-.115a3.292 3.292 0 0 1-1.1-.592l-.426 1.31M23.921 13.943l-.92 1.728h-1.637l4.357-7.468h1.846l.623 7.468h-1.923l-.135-1.728h-2.211m1.928-3.626l-1.411 2.654h1.618l-.207-2.654"/>
            </g>
        </g>
        <rect width={width} height={height} rx="4"/>
    </g>
</svg>

);

IconVisa.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
}

export default IconVisa;