import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { v4 as uuid} from "uuid";
import ProductItem from 'src/redesign/components/ProductItem';

import './style.scss';

const ProductList = ({ products }) => {
  return (
    <div className="product-list">
      {products.map(x => <ProductItem key={uuid()} {...x} />)}
    </div>
  )
};

ProductList.propTypes = {
  products: arrayOf(shape({
    name: string.isRequired,
    price: string.isRequired,
    url: string.isRequired
  }))
}

export default ProductList;