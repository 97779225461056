import React from 'react';
import classNames from 'classnames';
import { bool, func, shape, string } from 'prop-types';

import './style.scss';

const Tabs = ({ first, second, isLarge = true }) => (
    <div className='details-tabs-container'>
    <div className={classNames(
      'details-tab', 'details-tab-first', {
        'details-tab-large': isLarge,
        'details-tab-selected': first.isSelected
      }
    )} onClick={first.onClick}>
      {first.text}
    </div>
    <div className={classNames(
      'details-tab', 'details-tab-second', {
        'details-tab-large': isLarge,  
        'details-tab-selected': second.isSelected
      }
    )} onClick={second.onClick}>
      {second.text}
    </div>
  </div>
)

Tabs.propTypes = {
  first: shape({
    text: string.isRequired,
    isSelected: bool.isRequired,
    onClick: func.isRequired
  }),
  second: shape({
    text: string.isRequired,
    isSelected: bool.isRequired,
    onClick: func.isRequired
  }),
  isLarge: bool
}

export default Tabs;