import React from 'react';
import classNames from 'classnames';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { withRouter } from 'react-router';
import Loader from 'src/components/Loader';

import '../style.scss';

const LinkNavigation = ({ history, match: { url }, loading, tabs }) => {
  const selectedTab = tabs.find(x => x.to === url);
  const selectedTabUrl = selectedTab?.to;

  return (
    <>
      <div className="navigation-tabs">
        <div className="bottom-line"/>
          {tabs.map(({ text, to }, i) => (
            <div key={to} onClick={() => history.push(to)} className={classNames("navigation-tab", {'navigation-tab-selected': selectedTabUrl === to })}>
              {text}
              <div className={classNames("bottom-line", {'bottom-line-selected': selectedTabUrl === to })}/>
            </div>
          ))}
      </div>
      {loading 
        ? <Loader width={200} height={200} />
        : selectedTab?.onRender?.()
      }
    </>
  )

}

LinkNavigation.propTypes = {
  tabs: arrayOf(shape({
    text: string,
    to: string,
    onRender: func
  })),
  loading: bool
}

export default withRouter(LinkNavigation);