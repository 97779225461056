import React from 'react';
import strings from '../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../functions/Validation";
import { NavLink } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';



const LoginForm = ({
    onSubmit,
    onChange,
    errors,
    data,
    keyPress
}) => (
    <form id="login-form" onSubmit={ onSubmit } action = "#">
        <TextField
            className={"text-field"}
            label={ strings.login.email }
            error={ hasError(errors, 'email') }
            helperText={ getError(errors, 'email') }
            fullWidth
            autoFocus
            name='email'
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            value={ data.email }
            placeholder = { strings.login.email }
            variant="outlined"
        />

        <TextField
            label={ strings.login.password }
            error={ hasError(errors, 'password') }
            helperText={ getError(errors, 'password') }
            fullWidth
            name='password'
            type='password'
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            value={ data.password }
            placeholder = { strings.login.password }
            variant="outlined"
        />

        <NavLink className='forgot-password' to='/reset-password-request'>{ strings.login.forgotPassword }</NavLink>

        <div className='submit-container'>
            <Button className={'reg-button'} onClick={ () => this.goToSignUp() }>{ strings.login.registrationButton }</Button>
            <Button variant="contained" color="primary" onClick={ onSubmit }>
                { strings.login.login }
            </Button>
        </div>
    </form>
);

export default LoginForm;