import React from 'react';
import { func, node, string } from 'prop-types';
import classNames from 'classnames';

import IconExit from 'src/redesign/components/Icon/Exit';

import './style.scss';
import colors from '../../../scss/_colors.scss';

const Sidepane = ({ title, content, actions, className, style = {}, onClose }) => {

  // React.useEffect(() => {
  //   const clickListener = document.addEventListener('click', (event) => {
  //     if(event.target.className != 'sidepane') {
  //       console.log('outside');
  //     }
  //   });

  //   () => document.removeEventListener(clickListener);
  // }, []);

  return (
    <div className={classNames('sidepane', className)} style={{ ...style }}>
      <div className='sidepane-container'>
        <div className='sidepane-close' onClick={onClose}>
          <IconExit width={12} height={12} color={colors.primary} />
        </div>
        <div className='sidepane-title'>
          {title}
        </div>
        <div className='sidepane-content'>
          {content}
        </div>
        <div className='sidepane-actions'>
          {actions}
        </div>
      </div>
    </div> 
  )
}

Sidepane.propTypes = {
  title: string.isRequired,
  content: node.isRequired,
  actions: node.isRequired,
  onClose: func.isRequired
}

export default Sidepane;
