import React from 'react';
import { number, string } from 'prop-types';

const IconEmail = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.875 0H2.625C1.92881 0 1.26113 0.276562 0.768845 0.768845C0.276562 1.26113 0 1.92881 0 2.625V11.375C0 12.0712 0.276562 12.7389 0.768845 13.2312C1.26113 13.7234 1.92881 14 2.625 14H14.875C15.5712 14 16.2389 13.7234 16.7312 13.2312C17.2234 12.7389 17.5 12.0712 17.5 11.375V2.625C17.5 1.92881 17.2234 1.26113 16.7312 0.768845C16.2389 0.276562 15.5712 0 14.875 0ZM15.75 11.375C15.75 11.6071 15.6578 11.8296 15.4937 11.9937C15.3296 12.1578 15.1071 12.25 14.875 12.25H2.625C2.39294 12.25 2.17038 12.1578 2.00628 11.9937C1.84219 11.8296 1.75 11.6071 1.75 11.375V2.625C1.75 2.39294 1.84219 2.17038 2.00628 2.00628C2.17038 1.84219 2.39294 1.75 2.625 1.75H14.875C15.1071 1.75 15.3296 1.84219 15.4937 2.00628C15.6578 2.17038 15.75 2.39294 15.75 2.625V11.375ZM13.8075 4.7075C13.9506 4.88883 14.0161 5.11937 13.9899 5.34885C13.9637 5.57833 13.8478 5.78813 13.6675 5.9325L10.3863 8.5575C9.92138 8.92807 9.34449 9.12987 8.75 9.12987C8.15551 9.12987 7.57862 8.92807 7.11375 8.5575L3.8325 5.9325C3.67309 5.78203 3.57597 5.5772 3.56035 5.35855C3.54473 5.13989 3.61175 4.92334 3.74815 4.75174C3.88455 4.58013 4.0804 4.46598 4.29694 4.43186C4.51348 4.39774 4.73494 4.44615 4.9175 4.5675L8.2075 7.1925C8.36198 7.31457 8.55311 7.38097 8.75 7.38097C8.94689 7.38097 9.13802 7.31457 9.2925 7.1925L12.5825 4.5675C12.7638 4.42443 12.9944 4.35886 13.2238 4.38508C13.4533 4.41131 13.6631 4.52721 13.8075 4.7075Z" fill={color}/>
  </svg>
);

IconEmail.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconEmail;