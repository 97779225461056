import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import Sidepane from 'src/redesign/components/Sidepane';
import TextInput from 'src/redesign/components/Form/Sidepane/TextInput';
import SelectInput from 'src/redesign/components/Form/Sidepane/SelectInput';
import PrimarySubmitButton from 'src/redesign/components/Buttons/SubmitPrimary';
import * as Validators from 'src/redesign/util/validators';

import './style.scss';

export const DOCUMENT_TYPES = [{ key: 'DESIGN_TEMPLATE', text: 'DESIGN TEMPLATE' }]

const CreateEditDocumentSidepane = ({
  title,
  onClose,
  onSubmit,
  initialValues,
  saving
}) => {

  return (
    <Sidepane
      title={title}
      onClose={onClose}
      content={
        <FinalForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form id="addEditDocument" className='document-form' onSubmit={handleSubmit}>
              <TextInput
                name='name'
                placeholder='Name'
                validate={Validators.required(' ')}
              />
              <SelectInput
                name='document_type'
                placeholder='Select...'
                options={DOCUMENT_TYPES}
                validate={Validators.required(' ')}
              />
              <div className='upload-document-container'>
                <div className='upload-document-label'>
                  Upload document
                </div>
                <input
                  type='file'
                  id='documentPicker'
                  name='document'
                />
              </div>
            </form>
          )}
        />
      }
      actions={
        <PrimarySubmitButton
          text='Save'
          onClick={() =>
            document.getElementById('addEditDocument')
            .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
          }
          width={160}
          height={40}
          disabled={saving}
          loading={saving}
        />
      }
    />
  )
}

export default CreateEditDocumentSidepane;