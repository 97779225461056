import React from 'react';
import { number, string } from 'prop-types';

const IconBottomAlignment = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 17L0.5 17" stroke={color} strokeOpacity="0.7"/>
    <rect x="15.5" y="13.5" width="4" height="7" transform="rotate(-180 15.5 13.5)" stroke={color} strokeOpacity="0.7"/>
    <rect x="8.5" y="13.5" width="4" height="11" transform="rotate(-180 8.5 13.5)" stroke={color} strokeOpacity="0.7"/>
  </svg>
);

IconBottomAlignment.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconBottomAlignment;