const OrderSource = [
    {
        value: 0,
        name: 'Manual'
    },
    {
        value: 1,
        name: 'Shopify'
    },
    {
        value: 2,
        name: 'Etsy'
    }
];

export function getOrderSourceString(type) {

    switch(type) {
        case 0: return "Manual";
        case 1: return "Shopify";
        case 2: return "Etsy";
    }
}

export default OrderSource;