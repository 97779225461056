import React from 'react';
import { oneOf, shape } from 'prop-types';
import { ButtonProps } from 'src/redesign/components/Buttons/types';
import PrimaryButton from 'src/redesign/components/Buttons/Primary';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';

import './style.scss';

const CustomButtom = ({ type, icon, buttonProps }) => {
  const content = (
    <div className="custom-button">
      {icon}
      {buttonProps.text}
    </div>
  );
  return (
    type === 'primary' 
    ? <PrimaryButton {...buttonProps} text={content}/>
    : <SecondaryButton {...buttonProps} text={content}/>
  )
}

CustomButtom.propTypes = {
  type: oneOf(['primary', 'secondary']),
  buttonProps: shape(ButtonProps)
};

export default CustomButtom;