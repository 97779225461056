import React from 'react';
import classNames from 'classnames';
import Checkbox from 'src/redesign/components/Checkbox';

import './style.scss';

const StandardAttribute = ({ className, name, selected, onClick, disabled }) => {
  return (
    <div className={classNames('attribute-value-standard', {'attribute-value-standard-disabled': disabled}, className)} onClick={onClick}>
      <Checkbox checked={selected} onChange={onClick} disabled={disabled}/>
      <div className='attribute-color-text'>
        {name}
      </div>
    </div>
  )
}

export default StandardAttribute;