import React from 'react';
import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import { debounce } from 'lodash';
import IconSearch from 'src/redesign/components/Icon/Search';

import colors from 'src/scss/_colors.scss';
import './style.scss';

const Searchbox = ({ placeholder, onChange, className, inputClassName, disabled = false, loadDeps = [] }) => {
  const [searchboxText, setSearchboxText] = React.useState('');

  const load = React.useCallback(
    debounce((text) => onChange(text), 500)
    ,[...loadDeps]
  );

  return (
    <div className={classNames("search-box", className)}>
      <input className={classNames("input", inputClassName)} disabled={disabled} value={searchboxText} placeholder={placeholder} onChange={e => {
        const text = e.target.value;
        setSearchboxText(text);
        load(text);
      }}/>
        <div className={classNames("icon", {'icon-disabled': disabled })}>
          <IconSearch width={28} height={28} color={colors.primary}/>
        </div>
    </div>
  )
};

Searchbox.protoTypes = {
  placeholder: string,
  onChange: func,
  className: string,
  inputClassName: string,
  debounced: bool
}

export default Searchbox;