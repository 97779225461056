import React from 'react';
import classNames from 'classnames';
import { oneOfType, string, node, func, bool, number } from 'prop-types';
import Loader from 'src/redesign/components/Loader';

import './style.scss';

const SubmitButton = ({ text, onClick, disabled, className, loading, width, style = {} }) => {
  return <button className={classNames("submit-button", className, { 'submit-button-disabled': disabled })} style={{ width, ...style }} disabled={disabled} onClick={onClick}>
    {loading ? <Loader width={20} height={20} color='white'/> : text}
  </button>
};

SubmitButton.propTypes = {
  text: oneOfType([string, node]),
  onClick: func,
  className: string,
  disabled: bool,
  width: number
}

export default SubmitButton;