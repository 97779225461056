import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import {
    disconnectEtsy,
    disconnectShopify,
    getCurrentUserAction,
} from "../services/UserService"
import {setUserToLocalStorage} from "../base/OAuth";
import BlueLoader from "../components/BlueLoader";

class SalesChannels extends Page {

    constructor(props) {
        super(props);

        this.state = {
            loader: true,
            stores: {
                shopify: false,
                etsy: false
            }
        }

        this.getUserData = this.getUserData.bind(this);
        this.disconnectShopify = this.disconnectShopify.bind(this);
        this.disconnectEtsy = this.disconnectEtsy.bind(this);
    }

    componentDidMount(){
        this.getUserData();
    }

    getUserData(){
        getCurrentUserAction().then(response => {
            if (!response.ok){
                return
            }

            setUserToLocalStorage(response.data.user);

            const etsy = response.data.user.customer.user_customer_etsy;

            this.setState({
                stores: {
                    shopify: response.data.user.customer.shopify_access_token != null,
                    etsy: response.data.user.customer.user_customer_etsy?.store_name != null
                },
                storeUrl: response.data.user.customer.store_url,
                etsyStoreName: etsy ? etsy.store_name : null,
                data: {
                    storeUrl : response.data.user.customer.store_url
                },
                loader: false
            })
        })
    }

    disconnectShopify(){
        disconnectShopify().then(response => {
            if (!response.ok){
                return
            }

            this.getUserData();
            this.props.enqueueSnackbar("Shopify Store disconnected", {variant: 'success'});
        })
    }

    disconnectEtsy(){
        disconnectEtsy().then(response => {
            if (!response.ok){
                return
            }

            this.getUserData();
            this.props.enqueueSnackbar("Etsy Store disconnected", {variant: 'success'});
        })
    }

    render() {
        return (
            <div className={'sales'}>
                {
                    this.state.loader &&
                    <BlueLoader />
                }
                {
                    !this.state.loader &&
                    <>
                        <div className={'sales-channels'}>
                            <h2>Sales Channels</h2>
                            {
                                (this.state.stores.shopify === true) &&
                                <div className={'channel shopify'} onClick={(e)=> {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}>
                                    <img src={'/images/shopify.png'} />
                                    <div className={'right'}>
                                        <span className={'title'}>{this.state.storeUrl}</span>
                                        <span className={'description'}>Shopify</span>
                                    </div>
                                    <img className={'dots'} src={'/images/dots.png'} onClick={(e)=> {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        this.setState({
                                            ...this.state,
                                            disconnectShopify : !this.state.disconnectShopify
                                        })
                                    }} />
                                    {
                                        this.state.disconnectShopify &&
                                        <span className={'disconnect'} onClick={this.disconnectShopify}>Disconnect Store</span>
                                    }
                                </div>
                            }
                            {
                                (this.state.stores.etsy === true) &&
                                <div className={'channel etsy'} onClick={(e)=> {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}>
                                    <img src={'/images/etsy.png'}/>
                                    <div className={'right'}>
                                        <span className={'title'}>{this.state.etsyStoreName}</span>
                                        <span className={'description'}>Etsy</span>
                                    </div>
                                    <img className={'dots'} src={'/images/dots.png'} onClick={(e)=> {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        this.setState({
                                            ...this.state,
                                            disconnectEtsy : !this.state.disconnectEtsy
                                        })
                                    }}/>
                                    {
                                        this.state.disconnectEtsy &&
                                        <span className={'disconnect'} onClick={this.disconnectEtsy}>Disconnect Store</span>
                                    }
                                </div>
                            }
                        </div>
                        {
                            (this.state.stores.shopify === false || this.state.stores.etsy === false) &&
                            <div className={'select-platform'}>
                                <h3>Select a Platform</h3>
                                <div className={'platforms'}>
                                    {
                                        (this.state.stores.shopify === false) &&
                                        <div className={'platform'}>
                                            <div className={'frame'} onClick={()=> {
                                                this.props.history.push('/setup-instructions');
                                            }}>
                                                <img src={'/images/shopifyB.png'}/>
                                            </div>
                                            <span>Shopify</span>
                                        </div>
                                    }
                                    {
                                        (this.state.stores.etsy === false) &&
                                        <div className={'platform'}>
                                            <div className={'frame'} onClick={()=> {
                                                this.props.history.push('/setup-instructions-etsy');
                                            }}
                                            >
                                                <img src={'/images/etsyB.png'}/>
                                            </div>
                                            <span>Etsy</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        <span className={'disclose'}>The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.</span>
                    </>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(SalesChannels)));
