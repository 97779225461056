import React from 'react';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Navigation from 'src/redesign/components/Navigation/LinkNavigation';
import MyProfile from './tabs/MyProfile';
import BusinessInformation from './tabs/BusinessInformation';
import BillingInformation from './tabs/BillingInformation';
import { getCurrentUserAction } from 'src/services/UserService';
import Loader from 'src/redesign/components/Loader';
import { getCountryList } from 'src/services/CountryService';
import { getPrimaryMethod } from 'src/services/BillingInformation';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import './style.scss';
import { setUserToLocalStorage } from 'src/base/OAuth';
import { loadUser } from 'src/actions/AuthActions';
import { connect } from 'react-redux';

const STRIPE_PROMISE = loadStripe(process.env.REACT_APP_pk_live);

const SettingsPage = ({ updateStorageUser }) => {
  const [loading, setLoading] = React.useState(false);
  const [countryOptions, setCountryOptions] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [billingInformation, setBillingInformation] = React.useState({});

  React.useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setLoading(true);
    try {
      const { data: { countries }} = await getCountryList();
      const { data: { ...billingInformation }} = await getPrimaryMethod();
      const { data } = await getCurrentUserAction();
      const image = data.image ? process.env.REACT_APP_baseUrl + data.image : '';

      setUser({ ...data, image });
      setBillingInformation(billingInformation);
      setCountries(countries);
      setCountryOptions(countries.map(x => ({ key: x.id, text: x.name })));
    } catch (error) {

    } finally {
      setLoading(false);
    }
  }

  const onUpdate = user => {
    setUser(user);
    setUserToLocalStorage(user);
    updateStorageUser();
  }

  return (
    <ApplicationPage selectedTab='settings'>
      <ApplicationPageContent title="Settings">
        {loading ? <Loader width={200} height={200} /> : (
          <div className='settings-content'>
            <Navigation tabs={[
              {
                text: 'My Profile',
                to: '/settings/my-profile',
                onRender: () => (
                  <MyProfile
                    user={user}
                    setUser={setUser}
                    countries={countries}
                    countryOptions={countryOptions}
                    onUpdate={v => onUpdate({ ...user, ...v})}
                  />
                )
              },
              {
                text: 'Business information',
                to: '/settings/business-information',
                onRender: () => (
                  <BusinessInformation
                    user={user}
                    countries={countries}
                    countryOptions={countryOptions}
                    onUpdate={v => onUpdate({ ...user, customer: { ...user.customer, business_information: v } })}
                  />
                )
              },
              {
                text: 'Billing information',
                to: '/settings/billing-information',
                onRender: () => (
                  <Elements stripe={STRIPE_PROMISE}>
                    <BillingInformation
                      billingInformation={billingInformation}
                      countries={countries}
                      countryOptions={countryOptions}
                      onUpdate={setBillingInformation}
                    />
                  </Elements>
                )
              }
            ]}
            />
          </div>
          )
        }
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateStorageUser: () => dispatch(loadUser())
  }
}

export default connect(null, mapDispatchToProps)(SettingsPage);