import React from 'react';
import { withRouter } from 'react-router';
import { setUserToLocalStorage } from 'src/base/OAuth';
import { disconnectEtsy, disconnectShopify, getCurrentUserAction } from 'src/services/UserService';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Loader from 'src/redesign/components/Loader';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Store from './components/Store';
import Dialog from 'src/redesign/components/Dialog';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const StoresPage = ({ history }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const [isEtsyConnected, setIsEtsyConnected] = React.useState(false);
  const [etsyStoreName, setEtsyStoreName] = React.useState(null);
  
  const [isShopifyConnected, setIsShopifyConnected] = React.useState(false);
  const [shopifyStoreName, setShopifyStoreName] = React.useState(null);

  const [isDisconnectDialogOpen, setIsDisconnectDialogOpen] = React.useState(undefined);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, []);

  const getIsStoreConnected = (user) => {
    const isEtsyConnected = user?.customer?.user_customer_etsy && (user?.customer?.user_customer_etsy !== null);
    const isShopifyConnected = user?.customer?.shopify_access_token && (user?.customer?.shopify_access_token !== null);

    return { isEtsyConnected, isShopifyConnected };
  }

  const load = async () => {
    setIsLoading(true);
    try {
      const { data: { user, image }} = await getCurrentUserAction();
      const { isEtsyConnected, isShopifyConnected } = getIsStoreConnected(user);

      setEtsyStoreName(isEtsyConnected ? user?.customer?.user_customer_etsy?.store_name : null);
      setShopifyStoreName(isShopifyConnected ? user?.customer?.store_url : null);
      setUserToLocalStorage({...user, image});
      setIsEtsyConnected(isEtsyConnected);
      setIsShopifyConnected(isShopifyConnected);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load stores', false);
    } finally {
      setIsLoading(false);
    }
  }

  const disconnectEtsyStore = async () => {
    try {
      await disconnectEtsy();

      load();
      openSnackBar('Etsy store disconnected');
      setIsDisconnectDialogOpen(undefined);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to disconnect Etsy store', false);
    }
  }

  const disconnectShopifyStore = async () => {
    try {
      await disconnectShopify();

      load();
      openSnackBar('Shopify store disconnected');
      setIsDisconnectDialogOpen(undefined);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to disconnect Shopify store', false);
    }
  }

  return (
    <ApplicationPage selectedTab='stores'>
      <ApplicationPageContent title='Sales Channels'>
            <div className='stores-redesign'>
            {isLoading
            ? <Loader width={300} height={300} color={colors.primary} />
            : (
              <>
              <div className='stores'>

                <Store
                  image='/images/etsy.png'
                  name={etsyStoreName}
                  connected={isEtsyConnected}
                  onClickConnect={() => history.push('/setup-instructions-etsy')}
                  onClickDisconnect={() => setIsDisconnectDialogOpen('etsy')}
                />

                <Store
                  image='/images/shopify.png'
                  name={shopifyStoreName}
                  connected={isShopifyConnected}
                  onClickConnect={() => history.push('/setup-instructions')}
                  onClickDisconnect={() => setIsDisconnectDialogOpen('shopify')}
                />

              </div>
              <div className='etsy-text'>
                The term 'Etsy' is a trademark of Etsy, Inc.
                <br />
                This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.
              </div>
              </>
              )}
              {isDisconnectDialogOpen && (
                <Dialog
                    text='Are you sure you want to deactivate your store'
                    defaultButton={{
                      text: 'Cancel',
                      onClick: () => setIsDisconnectDialogOpen(undefined)
                    }}
                    primaryButton={{
                      text: 'Deactivate',
                      onClick: () => isDisconnectDialogOpen === 'etsy' ? disconnectEtsyStore() : disconnectShopifyStore()
                    }}
                    onClose={() => setIsDisconnectDialogOpen(undefined)}
                />
              )}
            </div>
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default withRouter(StoresPage);