import {withRouter} from "react-router-dom";
import React from 'react'
import AboutProduct from "../../components/admin/AboutProduct";
import Page from "../../common/Page";
import EditeAboutProduct from "../../components/admin/EditeAboutProduct";
import ProductVariations from "../../components/admin/ProductVariations";


class EditProduct extends Page{

    constructor(props) {
        super(props);

        this.state = {
            id : this.props.match.params.id,
            page : this.props.match.params.page,
            edited : false
        };

        this.toggleActive = this.toggleActive.bind(this);
        this.edit = this.edit.bind(this);
        this.doesHaveAdminPermissions();
    }

    componentDidUpdate() {
        if (this.state.page === this.props.match.params.page){
            return
        }

        this.setState({
           ...this.state,
           page : this.props.match.params.page
        });
    }

    toggleActive(active){
        if (this.state.edited && !window.confirm('Are you sure you want to save this thing into the database?')){
            return ;
        }

        this.props.history.push('/old/products/' + this.state.id + '/' + active)
    }

    edit(e){
        this.setState({
            ...this.state,
            edited : e
        })
    }

    render() {
        return (
            <div className={'container products'}>
                <div className={'nav'}>
                    <p onClick={()=> this.toggleActive('1')} className={this.state.page == '1' ? 'active' : ''}>About Product</p>
                    <p onClick={()=> this.toggleActive('2')} className={this.state.page == '2' ? 'active' : ''}>SKUs</p>
                </div>
                {
                    this.state.page == '1' &&
                    <EditeAboutProduct edit={this.edit} id={this.state.id} />
                }
                {
                    this.state.page == '2' &&
                    <ProductVariations id={this.state.id} />
                }
            </div>
        );
    }
}

export default withRouter(EditProduct);