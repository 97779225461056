import React from 'react';
import classNames from 'classnames';
import { ButtonProps } from 'src/redesign/components/Buttons/types';

import './style.scss';

const PrimaryButton = ({ text, onClick, disabled, className, isResponsive = false }) => {
  return <button className={classNames("primary-button", className, {['primary-button-responsive']: isResponsive})} onClick={onClick} disabled={disabled}>
    {text}
  </button>
};

PrimaryButton.propTypes = ButtonProps;

export default PrimaryButton;