import React, { useState } from 'react';
import classNames from 'classnames';
import { string, func, bool, arrayOf, shape, number, any } from 'prop-types';
import { v4 as uuid } from 'uuid';
import IconArrowUp from 'src/redesign/components/Icon/Arrow/ArrowUp';

import './style.scss';

const Dropdown = ({ label, options, selectedOption, onSelect, width, height, disabled, itemClassName, className }) => {
  // state
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  // helpers
  const isSelected = selectedOption !== undefined;
  const hoverProps = { onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false)};
  // style
  const containerStyle = classNames("dropdown", {'dropdown-active': isSelected, 'dropdown-hovered': isHovered, 'dropdown-disabled': disabled}, className);
  const arrowStyle = classNames('arrow',{'arrow-active': isOpen});
  const menuStyle = classNames("dropdown-menu", {['dropdown-menu-hovered']: isHovered});
  const itemStyle = option => classNames('dropdown-item',{ 'dropdown-item-selected': selectedOption?.key === option.key}, itemClassName);
  // props
  const containerProps = {
    style: { width, height: height ?? 32 },
    className: containerStyle,
    tabIndex: "0",
    onClick: () => !disabled && setIsOpen(prev => !prev),
    onBlur: () => setIsOpen(false),
  }
  const arrowProps = {
    width: 12,
    height: 8,
    color: isSelected ? '#ffffff' : '#CBCBCB'
  }
  const itemProps = option => ({
    key: option.key,
    className: itemStyle(option),
    onClick: () => onSelect(selectedOption?.key === option.key ? undefined : option)
  })

  return (
    <div {...containerProps} {...hoverProps}>
      <div className="label"
      style={{ height: height ? (height - 15) : 15}}
      >
      {isOpen ? label : selectedOption ? selectedOption.text : label}
      <div className={arrowStyle}>
        <IconArrowUp {...arrowProps}/>
      </div>
      </div>
      {isOpen && (
        <div className={menuStyle} style={{top: height ? (height - 7) : 25}} {...hoverProps}>
          {options.map(x => <div key={uuid()} {...itemProps(x)}>{x.text}</div>)}
        </div>
      )}
    </div>
  )
};

Dropdown.propTypes = {
  label: string,
  options: arrayOf(shape({
    key: any,
    text: string,
  })),
  selectedOption: shape({
    key: any,
    text: string,
  }),
  onSelect: func,
  disabled: bool,
  width: number,
  height: number,
  itemClassName: string
}

export default Dropdown;