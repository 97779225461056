import React from 'react';
import {Button, TextField, TextareaAutosize, Checkbox} from "@material-ui/core";
import Select from 'react-select'

import strings from '../localization';
import {getError, hasError} from "../functions/Validation";
import { getImage } from '../services/ImageService';

export const DOCUMENT_TYPES = [{label: 'DESIGN TEMPLATE', value: 'DESIGN_TEMPLATE'}]

const AddDocument = ({
    onChange,
    data,
    errors,
    submited,
    goBack,
    changeType
}) => {
    const previewImage = () => {
        var oFReader = new FileReader();
        oFReader.readAsDataURL(document.getElementById("document").files[0]);

        oFReader.onload = function (oFREvent) {
            document.getElementById("uploadPreview").src = oFREvent.target.result;
        };
    };

    return(
        <div id="add-category">
            <div className="header">
            { data.id ? 'Edit Document' :'Add new document' }
                <span className={'exit'} onClick={goBack}>✕</span>
            </div>
            <div className="form">
                <div className="content">
                    <TextField
                        name='name'
                        onChange={ onChange }
                        className={"category-name"}
                        type='text'
                        value={ data.name }
                        error={ getError(errors, 'name') }
                        helperText={ getError(errors, 'name') }
                        placeholder = { "Name" }
                        variant="outlined"
                    />  
                    <div>
                    <Select
                        name="document_type"
                        options={DOCUMENT_TYPES}
                        onChange={ changeType }
                        value={data.type}
                                            />
                   <br/><br/>
                    </div>
                    <label>Upload Document </label> 
                    <input type="file" id="document" name="document" onChange={onChange} />
                    <div className="buttons">
                        <Button className="save" onClick={submited}>{ strings.category.save }</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddDocument;