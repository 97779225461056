import React from 'react';
import { Image, Layer, Rect, Stage } from 'react-konva';
import { useDropzone } from "react-dropzone";

import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import IconTrash from 'src/redesign/components/Icon/Trash';
import TransformerComponent from 'src/components/TransformerComponent';
import IconChevronLeft from 'src/redesign/components/Icon/Chevron/Left';
import IconChevronRight from 'src/redesign/components/Icon/Chevron/Right';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const IMAGE_AREA_WIDTH = 340;

const ImageArea = ({
  rectRef,
  transfRef,
  image: initialImage,
  x,
  y,
  width,
  height,
  safeX,
  safeY,
  dropzoneWidth,
  dropzoneHeight,
  safeWidth,
  safeHeight,
  realDropzoneWidth,
  realDropzoneHeight,
  updateImageDimmensions,
  selectedImageNumber,
  totalImageNumber,
  onLeftArrowClick,
  onRightArrowClick,
  isAddImageDisabled,
  onAddImage,
  fileUploadInProgress,
  onDeleteImage,
  deleteImageInProgress
}) => {

  const image = new window.Image();
  image.src = initialImage;
  
  const aspectRatio = image.width/image.height;
  image.width = IMAGE_AREA_WIDTH;
  image.height = 340/aspectRatio;
  image.style.objectFit = 'cover';
  image.style.objectPosition = 'center center';

  // dimmension calculation

  const originalSafeWidthRatio = (safeWidth / realDropzoneWidth);
  const originalSafeHeightRatio = (safeHeight / realDropzoneHeight);

  const safezoneWidth = originalSafeWidthRatio*dropzoneWidth;
  const safezoneHeight = originalSafeHeightRatio*dropzoneHeight;

  //

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onAddImage, accept: 'image/*' });

  const hasImage = initialImage != null;

  const isButtonDisabled = isAddImageDisabled || fileUploadInProgress || deleteImageInProgress;
  
  return (
    <div className='image-area-container'>
    {hasImage ? (
      <div>
        <div
          className='safe-zone'
          style={{
            position: 'absolute',
            border: '2px dashed ' + colors.primary,
            width: safezoneWidth,
            height: safezoneHeight,
            top: safeY,
            left: safeX,
            zIndex: 100,
            pointerEvents: 'none'
          }}
        />
        <Stage width={IMAGE_AREA_WIDTH} height={image.height}>
          <Layer name='layer'>
            <Image image={image} />
            <Rect
              ref={rectRef}
              width={width}
              height={height}
              x={x}
              y={y}
              shadowBlur={5}
              name='rect'
              fill='rgba(255, 255, 255, 0.2)'
              onDragEnd={({ currentTarget: { attrs: { x, y, width, height }}}) => updateImageDimmensions({ x, y, width, height })}
              onTransformEnd={({ currentTarget: { attrs: { x, y, width, height }}}) => updateImageDimmensions({ x, y , width, height })}
              draggable
            />
            <TransformerComponent
              ref={transfRef}
              state={{
                x,
                y,
                width,
                height
              }}
              selectedShapeName='rect'
            />
          </Layer>
        </Stage>
        </div>
    ) : (
      <div className='image-area-container-placeholder'/>
      )}
      <div className='edit-variation-image-navigation'>
        <div className='edit-variation-image-navigation-button' onClick={onLeftArrowClick}>
          <IconChevronLeft width={24} height={24} color={colors.primary} />
        </div>
        {selectedImageNumber}/{totalImageNumber}
        <div className='edit-variation-image-navigation-button' onClick={onRightArrowClick}>
          <IconChevronRight width={24} height={24} color={colors.primary} />
        </div>
      </div>
      <div className='edit-variation-add-delete-container'>
        <SubmitPrimaryButton text={
          <div className='edit-variation-button'>
            <div className='edit-variation-add'>+</div>
            Add
            <input {...getInputProps()} />
          </div>
        }
          width={108}
          disabled={isButtonDisabled}
          loading={fileUploadInProgress}
          {...getRootProps()}
        />
        {/* TODO: on old design not working */}
        <SecondaryButton text={
          <div className='edit-variation-button'>
            <IconTrash width={24} height={24} color={colors.red} />
            Delete 
          </div>}
          disabled={isButtonDisabled}
          onClick={onDeleteImage}
        /> 
      </div>
      </div>
  )
}

export default ImageArea;