import React from 'react';

import Menu from 'src/redesign/components/Menu';
import FullScreen from 'src/redesign/components/Icon/FullScreen';
import IconTrash from 'src/redesign/components/Icon/Trash';
import Image from 'src/redesign/components/Image';

import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ArtworkThumbnail = ({ id, name, image, type, original_width, original_height, onRemove, onFullScreen, onSelect }) => {

  return (
    <div className='artwork-thumbnail' key={id}>
      <Image alt={getImageAlt()} className='artwork-thumbnail-image' src={image} onClick={onSelect}/>
      <div className='artwork-thumbnail-title'>
        {name}
      </div>
      <div className='artwork-thumbnail-description-container'>
        <div className='artwork-thumbnail-type'>
          {type?.toUpperCase()}
        </div>
        {original_width && original_height && (
          <div className='artwork-thumbnail-dimensions'>{original_width}x{original_height}</div>
        )}
        <Menu
          width={4}
          height={14}
          className='artwork-thumbnail-menu'
          contentClassName='artwork-menu-menu'
          options={[
            {
              key: id + 'fullsize',
              text: <div className='artwork-thumbnail-menu-item'>
                View Full-size
                <FullScreen width={18} height={18} color={colors.primary}/>
              </div>,
              onSelect: onFullScreen
            },
            {
              key: id + 'delete',
              text: <div className='artwork-thumbnail-menu-item'>
                Delete
                <IconTrash width={24} height={24} color={colors.primary}/>
              </div>,
              onSelect: onRemove
            }
          ]}
        />
      </div>
    </div>
  )
}

export default ArtworkThumbnail;