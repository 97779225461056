import React from 'react';
import { number, string } from 'prop-types';

const IconTopAlignment = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 2H19.5" stroke={color} strokeOpacity="0.7"/>
    <rect x="4.5" y="5.5" width="4" height="7" stroke={color} strokeOpacity="0.7"/>
    <rect x="11.5" y="5.5" width="4" height="11" stroke={color} strokeOpacity="0.7"/>
  </svg>

);

IconTopAlignment.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconTopAlignment;