import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function createAttribute(data) {
    return await request('/api/create_attribute' , data,  HttpMethod.POST);
}
export async function getAttributeValuesForAttribute(data) {
    return await request('/api/get_attribute_values_for_attribute' , data,  HttpMethod.POST);
}
export async function getAttributeValuesByIds(data) {
    return await request('/api/get_attribute_values_by_ids' , data,  HttpMethod.POST);
}
export async function createAttributeValue(data) {
    return await request('/api/create_attribute_value' , data,  HttpMethod.POST);
}
export async function updateAttribute(data) {
    return await request('/api/update_attribute' , data,  HttpMethod.POST);
}
export async function updateAttributeValue(data) {
    return await request('/api/update_attribute_value' , data,  HttpMethod.POST);
}
export async function getAttributes(type) {
    return await request('/api/get_attributes/' + type , [],  HttpMethod.GET);
}
export async function getAttributeValues(id, page) {
    return await request('/api/get_attribute_values/' + id + '/' + page, [],  HttpMethod.GET);
}
export async function deleteAttributeValue(id) {
    return await request('/api/delete_attribute_value', {id : id},  HttpMethod.POST);
}
export async function deleteAttribute(id) {
    return await request('/api/delete_attribute/' + id, [],  HttpMethod.DELETE);
}