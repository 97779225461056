import FormComponent from "../common/FormComponent";
import {withRouter} from "react-router-dom";
import React from "react";
import {Button} from "@material-ui/core";
import {addShopifyToken, deleteShopifyCollection} from "../services/UserService";
import {refreshLocalStorage} from "../base/OAuth";

class InstallationSuccessful extends FormComponent {

    constructor() {
        super();
    }

    componentDidMount() {
        refreshLocalStorage();

        addShopifyToken({token : this.props.match.params.token}).then(response => {
            if (!response.ok){
                return;
            }

            deleteShopifyCollection({token : this.props.match.params.token}).then(response => {
                if (!response.ok){
                    return;
                }
            })
        })
    }

    render() {
        return <div className={'installation-successful'}>
            <img src={'/images/installation-succesfull.png'} />
            <h3>Connection successful</h3>
            <p>You have connected Surge Print account to Shopify store: start exporting, importing and ordering through your Store.</p>
            <Button onClick={() => this.props.history.push('/home')}>Start Designing</Button>
        </div>
    }
}


export default withRouter(InstallationSuccessful);
