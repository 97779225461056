import React from 'react';
import classNames from 'classnames';
import { ButtonFilterProps } from 'src/redesign/components/Buttons/types';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const FilterButton = ({
  text,
  count,
  color,
  filters,
  activeFilters,
  onClick
}) => {
  const isActive = 
    filters.length === 0 
    ? activeFilters.length === 0
    : filters.every(x => activeFilters.includes(x));
  return (
    <button className={classNames("filter-button", {'filter-button-active': isActive})} onClick={() =>
      onClick(isActive
        ? activeFilters.filter(x => !filters.includes(x))
        : [...activeFilters, ...filters]
      )
    } style={{ backgroundColor: isActive ? colors.primary : color }}>
      {`${text} | ${count}`}
    </button>
  )
}

FilterButton.propTypes = ButtonFilterProps;

export default FilterButton;