import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import connect from "react-redux/es/connect/connect";
import MenuState from "../constants/MenuState";
import {getUserFromLocalStorage, lock, logout} from "../base/OAuth";

class HeaderMobile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            userRole: null,
            headerActive : false
        }
    }

    componentWillMount(){
        if (getUserFromLocalStorage()){
            this.state.userRole = getUserFromLocalStorage().roles[0]
        }
    }

    /** HANDLERS **/

    handleMenuClick(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleMenuClose() {
        this.setState({ anchorEl: null });
    }

    logout() {
        logout();
        this.props.logout();
        this.props.history.push('/');
    }

    lock() {
        lock();
        this.props.history.push('/');
    }

    getHeaderClass() {

        if(this.props.menu.state === MenuState.SHORT) {
            return 'short';
        }
        else {
            return '';
        }
    }

    cart() {
        this.props.history.push('/cart');
    }

    render() {

        if (!getUserFromLocalStorage()){
            return ''
        }

        return (
            <div id="header" className={'mobile'}>
                <img onClick={() => this.props.history.push('/home')} src={'/images/fullLogo.png'} alt={'fullLogo.png'} />
                <button onClick={()=> {
                    this.props.toggleNavigation();
                }} className={this.props.headerActive ? "hamburger hamburger--collapse is-active" : "hamburger hamburger--collapse"} type="button">
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeMenuState: Actions.changeMenuState,
        logout: Actions.logout,
        toggleNavigation: Actions.toggleNavigation,
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers })
{
    return { menu: menuReducers, user: authReducers.user, headerActive : siteDataReducers.headerActive };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderMobile));