import React from 'react';
import { Field } from 'react-final-form';
import Status from '../../Status';
import IconChevronDown from '../../Icon/Chevron/Down'
import IconChevronUp from '../../Icon/Chevron/Up';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const StatusDropdown = ({ name, disabled, options }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const iconProps = {
    width: 12,
    height: 6,
    color: colors.primary
  }

  return <Field
    name={name}
    render={({ input, meta }) => {
      const { onChange, value } = input;
      return (
        <div className='status-dropdown'>
          <div className='label' onClick={() => !disabled && setIsOpen(prev => !prev)} onBlur={() => {
            setIsOpen(false)
          }}>
            <Status statusNumber={value} large isFilter={false}/>
            <div className='chevron'>
              {isOpen ? <IconChevronUp {...iconProps} /> : <IconChevronDown {...iconProps} />}
            </div>
          </div>
          {isOpen && (
          <div className='menu'>
            {options.map(x => (
              <div className='item' onClick={() => {
                onChange({ target: { value: x.key } });
                setIsOpen(false);
              }}>
                <div className='item-content'>
                  <Status statusNumber={x.key} large isFilter={false}/>
                </div>
              </div>
            ))}
          </div>
          )}
        </div>
      );
    }
  }
  />
}

export default StatusDropdown;