import React from 'react';
import { ButtonAddProps } from 'src/redesign/components/Buttons/types';

import './style.scss';

const AddButton = ({ text, onClick }) => {
  return (
    <button className="add-button" onClick={onClick}>
      {`+ ${text}`}
    </button>
  )
}

AddButton.propTypes = ButtonAddProps;

export default AddButton;