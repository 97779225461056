import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

import BasicTextInput from '../../TextInput';

import './style.scss';

const PriceInput = (props) => {
  return (
    <BasicTextInput
      {...props}
      type="number"
      labelClassName={classNames('sidepane-price-input-label', props.labelClassName)}
      className={classNames('sidepane-price-input', props.className)}
      prefix={<div className={classNames('sidepane-price-prefix', props.prefixClassName)}>$</div>}
    />
  );
}

PriceInput.propTypes = {
  className: string,
  labelClassName: string,
  prefixClassName: string
}

export default PriceInput;