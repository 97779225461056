import React from 'react';
import { oneOfType, number, string } from 'prop-types';

const IconQuote = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.4409 22.5143C38.5464 4.35867 53.4024 0.625476 59.9422 1.02834C61.5824 1.02834 71.318 2.03235 77.1459 9.51209C79.6528 12.7297 80.6646 16.8623 80.9275 20.9328C82.018 37.818 70.5422 40.7493 64.4494 41.7174C57.6886 42.7917 52.3861 38.4945 51.193 37.8231C51.0704 37.7541 50.9362 37.6903 50.7941 37.6313C49.0546 36.9101 47.0377 37.8159 46.3469 39.5676C44.9846 43.0218 45.1143 48.583 48.9394 58.2348C54.7722 72.9527 71.873 82.0037 79.6943 84.6894V94.0896C37.9366 82.9437 22.5591 45.2089 31.4409 22.5143Z" stroke={color} strokeWidth="2"/>
    <path d="M2.4253 22.5143C9.53073 4.35867 24.3868 0.625476 30.9266 1.02834C32.5668 1.02834 42.3024 2.03235 48.1302 9.51209C50.6372 12.7297 51.649 16.8623 51.9119 20.9328C53.0024 37.818 41.5265 40.7493 35.4338 41.7174C28.673 42.7917 23.3704 38.4945 22.1774 37.8231C22.0547 37.7541 21.9206 37.6903 21.7784 37.6313C20.039 36.9101 18.0221 37.8159 17.3312 39.5676C15.969 43.0218 16.0987 48.583 19.9238 58.2348C25.7566 72.9527 42.8574 82.0037 50.6786 84.6894V94.0896C8.92094 82.9437 -6.4565 45.2089 2.4253 22.5143Z" fill={color}/>
  </svg>
);

IconQuote.propTypes = {
  width: oneOfType([number, string]).isRequired,
  height: oneOfType([number, string]).isRequired,
  color: string.isRequired,
}

export default IconQuote;