import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { withRouter } from 'react-router';
import { v4 as uuid } from 'uuid';
import { changeOrderItemQuantity, changeOrderItemStatus } from 'src/services/OrderService';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Counter from 'src/redesign/components/Counter';
import ProductItem from 'src/redesign/components/ProductItem';
import Status from 'src/redesign/components/Status';
import Expandable from 'src/redesign/components/Expandable';
import TextCopy from 'src/redesign/components/TextCopy';
import StatusDropdown from 'src/redesign/components/Dropdown/StatusDropdown';
import { getUserFromLocalStorage } from 'src/base/HTTP';
import { getAvailableStatuses, getIsStatusDisabled } from 'src/redesign/helpers/form';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import OrderEnum from 'src/constants/OrderEnum';

import './style.scss';

const OrderItem = ({ match, item, onUpdate }) => {
  const orderId = match.params.id;
  const { id, status, sku, quantity, attributes, extraFields, name, price, image, externalOrderNumber, externalSkuId, weight } = item;
  const user = getUserFromLocalStorage();
  const isAdmin = user?.roles?.includes('SUPER_ADMIN');

  const { openSnackBar } = useSnackbar();

  const changeQuantity = async quantity => {
    try {
      await changeOrderItemQuantity({
        quantity,
        orderItemId: id
      });
      onUpdate({...item, quantity });
      openSnackBar('Successfully updated item quantity');
    } catch(error) {
      console.error(error);
      openSnackBar(`Failed to change quantity for order id: ${id}`, false);
    }
  }

  const changeStatus = async ({ status }) => {
    try {
      await changeOrderItemStatus({
        orderItemId: id,
        orderId,
        status,
        comment: ''
      });
      onUpdate({ ...item, status });
      openSnackBar('Successfully updated item status');
    } catch(error) {
      console.error(error);
      openSnackBar('', false);
    }
  }

  const isStatusDisabled = getIsStatusDisabled(status, isAdmin);

  return (
    <div className='order-item'>
      <div className='description'>
        <h3>
          Item {id}
        </h3>
        <h5>
         <div>External Order Id</div>
         <div>{externalOrderNumber}</div>
        </h5>
        <h4>
          <div>Status</div>
          {isStatusDisabled ? (
            <Status statusNumber={status} large/>
          ) : (
            <FinalForm onSubmit={changeStatus} initialValues={{ status }} render={({ handleSubmit, valid, submitting }) => (
              <form className='status-form' onSubmit={handleSubmit}>
                <StatusDropdown
                  name='status'
                  options={getAvailableStatuses(status, isAdmin)}
               />
                <SubmitButton className='status-submit' type='submit' text='Change' width={110} disabled={!valid || submitting} loading={submitting}/>
              </form>
            )}
            />
          )}
        </h4>
        {!isAdmin && status === OrderEnum.NeedsCustomization && (
          <h4>
              <div className='status-note'>
                Once item status is in Cancelled/ ReadyForPrint only Admin can change it. Please contact Support if you need to make a change.
              </div>
          </h4>
        )}
        <h5>
          <div>SKU</div>
          <TextCopy text={sku}/>
        </h5>
        <h5>
          <div>Quantity</div>
          {/* TODO: change quantity needs to change price on stripe, needs to be handled on backend first */}
          <Counter count={quantity} onChange={changeQuantity} disabled/>
        </h5>
        {(attributes || extraFields) && (
          <Expandable
          >
            {externalSkuId && (
              <h5>
                <div>
                  External SKU id
                </div>
                <TextCopy text={externalSkuId}/>
              </h5>
            )}

            {externalOrderNumber && (
              <h5>
                <div>
                  External order number
                </div>
                <div>
                  {externalOrderNumber}
                </div>
              </h5>
            )}
           {weight && (
              <h5>
                <div>
                  Weight
                </div>
                <div>
                  {weight}
                </div>
              </h5>
            )}
            {attributes && attributes.map(x => (
              <h5 key={uuid()}>
                <div>
                  {x.name}
                </div>
                <div>
                  {x.value}
                </div> 
              </h5>
            ))}
            {extraFields && extraFields.map(x => (
              <h5 key={uuid()}>
                <div>
                  {x.name}
                </div>
                <TextCopy text={x.value} />
              </h5>
            ))}
          </Expandable>
        )}
      </div>
      <div className='image-container'>
        <div className='title'>
          Product image
        </div>
        {/* TODO: Change design should be on the bottom of the item, for next phase */}
        <ProductItem
          name={name}
          price={`$${price}`}
          url={image}
          disableLink
        />
      </div>
    </div>
  )
}

export default withRouter(OrderItem);