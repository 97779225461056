import React from 'react';
import classNames from 'classnames';

import Loader from 'src/redesign/components/Loader';
import SelectionButton from '../SelectionButton';
import ColorAttribute from 'src/redesign/components/ColorAttribute';
import SubmitButton from 'src/redesign/components/Buttons/Submit';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import AttributeType from 'src/constants/AttributeType';

const Attributes = ({ isLoading, attributes, selectedAttributes, setSelectedAttributes, onPreviousStepClick, onStartDesigningClick, isStartDesigningInProgress }) => {

  // attributes helper functions
  const isAttributeSelected = id => selectedAttributes.some(x => x === id);
  const onAttributeClick = id => isAttributeSelected(id) ? setSelectedAttributes(prev => prev.filter(x => x !== id)) : setSelectedAttributes(prev => [...prev, id]);

  const isAllSelected = values => values.every(x => selectedAttributes.some(a => a === x.id));
  const isAllUnselected = values => values.every(x => selectedAttributes.indexOf(x.id) === -1);

  const setAllSelected = values => {
    const unselectedAttributes = values.filter(x => !isAttributeSelected(x.id));
    setSelectedAttributes(prev => [...prev, ...unselectedAttributes.map(x => x.id)]);
  };
  const unselectAll = values => setSelectedAttributes(prev => prev.filter(x => !values.some(v => v.id === x)));

  const attributesLoaded = attributes.length !== 0;

  const numberOfVariations = attributes.reduce((acc, x) => acc * x.values.filter(v => selectedAttributes.some(s => s === v.id)).length, 1);

  return (
      isLoading ? <Loader className='attributes-loader' width={100} height={100} color={colors.primary}/> : (
      <div className='product-attributes'>
        {attributes.map(({ name, values, type, colorNames }, i) => (
          <div className='atribute-container' key={i}>
            <div className='attribute-name'>
              {name}
              <SelectionButton selected={isAllSelected(values)} onClick={() => setAllSelected(values)}/>
              <SelectionButton selected={isAllUnselected(values)} selectType={false} onClick={() => unselectAll(values)}/>
            </div>
            <div className='attribute-values'>
              {values.map(({ name, id }, attributeIndex) => (
                type === AttributeType.STRING ? (
                  <div className={classNames('attribute-value', {'attribute-selected': isAttributeSelected(id)})} key={id} onClick={() => onAttributeClick(id)}>
                    {name}
                  </div>
                ) : (
                  <ColorAttribute 
                    key={id}
                    color={name}
                    colorName={colorNames[attributeIndex]}
                    selected={isAttributeSelected(id)}
                    onClick={() => onAttributeClick(id)}
                  />
                )
              ))}
            </div>
          </div>
        ))}
        {attributesLoaded && (
          <div className='attribute-buttons'>
            <button className='previous-step-button' onClick={onPreviousStepClick}>Previous step</button>
            <SubmitButton className='start-designing-button' text={`Start Designing (${numberOfVariations})`} width={172} loading={isStartDesigningInProgress} disabled={isStartDesigningInProgress} onClick={() => onStartDesigningClick(numberOfVariations)}/>
          </div>
        )}
      </div>
    )
  )
}

export default Attributes;