import React, {Component} from 'react'


class SelectMockup extends Component
{
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    isSelected(id) {
        if(!this.props.selectedMockup) {
            return this.props.mockups[0] && this.props.mockups[0].id == id
        }

        return id === this.props.selectedMockup
    }

    render() {
        return (
            <div className="select-mockup__container">
            { this.props.mockups ? 
                this.props.mockups.map((mockup)  => 
                    <div 
                    className={`select-mockup__item ${this.isSelected(mockup.id) ? 'select-mockup__item--highlighted': ''}`} 
                    onClick={() => 
                        this.props.setSelectedMockup(mockup.id)
                    }>
                        <div className="select-mockup__item__img"> 
                            <img src={process.env.REACT_APP_baseUrl + mockup.image_path}/>
                        </div>
                        <p className="select-mockup__item__title">{mockup.name}</p> 
                    </div>
                )  
                : ''}
         </div>     
        );
    }
}

export default SelectMockup;
