import React from 'react';

import Page from 'src/redesign/components/Page';
import SignupLogin from 'src/redesign/components/SignupLogin';
import Image from 'src/redesign/components/Image';

import './style.scss';

const IntegrationShopifyPage = () => (
  <Page
    seoTitle='Print on Demand Shopify Integration | Surge Print'
  >
    <div className='integration-shopify-container'>
      <div className='integration-shopify-content'>
        <Image
          alt='integration-shopify-image'
          className='integration-shopify-image'
          src='/images/integration-shopify.png'
        />
        <h1 className='integration-shopify-title'>
          Surgeprint is a print-on-demand company that offers the best prices, fast production time, and dedicated support to its clients. We have recently created a direct integration with Shopify, which allows users to seamlessly export their products from Surgeprint to Shopify and automate the order flow.
        </h1>
        <div className='integration-shopify-description'>
          With this integration, Surgeprint users can now create products and designs on Surgeprint and export them directly to their Shopify store. This means that users don't have to manually add the products to their Shopify store, which saves them time and effort.
          <br />
          <br />
          Moreover, users can also import their existing Shopify products to Surgeprint and automate the order fulfillment process. This way, users can focus on other aspects of their business, such as marketing, while Surgeprint takes care of the production and shipping.
          <br />
          <br />
          Once the product has been exported to Shopify or imported from Shopify to Surgeprint, Surgeprint handles the fulfillment of the orders automatically. This includes printing, packaging, and shipping the products to the customers. Surgeprint also has a way to automatically add tracking links to Shopify orders and change the order status to completed, which further streamlines the order fulfillment process.
          <br />
          <br />
          In addition, Surgeprint has a warehouse where users can store their garments, and Surgeprint will print or decorate them once an order is received. This is great for users who want to keep their inventory low and avoid the hassle of storing and managing the products themselves.
          <br />
          <br />
          Surgeprint also offers great discounts for bulk orders or enterprise clients, making it an affordable and reliable choice for businesses of all sizes.
          <br />
          <br />
          In conclusion, Surgeprint's integration with Shopify is a game-changer for print-on-demand businesses. With this integration, users can streamline their workflow, save time, and focus on growing their business. With fast production times, dedicated support, and affordable prices, Surgeprint is a top choice for businesses looking to expand their product offerings and boost their revenue.
        </div>
      </div>
     <SignupLogin />
    </div>
  </Page>
)

export default IntegrationShopifyPage;