import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { number, string } from 'prop-types';

import TextInput from 'src/redesign/components/Form/Sidepane/TextInput';
import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import * as Validators from 'src/redesign/util/validators';

import './style.scss';

const ProductVariationInfo = ({ name, width, height, safeWidth, safeHeight, onUpdateVariationInfo }) => {
  const onUpdateInfo = async ({
    name,
    width,
    height,
    safeWidth,
    safeHeight
  }) => {
    onUpdateVariationInfo({
      name,
      width: parseInt(width),
      height: parseInt(height),
      safeWidth: parseInt(safeWidth),
      safeHeight: parseInt(safeHeight)
    });
  }

  return (
    <FinalForm
      onSubmit={onUpdateInfo}
      initialValues={{
        name,
        width,
        height,
        safeWidth,
        safeHeight
      }}
      render={({ handleSubmit, valid, submitting}) => (
        <form onSubmit={handleSubmit} className='edit-variation-info'>
          <TextInput
            name='name'
            placeholder='Product Name'
            containerClassName='edit-variation-info-name-container'
            labelClassName='edit-variation-info-name-label'
            className='edit-variation-info-name-input'
            label='Name'
            validate={Validators.required(' ')}
          />

          <div className='edit-variation-info-section'>
            <div className='edit-variation-info-section-title'>
              Dimensions
            </div>
            <div className='edit-variation-info-section-row'>
              <TextInput
                name='height'
                placeholder='Height'
                containerClassName='edit-variation-info-input-section-container'
                labelClassName='edit-variation-info-label'
                className='edit-variation-info-input-section'
                label='Height'
                type="number"
                validate={Validators.required(' ')}
              />
              <TextInput
                name='width'
                placeholder='Width'
                containerClassName='edit-variation-info-input-section-container'
                labelClassName='edit-variation-info-label'
                className='edit-variation-info-input-section'
                label='Width'
                type="number"
                validate={Validators.required(' ')}
              />
            </div>
          </div>

          <div className='edit-variation-info-section'>
            <div className='edit-variation-info-section-title'>
              Safe area
            </div>
            <div className='edit-variation-info-section-row'>
              <TextInput
                name='safeHeight'
                placeholder='Height'
                containerClassName='edit-variation-info-input-section-container'
                labelClassName='edit-variation-info-label'
                className='edit-variation-info-input-section'
                label='Height'
                type="number"
                validate={Validators.required(' ')}
              />
              <TextInput
                name='safeWidth'
                placeholder='Width'
                containerClassName='edit-variation-info-input-section-container'
                labelClassName='edit-variation-info-label'
                className='edit-variation-info-input-section'
                label='Width'
                type="number"
                validate={Validators.required(' ')}
              />
            </div>
          </div>


          <SubmitPrimaryButton
            text='Save'
            width={224}
            className='edit-variation-price-submit'
            type='submit'
            loading={submitting}
            disabled={!valid || submitting}
          />
        </form>
      )}
    />
  )
}

ProductVariationInfo.propTypes = {
  name: string,
  height: number,
  width: number,
  safeWidth: number,
  safeHeight: number
}

export default ProductVariationInfo;