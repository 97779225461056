import React from 'react';

import { oneOfType, string, node, func, bool, number, object } from 'prop-types';
import classNames from 'classnames';
import Loader from 'src/redesign/components/Loader';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const SecondaryButton = ({ text, onClick, className, disabled, loading, width, isResponsive = false, style = { } }) => {
  return <button className={classNames("secondary-button", className, {'secondary-button-disabled': disabled, ['secondary-button-responsive']: isResponsive})} style={{ width, minWidth: width, ...style }} disabled={disabled} onClick={onClick}>
    {loading
      ? <Loader width={20} heigt={20} color={colors.primary}/>
      : text}
  </button>
};

SecondaryButton.propTypes = {
  text: oneOfType([string, node]),
  loading: bool,
  disabled: bool,
  width: number,
  onClick: func,
  style: object,
  width: number
}

export default SecondaryButton;